import { INotificationTreatment } from '../../../interfaces/notification-treatment'

export type TreatmentValidationField = 'title' | 'body' | 'landingUrl' | 'ttlSeconds'

const fieldMappings = {
    title: 'validateTitle',
    body: 'validateBody',
    landingUrl: 'validateLandingUrl',
    ttlSeconds: 'validateTtlSeconds',
}

export class NotificationDataValidator {
    public readonly values: INotificationTreatment
    protected valid: boolean = true
    protected errors: Array<{ field: string; message: string }> = []

    public constructor(values: INotificationTreatment) {
        this.values = values
    }

    public getErrors(): ReadonlyArray<{ field: string; message: string }> {
        return this.errors
    }

    public hasErrors(): boolean {
        return !this.isValid()
    }

    public isValid(): boolean {
        return this.valid
    }

    public validate(fields: TreatmentValidationField[] = Object.keys(fieldMappings) as any): boolean {
        let isValid = true

        for (const field of fields) {
            const validator = fieldMappings[field]
            if (this[validator] && !this[validator].call(this)) {
                isValid = false
            }
        }

        this.valid = isValid
        return isValid
    }

    protected validateTitle(): boolean {
        let isValid = true
        const title = this.values.title
        const field = 'title'

        if (!title) {
            isValid = false
            this.errors.push({
                field,
                message: 'Title is required',
            })
        } else {
            if (title.trim() === '') {
                isValid = false
                this.errors.push({
                    field,
                    message: 'Title cannot be empty',
                })
            }
        }

        return isValid
    }

    protected validateBody(): boolean {
        return true
    }

    protected validateLandingUrl(): boolean {
        let isValid = true
        const landingUrl = this.values.landingUrl
        const field = 'landingUrl'

        if (!landingUrl) {
            isValid = false
            this.errors.push({
                field,
                message: 'Landing URL is required',
            })
        } else {
            if (landingUrl.trim() === '') {
                isValid = false
                this.errors.push({
                    field,
                    message: 'Landing URL cannot be empty',
                })
            }
        }

        return isValid
    }

    protected validateTtlSeconds(): boolean {
        let isValid = true
        const ttlSeconds = this.values.ttlSeconds
        const field = 'ttlSeconds'

        if (!ttlSeconds) {
            isValid = false
            this.errors.push({
                field,
                message: 'Lifespan is required.',
            })
        } else {
            if (ttlSeconds.toString().trim() === '') {
                isValid = false
                this.errors.push({
                    field,
                    message: 'Lifespan cannot be empty.',
                })
            } else if (!/^[\d]+$/.test(ttlSeconds.toString())) {
                isValid = false
                this.errors.push({
                    field,
                    message: 'Lifespan must be numeric.',
                })
            }
        }

        return isValid
    }
}
