import * as React from 'react'
import { useHistory } from 'react-router-dom'
import * as queryString from 'query-string'
import { Container } from 'typescript-ioc/es5'
import { AppService, NotificationService } from '../../../services'
import { AppState } from '../../../stores/app'
import { PageHeader } from '../../../components/page-header/page-header'
import { tryParseInt } from '../../../_utils/try-parse'
import { useRefEffect } from '../../../hooks/use-ref-effect'
import {
    getCustomMacroFields,
    getDomainKeywords,
    getEnabledDeliveryChannels,
    isDeliveryChannelEnabled,
} from '../../../_utils/domain'
import NotificationsLoader from '../../../components/notification-builder/notifications-loader'
import NotificationBuilder from '../../../components/notification-builder/notification-builder'
import NotificationBuilderAside from '../../../components/notification-builder/elements/notification-builder-aside'
import NotificationBuilderReach from '../../../components/notification-builder/elements/notification-builder-reach'
import NotificationBuilderPreview from '../../../components/notification-builder/elements/notification-builder-preview'
import NotificationBuilderMain from '../../../components/notification-builder/elements/notification-builder-main'
import NotificationTestBuilder from '../../../components/notification-builder/elements/notification-test-builder'
import NotificationAudienceBuilder from '../../../components/notification-builder/elements/notification-audience-builder'
import NotificationVariantBuilder from '../../../components/notification-builder/elements/notification-variant-builder'
import { useActiveDomainChangeEffect } from '../../../hooks/use-active-domain-change-effect'
import {
    buildPreviewOptions,
    buildSubmittedNotificationPackages,
    validateSaveDraft,
} from '../../../components/notification-builder/helpers'
import { ApiVersion } from '../../../enums/api-version.enum'
import { DeliveryChannelSelector } from '../../../components/delivery-channel-selector/delivery-channel-selector'

export const SendNotification2 = () => {
    useActiveDomainChangeEffect((activeDomain, appSvc) => {
        appSvc.routeWithinDomain('/notifications')
    })

    const { push: updateRoute } = useHistory()
    const qs = queryString.parse(location.search)
    const appState: AppState = Container.get(AppState)
    const appService: AppService = Container.get(AppService)
    const notifService: NotificationService = Container.get(NotificationService)

    const domain = appState.currentDomain!
    const requestedNotifId = tryParseInt(qs.template?.toString() ?? '') || undefined
    const requestedTestId = tryParseInt(qs.test?.toString() ?? '') || undefined

    const [keywordOptions] = useRefEffect(() => getDomainKeywords(domain.id), [domain.id])
    const [customMacroOptions] = useRefEffect(() => getCustomMacroFields(domain.id), [domain.id])

    const goBackToNotifs = (isDraft: boolean) => {
        updateRoute(isDraft ? `/domains/${domain.id}/notifications/drafts` : `/domains/${domain.id}/notifications`)
    }

    const goBackToPreviousNotifsTab = () => {
        appService.routeBack()
    }

    const activeDomainChannels = getEnabledDeliveryChannels(domain, true)
    return (
        <>
            <PageHeader title="Create Notification" />

            <NotificationsLoader domainId={domain.id} notifId={requestedNotifId} testId={requestedTestId}>
                {(notifications) => {
                    return (
                        <NotificationBuilder
                            mode="create"
                            domain={domain}
                            notifications={notifications}
                            defaultSelectedId={requestedNotifId}
                        >
                            {(props) => {
                                const {
                                    builder,
                                    dispatchChanges,
                                    validateSubmit,
                                    onChannelChange,
                                    onTestChange,
                                    onAudienceChange,
                                    onDistributionChange,
                                    variants,
                                    onVariantChange,
                                    variantManager,
                                } = props
                                const activeVariant = variants[builder.selectedVariantIdx]
                                const previewOptions = buildPreviewOptions(builder.channels)

                                return (
                                    <>
                                        <NotificationBuilderAside>
                                            <NotificationBuilderReach
                                                domain={domain}
                                                builder={builder}
                                                setBuilder={dispatchChanges}
                                                hideVariantReach={true}
                                                onDistributionChange={onDistributionChange}
                                            />
                                            <NotificationBuilderPreview
                                                loading={builder.loading}
                                                domain={domain}
                                                source={activeVariant}
                                                options={previewOptions}
                                                onPreviewRequest={async (type) => {
                                                    const valid = await validateSubmit(
                                                        builder,
                                                        domain,
                                                        appState.flags,
                                                        true,
                                                    )

                                                    if (valid) {
                                                        const builds = buildSubmittedNotificationPackages(
                                                            domain,
                                                            builder,
                                                            appState.flags,
                                                            type,
                                                        )
                                                        const previewBuild = builds[builder.selectedVariantIdx]

                                                        const sent = await notifService.sendNotification(
                                                            domain.id,
                                                            previewBuild,
                                                            {
                                                                cancellationKey: `notif-send-preview`,
                                                                version: ApiVersion.V4,
                                                            },
                                                        )
                                                    }
                                                }}
                                            />
                                        </NotificationBuilderAside>

                                        <NotificationBuilderMain>
                                            {activeDomainChannels.length > 1 && (
                                                <DeliveryChannelSelector
                                                    type="multiple"
                                                    loading={builder.loading}
                                                    value={builder.channels}
                                                    onChange={onChannelChange}
                                                    visibleChannels={activeDomainChannels}
                                                />
                                            )}
                                            <NotificationTestBuilder
                                                loading={builder.loading}
                                                value={builder.test}
                                                onChange={onTestChange}
                                            />

                                            <NotificationAudienceBuilder
                                                loading={builder.loading}
                                                domain={domain}
                                                setBuilder={dispatchChanges}
                                                value={activeVariant.getAudience()}
                                                onChange={onAudienceChange}
                                                channels={builder.channels}
                                            />

                                            <NotificationVariantBuilder
                                                key={activeVariant.getId()}
                                                id={builder.selectedVariantIdx}
                                                domain={domain}
                                                builder={builder}
                                                variant={activeVariant}
                                                manager={variantManager}
                                                hideImageMacroToggle={true}
                                                onChange={onVariantChange}
                                                onCancel={goBackToPreviousNotifsTab}
                                                onSubmit={async (isDraft?: boolean) => {
                                                    let sent = false
                                                    let saved = false
                                                    if (isDraft) {
                                                        const valid = await validateSaveDraft(builder)

                                                        if (valid) {
                                                            const builds = buildSubmittedNotificationPackages(
                                                                domain,
                                                                builder,
                                                                appState.flags,
                                                            )
                                                            if (builds.length === 1) {
                                                                saved = await notifService.saveDraft(
                                                                    domain.id,
                                                                    builds[0],
                                                                    {
                                                                        cancellationKey: `notif-save-draft`,
                                                                        version: ApiVersion.V4,
                                                                    },
                                                                )
                                                            } else {
                                                                saved = await notifService.createTest(
                                                                    domain.id,
                                                                    builds[0].abTest,
                                                                    builds,
                                                                    `notif-send`,
                                                                    true,
                                                                )
                                                            }
                                                        }
                                                    } else {
                                                        const valid = await validateSubmit(
                                                            builder,
                                                            domain,
                                                            appState.flags,
                                                        )

                                                        if (valid) {
                                                            const builds = buildSubmittedNotificationPackages(
                                                                domain,
                                                                builder,
                                                                appState.flags,
                                                                undefined,
                                                                null,
                                                                true,
                                                            )

                                                            if (builds.length === 1) {
                                                                sent = await notifService.sendNotification(
                                                                    domain.id,
                                                                    builds[0],
                                                                    {
                                                                        cancellationKey: `notif-send`,
                                                                        version: ApiVersion.V4,
                                                                    },
                                                                )
                                                            } else {
                                                                sent = await notifService.createTest(
                                                                    domain.id,
                                                                    builds[0].abTest,
                                                                    builds,
                                                                    `notif-send`,
                                                                )
                                                            }
                                                        }
                                                    }

                                                    if (sent || saved) {
                                                        goBackToNotifs(saved)
                                                    }
                                                }}
                                                keywordOptions={keywordOptions.current}
                                                customMacroOptions={customMacroOptions.current}
                                            />
                                        </NotificationBuilderMain>
                                    </>
                                )
                            }}
                        </NotificationBuilder>
                    )
                }}
            </NotificationsLoader>
        </>
    )
}
