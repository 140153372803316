export const COLUMN_TABLE_ORDER = {
    DELIVERY_DOMAIN: [
        'DATE',
        'DOMAIN.ID',
        'DOMAIN.NAME',
        'CHANNEL',
        'BUTTON.ID',
        'BUTTON.TYPE',
        'BUTTON.LABEL',
        'BUTTON.LANDING_URL',
        'OPERATING_SYSTEM',
        'PLACEMENT',
        'BROWSER',
        'NOTIFICATIONS_SENT',
        'DELIVERIES',
        'DELIVERY_RATE_DECIMAL',
        'IMPRESSIONS',
        'CLICKS',
        'CTR_DECIMAL',
        'CLICKS_PER_SEND',
        'PURCHASES',
        'PURCHASE_AMOUNT',
    ],
    DELIVERY_NOTIFICATION: [
        'DATE',
        'NOTIFICATION.ID',
        'NOTIFICATION.SCHEDULE_ID',
        'CHANNEL',
        'OPERATING_SYSTEM',
        'PLACEMENT',
        'BROWSER',
        'NOTIFICATION.TITLE',
        'NOTIFICATION.BODY',
        'NOTIFICATION_SOURCE',
        'NOTIFICATION.SCHEDULED_TIME',
        'NOTIFICATION.SCHEDULED_TIME_ZONE',
        'NOTIFICATION.DELIVERY_STRATEGY',
        'NOTIFICATION.TARGETED_CHANNELS',
        'NOTIFICATION.AUDIENCES',
        'NOTIFICATION.ICON_URL',
        'NOTIFICATION.BADGE_URL',
        'NOTIFICATION.IMAGE_URL',
        'NOTIFICATION.KEYWORDS',
        'NOTIFICATION.LANDING_URL',
        'NOTIFICATION.LIFESPAN',
        'NOTIFICATION.CREATED_BY',
        'BUTTON.ID',
        'BUTTON.TYPE',
        'BUTTON.LABEL',
        'BUTTON.LANDING_URL',
        'TEST.ID',
        'TEST.NAME',
        'TEST.CREATED_BY',
        'NOTIFICATION.WEIGHT',
        'CAMPAIGN.ID',
        'CAMPAIGN.NAME',
        'STEP.ID',
        'DELIVERIES',
        'DELIVERY_RATE_DECIMAL',
        'IMPRESSIONS',
        'CLICKS',
        'CTR_DECIMAL',
        'PURCHASES',
        'PURCHASE_AMOUNT',
        'PURCHASE_RATE_DECIMAL',
    ],
    DELIVERY_TEST: [
        'DATE',
        'OPERATING_SYSTEM',
        'PLACEMENT',
        'BROWSER',
        'TEST.ID',
        'TEST.NAME',
        'CHANNEL',
        'TEST.CREATED_BY',
        'DELIVERIES',
        'DELIVERY_RATE_DECIMAL',
        'IMPRESSIONS',
        'CLICKS',
        'CTR_DECIMAL',
    ],
    DELIVERY_CAMPAIGN: [
        'DATE',
        'CAMPAIGN.ID',
        'CAMPAIGN.NAME',
        'ENTRANCES',
        'EXITS',
        'DELIVERIES',
        'DELIVERY_RATE_DECIMAL',
        'IMPRESSIONS',
        'CLICKS',
        'CTR_DECIMAL',
        'PURCHASES',
        'PURCHASE_AMOUNT',
    ],
    DELIVERY_SEGMENT: [
        'DATE',
        'SEGMENT.ID',
        'SEGMENT.NAME',
        'CHANNEL',
        'PLACEMENT',
        'DELIVERIES',
        'IMPRESSIONS',
        'DELIVERY_RATE_DECIMAL',
        'CLICKS',
        'CTR_DECIMAL',
        'CLICKS_PER_SEND',
        'NOTIFICATIONS_SENT',
        'SIZE',
        'SEGMENT_LAST_TARGETED_DATE_TZ',
    ],
    DELIVERY_KEYWORD: [
        'DATE',
        'KEYWORD',
        'CHANNEL',
        'DELIVERIES',
        'IMPRESSIONS',
        'DELIVERY_RATE_DECIMAL',
        'CLICKS',
        'CTR_DECIMAL',
        'NOTIFICATIONS_SENT',
    ],
    SUBSCRIBER_DOMAIN: [
        'DATE',
        'DOMAIN.ID',
        'DOMAIN.NAME',
        'CHANNEL',
        'OPERATING_SYSTEM',
        'PLACEMENT',
        'BROWSER',
        'PERMISSIONS_SHOWN',
        'PERMISSIONS_DISMISSED',
        'PERMISSIONS_DENIED',
        'SUBSCRIPTIONS',
        'SUBSCRIPTION_RATE_DECIMAL',
        'PROMPTS_SHOWN',
        'PROMPTS_DISMISSED',
        'PROMPTS_SHOWN_NON_UNIQUE',
        'PROMPTS_DISMISSED_NON_UNIQUE',
        'PROMPTS_ACCEPTED',
        'PROMPT_ACCEPT_RATE_DECIMAL',
    ],
    SUBSCRIBER_PROMPT: [
        'DATE',
        'PROMPT.ID',
        'PROMPT.NAME',
        'CHANNEL',
        'OPERATING_SYSTEM',
        'PLACEMENT',
        'BROWSER',
        'PROMPT.STYLE',
        'PROMPT.PRIORITY',
        'GROUP.ID',
        'GROUP.NAME',
        'TEST.ID',
        'TEST.NAME',
        'PROMPT.VISITOR_CONDITIONS',
        'PERMISSIONS_SHOWN',
        'PERMISSIONS_DISMISSED',
        'PERMISSIONS_DENIED',
        'SUBSCRIPTIONS',
        'SUBSCRIPTION_RATE_DECIMAL',
        'PROMPTS_SHOWN',
        'PROMPTS_DISMISSED',
        'PROMPTS_SHOWN_NON_UNIQUE',
        'PROMPTS_DISMISSED_NON_UNIQUE',
        'PROMPTS_ACCEPTED',
        'PROMPT_ACCEPT_RATE_DECIMAL',
    ],
    SUBSCRIBER_TEST: [
        'DATE',
        'OPERATING_SYSTEM',
        'PLACEMENT',
        'BROWSER',
        'TEST.ID',
        'TEST.NAME',
        'PERMISSIONS_SHOWN',
        'PERMISSIONS_DISMISSED',
        'PERMISSIONS_DENIED',
        'SUBSCRIPTIONS',
        'SUBSCRIPTION_RATE_DECIMAL',
        'PROMPTS_SHOWN',
        'PROMPTS_DISMISSED',
        'PROMPTS_SHOWN_NON_UNIQUE',
        'PROMPTS_DISMISSED_NON_UNIQUE',
        'PROMPTS_ACCEPTED',
        'PROMPT_ACCEPT_RATE_DECIMAL',
    ],
    MONTHLY_UNIQUES_DOMAIN: [
        'DATE',
        'DOMAIN.ID',
        'PLACEMENT',
        'CHANNEL',
        'IMPRESSIONS',
        'CLICKS',
        'CTR_DECIMAL',
        'PERMISSIONS_SHOWN',
        'SUBSCRIPTIONS',
        'SUBSCRIPTION_RATE_DECIMAL',
    ],
    PUB_NETWORK_DOMAIN: ['DATE', 'IMPRESSIONS', 'PAID_CLICKS', 'PAID_CTR_DECIMAL', 'REVENUE'],
    ATTRITION_DOMAIN: ['DATE', 'SUBSCRIBERS_LOST', 'ATTRITION_RATE_DECIMAL'],
}
