import React, { useState } from 'react'
import './domain-integration.scss'
import * as clone from 'clone'
import { Flags } from '@pushly/aquarium-utils/lib/enums/flags'
import { getClassNames } from '../../_utils/classnames'
import { useMountedRef } from '../../_utils/use-mounted-ref'
import { DomainIntegrationService } from '../../services'
import { useService } from '../../hooks/use-service'
import { useLoadableDataState } from '../../hooks/use-loadable-data-state'
import ShopifyIntegration from './shopify-integration'
import SlackIntegration from './slack-integration'
import { AppState } from '../../stores/app'
import { useRefEffect } from '../../hooks/use-ref-effect'
import { DomainIntegrationProvider } from '../../enums/domain-integration-provider'
import AmplyIntegration from './amply-integration'

interface IDomainIntegrationsManager {
    domainId: number
}

const loadIntegrations = async (
    domainId: number,
    svc: DomainIntegrationService,
    onLoaded: (integrations: any[]) => void,
) => {
    const res = await svc.fetchAll(domainId, { showLoadingScreen: true, query: { pagination: false } })
    onLoaded(res.data)
}

const filterWhere =
    <T extends {}>(where: Partial<T>) =>
    (obj: T) => {
        const keys = Object.keys(where ?? {})
        const keysMatch = keys.every((k) => Object.keys(obj).includes(k))
        return keysMatch && keys.every((k) => where[k] === obj[k])
    }

const DomainIntegrationsManager = (props: IDomainIntegrationsManager) => {
    const { domainId } = props

    const runIfMounted = useMountedRef()[1]
    const [initialDsLoaded, setInitialDsLoaded] = useState(false)
    const [integrations, setIntegrations] = useLoadableDataState<any[]>({ loading: true, data: [] })
    const { currentDomain } = useService<AppState>(AppState)
    const svc = useService<DomainIntegrationService>(DomainIntegrationService)

    const [_, refreshIntegrations] = useRefEffect(async () => {
        return new Promise((res) => {
            setIntegrations((curr) => ({ ...curr, loading: true }))

            loadIntegrations(domainId, svc, (data) =>
                runIfMounted(() => {
                    setIntegrations({
                        loading: false,
                        data,
                    })

                    res(data)

                    setInitialDsLoaded(true)
                }),
            )
        })
    }, [domainId])

    const integrationDtos = clone(integrations.data ?? [])

    const hasEcommFlag = currentDomain?.flags?.includes(Flags.domain.FEAT_DOMAIN_INTEGRATIONS_ECOMM.key)
    const hasSlackFlag = currentDomain?.flags?.includes(Flags.domain.FEAT_DOMAIN_INTEGRATIONS_SLACK.key)
    const hasPubNetFlag = currentDomain?.flags?.includes(Flags.domain.FEAT_DOMAIN_INTEGRATIONS_PUB_NETWORK.key)

    const ecommIntegrations = integrationDtos.filter(
        filterWhere({ provider: DomainIntegrationProvider.SHOPIFY, isActive: true }),
    )
    const slackIntegrations = integrationDtos.filter(filterWhere({ provider: DomainIntegrationProvider.SLACK }))
    const amplyIntegrations = integrationDtos.filter(
        filterWhere({ provider: DomainIntegrationProvider.AMPLY, isActive: true }),
    )

    return (
        <div className={getClassNames('domain-integrations-manager')}>
            <div className="integrations-list">
                {hasPubNetFlag && (
                    <div className="integrations-col ecomm-integrations">
                        <div className="integrations-col-label">Pub Network</div>

                        {!initialDsLoaded && integrations.loading ? (
                            <AmplyIntegration mode="skeleton" domainId={domainId} loading={true} />
                        ) : amplyIntegrations.length === 0 ? (
                            <AmplyIntegration
                                mode="create"
                                domainId={domainId}
                                onAdd={() => {
                                    refreshIntegrations()
                                }}
                            />
                        ) : (
                            amplyIntegrations.map((integration) => (
                                <AmplyIntegration
                                    key={integration.id}
                                    mode="display"
                                    domainId={domainId}
                                    integration={integration}
                                    onRemove={({ id }) => {
                                        refreshIntegrations()
                                    }}
                                />
                            ))
                        )}
                    </div>
                )}
                {hasSlackFlag && (
                    <div className="integrations-col slack-integrations">
                        <div className="integrations-col-label">Communications & Messengers</div>

                        {!initialDsLoaded && integrations.loading ? (
                            <SlackIntegration mode="skeleton" domainId={domainId} loading={true} />
                        ) : (
                            <>
                                <SlackIntegration
                                    mode="create"
                                    className="new-slack-integration"
                                    domainId={domainId}
                                    onAdd={() => {
                                        refreshIntegrations()
                                    }}
                                />
                                {slackIntegrations.map((integration) => (
                                    <SlackIntegration
                                        key={integration.id}
                                        mode="display"
                                        domainId={domainId}
                                        integration={integration}
                                        onRemove={({ id }) => {
                                            refreshIntegrations()
                                        }}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                )}
                <div className="integrations-col ecomm-integrations">
                    <div className="integrations-col-label">E-Commerce</div>

                    {!initialDsLoaded && integrations.loading ? (
                        <ShopifyIntegration mode="skeleton" domainId={domainId} loading={true} />
                    ) : ecommIntegrations.length === 0 ? (
                        <ShopifyIntegration
                            mode="create"
                            domainId={domainId}
                            onAdd={() => {
                                refreshIntegrations()
                            }}
                        />
                    ) : (
                        ecommIntegrations.map((integration) => (
                            <ShopifyIntegration
                                key={integration.id}
                                mode="display"
                                domainId={domainId}
                                integration={integration}
                                onRemove={({ id }) => {
                                    refreshIntegrations()
                                }}
                            />
                        ))
                    )}
                </div>
            </div>
        </div>
    )
}

export default DomainIntegrationsManager
