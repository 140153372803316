import * as React from 'react'
import { BetterComponent } from '../../../components/better-component/better-component'
import { AppState } from '../../../stores/app'
import { DomainService } from '../../../services'
import { Container } from 'typescript-ioc'
import { getClassNames } from '../../../_utils/classnames'
import clone from 'clone'
import { BetterSelect } from '../../../components/better-select/better-select'
import './style/segment-id.filter-builder.scss'
import { SegmentDto } from '../../../dtos/segment'
import { Select } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'

interface IOptionsType {
    value: number
    label: string
}

interface ISegmentFilterProps {
    value: any
    onChange: (any) => void
}

interface ISegmentFilterState {
    loading: boolean
    value?: any
    segments: IOptionsType[]
}

export class SegmentsFilterBuilder extends BetterComponent<ISegmentFilterProps, ISegmentFilterState> {
    private appState: AppState
    private domainService: DomainService

    public constructor(props: ISegmentFilterProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.domainService = Container.get(DomainService)

        this.state = {
            loading: true,
            segments: [],
            value: [],
        }
    }

    public async componentDidMount() {
        await this.loadDomainSegments()
    }

    public render(): React.ReactNode {
        return (
            <div className={getClassNames('filter', 's_id-filter')}>
                <div className={getClassNames('filter-wrapper')}>
                    <div className={getClassNames('filter-display')}>
                        <div className="filter-op">{this.currentOperatorDisplay}</div>
                        <div className="filter-val">
                            <div id="borderDisplay"></div>
                            {this.state.loading ? (
                                <Loading3QuartersOutlined
                                    size={1}
                                    spin={true}
                                    style={{ display: 'inline-flex', marginTop: '2px' }}
                                />
                            ) : (
                                <span>{this.currentValueDisplay}</span>
                            )}
                        </div>
                    </div>
                    <div className={getClassNames('filter-editor')}>
                        <div className="filter-op">
                            <BetterSelect
                                size="small"
                                disableSearch={true}
                                dropdownClassName="filter-op-dropdown"
                                options={this.segmentOperatorOptions}
                                defaultValue={['IN']}
                                value={this.currentValue.operator}
                                onChange={this.handleOpChange}
                            />
                        </div>
                        <div className="filter-val">
                            <div id="borderEdit"></div>
                            <Select
                                mode="multiple"
                                size="small"
                                className="sw-v2-segment_id-filter"
                                options={this.state.segments || []}
                                optionFilterProp="label"
                                onChange={this.handleValueChange}
                                placeholder={
                                    this.state.loading ? (
                                        <Loading3QuartersOutlined
                                            size={1}
                                            spin={true}
                                            style={{ display: 'inline-flex', marginTop: '2px' }}
                                        />
                                    ) : (
                                        'Search by Segment Name...'
                                    )
                                }
                                value={this.currentValue.value ?? []}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected get currentValue(): any {
        const propsValue: any = this.props.value || {}
        return clone({
            field: propsValue.field,
            operator: propsValue.operator || 'ANY',
            value: propsValue.value ?? this.state.value,
        })
    }

    protected get currentOperatorDisplay(): string {
        switch (this.currentValue.operator) {
            case 'IN':
                return 'is'
            case 'NOT_IN':
                return 'is not'
            case 'ANY':
                return 'contains any of'
            case 'NOT_ANY':
                return 'contains none of'
            default:
                return 'is'
        }
    }

    protected get currentValueDisplay(): string {
        let display: string = ''
        let segments: any[] = []
        segments = this.state.segments.filter((s) => this.state.value?.includes(s.value)).map((s) => s.label)

        if (segments.length < 1) {
            display = 'any segment'
        } else {
            if (segments.length <= 2) {
                display = segments.join(' or ')
            } else {
                const lastValue = segments.pop()
                display = segments.join(', ') + `, or ${lastValue}`
            }
        }

        return display
    }
    protected get initialValue(): IOptionsType[] {
        const value = this.props.value
        return this.state?.segments
            .filter((s) => value.includes(s.value))
            .map((v) => ({ value: v.value, label: v.label }))
    }

    protected get segmentOperatorOptions(): any[] {
        const options = [
            { value: 'IN', label: 'is' },
            { value: 'NOT_IN', label: 'is not' },
        ]
        if (this.currentValue.field === 'NOTIFICATION_SEGMENTS') {
            options.push(
                { value: 'ANY', label: 'contains any of' },
                { value: 'NOT_ANY', label: 'does not contain any of' },
            )
        }

        return options
    }

    protected handleOpChange = (operator: string) => {
        this.props.onChange({
            ...this.props.value,
            operator,
            field: this.currentValue.field,
        })
    }

    protected handleValueChange = async (value) => {
        await this.setState({ value })

        await this.props.onChange({
            ...this.currentValue,
            value,
        })
    }

    protected async loadDomainSegments() {
        this.setState({ loading: true })
        const { data } = await this.domainService.fetchSegmentsByDomainId(this.appState.currentDomain!.id, {
            query: {
                includeTreated: 0,
                pagination: 0,
                fields: 'id,name,source,computedStatus,isDefault,iconUrl',
                source: 'standard',
            },
            showLoadingScreen: false,
            cancellationKey: `segment_id-filter-select-d${this.appState.currentDomain!.id}`,
        })

        const multiSelectFormat = data.map((s: SegmentDto) => ({
            value: s.id,
            label: s.name,
        }))

        let value: any[] = []

        if (this.props.value) {
            value = this.props.value.value
        }

        await this.setState({ segments: multiSelectFormat, value, loading: false })
    }
}
