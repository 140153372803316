import * as React from 'react'
import { getClassNames } from '../../../_utils/classnames'
import { ICampaignWizard } from '../interfaces'
import { ConfigurationEditor } from '../../campaign-builder/configuration-editor/configuration-editor'
import {
    CampaignBuilderMode,
    CampaignExitStatus,
    CampaignType,
    NodeType,
    TriggerType,
} from '../../campaign-builder/enums'
import { getEditableState } from '../../campaign-builder/helpers/campaign'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { dateNow } from '../../../_utils/datetime'
import { simpleNotification } from '../../../_utils/utils'
import * as deepEqual from 'react-fast-compare'
import { extractCampaignTrigger } from '../../../_utils/campaigns'
import { IConfigurationFlags } from '../../campaign-builder/configuration-editor/interfaces'
import { CampaignStep } from '../../../models/campaign/campaign-step'
import { generateUID } from '../../campaign-builder/helpers/uid'
import {
    CAMPAIGN_DEFAULT_ABANDONMENT_METRIC,
    CAMPAIGN_DEFAULT_ABANDONMENT_SECONDS,
} from '../../campaign-builder/configuration-editor/constants'
import { getEnabledDeliveryChannels } from '../../../_utils/domain'
import { DomainDto } from '../../../dtos/domain'

interface ICampaignConfigurationTab extends ICampaignWizard {
    disableRouterPrompt?: boolean
}

interface IState {
    tmpValue?: CampaignModel
}

export class CampaignConfigurationTab extends React.Component<ICampaignConfigurationTab, IState> {
    public static tabName = 'configuration'
    public static tabLabel = 'Configuration'

    private formRef: any
    private unmounting: boolean = false

    public constructor(props: ICampaignConfigurationTab) {
        super(props)

        this.state = {
            tmpValue: CampaignModel.build(this.props.campaign ?? this.getInitialCampaignBuild(this.props.domain)),
        }
    }

    public componentDidUpdate(prevProps: Readonly<ICampaignConfigurationTab>) {
        if (prevProps.loading && !prevProps.campaign && this.props.campaign) {
            if (!this.unmounting) {
                this.setState({ tmpValue: CampaignModel.build(this.props.campaign) })
            }
        }
    }

    public componentWillUnmount() {
        this.unmounting = true
    }

    public render() {
        const { mode } = this.props
        const editableState = getEditableState(this.props.campaign)

        return (
            <div className={getClassNames('cwzd-config')}>
                <ConfigurationEditor
                    ref={(el) => (this.formRef = el)}
                    mode={mode}
                    domain={this.props.domain}
                    campaign={this.campaign}
                    editableState={editableState}
                    loading={this.props.loading}
                    onChange={this.handleFormChange}
                    onSubmit={this.handleSubmit as any}
                    onSave={this.handleSave as any}
                    disableSubmit={this.isSubmitDisabled}
                    disableRouterPrompt={this.props.disableRouterPrompt}
                />
            </div>
        )
    }

    protected getInitialCampaignBuild(domain: DomainDto) {
        const initialChannels = getEnabledDeliveryChannels(domain, true)

        return {
            date_start: dateNow(domain).format('YYYY-MM-DD 00:00'),
            date_time_zone: 'STZ',
            channels: initialChannels,
        }
    }

    protected get campaign(): CampaignModel {
        const campaign =
            this.state.tmpValue ??
            CampaignModel.build(this.props.campaign ?? this.getInitialCampaignBuild(this.props.domain))

        const config = campaign.getConfiguration()
        const trigger = extractCampaignTrigger(campaign)
        const isAbandonedCartTrigger = trigger?.getConfiguration()?.type === TriggerType.ABANDONED_CART
        const isAbandonedViewTrigger = trigger?.getConfiguration()?.type === TriggerType.ABANDONED_VIEW
        const isEcommAbandonedTrigger = isAbandonedCartTrigger || isAbandonedViewTrigger

        if (!isEcommAbandonedTrigger) {
            config.setAbandonmentSeconds(undefined)
            config.setAbandonmentMetric(undefined)
        } else if (campaign.getConfiguration().getAbandonmentSeconds() === undefined) {
            campaign.setConfiguration({
                ...config.serialize(),
                abandonment_seconds: CAMPAIGN_DEFAULT_ABANDONMENT_SECONDS,
                abandonment_metric: CAMPAIGN_DEFAULT_ABANDONMENT_METRIC,
            })
        }

        return campaign.clone()
    }

    protected get isSubmitDisabled(): boolean {
        const isLoading = this.props.loading ?? false
        const propsCampaign = !this.props.campaign ? undefined : CampaignModel.build(this.props.campaign)
        const localCampaign = this.campaign
        const typeIsRecurring = localCampaign.getType() === CampaignType.RECURRING
        const name = localCampaign.getName()
        const nameIsTooShort = !name || name.length < 3
        const startDateIsMissing = localCampaign.getDateStart() === undefined
        const changesDetected =
            this.props.mode === CampaignBuilderMode.CREATE ||
            !propsCampaign ||
            !deepEqual(localCampaign.serialize(), propsCampaign.serialize())

        const disabled = isLoading || nameIsTooShort || startDateIsMissing || !changesDetected

        return disabled
    }

    protected handleFormChange = async (value: CampaignModel) => {
        await this.setState({ tmpValue: value })
    }

    protected validate(campaign: CampaignModel, flags: IConfigurationFlags): { ok: boolean; error?: any } {
        const res: any = { ok: true, error: undefined }

        const config = campaign.getConfiguration()
        const audience = config.getAudience()
        const campaignType = campaign.getType()
        const isTriggeredCampaign = campaignType === CampaignType.TRIGGERED
        const isRecurringCampaign = campaignType === CampaignType.RECURRING

        const trigger = extractCampaignTrigger(campaign)
        const triggerConfig = trigger?.getConfiguration()
        const triggerType = triggerConfig?.type

        const setNotOk = (reason: string) => {
            res.ok = false
            res.error = reason
        }

        // campaign name
        if (!campaign.getName()) {
            setNotOk('Name is required.')
        }

        // run dates
        if (res.ok) {
            if (!campaign.getDateStart()) {
                setNotOk('Start date is required.')
            } else if (flags.endDateEnabled && !campaign.getDateEnd()) {
                setNotOk('End date is required when enabled.')
            }
        }

        // trigger exists and is set
        if (res.ok && (!trigger || !triggerType)) {
            // TODO: there's got to be a better message for this
            setNotOk('Please complete campaign configuration.')
        }

        const triggerParams = triggerConfig?.params
        const isSubscribedTrigger = isTriggeredCampaign && triggerType === TriggerType.SUBSCRIBED
        const isAbandonedCartTrigger = isTriggeredCampaign && triggerType === TriggerType.ABANDONED_CART
        const isAbandonedViewTrigger = isTriggeredCampaign && triggerType === TriggerType.ABANDONED_VIEW
        const isEcommAbandonedTrigger = isTriggeredCampaign && (isAbandonedCartTrigger || isAbandonedViewTrigger)

        // criteria
        if (res.ok && isTriggeredCampaign) {
            if (flags.criteriaEnabled) {
                const criteria = triggerParams?.criteria
                if (!criteria || Object.keys(criteria).length === 0) {
                    setNotOk('Please configure at least one set of Entry Criteria.')
                }
            }
        }

        if (res.ok && isEcommAbandonedTrigger) {
            if (!config.getAbandonmentSeconds()) {
                let abandonedItemName = 'cart'
                if (isAbandonedViewTrigger) {
                    abandonedItemName = 'item'
                }

                setNotOk(`Please set a valid abandoned ${abandonedItemName} time frame.`)
            }

            const itemGroups = config.getItemGroupTargeting()
            if (flags.itemGroupsEnabled && !itemGroups?.getItemGroupIds()?.length) {
                setNotOk('Please select at least one Item Group to target.')
            }
        }

        // segments
        if (res.ok && !isSubscribedTrigger) {
            const segments = audience.getSegmentIds()
            const xSegments = audience.getExcludedSegmentIds()
            if (!segments || segments.length === 0) {
                setNotOk('Please select at least one Audience segment.')
            }

            if (res.ok && xSegments && xSegments.length === 0) {
                setNotOk('Please select at least one excluded Audience segment.')
            }
        }

        // recurrence schedule
        if (res.ok && isRecurringCampaign) {
            const schedule = config.getSchedule()!
            const scheduleType = schedule.getType()
            const scheduleSuperType = scheduleType.replace(/_relative|_specific/, '')

            if (!schedule) {
                setNotOk('Recurrence schedule is required.')
            } else if (!scheduleType) {
                setNotOk('Schedule type is required.')
            } else {
                if (scheduleSuperType === 'daily' || scheduleSuperType === 'weekly') {
                    const frequency = schedule.getFrequency()
                    const allowedDays = schedule.getAllowedDays()
                    const isDaily = scheduleSuperType === 'daily'

                    if (frequency === undefined) {
                        setNotOk(
                            isDaily
                                ? 'Daily schedule frequency is required.'
                                : 'Weekly schedule frequency is required.',
                        )
                    } else if (isNaN(frequency)) {
                        setNotOk((isDaily ? 'Daily' : 'Weekly') + ' schedule frequency is not a valid number.')
                    } else if (frequency <= 0) {
                        setNotOk((isDaily ? 'Daily' : 'Weekly') + ' schedule frequency must be greater than 0.')
                    } else if (!allowedDays || allowedDays.length === 0) {
                        setNotOk(
                            isDaily
                                ? 'At least one day selection is required for the current daily schedule.'
                                : 'Day of the week is required for the current weekly schedule.',
                        )
                    }
                } else if (scheduleType === 'monthly_specific') {
                    if (!schedule.getSpecificDay()) {
                        setNotOk('Specific day of the month is required for the current schedule.')
                    }
                } else if (scheduleType === 'monthly_relative') {
                    const rType = schedule.getRelativeType()
                    const ordinal = schedule.getRelativeDayOrdinal()
                    const day = schedule.getRelativeDay()

                    if (rType === 'relative_day' && (!ordinal || !day)) {
                        setNotOk(
                            'Invalid realtive monthly schedule. Both week number and day of the week are required.',
                        )
                    }
                }

                if (res.ok && !schedule.getSpecificTime()) {
                    setNotOk('Schedule delivery time is required.')
                }

                if (res.ok && !schedule.getTimeZone()) {
                    setNotOk('Schedule delivery time zone is required.')
                }
            }
        }

        // entry cap
        const subscriberFcap = config.getSubscriberFcap()
        if (res.ok && subscriberFcap) {
            const type = subscriberFcap.getType()
            const frequency = subscriberFcap.getFrequency()

            if (type === 'once_per_criteria') {
                if (frequency === undefined) {
                    setNotOk('Subscriber frequency value is required.')
                } else if (isNaN(frequency)) {
                    setNotOk('Subscriber frequency value is not a valid number.')
                } else if (frequency <= 0) {
                    setNotOk('Subscriber frequency value must be greater than 0.')
                }
            }
        }

        // quiet hours
        if (res.ok && flags.quietHoursEnabled) {
            const quietHours = config.serialize().quiet_hours
            if (!quietHours || !quietHours.start || !quietHours.end) {
                setNotOk('Quiet hours must have a valid start time and end time.')
            }
        }

        return res
    }

    protected async ensureInitialTriggerOutStep(campaign: CampaignModel) {
        const rev = campaign.getCurrentRevision()
        const steps = rev?.getSteps()

        const trigger = extractCampaignTrigger(campaign)
        if (!trigger.getId()) {
            // string ids are used during node creation
            trigger.setId(generateUID() as any)
        }

        let exitStep = steps?.find((s) => s.getType() === NodeType.CAMPAIGN_EXIT)
        if (!exitStep) {
            exitStep = CampaignStep.build({
                id: generateUID(),
                type: NodeType.CAMPAIGN_EXIT,
                configuration: {
                    params: {
                        exit_state: CampaignExitStatus.COMPLETED,
                    },
                    in_step_id: trigger.getId(),
                },
            })

            trigger.setConfiguration({
                ...trigger.getConfiguration(),
                out_step_id: exitStep.getId(),
            })

            rev?.setSteps([...rev?.getSteps(), exitStep])
            campaign.setCurrentRevision(rev)

            // save new exit step
            await this.setState({ tmpValue: campaign })
        }
    }

    protected handleSubmit = async (_: CampaignModel, flags: IConfigurationFlags) => {
        const campaign = this.campaign
        await this.ensureInitialTriggerOutStep(campaign)

        const res = this.validate(campaign, flags)
        if (!res.ok) {
            simpleNotification('error', res)
        } else {
            const submitRes = this.props.onSubmit?.(campaign)
            if (submitRes.ok) {
                this.setState({ tmpValue: submitRes.data })
            }
        }
    }

    protected handleSave = async (_: CampaignModel, flags: IConfigurationFlags) => {
        const campaign = this.campaign
        await this.ensureInitialTriggerOutStep(campaign)

        const res = this.validate(campaign, flags)
        if (!res.ok) {
            simpleNotification('error', res)
        } else {
            const submitRes = this.props.onSave?.(campaign)
            if (submitRes.ok) {
                this.setState({ tmpValue: submitRes.data })
            }
        }
    }
}
