import * as React from 'react'
import { AsyncButton } from '../../components/async-button/async-button.component'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService, NotificationService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { RecentNotificationCard } from './recent-notification-card'
import { NotificationScheduleService } from '../../services/notification-schedule'
import { NotificationScheduleDto } from '../../features/notifications/dtos/notification-schedule-dto'
import { InsightsService } from '../../services/insights'
import './recent-notifications.scss'
import { observe } from 'mobx'
import autobind from 'autobind-decorator'
import { NoNotificationCard } from './no-notification-card'
import { ApiVersion } from '../../enums/api-version.enum'

interface IRecentNotifsProps {
    className?: string
    limit?: number
}

interface IRecentNotifsState {
    reloading: boolean
    schedules: NotificationScheduleDto[]
    page: number
}

export class RecentNotifications extends BetterComponent<IRecentNotifsProps, IRecentNotifsState> {
    public static readonly defaultNotificationLimit: number = 3

    public readonly defaultClassName: string = 'sw-mv-recent-notifications'

    protected appState: AppState
    protected appService: AppService
    protected notifService: NotificationService
    protected scheduleService: NotificationScheduleService
    protected insightsService: InsightsService

    protected disposeObservers: any[] = []

    public constructor(props: IRecentNotifsProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.notifService = Container.get(NotificationService)
        this.scheduleService = Container.get(NotificationScheduleService)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            reloading: false,
            schedules: [],
            page: 1,
        }
    }

    public UNSAFE_componentWillMount() {
        this.disposeObservers.push(observe(this.appState, 'currentDomainJsonData', () => this.fetchNotifications(true)))

        this.fetchNotifications()
    }

    public componentDidMount(): void {}

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.disposeObservers.forEach((fn) => fn())
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    {this.state.schedules.length === 0 ? (
                        <NoNotificationCard loading={this.state.reloading} />
                    ) : (
                        <>
                            <div className={this.buildClassName('list')}>
                                {this.state.schedules.map((schedule) => (
                                    <RecentNotificationCard
                                        key={schedule.id}
                                        schedule={schedule}
                                        loading={this.state.reloading}
                                        onChange={this.handleNotificationChange}
                                    />
                                ))}
                            </div>
                            <div className={this.buildClassName('load-more')}>
                                <AsyncButton onClick={this.handleLoadMore} size="small">
                                    <span>Load more</span>
                                </AsyncButton>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }

    protected get notificationLimit(): number {
        let limit = this.props.limit || RecentNotifications.defaultNotificationLimit

        if (limit >= 5) limit = 5
        if (limit <= 0) limit = RecentNotifications.defaultNotificationLimit

        return limit
    }

    @autobind
    protected async handleNotificationChange(schedule: NotificationScheduleDto): Promise<void> {
        this.fetchNotifications(true)
    }

    @autobind
    protected async handleLoadMore(): Promise<void> {
        await this.setState(({ page }) => ({ page: page + 1 }))

        return this.fetchNotifications()
    }

    @autobind
    protected async fetchNotifications(reloadReplace: boolean = false): Promise<void> {
        if (!this.appState.currentDomain) {
            setTimeout(() => this.fetchNotifications(), 100)
            return
        }

        if (reloadReplace) {
            this.setState({
                reloading: true,
            })
        }

        const opts = {
            limit: this.notificationLimit * this.state.page,
            source: 'manual,trigger,feed',
            includeSegments: true,
            status: 'COMPLETED,DELIVERING,SCHEDULED,CANCELLED',
            page: 1,
        }

        const res = await this.scheduleService.fetchAllByDomainId(this.appState.currentDomain.id, {
            query: opts,
            cancellationKey: 'mv.rnl.fetch',
            showLoadingScreen: false,
            version: ApiVersion.V3,
        })

        if (res.ok) {
            // We are acutally pulling individual schedules in this scenario
            const schedules: NotificationScheduleDto[] = !!res.data ? res.data : []

            // Save and start render of data without stats
            this.setState({
                schedules,
                reloading: false,
            })
        }
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
