import { SegmentSource } from '../enums/segment-source.enum'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export class SegmentDto {
    public static parse(fields: SegmentDto): SegmentDto {
        const dto = new SegmentDto()

        type AllowedType = keyof SegmentDto
        const fieldsToAssign: AllowedType[] = [
            'id',
            'computedStatus',
            'domainId',
            'name',
            'source',
            'isDefault',
            'topicName',
            'filtersJson',
            'treatmentSpec',
            'estimatedReach',
            'iconUrl',
            'channels',
            'group',
            'lastTargetedDateUtc',
            'createdAt',
            'createdBy',
            'createdByUserName',
            'destroyedAt',
        ]

        fieldsToAssign.forEach((key) => {
            if (fields[key] !== undefined) {
                dto[key as any] = fields[key]
            }
        })

        return dto
    }

    public id: number
    public computedStatus: string
    public domainId: number
    public name: string
    public source: SegmentSource
    public isDefault: boolean
    public topicName: string
    public iconUrl?: string
    public filtersJson: any
    public treatmentSpec: any
    public estimatedReach: number | null
    public lastTargetedDateUtc?: Date
    public channels?: DeliveryChannel[]
    public group?: any
    public createdAt: Date
    public createdBy: number
    public createdByUserName?: string
    public destroyedAt?: Date
}
