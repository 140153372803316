import React from 'react'
import clone from 'clone'
import * as deepEqual from 'react-fast-compare'
import { INotificationBuilderState } from './interfaces/notification-builder-state.interface'
import {
    isAllFeatTest,
    isSingleContent,
    isSingleDelivery,
    removeAllOtherVariants,
    resetVariantDistributionMap,
} from './helpers'
import {
    ActionDispatcher,
    DispatchActionPack,
    DispatchAfterEffect,
    OverrideBuilderStateActionPack,
    PatchAudienceActionPack,
    PatchAvailableSegmentsActionPack,
    PatchBuilderStateActionPack,
    PatchDeliveryChannelActionPack,
    PatchReachEstimateActionPack,
    PatchTestActionPack,
    PatchVariantActionPack,
} from './types'

let reducerAfterEffectDebounceTimeout: any

export function getActionDispatcher(
    dispatcher: React.Dispatch<DispatchActionPack & { afterEffect: DispatchAfterEffect }>,
    afterEffect: ((builder: INotificationBuilderState) => any) | undefined,
): ActionDispatcher {
    return (action: DispatchActionPack) => {
        dispatcher({
            ...action,
            afterEffect: (state) => {
                afterEffect?.(state)
            },
        })
    }
}

export function getBuilderStateReducer(
    state: INotificationBuilderState,
    action: DispatchActionPack & { afterEffect: DispatchAfterEffect },
) {
    // ensure clone of current state is used to prevent
    // possible active-state mutations
    let currentState = clone(state)

    let update: INotificationBuilderState
    switch (action.entity) {
        case 'root':
            update = handleRootStateReducerRequest(currentState, action)
            break
        case 'audience':
            update = handleAudienceReducerRequest(currentState, action)
            break
        case 'variant':
            update = handleVariantReducerRequest(currentState, action)
            break
        case 'test':
            update = handleTestReducerRequest(currentState, action)
            break
        case 'reach-estimate':
            update = handleReachEstimateReducerRequest(currentState, action)
            break
        case 'available-segments':
            update = handleAvailableSegmentsReducerRequest(currentState, action)
            break
        case 'channel':
            update = handleDeliveryChannelsReducerRequest(currentState, action)
            break

        default:
            const err = new Error(`Encountered unknown dispatch action: ${action}`)
            console.error(err)
            throw err
    }

    // Schedule any possible after effects
    const { afterEffect } = action
    clearTimeout(reducerAfterEffectDebounceTimeout)
    reducerAfterEffectDebounceTimeout = setTimeout(() => {
        afterEffect(update)
    }, 10)

    return update
}

function handleRootStateReducerRequest(
    state: INotificationBuilderState,
    action: OverrideBuilderStateActionPack | PatchBuilderStateActionPack,
): INotificationBuilderState {
    switch (action.type) {
        case 'put':
            state = action.data
            break

        case 'patch':
            state = {
                ...state,
                ...action.data,
            }
            break
    }

    return state
}

function handleVariantReducerRequest(
    state: INotificationBuilderState,
    action: PatchVariantActionPack,
): INotificationBuilderState {
    const variantId = action.data.id
    const next = action.data.variant
    const curr = state.variants[variantId]
    const testTypes = state.test?.getTestTypes()

    if (isSingleContent(testTypes)) {
        state.variants.forEach((v) => v.setContent(next.getContent()))
    } else {
        curr.setContent(next.getContent())
    }

    if (isSingleDelivery(testTypes)) {
        state.variants.forEach((v) => v.setDelivery(next.getDelivery()))
    } else {
        curr.setDelivery(next.getDelivery())
    }

    if (curr.getId() !== next.getId()) {
        state.variants[variantId] = state.variants[variantId].clone(false)
    }

    return state
}

function handleAudienceReducerRequest(
    state: INotificationBuilderState,
    action: PatchAudienceActionPack,
): INotificationBuilderState {
    const domain = action.data.domain
    const next = action.data.audience

    for (const variant of state.variants) {
        variant.setAudience(next)

        const content = variant.getContent().getDefaultContent()
        const segmentIcon = next.getDefaultIconUrl()
        const isNotUsingIcon = !content.getIconUrl()
        const isUsingDomainIcon = content.getIconUrl() === domain.defaultIconUrl
        const isUsingSegmentIcon = !!content.getSegmentDefaultIconUrl()

        if (segmentIcon && (isNotUsingIcon || isUsingDomainIcon || isUsingSegmentIcon)) {
            content.setIconUrl(next.getDefaultIconUrl(), true)
        } else if (!segmentIcon && isUsingSegmentIcon) {
            content.setIconUrl(domain.defaultIconUrl)
        }
    }

    return state
}

function handleTestReducerRequest(
    state: INotificationBuilderState,
    action: PatchTestActionPack,
): INotificationBuilderState {
    const next = action.data

    const prevTestTypes = state.test?.getTestTypes() ?? []
    const nextTestTypes = next?.getTestTypes() ?? []

    const sourceVariant = state.variants[state.selectedVariantIdx]
    if (!next) {
        state = removeAllOtherVariants(state.variants, state)
    }

    state.test = next

    if (next) {
        if (
            !deepEqual(prevTestTypes, nextTestTypes) &&
            ((prevTestTypes.length > 1 && !isAllFeatTest(nextTestTypes)) ||
                isSingleContent(nextTestTypes) ||
                isSingleDelivery(nextTestTypes))
        ) {
            state.variants = [sourceVariant]
            resetVariantDistributionMap(state)
        }
    }

    if (!next || !isAllFeatTest(next.getTestTypes())) {
        state.selectedVariantIdx = 0
    }

    return state
}

function handleReachEstimateReducerRequest(
    state: INotificationBuilderState,
    action: PatchReachEstimateActionPack,
): INotificationBuilderState {
    state.reachEstimateLoaded = true
    state.reachEstimate = action.data
    return state
}

function handleAvailableSegmentsReducerRequest(
    state: INotificationBuilderState,
    action: PatchAvailableSegmentsActionPack,
): INotificationBuilderState {
    state.availableSegmentsLoaded = true
    state.availableSegments = action.data
    return state
}

function handleDeliveryChannelsReducerRequest(
    state: INotificationBuilderState,
    action: PatchDeliveryChannelActionPack,
): INotificationBuilderState {
    state.channels = action.data
    return state
}
