import * as React from 'react'
import { IComponentTab, ITab, TabbedView } from '../../../components/tabbed-view/tabbed-view'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc'
import { SegmentDetailsHeader } from './segment-details-header'
import { SegmentService } from '../../../services/segment'
import { SegmentSummaryTab } from './segment-summary-tab'
import './segment-details.scss'
import { SegmentModel } from '../../../models/segments/segment.model'
import { SegmentConfigTab } from './segment-config-tab'
import { onDomainIdChange } from '../../../_utils/domain'
import { onAccountIdChange } from '../../../_utils/account'
import { getPathEntityId } from '../../../_utils/get-path-entity-id'
import { onResponseError403 } from '../../../_utils/on-response-error-403'

interface ISegmentDetailsProps {}

interface ISegmentDetailsState {
    loading: boolean
    segment?: SegmentModel
    level?: 'org' | 'domain'
}

export class SegmentDetails extends TabbedView<ISegmentDetailsProps, ISegmentDetailsState> {
    protected readonly appState: AppState
    protected readonly segmentService: SegmentService

    protected animated = false
    private disposeObservers: any

    public constructor(props: ISegmentDetailsProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.segmentService = Container.get(SegmentService)

        this.state = {
            loading: true,
            tabs: [],
        }
    }

    public async componentDidMount() {
        let pathOrgId = getPathEntityId('organization')
        let pathDomainId = getPathEntityId('domain')

        if (pathOrgId) {
            await this.setState({ level: 'org' })
        } else {
            await this.setState({ level: 'domain' })
        }

        let handleEntityIdChange = pathOrgId ? onAccountIdChange : onDomainIdChange
        this.disposeObservers = [
            handleEntityIdChange(this.appState, () => {
                // recheck entity during switch
                pathOrgId = getPathEntityId('organization')
                pathDomainId = getPathEntityId('domain')

                this.appService.routeWithin(pathOrgId ? 'org' : 'domain', '/segments')
            }),
        ]

        await this.loadRequestedSegment()
        return this.loadTabsState()
    }

    public componentWillUnmount() {
        super.componentWillUnmount()
        this.disposeObservers.forEach((f: any) => f())
    }

    public render(): React.ReactNode {
        return (
            <div className="segment-details">
                <SegmentDetailsHeader segment={this.state.segment!} />

                {this.renderTabs()}
            </div>
        )
    }

    protected getSegmentIdFromUrl(): number {
        return this.injectedProps.match.params.segmentId
    }

    protected goBackToSegmentsList() {
        this.appService.routeWithinDomain('segments')
    }

    protected async loadRequestedSegment(): Promise<void> {
        const segmentId = this.getSegmentIdFromUrl()

        if (!this.unmounting) {
            const res = await this.segmentService.fetchSegmentById(
                this.appState.currentDomain!.id,
                segmentId,
                false,
                'sd.loadRequestedSegment',
                onResponseError403(() => {
                    this.goBackToSegmentsList()
                }),
            )

            return this.setState(({ segment }) => ({
                loading: !segment,
                segment: !res ? undefined : SegmentModel.build(res),
            }))
        }
    }

    protected loadTabsState = async (): Promise<void> => {
        const tabs = [
            {
                component: SegmentSummaryTab,
                props: () => ({
                    segment: this.state.segment,
                }),
            },
            {
                component: SegmentConfigTab,
                props: () => ({
                    segment: this.state.segment,
                }),
            },
        ]

        const activeTab = this.determineActiveTab(tabs)

        return this.setState({
            tabs,
            activeTab,
        })
    }

    protected getCurrentTab(): ITab | IComponentTab<any> {
        return this.determineActiveTab(this.state.tabs) ?? this.state.activeTab
    }
}
