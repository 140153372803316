import * as React from 'react'
import { AppState } from '../../stores/app'
import { DomainService } from '../../services/index'
import { Container } from 'typescript-ioc'
import { DomainDto } from '../../dtos/domain'
import { Button, Well } from '@pushly/aqe/lib/components'
import { Skeleton } from 'antd'
import { AppleFilled, EditOutlined } from '@ant-design/icons'
import { SendIntegrationChannel } from '../../interfaces/send-integrations'
import { simpleFormErrorNotification } from '../../_utils/utils'
import * as randomstring from 'randomstring'
import { SendIntegrationsDrawer } from './send-integrations-editor/send-integrations-drawer'
import { DomainSendIntegrationModel } from '../../models/send-integration-configurations/domain-send-integration.model'
import FirebaseIcon from '../icons/firebase-icon'
import { FEAT_CHANNEL_ANDROID, FEAT_CHANNEL_IOS } from '../../constants'
import { ApnsIntegrationWell } from './apns-integration-well'
import { NativeFcmIntegrationWell } from './native-fcm-integration-well'
import { getIntegrationsValuesUpdate } from './send-integrations-editor/helpers'
import { isDeliveryChannelEnabled } from '../../_utils/domain'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface ISendIntegrationsWellProps {
    domain: DomainDto
    loading?: boolean
    title?: string
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface ISendIntegrationsWellState {
    showDrawer: boolean
    drawerKey?: string
}

export class SendIntegrationsWell extends React.Component<ISendIntegrationsWellProps, ISendIntegrationsWellState> {
    public state: ISendIntegrationsWellState = {
        showDrawer: false,
    }

    protected appState: AppState
    protected domainService: DomainService

    public constructor(props: ISendIntegrationsWellProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.domainService = Container.get(DomainService)
    }

    public render() {
        const nativeFcmLegacyFlag = this.appState?.flags.findActive('FEAT_SEND_INT_NATIVE_FCM_LEGACY')?.getKey()
        const hasNativeFcmLegacyFlagEnabled = this.props.domain?.flags.includes(nativeFcmLegacyFlag!)

        let hasWebEnabled
        let hasIOSEnabled
        let hasAndroidEnabled
        if (this.props.domain) {
            hasWebEnabled = isDeliveryChannelEnabled(this.props.domain, DeliveryChannel.WEB)
            hasIOSEnabled = isDeliveryChannelEnabled(this.props.domain, DeliveryChannel.NATIVE_IOS)
            hasAndroidEnabled = isDeliveryChannelEnabled(this.props.domain, DeliveryChannel.NATIVE_ANDROID)
        }

        return (
            <Well
                className="nested"
                title="Send Integrations"
                actions={
                    <Button disabled={this.props.loading} onClick={this.handleDrawerOpen} size="small">
                        <EditOutlined />
                        <span>Edit</span>
                    </Button>
                }
                hideFooter={true}
            >
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading && (
                        <>
                            <Well
                                title={
                                    <span>
                                        <AppleFilled style={{ marginRight: '6px' }} />
                                        <span>Apple</span>
                                    </span>
                                }
                                className="nested"
                                mode="ghost"
                                hideFooter={true}
                            >
                                {hasWebEnabled && (
                                    <ApnsIntegrationWell
                                        apnsConfiguration={this.props.domain?.apnsConfiguration}
                                        channel={SendIntegrationChannel.WEB}
                                    />
                                )}

                                {hasIOSEnabled && (
                                    <ApnsIntegrationWell
                                        apnsConfiguration={this.props.domain?.nativeApnsConfiguration}
                                        channel={SendIntegrationChannel.NATIVE}
                                    />
                                )}
                            </Well>

                            {hasAndroidEnabled && (
                                <Well
                                    mode="ghost"
                                    hideFooter={true}
                                    title={
                                        <span>
                                            <FirebaseIcon />
                                            <span>Android</span>
                                        </span>
                                    }
                                >
                                    <NativeFcmIntegrationWell fcmConfig={this.props.domain?.nativeFcmConfiguration} />
                                </Well>
                            )}
                        </>
                    )}

                    <SendIntegrationsDrawer
                        key={this.state.drawerKey}
                        visible={this.state.showDrawer}
                        onClose={this.handleDrawerClose}
                        domain={this.props.domain}
                        onSubmit={this.handleIntegrationsSubmit}
                        submitDisabled={this.submitDisabled}
                        nativeFcmLegacy={hasNativeFcmLegacyFlagEnabled}
                    />
                </Skeleton>
            </Well>
        )
    }

    protected handleDrawerOpen = async () => {
        return this.setState({
            showDrawer: true,
            drawerKey: randomstring.generate(),
        })
    }

    protected handleDrawerClose = async () => {
        return this.setState({
            showDrawer: false,
            drawerKey: undefined,
        })
    }

    protected submitDisabled = (integrations: DomainSendIntegrationModel) => {
        return Object.keys(getIntegrationsValuesUpdate(this.props.domain, integrations)).length === 0
    }

    protected handleIntegrationsSubmit = async (integrations: DomainSendIntegrationModel) => {
        const update = getIntegrationsValuesUpdate(this.props.domain, integrations)

        try {
            const res = await this.domainService.updateDomainById(this.props.domain.id, update)

            if (res.ok) {
                this.props.onDomainUpdated(res.data)

                return res
            }
        } catch (error) {
            simpleFormErrorNotification(error)
        }

        return { ok: false }
    }
}
