import * as React from 'react'
import { AppService } from '../../../services'
import { useService } from '@pushly/aqe/lib/hooks'
import { Skeleton } from 'antd'
import { PageHeader } from '@pushly/aqe/lib/components'
import moment from 'moment-timezone'
import { SegmentModel } from '../../../models/segments/segment.model'
import { numberWithCommas } from '../../../_utils/utils'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { asCaslSubject, CurrentUserCan } from 'stores/app-ability'
import { AbilityAction } from '../../../enums/ability-action.enum'
import { SubjectEntity } from '../../../enums/ability-entity.enum'
import { NoTranslate } from '../../../components/no-translate/no-translate'
import { determineChannelBadge } from '../../../components/badges/_utils'
import { FEAT_CHANNEL_ANDROID, FEAT_CHANNEL_IOS } from '../../../constants'
import { AppState } from '../../../stores/app'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface IHeaderProps {
    segment: SegmentModel
}

export const SegmentDetailsHeader = ({ segment }: IHeaderProps): JSX.Element => {
    const appService = useService(AppService)
    const appState = useService(AppState)

    const editSegmentUrl = () => {
        return appService.routeWithin('domain', `/segments/${segment.getId()}`, true)
    }

    const goToEditSegment = () => {
        const editRoute = editSegmentUrl()
        return appService.route(editRoute)
    }

    const domain = appState.currentDomain!
    const nativeIOSFlag = appState.flags.findActive(FEAT_CHANNEL_IOS)?.getKey()
    const domainHasNativeIOS = domain.flags.includes(nativeIOSFlag!)
    const nativeAndroidFlag = appState.flags.findActive(FEAT_CHANNEL_ANDROID)?.getKey()
    const domainHasNativeAndroid = domain.flags.includes(nativeAndroidFlag!)

    const viewHasNativePush =
        (domainHasNativeIOS && domain.nativeApnsConfiguration?.isActive) ||
        (domainHasNativeAndroid && domain.nativeFcmConfiguration?.isActive)

    return (
        <div className="details-header">
            <Skeleton loading={!segment} active={true}>
                {segment && (
                    <div className="segment-title">
                        <PageHeader
                            title={
                                <div className="primary-title">
                                    <div>{segment.getName()}</div>

                                    <CurrentUserCan
                                        do={AbilityAction.UPDATE}
                                        on={asCaslSubject(SubjectEntity.SEGMENT, segment)}
                                    >
                                        <AsyncButton
                                            className="ant-btn-primary"
                                            onClick={() => goToEditSegment()}
                                            altHref={editSegmentUrl()}
                                        >
                                            <span>Edit Segment</span>
                                        </AsyncButton>
                                    </CurrentUserCan>
                                </div>
                            }
                            append={
                                <span>
                                    #{segment.getId()} | Created by{' '}
                                    <NoTranslate>{segment.getCreatedByUserName()}</NoTranslate> |{' '}
                                    {segment
                                        .getChannels()
                                        .filter((val) => (!viewHasNativePush ? val === DeliveryChannel.WEB : true))
                                        .map((ch) => (
                                            <span key={ch} className="segment-title-channel-append">
                                                {determineChannelBadge(ch)}
                                            </span>
                                        ))}
                                </span>
                            }
                            onTitleSet={appService.customizeTabTitle}
                        />
                        <div>
                            <span>
                                <b>Segment Size</b> {numberWithCommas(segment.getEstimatedReach())}
                            </span>
                        </div>
                        <div>
                            <span>
                                <b>Last Targeted</b>{' '}
                                {segment.getLastTargetedDateUtc()
                                    ? moment(segment.getLastTargetedDateUtc()).format('dddd MMMM D, YYYY')
                                    : 'Not Yet Targeted'}
                            </span>
                        </div>
                    </div>
                )}
            </Skeleton>
        </div>
    )
}
