import * as React from 'react'
import './simple-timeframe.scss'
import { Input, Select } from 'antd'
import { getClassNames } from '../../_utils/classnames'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

type TimeframeMetric = 'days' | 'hours' | 'minutes' | 'seconds'

export function calculateRawSecondsFromValue(value: number, metric: TimeframeMetric): number {
    let computed = value

    switch (metric) {
        case 'minutes':
            computed = computed * 60
            break
        case 'hours':
            computed = computed * 60 * 60
            break
        case 'days':
            computed = computed * 60 * 60 * 24
            break
    }

    return computed
}

export function calculateValueFromRawSeconds(value: number, metric: TimeframeMetric): number {
    let computed = value

    switch (metric) {
        case 'minutes':
            computed = computed / 60
            break
        case 'hours':
            computed = computed / 60 / 60
            break
        case 'days':
            computed = computed / 24 / 60 / 60
            break
    }

    return computed
}

interface ISimpleTimeframeValue {
    seconds: number
    metric: string | TimeframeMetric
}

interface ISimpleTimeframe {
    value?: ISimpleTimeframeValue
    defaultValue?: ISimpleTimeframeValue
    onChange?: (value: ISimpleTimeframeValue) => any
    disabledMetrics?: TimeframeMetric[]
    size?: SizeType
}

interface IState {
    timespanInputValue?: any
    value?: ISimpleTimeframeValue
}

export class SimpleTimeframe extends React.Component<ISimpleTimeframe, IState> {
    public state: IState = {}

    public render() {
        const { timespanInputValue } = this.state
        const value = this.getComputedValue()
        const calculatedValue = calculateValueFromRawSeconds(value.seconds, value.metric as TimeframeMetric)
        const size = this.props.size ?? 'middle'

        const allMetrics = ['seconds', 'minutes', 'hours', 'days'] as TimeframeMetric[]
        const disabledMetrics = this.props.disabledMetrics ?? ([] as TimeframeMetric[])
        const visibleMetrics = allMetrics.filter((m) => !disabledMetrics.includes(m))

        return (
            <div className={getClassNames('simple-timeframe')}>
                <Input
                    className="timeframe-value"
                    type="number"
                    size={size}
                    autoComplete="off"
                    value={!!this.props.value ? calculatedValue : timespanInputValue ?? calculatedValue}
                    onChange={this.handleCalculatedValueChange}
                    addonAfter={
                        <Select
                            className="timeframe-metric"
                            size={size}
                            value={value.metric}
                            onChange={this.handleMetricChange}
                        >
                            {visibleMetrics.map((m) => (
                                <Select.Option key={m} value={m}>
                                    {m.replace(/s$/, '(s)')}
                                </Select.Option>
                            ))}
                        </Select>
                    }
                />
            </div>
        )
    }

    protected getComputedValue(): ISimpleTimeframeValue {
        const { value, defaultValue } = this.props
        const { value: stateValue } = this.state

        return {
            seconds: value?.seconds ?? stateValue?.seconds ?? defaultValue?.seconds ?? 0,

            metric: value?.metric ?? stateValue?.metric ?? defaultValue?.metric ?? 'minutes',
        }
    }

    protected handleCalculatedValueChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { onChange } = this.props
        const value = this.getComputedValue()

        const timespanInputValue = ev.target.value
        value.seconds = calculateRawSecondsFromValue(timespanInputValue as any, value.metric as TimeframeMetric)

        this.setState({ timespanInputValue, value })
        onChange?.(value)
    }

    protected handleMetricChange = (metric: string): void => {
        const { onChange } = this.props
        const value = this.getComputedValue()

        const valueFromCurrentSeconds = calculateValueFromRawSeconds(value.seconds, value.metric as TimeframeMetric)
        const newSeconds = calculateRawSecondsFromValue(valueFromCurrentSeconds, metric as TimeframeMetric)

        value.metric = metric
        value.seconds = newSeconds

        this.setState({ value })
        onChange?.(value)
    }
}
