import { RefreshTimeout } from './enums'

export const REFRESH_OPTIONS: { value: number; label: string }[] = [
    { value: RefreshTimeout.FIVE_SECONDS, label: '5s' },
    { value: RefreshTimeout.TEN_SECONDS, label: '10s' },
    { value: RefreshTimeout.FIFTEEN_SECONDS, label: '15s' },
    { value: RefreshTimeout.THIRTY_SECONDS, label: '30s' },
    { value: RefreshTimeout.ONE_MINUTE, label: '1m' },
    { value: RefreshTimeout.FIVE_MINUTES, label: '5m' },
    { value: RefreshTimeout.TEN_MINUTES, label: '10m' },
    { value: RefreshTimeout.FIFTEEN_MINUTES, label: '15m' },
]
