import React from 'react'
import { SegmentBuilderContext } from './segment-builder-context'
import { Form, Popover, Select, Modal } from 'antd'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppState } from '../../stores/app'
import { domainOptionsSort } from './helpers'
import { equalArrays } from '../../_utils/array'
import DomainAudienceSelect, { DomainSelectorOption } from '../domain-audience-select/domain-audience-select'

/**
 * If closing/blur check for value change and prompt user.
 * Cancelling the change should reset to selection context.
 *
 * If no rules have been added - skip the confirm and emit the change
 */
function handleDomainSelectionChanges(
    prevDomainIds: number[],
    currDomainIds: number[],
    filters: any,
    onOk: (reset: boolean) => void,
    onCancel: () => void,
) {
    const currDomainIdsStillValid = currDomainIds.every((id) => prevDomainIds.includes(id))
    const hasAnd = Array.isArray(filters?.and) && filters.and.length > 0
    const orClause = !hasAnd ? null : filters.and.find((r) => r.or)?.or
    const hasOr = Array.isArray(orClause) && orClause.length > 0

    if (currDomainIdsStillValid || !(hasAnd && hasOr)) {
        onOk(false)
    } else {
    }
}

interface IOrgDetailsBuilderProps {
    onChange: (domainIds: number[], requiresValidation: boolean) => void
}

const SegmentOrgDetailsBuilder = (props: IOrgDetailsBuilderProps) => {
    const context = React.useContext(SegmentBuilderContext)
    const appState = useService(AppState)

    const propsSelections: number[] = []

    let domainSelectorOptions: DomainSelectorOption[] = []
    if (context.level === 'org' && appState.currentUserDomains) {
        domainSelectorOptions = appState.currentUserDomains
            .filter((d) => d.accountId === context.org.id)
            .map((d) => ({ value: d.id, label: d.displayName, title: d.displayName }))
        domainSelectorOptions.sort(domainOptionsSort)

        domainSelectorOptions.forEach((opt) => {
            if (context.selectedDomainIds.includes(opt.value)) {
                propsSelections.push(opt.value)
            }
        })
    }

    const [currSelections, setCurrSelections] = React.useState(propsSelections)
    const detailsDependenciesLoaded = context.levelDependenciesLoaded && context.segmentDependenciesLoaded
    const canRender = context.level === 'org' && detailsDependenciesLoaded

    if (context.level === 'org') {
        React.useEffect(() => {
            if (!equalArrays(context.selectedDomainIds, currSelections)) {
                setCurrSelections(context.selectedDomainIds)
            }
        }, [context.selectedDomainIds])
    }

    return !canRender ? null : (
        <Form.Item label="Domains" name="domains">
            <div>
                <DomainAudienceSelect
                    value={currSelections}
                    domainSelectorOptions={domainSelectorOptions}
                    onChange={(selections: number[]) => setCurrSelections(selections)}
                    onClear={() => props.onChange([], false)}
                    onDeselect={(selection: number) => {
                        const currValue = Array.from(currSelections)
                        currValue.splice(
                            currValue.findIndex((v) => v === selection),
                            1,
                        )
                        props.onChange(currValue, true)
                    }}
                    onDropdownVisibleChange={(open) => {
                        if (!open && !equalArrays(currSelections, propsSelections)) {
                            const requiresValidation = propsSelections.some((id) => !currSelections.includes(id))
                            props.onChange(currSelections, requiresValidation)
                        }
                    }}
                    handleDomainIdsChange={props.onChange}
                    domainIdChangeRequiresValidation={true}
                />
            </div>
        </Form.Item>
    )
}

export default SegmentOrgDetailsBuilder
