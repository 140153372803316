import * as React from 'react'
import { Well } from '../../well/well'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { NdfFormMode } from '../enums/ndf-form-mode.enum'
import { NdfFormTheme } from '../enums/ndf-form-theme.enum'
import { CloseOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Skeleton, Switch, Tooltip } from 'antd'
import autobind from 'autobind-decorator'
import { preventAll, simpleNotification } from '../../../_utils/utils'
import { type INotificationTreatment } from '../../../interfaces/notification-treatment'
import * as clone from 'clone'
import { DomainKeyword } from '../../../dtos/domain'
import { NotificationDataMessage } from '../../../features/notifications/notification-data-form/notification-data-message'
import * as randomstring from 'randomstring'
import { INdfSectionValidationResponse } from '../interfaces/ndf-section-validation-response'
import {
    NotificationDataValidator,
    TreatmentValidationField,
} from '../../../features/notifications/notification-data-form/notification-data-validator'
import { INdfAudienceValue } from '../interfaces/ndf-audience-value'
import { SegmentDto } from '../../../dtos/segment'

type NdfTreatmentSectionChangeEvent = (treatment: INotificationTreatment, idx: number) => any

interface INdfTreatmentSectionProps {
    className?: string
    mode: NdfFormMode
    theme: NdfFormTheme
    value?: INotificationTreatment
    onChange: NdfTreatmentSectionChangeEvent
    onAdd: NdfTreatmentSectionChangeEvent
    onSelect: NdfTreatmentSectionChangeEvent
    onDelete: NdfTreatmentSectionChangeEvent

    disableAb?: boolean
    abToggleChecked?: boolean
    onAbToggleChange?: (enabled: boolean) => any
    restrictToSingle?: boolean
    restrictToVariant?: boolean

    keywordOptions?: DomainKeyword[]
    treatmentOptions?: INotificationTreatment[]
    loading?: boolean
    deliveryInProgress?: boolean

    formActions?: {
        onSubmit?: () => any
        onCancel?: () => any
        submitDisabled?: boolean
    }

    showEcommElements?: boolean

    audience: INdfAudienceValue
    audienceOptions: SegmentDto[]
    abActions?: React.ReactNode
}

export class TreatmentSection extends React.Component<INdfTreatmentSectionProps, any> {
    public readonly defaultClassName: string = 'sw-v2-ndf-section'

    protected appState: AppState

    public constructor(props: INdfTreatmentSectionProps) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render(): React.ReactNode {
        const wellProps: any = {
            showFooter: false,
        }

        if (!!this.props.formActions) {
            wellProps.showFooter = !this.isLoading
            wellProps.onSubmit = this.props.formActions.onSubmit
            wellProps.onCancel = this.props.formActions.onCancel
            wellProps.submitDisabled = this.props.formActions.submitDisabled

            if (this.currentTheme === NdfFormTheme.CAMPAIGN) {
                wellProps.showCancel = false
                wellProps.submitText = 'Save & Continue'
            }
        }

        return (
            <div className={this.buildRootClassNames('treatment')}>
                <div className={this.buildClassName('wrapper')}>
                    <Well
                        title="Message"
                        showFooter={false}
                        action={this.buildAbActions()}
                        headerExtension={this.buildAbSelection()}
                        {...wellProps}
                    >
                        <Skeleton
                            active={true}
                            title={false}
                            avatar={false}
                            paragraph={{ rows: 2 }}
                            loading={this.props.loading}
                        >
                            <div>
                                <NotificationDataMessage
                                    key={
                                        this.currentMode === NdfFormMode.CREATE
                                            ? this.appState.currentDomain?.id.toString()
                                            : this.currentValue.id
                                    }
                                    mode={this.currentMode}
                                    theme={this.currentTheme}
                                    treatment={this.currentValue}
                                    errors={this.currentValue.errors}
                                    domainKeywords={this.keywordOptions}
                                    defaultDomainIconUrl={this.currentDomainDefaultIconUrl}
                                    defaultSegmentIconUrl={this.currentSegmentDefaultIconUrl}
                                    onChange={this.handleChange}
                                    isWelcomeNotificationForm={false}
                                    showEcommElements={this.props.showEcommElements}
                                />
                            </div>
                        </Skeleton>
                    </Well>
                </div>
            </div>
        )
    }

    public validate(): INdfSectionValidationResponse {
        const response: INdfSectionValidationResponse = { ok: true }
        const value = this.currentValue

        const fieldsToValidate: TreatmentValidationField[] = ['title', 'body', 'ttlSeconds']
        if (!value.landingUrlDisabled) {
            fieldsToValidate.push('landingUrl')
        }

        const validator = new NotificationDataValidator(value)
        validator.validate(fieldsToValidate)

        response.ok = validator.isValid()

        if (validator.hasErrors()) {
            response.error = validator
                .getErrors()
                .map((err) => err.message)
                .join('. ')
        }

        return response
    }

    protected get isLoading(): boolean {
        return this.props.loading || false
    }

    protected get currentMode(): NdfFormMode {
        return this.props.mode
    }

    protected get currentTheme(): NdfFormTheme {
        return this.props.theme
    }

    protected get defaultTreatment(): INotificationTreatment {
        return {
            id: randomstring.generate(),
        }
    }

    protected get currentValue(): INotificationTreatment {
        return clone(this.props.value || this.defaultTreatment)
    }

    protected get currentDomainDefaultIconUrl(): string | undefined {
        return !!this.appState.currentDomain ? this.appState.currentDomain.defaultIconUrl : ''
    }

    protected get currentSegmentDefaultIconUrl(): string | undefined {
        const domainSegments = this.props.audienceOptions || []
        const includedSegmentIds = this.props.audience.includedSegmentIds

        let iconUrl

        if (!!domainSegments && !!includedSegmentIds && includedSegmentIds.length === 1) {
            const selectedSegment = domainSegments.find((seg: SegmentDto) => seg.id === includedSegmentIds[0])

            if (selectedSegment) {
                iconUrl = selectedSegment.iconUrl
            }
        }

        return iconUrl
    }

    protected get deliveryInProgress(): boolean {
        return this.props.deliveryInProgress || false
    }

    protected get abDisabled(): boolean {
        return this.props.disableAb || false
    }

    protected get showAbToggle(): boolean {
        return !this.abDisabled
    }

    protected get abToggleDisabled(): boolean {
        return this.abDisabled || this.deliveryInProgress || this.restrictToSingle || this.restrictToVariant
    }

    protected get abToggleChecked(): boolean {
        return this.props.abToggleChecked || false
    }

    protected get restrictToSingle(): boolean {
        return this.props.restrictToSingle || false
    }

    protected get restrictToVariant(): boolean {
        return this.props.restrictToVariant || false
    }

    protected get keywordOptions(): DomainKeyword[] {
        return this.props.keywordOptions || []
    }

    protected get treatmentOptions(): INotificationTreatment[] {
        return this.props.treatmentOptions || []
    }

    protected get currentTreatmentIdx(): number {
        return this.treatmentOptions.findIndex((t) => t.id === this.currentValue.id)
    }

    protected buildAbActions(): React.ReactNode {
        if (!this.showAbToggle) return

        return (
            <Form.Item>
                <div className="abenable-action-toggle">
                    <div className="abenable-left">
                        <span className="well-title">Testing</span>
                    </div>
                    <div className="abenable-right">
                        <Switch
                            onChange={this.handleAbEnabledChange}
                            checked={this.abToggleChecked}
                            disabled={this.isLoading || this.abToggleDisabled}
                        />
                        {(this.restrictToVariant || this.restrictToSingle) && (
                            <Tooltip
                                title={
                                    this.restrictToVariant
                                        ? 'A Notification Test cannot be changed to a singular Notification.'
                                        : 'A single Notification cannot be changed to a Notification Test.'
                                }
                            >
                                <ExclamationCircleOutlined className="info-icon ab-disabled-info" />
                            </Tooltip>
                        )}
                    </div>
                </div>
            </Form.Item>
        )
    }

    protected buildAbSelection(): React.ReactNode {
        if (this.abDisabled) return

        const treatments = this.treatmentOptions

        const hasMaxTreatments = treatments.length >= 4
        const hasMultipleTreatments = treatments.length > 1

        return (
            this.abToggleChecked && (
                <div className="well-tab-list">
                    {treatments.map((treatment, idx) => {
                        const name = ['A', 'B', 'C', 'D', 'E'][idx]
                        const isCurrentTreatment = idx === this.currentTreatmentIdx
                        const handleTreatmentSelect = this.handleSelect.bind(this, treatment, idx)
                        const handleTreatmentDelete = this.handleDelete.bind(this, treatment, idx)

                        return (
                            <div
                                key={name}
                                className={`well-tab${isCurrentTreatment ? ' active' : ''}`}
                                onClick={handleTreatmentSelect}
                            >
                                {hasMultipleTreatments && !this.deliveryInProgress && (
                                    <span className="well-tab-close" onClick={handleTreatmentDelete}>
                                        <CloseOutlined />
                                    </span>
                                )}
                                <span>Variant {name}</span>
                            </div>
                        )
                    })}
                    {!hasMaxTreatments && !this.deliveryInProgress && (
                        <div className="well-tab well-tab-new" onClick={this.handleAdd}>
                            <span>
                                <PlusOutlined />
                            </span>
                        </div>
                    )}
                </div>
            )
        )
    }

    @autobind
    protected handleChange(value: INotificationTreatment): void {
        this.emitChange(value)
    }

    @autobind
    protected async handleAdd(): Promise<void> {
        // Validate current treatment before changing selection
        const validation = await this.validate()

        if (validation.ok) {
            this.props.onAdd(this.currentValue, this.currentTreatmentIdx)
        } else {
            simpleNotification('error', validation.error)
        }
    }

    @autobind
    protected async handleDelete(
        treatment: INotificationTreatment,
        idx: number,
        ev?: React.MouseEvent<HTMLElement>,
    ): Promise<void> {
        preventAll(ev)

        this.props.onDelete(treatment, idx)
    }

    @autobind
    protected async handleSelect(
        treatment: INotificationTreatment,
        idx: number,
        ev?: React.MouseEvent<HTMLElement>,
    ): Promise<void> {
        preventAll(ev)

        // Validate current treatment before changing selection
        const validation = await this.validate()

        if (validation.ok) {
            this.props.onSelect(treatment, idx)
        } else {
            simpleNotification('error', validation.error)
        }
    }

    @autobind
    protected async handleAbEnabledChange(enabled: boolean): Promise<void> {
        if (this.props.onAbToggleChange) this.props.onAbToggleChange(enabled)
    }

    protected emitChange(changes: Partial<INotificationTreatment>): void {
        this.props.onChange(
            {
                ...this.currentValue,
                ...changes,
            },
            this.currentTreatmentIdx,
        )
    }

    protected buildClassName(className: string, extras?: string): string {
        className = `${this.defaultClassName}-${className}`
        if (!!extras) className = `${className} ${extras}`
        return className
    }

    protected buildRootClassNames(sectionName: string): string {
        const classNames: string[] = [
            this.defaultClassName,
            `section-${sectionName}`,
            `theme-${this.currentTheme.toLowerCase()}`,
            `mode-${this.currentMode.toLowerCase()}`,
        ]

        if (!!this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
