export interface IDropdownOption {
    value: string
    label: string
    default?: boolean
    condition?: (type: string, level: string, breakdown: string) => boolean
}

export interface IDropdownGroup {
    label: string
    options: IDropdownOption[]
}

export type DropdownMapping = Record<string, IDropdownGroup[]>

function ensureBreakdown(bd: string) {
    return (type, level, breakdown) => {
        return breakdown === bd
    }
}

export const COLUMN_DROPDOWN_OPTIONS: DropdownMapping = {
    DELIVERY_DOMAIN: [
        {
            label: 'Dimensions',
            options: [
                { value: 'BUTTON.ID', label: 'Button ID', condition: ensureBreakdown('BUTTON') },
                { value: 'BUTTON.TYPE', label: 'Button Type', condition: ensureBreakdown('BUTTON') },
                { value: 'BUTTON.LABEL', label: 'Button Label', condition: ensureBreakdown('BUTTON') },
                { value: 'BUTTON.LANDING_URL', label: 'Button URL', condition: ensureBreakdown('BUTTON') },
            ],
        },
        {
            label: 'Measures',
            options: [
                { value: 'CLICKS', label: 'Clicks', default: true },
                { value: 'CTR', label: 'CTR', default: true },
                { value: 'DELIVERIES', label: 'Deliveries' },
                { value: 'DELIVERY_RATE', label: 'Impression Rate' },
                { value: 'IMPRESSIONS', label: 'Impressions', default: true },
                { value: 'NOTIFICATIONS_SENT', label: 'Notifications Sent' },
                { value: 'CPS', label: 'Clicks Per Send (CPS)' },
                { value: 'PURCHASES', label: 'Purchases' },
                { value: 'PURCHASE_AMOUNT', label: 'Purchase Amount' },
            ],
        },
    ],
    DELIVERY_NOTIFICATION: [
        {
            label: 'Dimensions',
            options: [
                { value: 'NOTIFICATION.AUDIENCES', label: 'Audiences', default: true },
                { value: 'NOTIFICATION.BADGE_URL', label: 'Badge' },
                { value: 'NOTIFICATION.BODY', label: 'Body', default: true },
                { value: 'BUTTON.ID', label: 'Button ID', condition: ensureBreakdown('BUTTON') },
                { value: 'BUTTON.TYPE', label: 'Button Type', condition: ensureBreakdown('BUTTON') },
                { value: 'BUTTON.LABEL', label: 'Button Label', condition: ensureBreakdown('BUTTON') },
                { value: 'BUTTON.LANDING_URL', label: 'Button URL', condition: ensureBreakdown('BUTTON') },
                { value: 'CAMPAIGN.ID', label: 'Campaign ID' },
                { value: 'CAMPAIGN.NAME', label: 'Campaign Name' },
                { value: 'STEP.ID', label: 'Campaign Step ID' },
                { value: 'NOTIFICATION.CREATED_BY', label: 'Created By' },
                { value: 'NOTIFICATION.DELIVERY_STRATEGY', label: 'Delivery Strategy' },
                { value: 'NOTIFICATION.ICON_URL', label: 'Icon' },
                { value: 'NOTIFICATION.IMAGE_URL', label: 'Image', default: true },
                { value: 'NOTIFICATION.KEYWORDS', label: 'Keywords' },
                { value: 'NOTIFICATION.LANDING_URL', label: 'Landing URL' },
                { value: 'NOTIFICATION.LIFESPAN', label: 'Lifespan' },
                { value: 'NOTIFICATION.SCHEDULED_TIME', label: 'Scheduled Time', default: true },
                { value: 'NOTIFICATION_SOURCE', label: 'Source' },
                { value: 'NOTIFICATION.TARGETED_CHANNELS', label: 'Targeted Channels', default: false },
                { value: 'TEST.NAME', label: 'A/B Test Name' },
                { value: 'NOTIFICATION.TITLE', label: 'Title', default: true },
                { value: 'NOTIFICATION.WEIGHT', label: 'A/B Test Weight' },
            ],
        },
        {
            label: 'Measures',
            options: [
                { value: 'CLICKS', label: 'Clicks', default: true },
                { value: 'CTR', label: 'CTR', default: true },
                { value: 'DELIVERIES', label: 'Deliveries' },
                { value: 'DELIVERY_RATE', label: 'Impression Rate' },
                { value: 'IMPRESSIONS', label: 'Impressions', default: true },
                { value: 'PURCHASES', label: 'Purchases' },
                { value: 'PURCHASE_AMOUNT', label: 'Purchase Amount' },
                { value: 'PURCHASE_RATE_DECIMAL', label: 'Purchase Rate' },
            ],
        },
    ],
    DELIVERY_TEST: [
        {
            label: 'Dimensions',
            options: [
                { value: 'TEST.CREATED_BY', label: 'Created By' },
                { value: 'TEST.NAME', label: 'Name', default: true },
            ],
        },
        {
            label: 'Measures',
            options: [
                { value: 'CLICKS', label: 'Clicks', default: true },
                { value: 'CTR', label: 'CTR', default: true },
                { value: 'DELIVERIES', label: 'Deliveries' },
                { value: 'DELIVERY_RATE', label: 'Impression Rate' },
                { value: 'IMPRESSIONS', label: 'Impressions', default: true },
            ],
        },
    ],
    DELIVERY_CAMPAIGN: [
        {
            label: 'Dimensions',
            options: [{ value: 'CAMPAIGN.NAME', label: 'Campaign Name', default: true }],
        },
        {
            label: 'Measures',
            options: [
                { value: 'ENTRANCES', label: 'Entrances', default: true },
                { value: 'CLICKS', label: 'Clicks', default: true },
                { value: 'CTR', label: 'CTR', default: true },
                { value: 'DELIVERIES', label: 'Deliveries' },
                { value: 'DELIVERY_RATE', label: 'Impression Rate' },
                { value: 'IMPRESSIONS', label: 'Impressions', default: true },
                { value: 'PURCHASES', label: 'Purchases' },
                { value: 'PURCHASE_AMOUNT', label: 'Purchase Amount' },
            ],
        },
    ],
    DELIVERY_SEGMENT: [
        {
            label: 'Dimensions',
            options: [
                { value: 'SEGMENT.NAME', label: 'Segment Name', default: true },
                { value: 'SEGMENT_LAST_TARGETED_DATE_TZ', label: 'Last Targeted Date', default: true },
            ],
        },
        {
            label: 'Measures',
            options: [
                { value: 'DELIVERIES', label: 'Deliveries' },
                { value: 'DELIVERY_RATE', label: 'Impression Rate' },
                { value: 'IMPRESSIONS', label: 'Impressions', default: true },
                { value: 'CLICKS', label: 'Clicks', default: true },
                { value: 'CTR', label: 'CTR', default: true },
                { value: 'NOTIFICATIONS_SENT', label: 'Notifications Sent', default: true },
                { value: 'CPS', label: 'Clicks Per Send (CPS)', default: true },
                { value: 'SIZE', label: 'Segment Size' },
            ],
        },
    ],
    DELIVERY_KEYWORD: [
        {
            label: 'Dimensions',
            options: [{ value: 'KEYWORD', label: 'Keyword', default: true }],
        },
        {
            label: 'Measures',
            options: [
                { value: 'DELIVERIES', label: 'Deliveries' },
                { value: 'DELIVERY_RATE', label: 'Impression Rate' },
                { value: 'IMPRESSIONS', label: 'Impressions', default: true },
                { value: 'CLICKS', label: 'Clicks', default: true },
                { value: 'CTR', label: 'CTR', default: true },
                { value: 'NOTIFICATIONS_SENT', label: 'Notifications Sent', default: true },
            ],
        },
    ],
    SUBSCRIBER_DOMAIN: [
        {
            label: 'Measures',
            options: [
                { value: 'PERMISSIONS_DENIED', label: 'Permissions Denied' },
                { value: 'PERMISSIONS_DISMISSED', label: 'Permissions Dismissed' },
                { value: 'PERMISSIONS_SHOWN', label: 'Permissions Shown', default: true },
                { value: 'PROMPTS_ACCEPTED', label: 'Prompts Accepted', default: true },
                { value: 'PROMPT_ACCEPT_RATE', label: 'Prompt Accept Rate', default: true },
                { value: 'PROMPTS_DISMISSED', label: 'Prompts Dismissed' },
                { value: 'PROMPTS_DISMISSED_NON_UNIQUE', label: 'Prompts Dismissed (Non-Unique)' },
                { value: 'PROMPTS_SHOWN', label: 'Prompts Shown', default: true },
                { value: 'PROMPTS_SHOWN_NON_UNIQUE', label: 'Prompts Shown (Non-Unique)' },
                { value: 'SUBSCRIPTIONS', label: 'Subscriptions', default: true },
                { value: 'SUBSCRIPTION_RATE', label: 'Subscription Rate', default: true },
            ],
        },
    ],
    SUBSCRIBER_PROMPT: [
        {
            label: 'Dimensions',
            options: [
                { value: 'PROMPT.NAME', label: 'Name', default: true },
                { value: 'PROMPT.PRIORITY', label: 'Priority' },
                { value: 'PROMPT.STYLE', label: 'Style' },
                { value: 'PROMPT.VISITOR_CONDITIONS', label: 'Visitor Conditions' },
                { value: 'TEST.ID', label: 'A/B Test ID' },
                { value: 'TEST.NAME', label: 'A/B Test Name' },
            ],
        },
        {
            label: 'Measures',
            options: [
                { value: 'PERMISSIONS_DENIED', label: 'Permissions Denied' },
                { value: 'PERMISSIONS_DISMISSED', label: 'Permissions Dismissed' },
                { value: 'PERMISSIONS_SHOWN', label: 'Permissions Shown', default: true },
                { value: 'PROMPTS_ACCEPTED', label: 'Prompts Accepted', default: true },
                { value: 'PROMPT_ACCEPT_RATE', label: 'Prompt Accept Rate', default: true },
                { value: 'PROMPTS_DISMISSED', label: 'Prompts Dismissed' },
                { value: 'PROMPTS_DISMISSED_NON_UNIQUE', label: 'Prompts Dismissed (Non-Unique)' },
                { value: 'PROMPTS_SHOWN', label: 'Prompts Shown', default: true },
                { value: 'PROMPTS_SHOWN_NON_UNIQUE', label: 'Prompts Shown (Non-Unique)' },
                { value: 'SUBSCRIPTIONS', label: 'Subscriptions', default: true },
                { value: 'SUBSCRIPTION_RATE', label: 'Subscription Rate', default: true },
            ],
        },
    ],
    SUBSCRIBER_TEST: [
        {
            label: 'Dimensions',
            options: [{ value: 'TEST.NAME', label: 'Name', default: true }],
        },
        {
            label: 'Measures',
            options: [
                { value: 'PERMISSIONS_DENIED', label: 'Permissions Denied' },
                { value: 'PERMISSIONS_DISMISSED', label: 'Permissions Dismissed' },
                { value: 'PERMISSIONS_SHOWN', label: 'Permissions Shown', default: true },
                { value: 'PROMPTS_ACCEPTED', label: 'Prompts Accepted', default: true },
                { value: 'PROMPT_ACCEPT_RATE', label: 'Prompt Accept Rate', default: true },
                { value: 'PROMPTS_DISMISSED', label: 'Prompts Dismissed' },
                { value: 'PROMPTS_DISMISSED_NON_UNIQUE', label: 'Prompts Dismissed (Non-Unique)' },
                { value: 'PROMPTS_SHOWN', label: 'Prompts Shown', default: true },
                { value: 'PROMPTS_SHOWN_NON_UNIQUE', label: 'Prompts Shown (Non-Unique)' },
                { value: 'SUBSCRIPTIONS', label: 'Subscriptions', default: true },
                { value: 'SUBSCRIPTION_RATE', label: 'Subscription Rate', default: true },
            ],
        },
    ],
    MONTHLY_UNIQUES_DOMAIN: [
        {
            label: 'Measures',
            options: [
                { value: 'IMPRESSIONS', label: 'Impressions', default: true },
                { value: 'CLICKS', label: 'Clicks', default: true },
                { value: 'CTR', label: 'CTR', default: true },
                { value: 'PERMISSIONS_SHOWN', label: 'Permissions Shown', default: true },
                { value: 'SUBSCRIPTIONS', label: 'Subscriptions', default: true },
                { value: 'SUBSCRIPTION_RATE', label: 'Subscription Rate', default: true },
            ],
        },
    ],
    PUB_NETWORK_DOMAIN: [
        {
            label: 'Measures',
            options: [
                { value: 'PAID_CLICKS', label: 'Clicks', default: true },
                { value: 'PAID_CTR', label: 'CTR', default: true },
                { value: 'IMPRESSIONS', label: 'Impressions', default: true },
                { value: 'REVENUE', label: 'Revenue', default: true },
            ],
        },
    ],
    ATTRITION_DOMAIN: [
        {
            label: 'Measures',
            options: [
                { value: 'ATTRITION_RATE', label: 'Attrition Rate', default: true },
                { value: 'SUBSCRIBERS_LOST', label: 'Subscribers Lost', default: true },
            ],
        },
    ],
}
