import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { AppService } from '../../../services'
import { CampaignTableWell } from '../campaign-table-well/campaign-table-well'
import './campaign-list.scss'
import { PageHeader } from '../../../components/page-header/page-header'
import { CurrentUserCan } from 'stores/app-ability'
import { AbilityAction } from 'enums/ability-action.enum'
import { SubjectEntity } from '../../../enums/ability-entity.enum'
import { AppState } from '../../../stores/app'

export class CampaignList extends React.Component {
    protected appState: AppState
    protected appService: AppService

    public constructor(props) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render(): React.ReactNode {
        const { abilityStore } = this.appState
        return (
            <div className="campaign-list">
                <PageHeader
                    title="Campaigns"
                    action={
                        <CurrentUserCan
                            do={AbilityAction.CREATE}
                            on={abilityStore.getDomainOwnedIdentityFor(SubjectEntity.CAMPAIGN)}
                        >
                            <AsyncButton
                                className="new-notification-button"
                                type="primary"
                                altHref={this.buildCreateCampaignUrl()}
                                onClick={this.jumpToCreateCampaign}
                            >
                                <span>Create Campaign</span>
                            </AsyncButton>
                        </CurrentUserCan>
                    }
                />

                <CampaignTableWell showHeader={false} {...this.props} />
            </div>
        )
    }

    protected buildCreateCampaignUrl = (): string => {
        return this.appService.routeWithinDomain('/campaigns/new', true)
    }

    protected jumpToCreateCampaign = (): void => {
        this.appService.route(this.buildCreateCampaignUrl())
    }
}
