import * as React from 'react'
import { Switch } from 'antd'
import { FeatureDisplayWrapper } from '../feature-display-wrapper/feature-display-wrapper'
import { WebBadge } from '../badges/web-badge'
import { NativeIosBadge } from '../badges/native-ios-badge'
import { NativeAndroidBadge } from '../badges/native-android-badge'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface IChannelSwitch {
    className?: string
    value: DeliveryChannel[]
    channel: DeliveryChannel
    onChange: (change: DeliveryChannel[] | any) => void
    disabled?: boolean
    disabledToggle?: boolean
    label?: string
    iconOptions?: {
        showIcon: boolean
        type?: 'default' | 'insights'
        color?: string
    }
}

const FragmentWrapper = (_: any) => _.children

export const ChannelSwitch = (props: IChannelSwitch) => {
    const { value, disabled, disabledToggle, onChange, channel, className, iconOptions } = props
    let label = props.label ?? DeliveryChannel.getLongName(channel)
    let icon

    const showIcon = iconOptions?.showIcon
    const DisplayWrapper = disabled ? FeatureDisplayWrapper : FragmentWrapper

    if (showIcon) {
        switch (channel) {
            case DeliveryChannel.WEB:
                if (showIcon) {
                    icon = <WebBadge />
                }
                break
            case DeliveryChannel.NATIVE_IOS:
                if (showIcon) {
                    icon = <NativeIosBadge />
                }
                break
            case DeliveryChannel.NATIVE_ANDROID:
                if (showIcon) {
                    icon = <NativeAndroidBadge />
                }
                break
            default:
                break
        }
    }

    return (
        <div className={`channel-selector-item ${channel.toLowerCase()}-selector ${className ?? ''}`}>
            <DisplayWrapper
                type={'tooltip'}
                forwardProps={{ title: 'To enable this selection please contact your Account Manager.' }}
            >
                <label>
                    <Switch
                        checked={value.includes(channel)}
                        size="small"
                        onChange={(enabled) => {
                            const chIdx = value.indexOf(channel)
                            if (enabled && chIdx === -1) {
                                value.push(channel)
                            } else {
                                if (chIdx !== -1) {
                                    value.splice(chIdx, 1)
                                }
                            }

                            if (!disabledToggle) {
                                onChange(value)
                            }
                        }}
                        disabled={disabled}
                    />
                    <span className="channel-selector-label">
                        {icon} {label}
                    </span>
                </label>
            </DisplayWrapper>
        </div>
    )
}
