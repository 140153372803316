import * as React from 'react'
import { Component } from 'react'
import { AutoSizer } from 'react-virtualized'
import * as dotProp from 'dot-prop'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { AppState } from '../../../stores/app'
import { Table as FDTable, Column as FDColumn, Cell as FDCell, ColumnProps } from 'fixed-data-table-2'
import { BetterComponent } from '../../../components/better-component/better-component'
import { isFunction } from '../../../_utils/utils'
import { AppService } from '../../../services'
import { DELIVERY_TABLE_COLUMN_DEFINITIONS } from '../constants/delivery-table-column-definitions'
import { SUBSCRIBER_TABLE_COLUMN_DEFINITIONS } from '../constants/subscriber-table-column-definitions'
import { PUB_NETWORK_TABLE_COLUMN_DEFINITIONS } from '../constants/pub-network-table-column-definitions'
import { MONTHLY_UNIQUES_TABLE_COLUMN_DEFINITIONS } from '../constants/monthly-uniques-table-column-definitions';
import { COLUMN_TABLE_ORDER } from '../constants/column-table-order'
import { IReportConfig } from '../interfaces/report-config'
import './report-table.scss'

const PROMPT_DIMS: any = {}

const PROMPT_MEASURES: any = {}

const SORT_TYPES = {
    ASC: 'ASC',
    DESC: 'DESC',
}

function reverseSortDirection(sortDir: any) {
    return sortDir === SORT_TYPES.DESC ? SORT_TYPES.ASC : SORT_TYPES.DESC
}

interface ITableColProps extends ColumnProps {
    title: string
    formatter?: Function
    sortable?: boolean
    className?: string
    hideFooter?: boolean
    render?: Function
}

interface ISortHeaderCell extends ColumnProps {
    className?: string
    onSortChange: Function
    sortDir: any
    sortBy: string
    title: string
}

class SortHeaderCell extends Component<ISortHeaderCell, {}> {
    constructor(props: ISortHeaderCell) {
        super(props)
    }

    public render(): React.ReactNode {
        const { onSortChange, sortDir, sortBy, title, ...props } = this.props

        let indicator = sortDir ? (sortDir === SORT_TYPES.DESC ? '↓' : '↑') : ''
        if (props.columnKey! !== sortBy) {
            indicator = ''
        }

        return (
            <FDCell {...props}>
                <div className="table-sort-header" onClick={this.onSortChange}>
                    {title} {indicator}
                </div>
            </FDCell>
        )
    }

    @autobind
    private onSortChange(ev: any) {
        ev.preventDefault()

        if (this.props.onSortChange) {
            this.props.onSortChange(
                this.props.columnKey,
                this.props.sortDir ? reverseSortDirection(this.props.sortDir) : SORT_TYPES.DESC,
            )
        }
    }
}

interface IProps {
    loading: boolean
    config: IReportConfig
    data: any[]
    columns: string[]
    hiddenColumns?: string[]
}

interface IState {
    sortBy?: any
    sortDirection?: any
    sortedData: any[]
}

export class ReportTable extends BetterComponent<IProps, IState> {
    private readonly appState: AppState
    private readonly appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        const sortingDefaults = {
            sortBy: 'send_date',
            sortDirection: SORT_TYPES.DESC,
        }
        window.__SW_REPORT_TABLE__ = window.__SW_REPORT_TABLE__ || sortingDefaults

        this.state = {
            sortedData: [],
            ...sortingDefaults,
        }
    }

    public componentDidMount(): void {
        window.addEventListener('sw-force-report-sort', this.handleForcedSort.bind(this))
    }

    public componentWillUnmount() {
        super.componentWillUnmount()

        window.removeEventListener('sw-force-report-sort', this.handleForcedSort.bind(this))
    }

    public handleForcedSort(event: CustomEvent): void {
        this.sort(event.detail.sortBy, event.detail.sortDirection)
    }

    public render(): React.ReactNode {
        const { config, data = [] } = this.props
        const columnNodes: React.ReactNode[] = []
        let requestedColumns = this.props.columns || []
        const hiddenColumns = this.props.hiddenColumns ?? []

        let columnConfigs = {}
        switch (config.type) {
            case 'DELIVERY':
                columnConfigs = DELIVERY_TABLE_COLUMN_DEFINITIONS
                break

            case 'SUBSCRIBER':
                columnConfigs = SUBSCRIBER_TABLE_COLUMN_DEFINITIONS
                break

            case 'MONTHLY_UNIQUES':
                columnConfigs = MONTHLY_UNIQUES_TABLE_COLUMN_DEFINITIONS
                break

            case 'PUB_NETWORK':
                columnConfigs = PUB_NETWORK_TABLE_COLUMN_DEFINITIONS
                break
        }

        const columnDefKey = `${config.type}_${config.level}`
        const columnOrder: string[] = COLUMN_TABLE_ORDER[columnDefKey] || []

        requestedColumns = requestedColumns
            .filter((col) => !!col && !hiddenColumns.includes(col))
            .map((col) => col.toUpperCase())

        if (config.dateIncrement !== 'LIFETIME') {
            requestedColumns.push('DATE')
        }

        columnOrder.forEach((col) => {
            if (requestedColumns.indexOf(col) !== -1) {
                if (col in columnConfigs) {
                    const colConfig = columnConfigs[col]

                    if (Array.isArray(colConfig)) {
                        colConfig.forEach((conf) => columnNodes.push(this.renderFDColumn(config, conf)))
                    } else {
                        columnNodes.push(this.renderFDColumn(config, colConfig))
                    }
                }
            }
        })

        return (
            <div className="report-table-wrapper">
                <AutoSizer>
                    {({ width, height }) => (
                        <>
                            <FDTable
                                className="report-table"
                                width={width}
                                height={138 + data.length * 60 < height ? 138 + data.length * 60 : height}
                                rowsCount={data.length}
                                rowHeight={60}
                                headerHeight={60}
                                footerHeight={60}
                            >
                                {columnNodes}
                            </FDTable>
                        </>
                    )}
                </AutoSizer>
            </div>
        )
    }

    private renderFDColumn(reportConfig: any, config: ITableColProps): React.ReactNode {
        let header: any
        let footer: any

        if (!config.hideFooter) {
            footer = (props: any) => {
                let result = config.footer

                if (result && isFunction(result)) {
                    result = (result as Function)(this.props.data, props)
                }

                if (!result) {
                    result = (
                        <FDCell {...props}>
                            <div className="footer-primary empty"></div>
                            <div className="footer-secondary"></div>
                        </FDCell>
                    )
                }

                return result
            }
        }

        let title: any = config.title
        if (title && isFunction(title)) {
            title = (title as Function)(reportConfig, config)
        }

        header = config.sortable ? (
            <SortHeaderCell
                className={config.className}
                onSortChange={(sortBy: string, sortDirection: string) => this.sort(sortBy, sortDirection, config)}
                sortDir={this.state.sortDirection}
                sortBy={this.state.sortBy}
                width={config.width}
                title={title}
            />
        ) : (
            <FDCell className={config.className}>{title}</FDCell>
        )

        return (
            <FDColumn
                {...config}
                key={config.columnKey || config.key}
                header={header}
                footer={footer}
                allowCellsRecycling={true}
                cell={({ rowIndex, ...props }) => {
                    const record: any = this.props.data[rowIndex]

                    let content
                    if (!!config.render) {
                        content = config.render(record, this.props.data, this.props.config, this.appState)
                    } else {
                        if (config.columnKey) {
                            content = dotProp.get(record, config.columnKey.toString())
                        }
                    }

                    return (
                        <FDCell {...props} className={config.className}>
                            {content}
                        </FDCell>
                    )
                }}
            />
        )
    }

    private isDeliveryReport() {
        const config = this.props.config

        return !!config && config.type === 'DELIVERY'
    }

    private isPubNetworkReport() {
        const config = this.props.config

        return !!config && config.type === 'PUB_NETWORK'
    }

    @autobind
    private async sort(sortBy?: string, sortDirection?: string, config?: any): Promise<void> {
        sortBy = sortBy || this.state.sortBy
        sortDirection = sortDirection || this.state.sortDirection

        let correctedSortBy = sortBy
        if (this.isDeliveryReport() && sortBy === 'display') {
            correctedSortBy = 'title'
        } else if (this.isPubNetworkReport() && sortBy === 'display') {
            correctedSortBy = 'report_date'
        } else if (!this.isDeliveryReport() && sortBy === 'display') {
            correctedSortBy = 'name'
        }

        const sortedData = this.sortList(correctedSortBy!, sortDirection!, config)

        window.__SW_REPORT_TABLE__ = { sortBy, sortDirection }
        await this.setState({ sortBy, sortDirection, sortedData })
    }

    private sortList(sortBy: string, sortDirection: string, config: any): any[] {
        const reportData: any[] = this.props.data || []

        let sortFunc = (a: any, b: any) => {
            let aValue: any = String(dotProp.get(a, sortBy)).toLowerCase()
            let bValue: any = String(dotProp.get(b, sortBy)).toLowerCase()

            const aIsNumeric = !isNaN(aValue as any)
            const bIsNumeric = !isNaN(bValue as any)
            if (aIsNumeric && bIsNumeric) {
                aValue = parseFloat(aValue)
                bValue = parseFloat(bValue)
            }

            if (aValue === '--' || aValue === 'null') {
                aValue = -1
            }
            if (bValue === '--' || bValue === 'null') {
                bValue = -1
            }

            if (config && config.sortType !== 'number') {
                aValue = aValue.toString()
                bValue = bValue.toString()
            }

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        }

        if (config && config.sort) {
            sortFunc = config.sort
        }

        let data = reportData.sort(sortFunc)

        if (sortDirection === SORT_TYPES.DESC) {
            data = data.reverse()
        }

        return data
    }
}
