import * as React from 'react'
import './sandbox.scss'
import { PageHeader } from '../../components/page-header/page-header'
import { useService } from '../../hooks/use-service'
import { AppState } from '../../stores/app'
import { DomainService } from '../../services'

function Sandbox() {
    // const qs = getQueryString();
    const appState = useService(AppState)
    const domainSvc = useService(DomainService)

    return (
        <React.Fragment>
            <PageHeader title="Sandbox" />
        </React.Fragment>
    )
}

export default Sandbox
