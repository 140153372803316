import * as React from 'react'
import { AccountNotificationService } from '../../../services/account-notification'
import { useService } from '../../../hooks/use-service'
import { OrgNotificationModel } from '../../../models/notification/org-notification.model'
import type { ILoadableDataState } from '../../../hooks/use-loadable-data-ref-state'
import { useLoadableDataRefState } from '../../../hooks/use-loadable-data-ref-state'
import { parseNotificationVariantFromApiResponse } from './parse-org-notification-model'
import { InsightsService } from '../../../services/insights'
import { arrayUnqiue, flatMap } from '../../../_utils/array'
import { ApiVersion } from '../../../enums/api-version.enum'

interface IOrgNotificationLoaderProps {
    orgId: number
    notifId: string | undefined
    stats?: any
    children: (
        notification: React.MutableRefObject<ILoadableDataState<OrgNotificationModel | undefined>>,
    ) => JSX.Element
}

const OrgNotificationLoader = ({ orgId, notifId, stats, children }: IOrgNotificationLoaderProps) => {
    const svc = useService<AccountNotificationService>(AccountNotificationService)
    const statsSvc = useService<InsightsService>(InsightsService)
    const [loader, setLoader] = useLoadableDataRefState<OrgNotificationModel | undefined>({ loading: true })

    React.useEffect(() => {
        const loadData = async () => {
            setLoader({ ...loader, loading: true })
            const update: any = { loading: false }

            if (notifId) {
                const { ok, data } = await svc.fetchById(orgId, notifId, {
                    query: {
                        include_segments: true,
                    },
                    version: ApiVersion.V4,
                })
                if (ok) {
                    const model = OrgNotificationModel.build(data)
                    const variant = parseNotificationVariantFromApiResponse(data)

                    const orgSegmentIds = flatMap(model.getNotifications(), (n) => n.includedSegments ?? [])
                        .filter((s) => !s.isDefault)
                        .map((s) => s.groupId)
                    const uqOrgSegmentIds = arrayUnqiue(orgSegmentIds)

                    if (uqOrgSegmentIds.length) {
                        variant.getAudience().setSegmentIds(uqOrgSegmentIds)
                    }

                    model.setVariant(variant)
                    update.data = model

                    if (stats === true || (typeof stats === 'object' && Object.keys(stats).length > 0)) {
                        const domainIds = arrayUnqiue(model.getNotifications().map((n) => n.domainId))
                        const overrides = typeof stats === 'object' ? stats : {}

                        const insightsPkg = {
                            dateIncrement: 'lifetime',
                            datePreset: 'lifetime',
                            fields: [
                                'notification_group.id',
                                'deliveries',
                                'impressions',
                                'clicks',
                                'ctr',
                                'ctr_decimal',
                                'delivery_rate',
                                'delivery_rate_decimal',
                            ],
                            ...overrides,
                            entity: 'notifications',
                            breakdowns: ['notification_group'],
                            filters: [
                                {
                                    field: 'domain.id',
                                    operator: 'in',
                                    value: domainIds,
                                },
                                {
                                    field: 'notification_group.id',
                                    operator: 'eq',
                                    value: model.getId(),
                                },
                            ],
                        }

                        const statsRes = await statsSvc.fetch(insightsPkg, false, 'org-notifs-list-stats', true)
                        const statsMatch = statsRes?.find((r) => r.notification_group?.id === model.getId())

                        if (!!statsMatch) {
                            model.setStats({
                                deliveries: statsMatch.deliveries,
                                impressions: statsMatch.impressions,
                                clicks: statsMatch.clicks,
                                ctr: statsMatch.ctr,
                                ctr_decimal: statsMatch.ctr_decimal,
                                delivery_rate: statsMatch.delivery_rate,
                                delivery_rate_decimal: statsMatch.delivery_rate_decimal,
                            })
                        }
                    }
                }
            }

            setLoader(update)
        }

        loadData()
    }, [orgId, notifId])

    return children(loader)
}

export default OrgNotificationLoader
