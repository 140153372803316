import * as React from 'react'
import { BetterComponent } from '../../../better-component/better-component'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { AutoComplete } from 'antd'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'
import TypeaheadInput, { TypeaheadTransform } from '../typeahead/typeahead'

interface IFreeTextInputProps {
    rule: IRule
    onChange: (value: string) => any
    autofocus?: boolean
    typeaheadUrl?: string
    typeaheadTransform?: TypeaheadTransform
    suggestions?: string[]
}

export class FreeTextInput extends BetterComponent<IFreeTextInputProps, {}> {
    public readonly defaultClassName = 'sw-v2-rb-free-text-input'
    public ref: any

    public componentDidMount(): void {
        if (this.props.autofocus) this.autofocus()
    }

    public render(): React.ReactNode {
        return this.props.typeaheadUrl ? (
            <TypeaheadInput
                className={this.buildRootClassNames()}
                rule={this.props.rule}
                typeaheadUrl={this.props.typeaheadUrl}
                typeaheadTransform={this.props.typeaheadTransform}
                onChange={this.handleChange}
                minTypeaheadLength={3}
                autofocus={this.props.autofocus}
            />
        ) : (
            <AutoComplete
                ref={(el) => (this.ref = el)}
                className={this.buildRootClassNames()}
                dataSource={this.filteredSuggestions}
                onSelect={(e) => this.handleChange(e.toString())}
                onSearch={(e) => this.handleChange(e)}
                defaultValue={this.props.rule.rule.value}
                dropdownClassName="typeahead-suggestions"
            />
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }
        return response
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected autofocus(): void {
        this.ref?.focus()
    }

    protected get filteredSuggestions() {
        let filteredSuggestions: string[] = this.props.suggestions || []
        if (this.props.rule.rule.value) {
            filteredSuggestions = filteredSuggestions
                .filter((s) => s.indexOf(this.props.rule.rule.value) !== -1)
                .sort((a, b) => (a.length < b.length ? -1 : b.length < a.length ? 1 : 0))
        }
        return Array.from(new Set(filteredSuggestions))
    }

    protected handleChange = async (value: any): Promise<void> => {
        this.props.onChange(value)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
