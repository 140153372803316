import * as React from 'react'
import { Radio, Skeleton } from 'antd'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { ChannelSwitch } from '../channel-switch/channel-switch'
import { getClassNames } from '../../_utils/classnames'
import { Well } from '@pushly/aqe/lib/components'
import classNames from 'classnames'
import './delivery-channel-selector.scss'

// tslint:disable-next-line:interface-name
interface DeliveryChannelSelectProps {
    onChange: (change: DeliveryChannel[] | DeliveryChannel) => void
    value?: DeliveryChannel[]
    type: 'single' | 'multiple'
    loading?: boolean
    titleAppend?: string | React.ReactNode

    wellMode?: Well['props']['mode']
    wellNested?: boolean
    disabledChannels?: DeliveryChannel[]
    visibleChannels?: DeliveryChannel[]
    nested?: boolean
}

// tslint:disable-next-line:interface-name
interface MultiChannelSelectProps extends DeliveryChannelSelectProps {
    type: 'multiple'
    onChange: (change: DeliveryChannel[]) => void
    allowEmptySelection?: boolean
}

// tslint:disable-next-line:interface-name
interface SingleChannelSelectProps extends DeliveryChannelSelectProps {
    type: 'single'
    onChange: (change: DeliveryChannel) => void
}

const FragmentWrapper = (_: any) => _.children

export const DeliveryChannelSelector = (props: MultiChannelSelectProps | SingleChannelSelectProps) => {
    const { onChange, value, titleAppend, loading, type, disabledChannels } = props

    const ComponentWrapper =
        props.nested === true
            ? (_) => <FragmentWrapper>{_.children}</FragmentWrapper>
            : (_) => (
                  <Well
                      className={getClassNames('notification-channel-selector', 'nested')}
                      title={<span>Delivery Channels{titleAppend ? titleAppend : ''}</span>}
                      hideFooter={true}
                      mode={props.wellMode}
                  >
                      <Skeleton loading={loading} active={true} avatar={false} title={false}>
                          <Well
                              mode="ghost"
                              hideHeader={true}
                              hideFooter={true}
                              className={classNames({
                                  nested: props.wellNested,
                                  ['type-2']: props.wellNested,
                              })}
                          >
                              <div className="channel-selectors">{_.children}</div>
                          </Well>
                      </Skeleton>
                  </Well>
              )

    let options = DeliveryChannel.getAllChannels().sort((a, b) => (a < b ? 1 : -1))

    let disableToggle: DeliveryChannel | undefined
    if (value?.length === 1 && type === 'multiple' && !props.allowEmptySelection) {
        disableToggle = value[0]
    }

    if (disabledChannels?.length) {
        options = options.filter((channel) => disabledChannels.includes(channel))
    }

    if (props.visibleChannels && !props.visibleChannels.length) {
        return null
    }

    return (
        <ComponentWrapper>
            {type === 'single' && (
                <Radio.Group
                    size="small"
                    buttonStyle="solid"
                    value={value}
                    onChange={(ev) => {
                        const channel = ev.target.value

                        onChange?.(channel)
                    }}
                >
                    {options.map((opt) => (
                        <Radio.Button key={opt} value={opt}>
                            {DeliveryChannel.getLongName(opt)}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            )}
            {type === 'multiple' && (
                <>
                    {options
                        .filter((ch) => props.visibleChannels?.includes(ch))
                        .map((channel) => {
                            return (
                                <ChannelSwitch
                                    key={channel}
                                    value={value ?? DeliveryChannel.getAllChannels()}
                                    channel={channel}
                                    onChange={props.onChange}
                                    disabled={disabledChannels?.includes(channel)}
                                    disabledToggle={disableToggle === channel}
                                    iconOptions={{ showIcon: true }}
                                />
                            )
                        })}
                </>
            )}
        </ComponentWrapper>
    )
}
