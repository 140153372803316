import { Flags } from '@pushly/aquarium-utils/lib/enums/flags'

export const DEFAULT_TYPE = {
    value: 'DELIVERY',
    label: 'Delivery Report',
}

export const TYPE_DROPDOWN_OPTIONS = [
    {
        label: 'New',
        options: [
            { value: 'DELIVERY', label: 'Delivery Report' },
            { value: 'SUBSCRIBER', label: 'Acquisition Report' },
            { value: 'MONTHLY_UNIQUES', label: 'Monthly Uniques Report'},
            {
                value: 'PUB_NETWORK',
                label: 'Publisher Network Report',
                condition: (domain) => {
                    return domain.flags?.includes(Flags.domain.FEAT_DOMAIN_INTEGRATIONS_PUB_NETWORK.key) ?? false
                },
            },
            // { value: 'ATTRITION', label: 'Attrition Report' },
        ],
    },
]
