const NOTIFICATION_TYPE_FILTER = { value: 'NOTIFICATION_SOURCE', label: 'Source' }
const NOTIFICATION_ID_FILTER = { value: 'NOTIFICATION_ID', label: 'Notification' }
const NOTIFICATION_KEYWORD_FILTER = { value: 'NOTIFICATION_KEYWORDS', label: 'Keywords' }
const NOTIFICATION_TITLE_FILTER = { value: 'NOTIFICATION_TITLE', label: 'Title' }
const NOTIFICATION_BODY_FILTER = { value: 'NOTIFICATION_BODY', label: 'Body' }
const NOTIFICATION_URL_FILTER = { value: 'NOTIFICATION_LANDING_URL', label: 'Landing Url' }
const NOTIFICATION_SEGMENTS_FILTER = { value: 'NOTIFICATION_SEGMENTS', label: 'Segments' }
const CAMPAIGN_ID_FILTER = { value: 'CAMPAIGN_ID', label: 'Campaign' }
const SEGMENTS_ID_FILTER = { value: 'SEGMENT_ID', label: 'Segments' }
const PROMPT_ID_FILTER = { value: 'PROMPT_ID', label: 'Prompt' }
const TEST_ID_FILTER = { value: 'TEST_ID', label: 'Test' }
const BROWSER_FILTER = { value: 'BROWSER', label: 'Browser' }
const OS_FILTER = { value: 'OPERATING_SYSTEM', label: 'Operating System' }
const PLACEMENT_FILTER = { value: 'PLACEMENT', label: 'Device Type' }
const CHANNEL_FILTER = { value: 'CHANNEL', label: 'Delivery Channel' }

const NOTIFICATION_FEAT_FILTER_PACK = [
    NOTIFICATION_BODY_FILTER,
    NOTIFICATION_KEYWORD_FILTER,
    NOTIFICATION_TYPE_FILTER,
    NOTIFICATION_TITLE_FILTER,
    NOTIFICATION_SEGMENTS_FILTER,
]

const NOTIFICATION_FILTER_PACK = [
    NOTIFICATION_ID_FILTER,
    NOTIFICATION_URL_FILTER,
    TEST_ID_FILTER,
    CHANNEL_FILTER,
    ...NOTIFICATION_FEAT_FILTER_PACK,
]

const BROWSER_PLACEMENT_FILTER_PACK = [BROWSER_FILTER, PLACEMENT_FILTER]

const BROWSER_PLACEMENT_OS_FILTER_PACK = [BROWSER_FILTER, PLACEMENT_FILTER, OS_FILTER]

const BROWSER_OS_FILTER_PACK = [BROWSER_FILTER, OS_FILTER]

const PLACEMENT_OS_FILTER_PACK = [PLACEMENT_FILTER, OS_FILTER]

const PACK = []

export const FILTER_DROPDOWN_OPTIONS = {
    NONE_NONE_NONE: [],

    DELIVERY_DOMAIN_CHANNEL: [...NOTIFICATION_FEAT_FILTER_PACK, CHANNEL_FILTER],
    DELIVERY_DOMAIN_NONE: [...NOTIFICATION_FEAT_FILTER_PACK, CHANNEL_FILTER],
    DELIVERY_DOMAIN_BUTTON: [...NOTIFICATION_FEAT_FILTER_PACK],
    DELIVERY_DOMAIN_BROWSER: [...NOTIFICATION_FEAT_FILTER_PACK, BROWSER_FILTER],
    'DELIVERY_DOMAIN_BROWSER,PLACEMENT': [...NOTIFICATION_FEAT_FILTER_PACK, ...BROWSER_PLACEMENT_FILTER_PACK],
    'DELIVERY_DOMAIN_BROWSER,PLACEMENT,OPERATING_SYSTEM': [
        ...NOTIFICATION_FEAT_FILTER_PACK,
        ...BROWSER_PLACEMENT_OS_FILTER_PACK,
    ],
    'DELIVERY_DOMAIN_BROWSER,OPERATING_SYSTEM': [...NOTIFICATION_FEAT_FILTER_PACK, ...BROWSER_OS_FILTER_PACK],
    DELIVERY_DOMAIN_OPERATING_SYSTEM: [...NOTIFICATION_FEAT_FILTER_PACK, OS_FILTER],
    DELIVERY_DOMAIN_PLACEMENT: [...NOTIFICATION_FEAT_FILTER_PACK, PLACEMENT_FILTER],
    'DELIVERY_DOMAIN_PLACEMENT,OPERATING_SYSTEM': [...NOTIFICATION_FEAT_FILTER_PACK, ...PLACEMENT_OS_FILTER_PACK],
    DELIVERY_NOTIFICATION_NONE: [...NOTIFICATION_FILTER_PACK],
    DELIVERY_NOTIFICATION_CHANNEL: [...NOTIFICATION_FEAT_FILTER_PACK, CHANNEL_FILTER],
    DELIVERY_NOTIFICATION_BUTTON: [...NOTIFICATION_FILTER_PACK],
    DELIVERY_NOTIFICATION_BROWSER: [...NOTIFICATION_FILTER_PACK, BROWSER_FILTER],
    'DELIVERY_NOTIFICATION_BROWSER,PLACEMENT': [...NOTIFICATION_FILTER_PACK, ...BROWSER_PLACEMENT_FILTER_PACK],
    'DELIVERY_NOTIFICATION_BROWSER,PLACEMENT,OPERATING_SYSTEM': [
        ...NOTIFICATION_FILTER_PACK,
        ...BROWSER_PLACEMENT_OS_FILTER_PACK,
    ],
    'DELIVERY_NOTIFICATION_BROWSER,OPERATING_SYSTEM': [...NOTIFICATION_FILTER_PACK, ...BROWSER_OS_FILTER_PACK],
    DELIVERY_NOTIFICATION_OPERATING_SYSTEM: [...NOTIFICATION_FILTER_PACK, OS_FILTER],
    DELIVERY_NOTIFICATION_PLACEMENT: [...NOTIFICATION_FILTER_PACK, PLACEMENT_FILTER],
    'DELIVERY_NOTIFICATION_PLACEMENT,OPERATING_SYSTEM': [...NOTIFICATION_FILTER_PACK, ...PLACEMENT_OS_FILTER_PACK],
    DELIVERY_TEST_NONE: [TEST_ID_FILTER, CHANNEL_FILTER],
    DELIVERY_TEST_CHANNEL: [...NOTIFICATION_FEAT_FILTER_PACK, CHANNEL_FILTER],
    DELIVERY_TEST_BROWSER: [TEST_ID_FILTER, BROWSER_FILTER],
    'DELIVERY_TEST_BROWSER,PLACEMENT': [TEST_ID_FILTER, ...BROWSER_PLACEMENT_FILTER_PACK],
    'DELIVERY_TEST_BROWSER,PLACEMENT,OPERATING_SYSTEM': [TEST_ID_FILTER, ...BROWSER_PLACEMENT_OS_FILTER_PACK],
    'DELIVERY_TEST_BROWSER,OPERATING_SYSTEM': [TEST_ID_FILTER, ...BROWSER_OS_FILTER_PACK],
    DELIVERY_TEST_OPERATING_SYSTEM: [TEST_ID_FILTER, OS_FILTER],
    DELIVERY_TEST_PLACEMENT: [TEST_ID_FILTER, PLACEMENT_FILTER],
    'DELIVERY_TEST_PLACEMENT,OPERATING_SYSTEM': [TEST_ID_FILTER, ...PLACEMENT_OS_FILTER_PACK],
    DELIVERY_CAMPAIGN_NONE: [CAMPAIGN_ID_FILTER],
    DELIVERY_SEGMENT_NONE: [SEGMENTS_ID_FILTER],
    DELIVERY_SEGMENT_CHANNEL: [...NOTIFICATION_FEAT_FILTER_PACK, SEGMENTS_ID_FILTER],
    DELIVERY_SEGMENT_PLACEMENT: [SEGMENTS_ID_FILTER],
    DELIVERY_KEYWORD_NONE: [CHANNEL_FILTER],
    DELIVERY_KEYWORD_CHANNEL: [CHANNEL_FILTER],
    SUBSCRIBER_DOMAIN_NONE: [],
    SUBSCRIBER_DOMAIN_BROWSER: [BROWSER_FILTER],
    'SUBSCRIBER_DOMAIN_BROWSER,PLACEMENT': [...BROWSER_PLACEMENT_FILTER_PACK],
    'SUBSCRIBER_DOMAIN_BROWSER,PLACEMENT,OPERATING_SYSTEM': [...BROWSER_PLACEMENT_OS_FILTER_PACK],
    'SUBSCRIBER_DOMAIN_BROWSER,OPERATING_SYSTEM': [...BROWSER_OS_FILTER_PACK],
    SUBSCRIBER_DOMAIN_OPERATING_SYSTEM: [OS_FILTER],
    SUBSCRIBER_DOMAIN_PLACEMENT: [PLACEMENT_FILTER],
    'SUBSCRIBER_DOMAIN_PLACEMENT,OPERATING_SYSTEM': [...PLACEMENT_OS_FILTER_PACK],
    SUBSCRIBER_PROMPT_NONE: [PROMPT_ID_FILTER, TEST_ID_FILTER],
    SUBSCRIBER_PROMPT_BROWSER: [PROMPT_ID_FILTER, BROWSER_FILTER, TEST_ID_FILTER],
    'SUBSCRIBER_PROMPT_BROWSER,PLACEMENT': [PROMPT_ID_FILTER, TEST_ID_FILTER, ...BROWSER_PLACEMENT_FILTER_PACK],
    'SUBSCRIBER_PROMPT_BROWSER,PLACEMENT,OPERATING_SYSTEM': [
        PROMPT_ID_FILTER,
        TEST_ID_FILTER,
        ...BROWSER_PLACEMENT_OS_FILTER_PACK,
    ],
    'SUBSCRIBER_PROMPT_BROWSER,OPERATING_SYSTEM': [PROMPT_ID_FILTER, TEST_ID_FILTER, ...BROWSER_OS_FILTER_PACK],
    SUBSCRIBER_PROMPT_OPERATING_SYSTEM: [PROMPT_ID_FILTER, TEST_ID_FILTER, OS_FILTER],
    SUBSCRIBER_PROMPT_PLACEMENT: [PROMPT_ID_FILTER, TEST_ID_FILTER, PLACEMENT_FILTER],
    'SUBSCRIBER_PROMPT_PLACEMENT,OPERATING_SYSTEM': [PROMPT_ID_FILTER, TEST_ID_FILTER, ...PLACEMENT_OS_FILTER_PACK],
    SUBSCRIBER_TEST_NONE: [TEST_ID_FILTER],
    SUBSCRIBER_TEST_BROWSER: [TEST_ID_FILTER, BROWSER_FILTER],
    'SUBSCRIBER_TEST_BROWSER,PLACEMENT': [TEST_ID_FILTER, ...BROWSER_PLACEMENT_FILTER_PACK],
    'SUBSCRIBER_TEST_BROWSER,PLACEMENT,OPERATING_SYSTEM': [TEST_ID_FILTER, ...BROWSER_PLACEMENT_OS_FILTER_PACK],
    'SUBSCRIBER_TEST_BROWSER,OPERATING_SYSTEM': [TEST_ID_FILTER, ...BROWSER_OS_FILTER_PACK],
    SUBSCRIBER_TEST_OPERATING_SYSTEM: [TEST_ID_FILTER, OS_FILTER],
    SUBSCRIBER_TEST_PLACEMENT: [TEST_ID_FILTER, PLACEMENT_FILTER],
    'SUBSCRIBER_TEST_PLACEMENT,OPERATING_SYSTEM': [TEST_ID_FILTER, ...PLACEMENT_OS_FILTER_PACK],

    PUB_NETWORK_DOMAIN_NONE: [],
}
