import * as React from 'react'
import * as deepEqual from 'react-fast-compare'
import { BetterComponent } from '../better-component/better-component'
import { Sticky } from '../sticky/sticky'
import { AppState } from '../../stores/app'
import { AppService, DomainService, NotificationService, UserService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import { NdfFormMode } from './enums/ndf-form-mode.enum'
import { NdfFormTheme } from './enums/ndf-form-theme.enum'
import { INdfValue } from './interfaces/ndf-value'
import * as clone from 'clone'
import { AudienceSection } from './sections/audience-section'
import autobind from 'autobind-decorator'
import './notification-data-form.scss'
import { type INotificationTreatment } from '../../interfaces/notification-treatment'
import * as randomstring from 'randomstring'
import { type INdfAudienceValue } from './interfaces/ndf-audience-value'
import { NdfAudienceType } from './enums/ndf-audience-type.enum'
import { SegmentDto } from '../../dtos/segment'
import { SchedulingSection } from './sections/scheduling-section'
import { type INdfSchedulingValue } from './interfaces/ndf-scheduling-value'
import * as moment from 'moment-timezone'
import { NotificationDto, SendNotificationRequestDto } from '../../features/notifications'
import { TreatmentSection } from './sections/treatment-section'
import { DomainKeyword } from '../../dtos/domain'
import {
    arrayContains,
    numberWithCommas,
    pause,
    simpleFormErrorNotification,
    simpleNotification,
} from '../../_utils/utils'
import {
    BASE_TIME_FORMAT,
    BASE_TIME_FORMAT_WITHOUT_TZ,
    FEAT_AUTO_KW_DISCOVERY,
    FEAT_IMM_NOTIF_CONFIRM,
    FEAT_SCHED_NOTIF_CONFIRM,
} from '../../constants'
import { NotificationDeliverySpec } from '../../features/notifications/dtos/notification-schedule-dto'
import { NotificationDeliveryType } from '../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'
import { StatusType } from '../../enums/status-type'
import { NotificationSource } from '../../enums/notification-source'
import { NdfSubmitRequestType } from './interfaces/ndf-submit-request-type'
import {
    NotificationDataValidator,
    TreatmentValidationField,
} from '../../features/notifications/notification-data-form/notification-data-validator'
import { Modal } from 'antd'
import { INdfSectionValidationResponse } from './interfaces/ndf-section-validation-response'
import { TestSection } from './sections/test-section'
import { type INdfTestValue } from './interfaces/ndf-test-value'
import { NdfTestType } from './enums/ndf-test-type.enum'
import { NdfTestScheduleType } from './enums/ndf-test-schedule-type.enum'
import { INotificationAction, NotificationActionType } from '../../interfaces/notification-action'
import { FormContext } from './context'
import { Platform } from '@pushly/cuttlefish'
import { ReachDisplay } from './sections/reach-display'
import { PreviewDisplay } from './sections/preview-display'
import { EventBus } from '../campaign-builder/events'
import { EcommSection } from './sections/ecomm-section'
import { EcommItemPickStrategy } from '../../enums/ecomm-item-pick-strategy'
import { isMacroMatch } from '../../_utils/macros'
import { ApiVersion } from '../../enums/api-version.enum'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

declare const PushlySDK: any

interface INdfProps {
    className?: string
    mode?: NdfFormMode
    theme?: NdfFormTheme
    template?: NotificationDto
    test?: any
    completionRedirect?: string
    onSubmit?: (value: any, data: INdfValue) => any
    useManualSubmit?: boolean
    stickyContainer?: any
    showEcommStrategy?: boolean
    defaultEcommStrategy?: EcommItemPickStrategy
    previewMeta?: any

    showAudienceSection?: boolean
    showMessageSection?: boolean
    showSchedulingSection?: boolean
    showTestSection?: boolean
    showPreview?: boolean
    showReachEstimate?: boolean

    loading?: boolean
}

interface INdfState {
    value?: INdfValue

    loadingSegments: boolean
    availableSegments: SegmentDto[]

    loadingKeywords: boolean
    availableKeywords: DomainKeyword[]

    abEnabled?: boolean
    currentTreatmentIdx: number

    inlinePreviewPlatform: Platform

    reachEstimate?: number
    lastReachEstimateQuery?: any
    updatingReachEstimate: boolean
}

export class NotificationDataFormV2 extends BetterComponent<INdfProps, INdfState> {
    public static readonly defaultMode = NdfFormMode.CREATE
    public static readonly defaultTheme = NdfFormTheme.STANDARD

    public readonly defaultClassName: string = 'sw-v2-ndf'

    protected appState: AppState
    protected appService: AppService
    protected notificationService: NotificationService
    protected domainService: DomainService
    protected userService: UserService

    protected ecommSectionRef: any
    protected audienceSectionRef: any
    protected treatmentSectionRef: any
    protected testSectionRef: any
    protected schedulingSectionRef: any

    public constructor(props: INdfProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.notificationService = Container.get(NotificationService)
        this.domainService = Container.get(DomainService)
        this.userService = Container.get(UserService)

        this.state = {
            loadingSegments: true,
            availableSegments: [],
            loadingKeywords: true,
            availableKeywords: [],
            currentTreatmentIdx: 0,
            abEnabled: false,
            updatingReachEstimate: true,
            inlinePreviewPlatform: Platform.ANDROID,
        }
    }

    public componentDidMount(): void {
        this.eventBus.observe('new-segment-created', this.handleNewSegmentCreated)
    }

    public UNSAFE_componentWillMount() {
        this.fetchAvailableSegments().then()
        this.fetchAvailableKeywords().then()

        if (!!this.props.template || !!this.props.test) {
            this.ensureInitialValue().then()
        }
    }

    public componentDidUpdate(prevProps: Readonly<INdfProps>, prevState: Readonly<INdfState>): void {
        const prevTemplate = prevProps.template
        const prevTest = prevProps.test
        const nextTemplate = this.props.template
        const nextTest = this.props.test
        const prevValue = this.state.value

        if (((!prevTemplate && !!nextTemplate) || (!prevTest && !!nextTest)) && !prevValue) {
            this.ensureInitialValue().then()
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.eventBus.unobserve('new-segment-created', this.handleNewSegmentCreated)
    }

    public render(): React.ReactNode {
        return (
            <FormContext.Provider
                value={{
                    theme: this.currentTheme,
                    mode: this.currentMode,
                    domain: this.appState.currentDomain!,
                    reachEstimate: this.state.reachEstimate,
                    inlinePreviewPlatform: this.state.inlinePreviewPlatform,
                    useManualSubmit: this.props.useManualSubmit,
                }}
            >
                <div className={this.buildRootClassNames()}>
                    <div className={this.buildClassName('wrapper')}>
                        <div className={this.buildClassName('left-sections')}>
                            <div className={this.buildClassName('left-sections-wrapper')}>
                                {this.showEcommSection && (
                                    <EcommSection
                                        ref={(el) => (this.ecommSectionRef = el)}
                                        mode={this.currentMode}
                                        theme={this.currentTheme}
                                        value={this.currentValue}
                                        onChange={this.handleEcommChange}
                                        loading={this.isLoading}
                                        deliveryInProgress={this.deliveryInProgress}
                                    />
                                )}

                                {this.showAudienceSection && (
                                    <AudienceSection
                                        ref={(el) => (this.audienceSectionRef = el)}
                                        mode={this.currentMode}
                                        theme={this.currentTheme}
                                        value={this.currentAudienceValue}
                                        onChange={this.handleAudienceChange}
                                        options={this.state.availableSegments}
                                        loading={this.isLoadingAudienceDependencies}
                                        deliveryInProgress={this.deliveryInProgress}
                                    />
                                )}

                                {this.showMessageSection && (
                                    <TreatmentSection
                                        ref={(el) => (this.treatmentSectionRef = el)}
                                        mode={this.currentMode}
                                        theme={this.currentTheme}
                                        value={this.currentSelectedTreatment}
                                        onChange={this.handleTreatmentChange}
                                        onSelect={this.handleTreatmentSelect}
                                        onAdd={this.handleTreatmentAddition}
                                        onDelete={this.handleTreatmentDelete}
                                        disableAb={this.currentTheme !== NdfFormTheme.STANDARD}
                                        abToggleChecked={this.abEnabled}
                                        onAbToggleChange={this.handleAbEnabledChange}
                                        restrictToSingle={
                                            this.currentMode === NdfFormMode.EDIT &&
                                            !!this.props.template &&
                                            !this.props.test
                                        }
                                        restrictToVariant={
                                            this.currentMode === NdfFormMode.EDIT &&
                                            !!this.props.template &&
                                            !!this.props.test
                                        }
                                        treatmentOptions={this.currentTreatments}
                                        keywordOptions={this.state.availableKeywords}
                                        loading={this.isLoadingTreatmentDependencies}
                                        deliveryInProgress={this.deliveryInProgress}
                                        showEcommElements={this.props.showEcommStrategy}
                                        audience={this.currentAudienceValue}
                                        audienceOptions={this.state.availableSegments}
                                        formActions={this.buildFormActions('treatment')}
                                    />
                                )}

                                {this.showTestSection && (
                                    <TestSection
                                        ref={(el) => (this.testSectionRef = el)}
                                        mode={this.currentMode}
                                        theme={this.currentTheme}
                                        value={this.currentValue.test!}
                                        onChange={this.handleTestChange}
                                        treatments={this.currentTreatments}
                                        loading={this.isLoadingAudienceDependencies}
                                        reachEstimate={this.state.reachEstimate}
                                        schedule={this.currentSchedulingValue}
                                        audience={this.currentAudienceValue}
                                        deliveryInProgress={this.deliveryInProgress}
                                        segmentOptions={this.state.availableSegments}
                                    />
                                )}

                                {this.showSchedulingSection && (
                                    <SchedulingSection
                                        ref={(el) => (this.schedulingSectionRef = el)}
                                        mode={this.currentMode}
                                        theme={this.currentTheme}
                                        value={this.currentSchedulingValue}
                                        onChange={this.handleSchedulingChange}
                                        loading={this.isLoading}
                                        deliveryInProgress={this.deliveryInProgress}
                                        formActions={this.buildFormActions('scheduling')}
                                        test={this.currentValue.test}
                                        treatments={this.currentTreatments}
                                    />
                                )}
                            </div>
                        </div>

                        {this.showRightSections && (
                            <div className={this.buildClassName('right-sections')}>
                                <Sticky
                                    getContainer={
                                        !this.props.stickyContainer ? undefined : () => this.props.stickyContainer
                                    }
                                    threshold={18}
                                >
                                    <div className={this.buildClassName('right-sections-wrapper')}>
                                        {this.showReachEstimate && (
                                            <ReachDisplay loading={this.isLoadingReachDependencies} />
                                        )}

                                        {this.showPreview && (
                                            <PreviewDisplay
                                                loading={this.isLoadingTreatmentDependencies}
                                                treatment={this.currentSelectedTreatment}
                                                onPlatformChange={this.handleInlinePreviewPlatformChange}
                                                onSendRequest={this.handlePreviewRequest}
                                            />
                                        )}
                                    </div>
                                </Sticky>
                            </div>
                        )}
                    </div>
                </div>
            </FormContext.Provider>
        )
    }

    public async _processSubmitRequest(
        requestType: NdfSubmitRequestType = NdfSubmitRequestType.STANDARD,
    ): Promise<any> {
        return this.processSubmitRequest(requestType)
    }

    public handleNewSegmentCreated = async ({ cb }: any) => {
        await this.fetchAvailableSegments(true)
        cb?.()
    }

    protected get eventBus() {
        return EventBus.get('ndf-event-bus')
    }

    protected get isLoading(): boolean {
        return this.props.loading || false
    }

    protected get isLoadingTreatmentDependencies(): boolean {
        return this.isLoading || this.state.loadingKeywords
    }

    protected get isLoadingAudienceDependencies(): boolean {
        return this.isLoading || this.state.loadingSegments
    }

    protected get isLoadingReachDependencies(): boolean {
        return this.isLoadingAudienceDependencies || this.state.updatingReachEstimate
    }

    protected get currentMode(): NdfFormMode {
        return this.props.mode || NotificationDataFormV2.defaultMode
    }

    protected get currentTheme(): NdfFormTheme {
        return this.props.theme || NotificationDataFormV2.defaultTheme
    }

    protected get currentThemeIsCampaign(): boolean {
        return this.currentTheme === NdfFormTheme.CAMPAIGN
    }

    protected get currentThemeIsMobile(): boolean {
        return this.currentTheme === NdfFormTheme.MOBILE
    }

    protected get showRightSections(): boolean {
        let show

        if (this.currentThemeIsMobile) {
            show = false
        } else {
            show = this.showReachEstimate || this.showPreview
        }

        return show
    }

    protected get showEcommSection(): boolean {
        return this.currentThemeIsCampaign && !!this.props.showEcommStrategy
    }

    protected get showAudienceSection(): boolean {
        return !this.currentThemeIsCampaign && this.props.showAudienceSection !== false
    }

    protected get showMessageSection(): boolean {
        return this.props.showMessageSection !== false
    }

    protected get showSchedulingSection(): boolean {
        return !this.currentThemeIsCampaign && this.props.showSchedulingSection !== false
    }

    protected get showTestSection(): boolean {
        return !this.currentThemeIsCampaign && this.props.showTestSection !== false && this.abEnabled
    }

    protected get showReachEstimate(): boolean {
        return !this.currentThemeIsCampaign && this.props.showReachEstimate !== false
    }

    protected get showPreview(): boolean {
        return this.props.showPreview !== false
    }

    protected get defaultDomainSegment(): SegmentDto | undefined {
        return this.state.availableSegments.find((s) => s.isDefault)
    }

    protected get defaultDomainIconUrl(): string | undefined {
        return this.appState.currentDomain?.defaultIconUrl
    }

    protected get defaultDomainBadgeUrl(): string | undefined {
        return this.appState.currentDomain?.defaultBadgeUrl
    }

    protected get defaultAudience(): INdfAudienceValue {
        return {
            type: NdfAudienceType.ALL_SUBSCRIBERS,
        }
    }

    protected get defaultSuggestedTimezone(): string {
        return this.appState.currentDomain ? this.appState.currentDomain.timezone : moment.tz.guess()
    }

    protected get defaultSchedule(): INdfSchedulingValue {
        return {
            type: NotificationDeliveryType.IMMEDIATE,
            window: NotificationDeliveryWindow.STANDARD,
            timezone: this.defaultSuggestedTimezone,
        }
    }

    protected get currentValue(): INdfValue {
        const value: INdfValue = clone({
            audience: this.defaultAudience,
            schedule: this.defaultSchedule,
            treatments: [],

            ...this.templateValue,
            ...this.state.value,
        })

        // ensure strategy for ecomm builds
        if (this.showEcommSection) {
            value.ecomm = {
                itemPickStrategy: this.props.defaultEcommStrategy ?? EcommItemPickStrategy.RANDOM,
                ...value.ecomm,
            }
        }

        return value
    }

    protected get currentAudienceValue(): INdfAudienceValue {
        return clone({
            ...this.currentValue.audience,
        })
    }

    protected get currentSchedulingValue(): INdfSchedulingValue {
        return clone({
            ...this.currentValue.schedule,
        })
    }

    protected get currentTreatments(): INotificationTreatment[] {
        return clone(this.currentValue.treatments || [])
    }

    protected get currentSelectedTreatment(): INotificationTreatment | undefined {
        return this.currentTreatments.length > 0 ? this.currentTreatments[this.state.currentTreatmentIdx] : undefined
    }

    protected get abEnabled(): boolean {
        return this.state.abEnabled || false
    }

    protected get templateValue(): INdfValue | undefined {
        let value: INdfValue | undefined

        // Single Template
        if (!!this.props.template) {
            value = {}
            const temp = clone(this.props.template)

            // AUDIENCE PARSING
            if (!!temp.audience) {
                const audience: INdfAudienceValue = {
                    type: NdfAudienceType.ALL_SUBSCRIBERS,
                    exclusionsEnabled: false,
                }

                if (Array.isArray(temp.audience.segmentIds) && temp.audience.segmentIds.length > 0) {
                    const sampleSegmentId = temp.audience.segmentIds[0]
                    if (!this.defaultDomainSegment || sampleSegmentId !== this.defaultDomainSegment.id) {
                        audience.type = NdfAudienceType.SUBSCRIBER_SEGMENTS
                        audience.includedSegmentIds = temp.audience.segmentIds
                    }
                }
                if (Array.isArray(temp.audience.excludedSegmentIds) && temp.audience.excludedSegmentIds.length > 0) {
                    audience.exclusionsEnabled = true
                    audience.excludedSegmentIds = temp.audience.excludedSegmentIds
                }

                value.audience = audience
            }

            if (!!temp.deliverySpec) {
                // SCHEDULE PARSING
                const schedule: INdfSchedulingValue = {
                    type: (temp.deliverySpec.type as any) || NotificationDeliveryType.IMMEDIATE,
                    window: (temp.deliverySpec.window as any) || NotificationDeliveryWindow.STANDARD,
                }

                if (schedule.type === NotificationDeliveryType.SCHEDULED) {
                    schedule.timezone = temp.deliverySpec.timezone
                    schedule.sendDate = temp.deliverySpec.sendDateUtc
                }

                value.schedule = schedule
            }

            // TREATMENT PARSING
            value.treatments = [this.extractNotificationTreatment(temp)]
        }

        // A/B Test Template
        if (!!this.props.test && !!this.props.test.notifications) {
            value = { ...value }

            // TEST PARSING
            const test: INdfTestValue = {
                type: this.props.test.type || NdfTestType.SPLIT,
                name: this.props.test.name,
                isTimeVariant: !!this.props.test.isTimeVariant,
                ctrThreshold: this.props.test.ctrThreshold,
            }

            const evaluationSchedule = this.props.test.evaluationSchedule
            if (!!evaluationSchedule && Object.keys(evaluationSchedule).length > 0) {
                test.schedule = {
                    type: evaluationSchedule.type || NdfTestScheduleType.ELAPSED,
                }

                if (test.schedule.type === NdfTestScheduleType.ELAPSED) {
                    test.schedule.value = (evaluationSchedule.elapsed_seconds || 3600) / 60 / 60
                } else {
                    test.schedule.value = moment
                        .tz(evaluationSchedule.evaluation_date_utc, evaluationSchedule.$evaluation_date_timezone)
                        .format(BASE_TIME_FORMAT_WITHOUT_TZ)
                    test.schedule.$timezone = evaluationSchedule.$evaluation_date_timezone
                }
            }

            value.test = test

            // TREATMENT PARSING
            const notifications: NotificationDto[] = this.props.test.notifications.filter(
                (n: NotificationDto) => n.abTest && !n.abTest.winnerOriginId,
            )

            notifications.forEach((n) => {
                const schedule = this.props.test.schedules.find((s) => s.notificationId === n.id) || undefined

                n.deliverySpec = {
                    ...n.deliverySpec,
                    ...schedule?.deliverySpec,
                }
            })

            value.test.distributions = notifications.map((n) => n.abTest.weight)
            value.treatments = notifications.map((n) => this.extractNotificationTreatment(n))
        }

        return value
    }

    protected buildFormActions(sectionName: string): any {
        /**
         * ***
         * all internal user roles are prohibited from sending on non-demo domains
         * ***
         */
        const domain = this.appState.currentDomain
        const domainIsDemoDomain = domain?.isDemoDomain
        const domainIsInternal = domain?.isInternal
        const domainIsPreviewDomain = domain?.isPreviewDomain
        const currentUserIsInternal = this.appState.currentUser?.isInternalUser
        const isSubmitDisabled =
            domainIsPreviewDomain || (currentUserIsInternal && !domainIsDemoDomain && !domainIsInternal)

        let actions: any = {
            onSubmit: this.handleSubmit,
            onCancel: this.handleCancel,
            submitDisabled: isSubmitDisabled,
        }

        if (sectionName === 'scheduling') {
            if (this.currentTheme === NdfFormTheme.CAMPAIGN) {
                actions = undefined
            }
        }

        if (sectionName === 'treatment') {
            if (this.currentTheme !== NdfFormTheme.CAMPAIGN) {
                actions = undefined
            }
        }

        if (this.props.useManualSubmit) {
            actions = undefined
        }

        return actions
    }

    @autobind
    protected async handleAudienceChange(audience: INdfAudienceValue): Promise<void> {
        await this.setState({
            value: {
                ...this.currentValue,
                audience,
            },
        })

        this.fetchReachEstimate().then()
    }

    @autobind
    protected async handleSchedulingChange(
        schedule: INdfSchedulingValue,
        test?: INdfTestValue,
        treatments?: INotificationTreatment[],
    ): Promise<void> {
        const state: any = {
            value: {
                ...this.currentValue,
                schedule,
            },
        }

        if (!!test) {
            state.value = {
                ...state.value,
                test,
            }
        }

        if (treatments) {
            state.value = {
                ...state.value,
                treatments,
            }
        }

        this.setState(state)
    }

    @autobind
    protected async handleTestChange(test: INdfTestValue): Promise<void> {
        this.setState({
            value: {
                ...this.currentValue,
                test,
            },
        })
    }

    protected handleEcommChange = async (value: INdfValue): Promise<void> => {
        return this.setState({
            value: {
                ...this.currentValue,
                ecomm: value.ecomm ?? {},
            },
        })
    }

    @autobind
    protected async handleTreatmentChange(treatment: INotificationTreatment, idx: number): Promise<void> {
        const treatments = this.currentTreatments

        if (idx === -1) {
            treatments.push(treatment)
        } else {
            treatments.splice(idx, 1, treatment)
        }

        this.setState({
            value: {
                ...this.currentValue,
                treatments,
            },
        })
    }

    @autobind
    protected async handleTreatmentAddition(treatment: INotificationTreatment, idx: number): Promise<void> {
        const newIdx = idx + 1
        const newTreatment = clone(treatment)
        newTreatment.id = randomstring.generate()

        this.setState({
            value: {
                ...this.currentValue,
                treatments: [...this.currentTreatments, newTreatment],
            },
            currentTreatmentIdx: newIdx,
        })
    }

    @autobind
    protected async handleTreatmentDelete(treatment: INotificationTreatment, idx: number): Promise<void> {
        const newIdx = idx - 1
        const treatments = this.currentTreatments
        treatments.splice(idx, 1)

        this.setState({
            value: {
                ...this.currentValue,
                treatments,
            },
            currentTreatmentIdx: newIdx < 0 ? 0 : newIdx,
        })
    }

    @autobind
    protected async handleTreatmentSelect(treatment: INotificationTreatment, idx: number): Promise<void> {
        this.setState({ currentTreatmentIdx: idx })
    }

    @autobind
    protected async handleAbEnabledChange(abEnabled: boolean): Promise<void> {
        const update: Partial<INdfState> = {
            ...this.state,
            abEnabled,
        }

        if (!abEnabled) {
            update.currentTreatmentIdx = 0

            update.value = {
                ...update.value,
                treatments: [this.currentSelectedTreatment!],
                test: undefined,
            }

            this.testSectionRef.clear()
        }

        this.setState(update as any)
    }

    protected handleInlinePreviewPlatformChange = (previewPlatform: Platform) => {
        this.setState({ inlinePreviewPlatform: previewPlatform })
    }

    @autobind
    protected async handlePreviewRequest(
        requestType: NdfSubmitRequestType.TEAM_PREVIEW | NdfSubmitRequestType.PERSONAL_PREVIEW,
    ): Promise<any> {
        await this.registerUserPuuid()
        return this.processSubmitRequest(requestType)
    }

    @autobind
    protected handleCancel(): void {
        this.appService.routeBack()
    }

    @autobind
    protected async handleSubmit(): Promise<void> {
        return this.processSubmitRequest(NdfSubmitRequestType.STANDARD)
    }

    protected async validateSections(): Promise<INdfSectionValidationResponse> {
        let response: INdfSectionValidationResponse = { ok: true }

        if (response.ok) response = (await this.audienceSectionRef?.validate?.()) ?? response
        if (response.ok) response = (await this.treatmentSectionRef?.validate?.()) ?? response
        if (response.ok) response = (await this.testSectionRef?.validate?.()) ?? response
        if (response.ok) response = (await this.schedulingSectionRef?.validate?.()) ?? response

        if (!response.ok) {
            simpleNotification('error', response.error)
        }

        return response
    }

    protected async validateTreatment(treatment: INotificationTreatment): Promise<boolean> {
        const fieldsToValidate: TreatmentValidationField[] = ['title', 'body', 'ttlSeconds']

        if (!treatment?.landingUrlDisabled) {
            fieldsToValidate.push('landingUrl')
        }

        const validator = new NotificationDataValidator(treatment)
        validator.validate(fieldsToValidate)

        treatment.errors = undefined
        if (validator.hasErrors()) {
            treatment.errors = validator.getErrors()
        }

        return validator.isValid()
    }

    protected get deliveryInProgress(): boolean {
        let inProgress = false

        if (!!this.props.template && !!this.props.template.schedules && this.props.template.schedules.length > 0) {
            const sample = this.props.template.schedules[0]

            if (!!sample.startedDateUtc) {
                inProgress = true
            }
        }

        return inProgress
    }

    protected requestTypeIsPreview(requestType: NdfSubmitRequestType): boolean {
        return (
            requestType === NdfSubmitRequestType.PERSONAL_PREVIEW || requestType === NdfSubmitRequestType.TEAM_PREVIEW
        )
    }

    protected async processAudienceForSubmit(
        requestType: NdfSubmitRequestType,
        audience: INdfAudienceValue,
        _values: INdfValue,
    ): Promise<any> {
        const response: any = {}

        if (requestType === NdfSubmitRequestType.PERSONAL_PREVIEW) {
            response.personalPreview = true
        } else if (requestType === NdfSubmitRequestType.TEAM_PREVIEW) {
            response.teamPreview = true
        } else if (audience.type === NdfAudienceType.SUBSCRIBER_SEGMENTS) {
            response.segmentIds = audience.includedSegmentIds
        } else {
            response.segmentIds = [this.defaultDomainSegment!.id]
        }

        if (requestType === NdfSubmitRequestType.STANDARD) {
            if (audience.exclusionsEnabled && !!audience.excludedSegmentIds) {
                response.excludedSegmentIds = audience.excludedSegmentIds
            }
        }

        return response
    }

    protected async processDeliverySpecForSubmit(
        requestType: NdfSubmitRequestType,
        schedule: INdfSchedulingValue,
        values: INdfValue,
        confirmPassedTimezoneSends: boolean,
    ): Promise<any> {
        const response = NotificationDeliverySpec.fromSpec({
            type:
                schedule.type! === NotificationDeliveryType.IMMEDIATE
                    ? NotificationDeliveryType.IMMEDIATE
                    : NotificationDeliveryType.SCHEDULED,
            window: this.requestTypeIsPreview(requestType)
                ? NotificationDeliveryWindow.STANDARD
                : schedule.window! || NotificationDeliveryWindow.STANDARD,
        })

        if (this.requestTypeIsPreview(requestType)) {
            response.type = NotificationDeliveryType.IMMEDIATE
        }

        if (response.type === NotificationDeliveryType.SCHEDULED && !!schedule.sendDate) {
            const tz = schedule.timezone || this.appState.currentDomain!.timezone
            response.sendDateUtc = moment.tz(schedule.sendDate, tz).utc().toISOString()
            response.timezone = tz
        }

        if (confirmPassedTimezoneSends && response.window === NotificationDeliveryWindow.TIMEZONE) {
            const tzLimitName = 'Pacific/Kiritimati'
            const scheduledTime = moment.tz(response.sendDateUtc, response.timezone)
            const nowAtLimit = moment.tz(new Date().toISOString(), tzLimitName)
            const format = 'YYYY-MM-DD HH:mm:ss'

            if (nowAtLimit.format(format) > scheduledTime.format(format)) {
                const tzPassedMsg =
                    'The scheduled date for this time zone notification has already passed in at least one of the world’s time zones. Subscribers for which the schedule date has already passed will not receive this notification.'

                const shouldContinue = await new Promise((res) => {
                    Modal.confirm({
                        title: 'Confirm Time Zone Delivery',
                        content: tzPassedMsg,
                        okText: 'Send Anyway',
                        onOk: () => res(true),
                        cancelText: 'Do Not Send',
                        onCancel: () => res(false),
                    })
                })

                if (!shouldContinue) {
                    return
                }
            }
        }

        return response
    }

    protected async processTestForSubmit(
        requestType: NdfSubmitRequestType,
        test: INdfTestValue,
        _values: INdfValue,
        _shell: any,
    ): Promise<any> {
        let response: any

        if (this.state.abEnabled && !!test) {
            response = {
                name: test.name,
                status: StatusType.ACTIVE.name,
                type: test.type || NdfTestType.SPLIT,
                isTimeVariant: test.isTimeVariant || false,
                ctrThreshold: test.ctrThreshold,
            }

            if (response.type === NdfTestType.SAMPLE && !!test.schedule) {
                response.evaluationSchedule = {
                    type: test.schedule.type,
                }

                if (test.schedule.type === NdfTestScheduleType.ELAPSED) {
                    response.evaluationSchedule.$elapsed_metric = 'hour'
                    response.evaluationSchedule.elapsed_seconds = (test.schedule.value as number) * 60 * 60
                } else {
                    // SPECIFIC
                    response.evaluationSchedule.$evaluation_date_timezone =
                        test.schedule.$timezone || this.defaultSuggestedTimezone

                    response.evaluationSchedule.evaluation_date_utc = moment(
                        test.schedule.value,
                        BASE_TIME_FORMAT_WITHOUT_TZ,
                        response.evaluationSchedule.$evaluation_date_timezone,
                    ).toISOString()
                }
            }
        }

        return response
    }

    protected processActionsForSubmit(requestType: NdfSubmitRequestType, actions: INotificationAction & any): any {
        const response: any[] = []

        for (const action of actions) {
            const isOpenUrlAction = action.type === NotificationActionType.OPEN_URL
            const isCloseAction = action.type === NotificationActionType.CLOSE
            const isUsingPrimaryLandingUrl = action.usePrimaryLandingUrl ?? false
            const hasCustomLandingUrl = !!action.landingUrl

            const data = {
                ordinal: action.ordinal,
                type: action.type,
                label: action.label,
                usePrimaryLandingUrl: isUsingPrimaryLandingUrl,
                landingUrl:
                    !isCloseAction && !isUsingPrimaryLandingUrl && hasCustomLandingUrl
                        ? this.buildFinalLandingUrl(action.landingUrl)
                        : undefined,
            }

            if (!data.label || !data.label.trim()) {
                throw {
                    actions: {
                        errors: [{ message: 'Button labels cannot be empty.' }],
                    },
                }
            }

            const hasMissingOrEmptyLandingUrl = !data.landingUrl || !data.landingUrl.trim()
            if (isOpenUrlAction && !isUsingPrimaryLandingUrl && hasMissingOrEmptyLandingUrl) {
                throw {
                    actions: {
                        errors: [{ message: 'Buttons with type of Open URL must have a valid Landing URL.' }],
                    },
                }
            }

            response.push(data)
        }

        return response
    }
    @autobind
    protected async processSubmitRequest(
        requestType: NdfSubmitRequestType = NdfSubmitRequestType.STANDARD,
    ): Promise<any> {
        const domain = this.appState.currentDomain!
        const isPreviewSend = this.requestTypeIsPreview(requestType)
        const mustConfirmImmediateSend = arrayContains(domain.flags, FEAT_IMM_NOTIF_CONFIRM) && !isPreviewSend
        const mustConfirmScheduledSend = arrayContains(domain.flags, FEAT_SCHED_NOTIF_CONFIRM) && !isPreviewSend

        const value = this.currentValue
        const currentTreatment = this.currentSelectedTreatment!
        const currentTreatmentIsValid = await this.validateTreatment(currentTreatment)

        const validation = await this.validateSections()
        if (!validation.ok) return

        const shell: any = {}
        if (!this.currentThemeIsCampaign || isPreviewSend) {
            shell.audience = await this.processAudienceForSubmit(requestType, value.audience!, value)
        }
        if (!this.currentThemeIsCampaign || isPreviewSend) {
            shell.deliverySpec = await this.processDeliverySpecForSubmit(requestType, value.schedule!, value, true)
            if (!shell.deliverySpec) {
                return
            }
        }

        const abTest = await this.processTestForSubmit(requestType, value.test!, value, shell)

        try {
            const notificationDtos: SendNotificationRequestDto[] = []
            const treatmentsToSend = isPreviewSend ? [currentTreatment] : value.treatments!
            const hasAutoKwDiscoveryFlag = arrayContains(domain.flags, FEAT_AUTO_KW_DISCOVERY)

            for (let treatmentIdx = 0; treatmentIdx < treatmentsToSend.length; treatmentIdx++) {
                const treatment = treatmentsToSend[treatmentIdx]

                let keywords: string[] | undefined
                if (treatment.keywords && Array.isArray(treatment.keywords)) {
                    keywords = treatment.keywords.map((kw: string) => kw.trim())

                    const domainKeywords = this.state.availableKeywords || []
                    const domainKeywordNames = domainKeywords.map((kw) => kw.name)
                    const selectedKeywordNames = keywords
                    const newKeywordNames = selectedKeywordNames?.filter((kw) => !arrayContains(domainKeywordNames, kw))

                    const newDomainKeywords = [
                        ...domainKeywords,
                        ...newKeywordNames?.map((kw: string) => ({
                            id: randomstring.generate(),
                            name: kw.toLowerCase(),
                        })),
                    ]

                    this.setState({ availableKeywords: newDomainKeywords as any })
                }

                const landingUrlIsEmpty: boolean = !treatment.landingUrl || String(treatment.landingUrl).trim() === ''
                if (landingUrlIsEmpty || treatment.landingUrlDisabled === true) {
                    treatment.landingUrl = undefined
                } else if (treatment.landingUrl) {
                    treatment.landingUrl = this.buildFinalLandingUrl(treatment.landingUrl)
                }

                const title = !!treatment.title ? treatment.title.trim() : undefined
                const body = !!treatment.body ? treatment.body.trim() : undefined

                const test = clone(abTest)
                if (!!test && !!value.test && !!value.test.distributions) {
                    test.weight = value.test.distributions[treatmentIdx]
                }

                let actions: any
                if (!!treatment.actions) {
                    actions = this.processActionsForSubmit(requestType, treatment.actions)
                }

                let deliverySpec = shell.deliverySpec
                if (value.test?.isTimeVariant) {
                    deliverySpec = await this.processDeliverySpecForSubmit(
                        requestType,
                        treatment.schedule!,
                        value,
                        false,
                    )
                }

                const notificationBuild: any = {
                    ...shell,
                    abTest: isPreviewSend ? undefined : test,
                    source: isPreviewSend ? 'PREVIEW' : (NotificationSource.MANUAL as any),
                    id: treatment.id,
                    meta: {
                        origin_ui: this.currentThemeIsMobile ? 'mobile' : 'new-notif-flow',
                    },
                    template: {
                        channels: {
                            web: {
                                domainId: domain.id,
                                title,
                                body,
                                iconUrl: treatment.icon || null,
                                imageUrl: treatment.image,
                                badgeUrl: treatment.badge,
                                landingUrl: treatment.landingUrl,
                                actions,
                                overrideDefaultActions: !!actions,
                                isSilent: treatment.isSilent ?? false,
                                requireInteraction: treatment.requireInteraction ?? true,
                            },
                        },
                        keywords,
                        auto_keyword_discovery: hasAutoKwDiscoveryFlag ? true : undefined,
                    },
                    deliverySpec: {
                        ...deliverySpec,
                        ttlSeconds: treatment.ttlSeconds,
                        ttlMetric: treatment.ttlMetric || 'days',
                    },
                }

                if (isPreviewSend) {
                    // ensure all previews pass the origin domain id for FC
                    notificationBuild.meta = {
                        ...(notificationBuild.meta ?? {}),
                        origin_domain_id: this.appState.currentDomain!.id,
                    }

                    // add any addt meta passed from the consumer component
                    if (!!this.props.previewMeta && Object.keys(this.props.previewMeta).length > 0) {
                        notificationBuild.meta = {
                            ...(notificationBuild.meta ?? {}),
                            ...this.props.previewMeta,
                        }
                    }
                }

                notificationDtos.push(notificationBuild)
            }

            const verified = await this.verifySubmitProcess(shell, mustConfirmImmediateSend, mustConfirmScheduledSend)
            if (verified) {
                const completeStatus = await this.completeSubmitProcess(requestType, notificationDtos, abTest)

                if (!isPreviewSend) {
                    // Pass through if requested
                    if (this.props.onSubmit) {
                        this.props.onSubmit!(completeStatus, this.currentValue)
                        return true
                    }

                    // Status completed - redirect to next page
                    if (!this.currentThemeIsCampaign && !!completeStatus) {
                        const path = this.props.completionRedirect || '/notifications'
                        this.appService.routeWithinDomain(path)
                    }
                }
            }
        } catch (error) {
            if (!currentTreatmentIsValid) {
                error.treatment = currentTreatment
            }

            simpleFormErrorNotification(error)
        }

        return false
    }

    protected async verifySubmitProcess(
        shell: any,
        mustConfirmImmediateSend: boolean,
        mustConfirmScheduledSend: boolean,
    ): Promise<any> {
        if (this.currentThemeIsCampaign) {
            return true
        }

        let isAllSend = false
        let includedSegments: SegmentDto[] = []
        let excludedSegments: SegmentDto[] = []

        if (!!shell && !!shell.audience) {
            if ('segmentIds' in shell.audience) {
                const ids = shell.audience.segmentIds
                includedSegments = this.state.availableSegments.filter((s) => arrayContains(ids, s.id))

                if (includedSegments.length === 1 && includedSegments[0].isDefault) {
                    isAllSend = true
                }
            }

            if ('excludedSegmentIds' in shell.audience) {
                const ids = shell.audience.excludedSegmentIds
                excludedSegments = this.state.availableSegments.filter((s) => arrayContains(ids, s.id))
            }
        }

        const audienceDisplay = (
            <div>
                <br />

                {isAllSend ? (
                    <p>
                        Targeted Segments:
                        <ul>
                            <li>All Subscribers</li>
                        </ul>
                    </p>
                ) : (
                    <p>
                        Targeted Segments:
                        <ul>
                            {includedSegments.map((s) => (
                                <li key={s.id}>{s.name}</li>
                            ))}
                        </ul>
                    </p>
                )}

                {excludedSegments.length > 0 && (
                    <p>
                        Excluded Segments:
                        <ul>
                            {excludedSegments.map((s) => (
                                <li key={s.id}>{s.name}</li>
                            ))}
                        </ul>
                    </p>
                )}
            </div>
        )

        return new Promise(async (res) => {
            const isSTZ = shell.deliverySpec.window === NotificationDeliveryWindow.TIMEZONE

            if (isSTZ && this.deliveryInProgress) {
                Modal.confirm({
                    title: 'Confirm Update',
                    content: (
                        <div>
                            Changes made to this notification will take effect for subscribers in time zones that have
                            not yet began delivery. Are you sure you want to make these changes?
                        </div>
                    ),
                    okText: `Yes, I'm Sure`,
                    cancelText: 'Cancel',
                    onOk: () => res(true),
                    onCancel: () => res(false),
                })
            } else if (shell.deliverySpec.type === NotificationDeliveryType.IMMEDIATE && mustConfirmImmediateSend) {
                Modal.confirm({
                    title: 'Confirm Immediate Delivery',
                    content: (
                        <div>
                            Are you sure you want to send this notification <b>immediately</b> to{' '}
                            {numberWithCommas(this.state.reachEstimate || 0)} subscribers?
                            {audienceDisplay}
                        </div>
                    ),
                    okText: 'Yes, Send Immediately',
                    cancelText: 'Cancel',
                    onOk: () => res(true),
                    onCancel: () => res(false),
                })
            } else if (shell.deliverySpec.type === NotificationDeliveryType.SCHEDULED && mustConfirmScheduledSend) {
                const scheduledDateTime = moment
                    .tz(shell.deliverySpec.sendDateUtc, shell.deliverySpec.timezone)
                    .format(isSTZ ? `${BASE_TIME_FORMAT_WITHOUT_TZ} [STZ]` : BASE_TIME_FORMAT)

                Modal.confirm({
                    title: 'Confirm Scheduled Delivery',
                    content: (
                        <div>
                            Are you sure you want to schedule this notification to be sent to{' '}
                            {numberWithCommas(this.state.reachEstimate || 0)} subscribers at {scheduledDateTime}?
                            {audienceDisplay}
                        </div>
                    ),
                    okText: 'Yes, Schedule Delivery',
                    cancelText: 'Cancel',
                    onOk: () => res(true),
                    onCancel: () => res(false),
                })
            } else {
                res(true)
            }
        })
    }

    protected async completeSubmitProcess(
        requestType: NdfSubmitRequestType,
        dtos: SendNotificationRequestDto[],
        test: any,
    ): Promise<any> {
        const isPreviewRequest = this.requestTypeIsPreview(requestType)
        const domain = this.appState.currentDomain!
        const abTestEnabled = this.state.abEnabled
        let responder: any

        if (!isPreviewRequest && this.currentThemeIsCampaign) {
            // Campaign short circuits standard response
            //
            responder = dtos
        } else {
            if (!abTestEnabled) {
                // Single Notification send
                //
                const notif = dtos[0]

                if (!isPreviewRequest && this.currentMode === NdfFormMode.EDIT) {
                    responder = this.notificationService.updateNotification(domain.id, this.props.template!.id, notif)
                } else {
                    responder = this.notificationService.sendNotification(domain.id, notif)
                }
            } else {
                // Multivariate Notification send
                //
                if (!isPreviewRequest && this.currentMode === NdfFormMode.EDIT) {
                    // Process standard A/B update
                    //
                    responder = this.notificationService.updateTest(domain.id, this.props.test.id, test, dtos)
                } else {
                    if (isPreviewRequest) {
                        // Preview requests for A/B Testing results in a send for each notification
                        //
                        const calls: any = []

                        dtos.forEach((dto) => {
                            calls.push(this.notificationService.sendNotification(domain.id, dto))
                        })

                        responder = Promise.all(calls)
                    } else {
                        // Process standard A/B create
                        //
                        responder = this.notificationService.createTest(domain.id, test, dtos)
                    }
                }
            }
        }

        return responder
    }

    protected buildFinalLandingUrl(landingUrl: string): string | undefined {
        let finalLandingUrl: string | undefined

        if (landingUrl) {
            finalLandingUrl = landingUrl

            const protocolRgx = /^http[s]?:\/\//i
            if (
                finalLandingUrl &&
                !isMacroMatch(finalLandingUrl, 'item.url') &&
                !protocolRgx.test(String(finalLandingUrl))
            ) {
                finalLandingUrl = `http://${finalLandingUrl}`
            }
        }

        return finalLandingUrl
    }

    protected async ensureInitialValue(): Promise<void> {
        if (!!this.props.test) {
            const state: any = {
                value: this.currentValue,
            }

            if (!!state.value.test) {
                state.abEnabled = true
            }

            await this.setState(state)
        }

        this.updateCurrentTreatmentIdx()
        this.fetchReachEstimate()
    }

    protected updateCurrentTreatmentIdx(): void {
        if (!!this.props.template && !!this.props.test) {
            const notifs: NotificationDto[] = (this.props.test.notifications || []).filter(
                (n: NotificationDto) => !!n.abTest && !n.abTest.winnerOriginId,
            )
            const idx = notifs.findIndex((n) => n.id === this.props.template!.id)

            if (idx !== -1) {
                this.setState({ currentTreatmentIdx: idx }).then()
            }
        }
    }

    protected extractNotificationTreatment(notification: NotificationDto): INotificationTreatment {
        const deliverySpec = notification.deliverySpec || ({} as any)
        const template = notification.defaultTemplate || ({} as any)
        const advancedActionsEnabled = template.overrideDefaultActions ?? false

        let schedule: INdfSchedulingValue | undefined

        if (this.props.test?.isTimeVariant) {
            schedule = {
                type: notification.deliverySpec.type,
                window: notification.deliverySpec.window,
                sendDate: notification.deliverySpec.sendDateUtc,
                timezone: notification.deliverySpec.timezone,
            }
        }

        return {
            id: (notification.id as any) || randomstring.generate(5),
            title: template.title || undefined,
            body: template.body || undefined,
            image: template.imageUrl || undefined,
            icon: template.iconUrl || template.isUsingDomainDefaultIcon ? this.defaultDomainIconUrl : undefined,
            badge: template.badgeUrl || template.isUsingDomainDefaultIcon ? this.defaultDomainBadgeUrl : undefined,
            landingUrl: template.landingUrl || undefined,
            landingUrlDisabled: !template.landingUrl,
            actions: advancedActionsEnabled ? template.actions : undefined,
            advancedActionsEnabled,
            keywords: notification.keywords,
            ttlSeconds: deliverySpec.ttlSeconds,
            ttlMetric: deliverySpec.ttlMetric ?? 'days',
            isSilent: template.isSilent ?? false,
            requireInteraction: template.requireInteraction ?? true,
            schedule,
        }
    }

    protected async fetchAvailableSegments(showLoadingScreen?: boolean): Promise<void> {
        if (!this.currentThemeIsCampaign) {
            if (!this.appState.currentDomain) {
                setTimeout(() => this.fetchAvailableSegments(), 100)
                return
            }

            const { data: availableSegments } = await this.domainService.fetchSegmentsByDomainId(
                this.appState.currentDomain.id,
                {
                    query: {
                        pagination: 0,
                        fields: 'id,name,source,computedStatus,isDefault,iconUrl',
                    },
                    showLoadingScreen,
                },
            )

            await this.setState({
                loadingSegments: false,
                availableSegments: availableSegments.filter((s) => s.computedStatus === 'READY') as any,
            })

            this.fetchReachEstimate().then()
        }
    }

    protected async fetchAvailableKeywords(): Promise<void> {
        if (!this.appState.currentDomain) {
            setTimeout(() => this.fetchAvailableKeywords(), 100)
            return
        }

        const availableKeywords =
            (await this.domainService.fetchKeywordsByDomainId(this.appState.currentDomain.id, {
                query: { pagination: 0 },
            })) || []

        await this.setState({
            loadingKeywords: false,
            availableKeywords: availableKeywords.data,
        })
    }

    protected async fetchReachEstimate() {
        if (!this.currentThemeIsCampaign) {
            const audience = this.state.value?.audience ?? {}
            const audienceType = audience.type ?? NdfAudienceType.ALL_SUBSCRIBERS

            const domain = this.appState.currentDomain!
            let exIds = audience.excludedSegmentIds ?? []
            let inIds = audience.includedSegmentIds ?? []

            if (audienceType === NdfAudienceType.ALL_SUBSCRIBERS && !!this.defaultDomainSegment?.id) {
                inIds = [this.defaultDomainSegment.id]
            }

            if (
                !!this.currentAudienceValue.includedSegmentIds &&
                !deepEqual(this.currentAudienceValue.includedSegmentIds, inIds)
            ) {
                inIds = this.currentAudienceValue.includedSegmentIds
            }
            if (
                !!this.currentAudienceValue.excludedSegmentIds &&
                !deepEqual(this.currentAudienceValue.excludedSegmentIds, exIds)
            ) {
                exIds = this.currentAudienceValue.excludedSegmentIds
            }

            const reachEstimateQuery = { inIds, exIds }
            if (!deepEqual(reachEstimateQuery, this.state.lastReachEstimateQuery)) {
                const update = {
                    updatingReachEstimate: true,
                } as INdfState

                await this.setState(update)

                const res = await this.domainService.estimateSegmentReach(
                    domain.id,
                    inIds,
                    exIds,
                    [DeliveryChannel.WEB],
                    'count',
                    { cancellationKey: 'ndf.reach.update' },
                )

                if (!res.cancelled) {
                    update.updatingReachEstimate = false

                    if (res.ok && res.data !== this.state.reachEstimate) {
                        update.lastReachEstimateQuery = reachEstimateQuery
                        update.reachEstimate = res.data
                    }

                    this.setState(update)
                }
            }
        }
    }

    protected async registerUserPuuid(): Promise<void> {
        const platformUser = this.appState.currentUser!
        const platformDomain = this.appState.currentDomain!
        const sdkUser: any = PushlySDK.context.user
        const sdkDomain: any = PushlySDK.context.domain

        const currentRegistrations: any[] = platformUser.puuids || []

        const permission = await new Promise<string>(async (res) => {
            if (!(await sdkUser.isSubscribed({ websitePushId: sdkDomain.getWebsitePushId() }))) {
                PushlySDK.on('subscribed', async () => {
                    // Allow 3 secs for ES doc to be updated
                    await pause(3000)
                    res('granted')
                })

                PushlySDK.on('permission_dismissed', () => res('dismissed'))
                PushlySDK.on('permission_denied', () => res('denied'))
                PushlySDK.push(['show_prompt'])
            } else {
                res('granted')
            }
        })

        if (permission === 'granted') {
            const existingRegistration = currentRegistrations.find(
                (r) => r.puuid === sdkUser.puuid && r.domainId === platformDomain.id,
            )

            if (!existingRegistration) {
                await this.userService.registerPuuid(platformUser.id, platformDomain.id, sdkUser.puuid)
            }
        } else if (permission === 'denied') {
            simpleNotification(
                'error',
                `We are unable to send you a preview 
                of this notification because you have denied notification 
                permissions. Please manually reset your browser permissions 
                and attempt to send the preview again.`,
            )
        }
    }

    protected buildClassName(className: string, extras?: string): string {
        className = `${this.defaultClassName}-${className}`
        if (!!extras) className = `${className} ${extras}`
        return className
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [
            this.defaultClassName,
            `theme-${this.currentTheme.toLowerCase()}`,
            `mode-${this.currentMode.toLowerCase()}`,
        ]

        if (!!this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
