import * as React from 'react'
import * as clone from 'clone'
import { Well } from '@pushly/aqe/lib/components'
import { AppState } from '../../../stores/app'
import { AppService, DomainService } from '../../../services/index'
import { Container } from 'typescript-ioc/es5'
import { NdfFormMode } from '../enums/ndf-form-mode.enum'
import { NdfFormTheme } from '../enums/ndf-form-theme.enum'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Skeleton, Select, Tooltip } from 'antd'
import { INdfSectionValidationResponse } from '../interfaces/ndf-section-validation-response'
import { EcommItemPickStrategy } from '../../../enums/ecomm-item-pick-strategy'
import { INdfValue } from '../interfaces/ndf-value'

interface INdfEcommSectionProps {
    className?: string
    mode: NdfFormMode
    theme: NdfFormTheme
    value: any
    onChange: (value: any) => any

    loading?: boolean
    deliveryInProgress?: boolean
}

interface INdfEcommSectionState {
    ctrThresholdValue?: number | string
}

export class EcommSection extends React.Component<INdfEcommSectionProps, INdfEcommSectionState> {
    public readonly defaultClassName: string = 'sw-v2-ndf-section'

    protected appState: AppState
    protected appService: AppService
    protected domainService: DomainService

    protected distributionRef: any
    protected debounceTimer: any
    protected debounceValue: 320

    public constructor(props: INdfEcommSectionProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)

        this.state = {}
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames('ecomm')}>
                <div className={this.buildClassName('wrapper')}>
                    <Well className="nested" title="Ecomm Configuration" hideFooter={true}>
                        <Skeleton
                            active={true}
                            title={false}
                            avatar={false}
                            paragraph={{ rows: 3 }}
                            loading={this.isLoading}
                        >
                            <Well
                                title={
                                    <span>
                                        <span>Item Selection</span>
                                        <Tooltip
                                            title={
                                                <span>
                                                    Determines how the cart item that will be included within the
                                                    notification will be chosen.
                                                </span>
                                            }
                                        >
                                            <QuestionCircleOutlined className="info-icon" />
                                        </Tooltip>
                                    </span>
                                }
                                mode="ghost"
                                hideFooter={true}
                            >
                                <Select
                                    className="ndf-ecomm-strategy-select"
                                    dropdownClassName="ndf-ecomm-strategy-dropdown"
                                    placeholder="Select a strategy"
                                    value={this.currentValue.ecomm?.itemPickStrategy ?? EcommItemPickStrategy.RANDOM}
                                    onChange={this.handleStrategyChagne}
                                    disabled={this.deliveryInProgress}
                                >
                                    <Select.Option value={EcommItemPickStrategy.RANDOM}>Random</Select.Option>
                                    <Select.Option value={EcommItemPickStrategy.HIGHEST_PRICE}>
                                        Highest Price
                                    </Select.Option>
                                    <Select.Option value={EcommItemPickStrategy.LOWEST_PRICE}>
                                        Lowest Price
                                    </Select.Option>
                                    <Select.Option value={EcommItemPickStrategy.MOST_RECENT}>Most Recent</Select.Option>
                                </Select>
                            </Well>
                        </Skeleton>
                    </Well>
                </div>
            </div>
        )
    }

    // All of this logic sits within the API as well
    public validate(): INdfSectionValidationResponse {
        const response: INdfSectionValidationResponse = { ok: true }

        if (!this.currentValue.ecomm?.itemPickStrategy) {
            response.ok = false
            response.error = 'Please provide a name for your Notification Test.'
        }

        return response
    }

    public clear(): void {
        if (!!this.distributionRef) this.distributionRef.reset()
    }

    protected get isLoading(): boolean {
        return this.props.loading || false
    }

    protected get currentMode(): NdfFormMode {
        return this.props.mode
    }

    protected get currentTheme(): NdfFormTheme {
        return this.props.theme
    }

    protected get currentValue(): INdfValue {
        return clone({
            ...this.props.value,
        })
    }

    protected get deliveryInProgress(): boolean {
        return this.props.deliveryInProgress || false
    }

    protected handleStrategyChagne = (itemPickStrategy: EcommItemPickStrategy): void => {
        const changes: Partial<INdfValue> = {
            ecomm: {
                itemPickStrategy,
            },
        }

        this.emitChange(changes)
    }

    protected emitChange(changes: Partial<INdfValue>): void {
        const change = {
            ...this.currentValue,
            ...changes,
        }

        this.props.onChange(change)
    }

    protected buildClassName(className: string, extras?: string): string {
        className = `${this.defaultClassName}-${className}`
        if (!!extras) className = `${className} ${extras}`
        return className
    }

    protected buildRootClassNames(sectionName: string): string {
        const classNames: string[] = [
            this.defaultClassName,
            `section-${sectionName}`,
            `theme-${this.currentTheme.toLowerCase()}`,
            `mode-${this.currentMode.toLowerCase()}`,
        ]

        if (!!this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }

    protected debounce(fn: Function, timeout?: number) {
        if (!!this.debounceTimer) clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(fn, timeout ?? this.debounceValue)
    }
}
