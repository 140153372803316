import { Can } from '@casl/react'
import { Ability } from '@casl/ability'
import { computed, IReactionDisposer, observable, when } from 'mobx'
import { inject } from 'mobx-react'
import { Singleton } from 'typescript-ioc/es5'
import { AbilityAction } from '../enums/ability-action.enum'
import { SubjectEntity } from '../enums/ability-entity.enum'
import { AppState } from './app'
import AccessControlService from '../services/access-control.service'
import { DomainDto } from '../dtos/domain'
import { AccountDto } from '../dtos/account.dto'
import { UserDto } from '../dtos/user'
import { SegmentModel } from '../models/segments/segment.model'
import {
    addVisibilityAwareIntervalRunner,
    LastRunTimestampUpdater,
    VisibilityAwareIntervalCleaner,
} from '../_utils/visibility-api'

const ABILITY_REFRESH_INTERVAL = 300000

const emptyAbilitySet: AppAbility = new Ability()

// TODO-RBAC
//  - remove helper once all entities have valid dtos/models
export interface ICaslSubject<T extends SubjectEntity> {
    __caslSubjectType__: T | string
}

export type AppAbility = Ability<[AbilityAction, any]>

type KnownIdentity = DomainDto | AccountDto | UserDto | SegmentModel
export const asCaslSubject = <T extends SubjectEntity>(type: T, conditions: any): ICaslSubject<T> =>
    ({
        __caslSubjectType__: type,
        ...conditions,
    }) as any

@Singleton
export class AbilityStore {
    public acs: AccessControlService

    protected abilitiesLoader: Promise<void> | undefined
    protected abilitiesLoaded: boolean = false
    protected autoRefreshHandler:
        | {
              cleanup: VisibilityAwareIntervalCleaner
              updateLastRunTimestamp: LastRunTimestampUpdater
          }
        | undefined

    @observable
    protected _abilities: AppAbility = emptyAbilitySet

    protected disposers: {
        load?: IReactionDisposer
        flush?: IReactionDisposer
    } = {}

    public constructor(protected readonly appState: AppState) {
        this.setupEventWatchers()
        this.loadAbilitiesForCurrentUser()
    }

    @computed
    public get abilities() {
        return this._abilities
    }

    /**
     * generates basic AbilityIdentity for the currently active domain's org
     */
    public get currentOrgIdentity(): ICaslSubject<SubjectEntity.ORG> {
        return asCaslSubject(SubjectEntity.ORG, {
            id: this.appState.currentDomain!.accountId,
            statusId: this.appState.currentDomain!.statusId,
        })
    }

    /**
     * generates basic AbilityIdentity for the currently active domain
     */
    public get currentDomainIdentity(): ICaslSubject<SubjectEntity.DOMAIN> {
        return asCaslSubject(SubjectEntity.DOMAIN, {
            id: this.appState.currentDomain?.id,
            statusId: this.appState.currentDomain?.statusId,
        })
    }

    /**
     * generates basic AbilityIdentity for the currently active user
     */
    public get currentUserIdentity(): ICaslSubject<SubjectEntity.USER> {
        return asCaslSubject(SubjectEntity.USER, {
            id: this.appState.currentUser!.id,
        })
    }

    /**
     * passthrough to @casl/ability can(...)
     */
    public can<T extends SubjectEntity>(
        action: AbilityAction,
        subject: T | ICaslSubject<T> | KnownIdentity,
        field?: string,
    ): boolean {
        return this._abilities.can(action, subject, field)
    }

    /**
     * passthrough to @casl/ability cannot(...)
     */
    public cannot<T extends SubjectEntity>(
        action: AbilityAction,
        subject: T | ICaslSubject<T> | KnownIdentity,
        field?: string,
    ): boolean {
        return this._abilities.cannot(action, subject, field)
    }

    /**
     * generates basic AbilityIdentity for given entity type
     * using the currently active domain's org id
     */
    public getOrgOwnedIdentityFor<T extends SubjectEntity>(type: T) {
        return asCaslSubject<T>(type, { accountId: this.appState.currentDomain!.accountId })
    }

    /**
     * generates basic AbilityIdentity for given entity type
     * using the currently active domain's id
     */
    public getDomainOwnedIdentityFor<T extends SubjectEntity>(type: T) {
        return asCaslSubject<T>(type, { domainId: this.appState.currentDomain?.id })
    }

    /**
     * force refresh abilities from api layer
     */
    public async refresh() {
        this._abilities = await this.acs.getAbilities(true)
        this.autoRefreshHandler?.updateLastRunTimestamp()
    }

    /**
     * Removes abilities and resets state.
     * should only be used when user is logged out
     * of the application.
     */
    public flush = () => {
        if (this.abilitiesLoaded) {
            // reset abilities and loaded state
            this.abilitiesLoaded = false
            this._abilities = emptyAbilitySet

            // cleanup and remove visibility auto refresh handler
            this.autoRefreshHandler?.cleanup()
            this.autoRefreshHandler = undefined

            // reset load event watcher
            this.setupEventWatchers()
        }
    }

    /**
     * Waits for current user to be loaded.
     * Once available permissions are requested
     * and loaded into the current scope.
     */
    public async loadAbilitiesForCurrentUser() {
        if (this.appState.currentUser && !this.abilitiesLoaded) {
            this.abilitiesLoader = new Promise(async (res) => {
                this.abilitiesLoaded = true
                /**
                 * modifying the load count before login will
                 * result in a brief UI flash.
                 */
                let shouldAlterAppLoadingCount = this.appState.isAuthenticated

                try {
                    if (shouldAlterAppLoadingCount) {
                        this.appState.loadingCount++
                    }

                    this.acs = AccessControlService.forUser(this.appState.currentUser!)
                    this._abilities = await this.acs.getAbilities()

                    // stop watching for load event
                    this.disposers.load?.()
                } catch (err) {
                    this.abilitiesLoaded = false

                    if (process.env.IS_LOCAL) {
                        console.debug('ability_store_error', err)
                    }
                } finally {
                    if (shouldAlterAppLoadingCount) {
                        this.appState.loadingCount--
                    }

                    res()

                    this.abilitiesLoader = undefined
                }
            })
        }

        return this.abilitiesLoader
    }

    protected setupEventWatchers() {
        this.disposers.load = when(
            () => !!this.appState.currentUser?.id,
            async () => {
                await this.loadAbilitiesForCurrentUser()
                this.initializeAbilitiesRefresher()
            },
        )
    }

    protected initializeAbilitiesRefresher() {
        if (!this.autoRefreshHandler) {
            const [cleanup, updateTS] = addVisibilityAwareIntervalRunner(
                'user-permissions',
                async () => this.refresh(),
                ABILITY_REFRESH_INTERVAL,
                true,
            )

            this.autoRefreshHandler = {
                cleanup,
                updateLastRunTimestamp: updateTS,
            }
        }
    }
}

/**
 * bound fn component for @casl/react <Can ... />
 * using the injected abilities
 */
interface IInjectedAbilityStore {
    ['AbilityStore']?: AbilityStore
}
export const CurrentUserCan = inject((allStores: IInjectedAbilityStore) => ({
    ability: allStores.AbilityStore?.abilities ?? new Ability(),
}))(class extends Can<AppAbility, true> {})
