import * as React from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import Icon, { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Tooltip } from 'antd'
import { Modal } from '@pushly/aqe/lib/components'
import autobind from 'autobind-decorator'
import { ButtonProps } from 'antd/lib/button'
import './async-button.scss'
import * as randomstring from 'randomstring'

interface IProps {
    onClick: (ev?: any) => any | Promise<any>
    actions?: any[]
    triggerSubmitOnEnter?: boolean
    altHref?: string
    confirm?: any
    color?: string
}

interface IState {
    isLoading: boolean
}

export class AsyncButton extends React.Component<IProps & ButtonProps, IState> {
    public state = {
        isLoading: false,
    }

    private unmounting: boolean = false

    public componentDidMount() {
        if (this.props.triggerSubmitOnEnter) {
            document.addEventListener('keydown', this.handleKeyDown)
        }
    }

    public componentWillUnmount() {
        this.unmounting = true

        if (this.props.triggerSubmitOnEnter) {
            document.removeEventListener('keydown', this.handleKeyDown)
        }
    }

    public render() {
        const {
            className,
            triggerSubmitOnEnter,
            actions,
            children,
            altHref,
            icon: primaryIcon,
            color,
            ...props
        } = this.props

        const classNames: string[] = ['sw-async-btn']
        if (className) {
            classNames.push(className)
        }
        if (actions?.length) {
            classNames.push('with-actions')
        }

        let iconType = primaryIcon
        let iconTheme: any
        const iconProps: any = {}
        if (!!primaryIcon && typeof primaryIcon === 'string') {
            const iconParts = primaryIcon.split(':')
            iconType = iconParts[0]

            if (iconParts.length > 1) {
                iconTheme = iconParts[1]
            }

            if (!!color) {
                iconProps.style = { color }
            }
        }

        const primaryContent = (
            <>
                {iconType &&
                    (typeof iconType === 'string' ? (
                        <LegacyIcon type={iconType} theme={iconTheme} {...iconProps} />
                    ) : (
                        <LegacyIcon component={iconType as any} />
                    ))}
                {children}
            </>
        )

        return actions?.length ? (
            <React.Fragment>
                <Button
                    {...props}
                    className={classNames.join(' ')}
                    loading={this.state.isLoading}
                    onClick={this.onClick}
                >
                    {altHref ? (
                        <a onClick={this.preventDefault} href={altHref}>
                            {primaryContent}
                        </a>
                    ) : (
                        primaryContent
                    )}
                </Button>
                <Dropdown
                    placement="bottomRight"
                    overlay={
                        <Menu className="sw-async-actions-dropdown" onClick={this.handleDropdownActionClick}>
                            {actions.map((action: any) => {
                                const {
                                    divider,
                                    text,
                                    altHref: actionAltHref,
                                    icon,
                                    onClick,
                                    confirm,
                                    confirmWithText,
                                    confirmWithBody,
                                    disabledMessage,
                                    ...actionProps
                                } = action

                                if (divider) {
                                    return <Menu.Divider key={`div_${randomstring.generate()}`} />
                                }

                                const itemContent = actionAltHref ? (
                                    <a onClick={this.preventDefault} href={actionAltHref}>
                                        {icon &&
                                            (typeof icon === 'string' ? (
                                                <LegacyIcon type={icon} />
                                            ) : (
                                                <Icon component={icon} />
                                            ))}
                                        <span>{text}</span>
                                    </a>
                                ) : (
                                    <span>
                                        {icon &&
                                            (typeof icon === 'string' ? (
                                                <LegacyIcon type={icon} />
                                            ) : (
                                                <Icon component={icon} />
                                            ))}
                                        <span>{text}</span>
                                    </span>
                                )

                                return (
                                    <Menu.Item key={text} {...actionProps}>
                                        {actionProps.disabled && !!disabledMessage ? (
                                            <Tooltip title={disabledMessage}>{itemContent}</Tooltip>
                                        ) : (
                                            itemContent
                                        )}
                                    </Menu.Item>
                                )
                            })}
                        </Menu>
                    }
                >
                    <Button type={props.type} size={props.size}>
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </React.Fragment>
        ) : (
            <Button {...props} className={classNames.join(' ')} loading={this.state.isLoading} onClick={this.onClick}>
                {altHref ? (
                    <a onClick={this.preventDefault} href={altHref}>
                        {primaryContent}
                    </a>
                ) : (
                    primaryContent
                )}
            </Button>
        )
    }

    @autobind
    private async handleKeyDown(e: any) {
        if (e.key && e.key.toUpperCase() === 'ENTER') {
            await this.onClick()
        }
    }

    @autobind
    private async handleDropdownActionClick(item: any, confirmed?: boolean) {
        this.setState({ isLoading: true })
        const ev = item.domEvent
        const action = this.props.actions!.find((a) => (a.key || a.text) === item.key)
        const shouldPerformNative = action.altHref && ev && ev.metaKey

        if (!shouldPerformNative) {
            if ((!action.confirmWithBody && !action.confirmWithText && !action.confirm) || confirmed === true) {
                await action.onClick(item.item)
            } else {
                if (action.confirm) {
                    const confirmationCheck = Modal.confirm({
                        okText: 'Confirm',
                        ...action.confirm,
                        onOk: async () => this.handleDropdownActionClick(item, true),
                    })
                }
                if (action.confirmWithText) {
                    const confirmationCheck = (Modal as any).confirmWithTextInput({
                        okText: 'Confirm',
                        ...action.confirmWithText,
                        onOk: async () => this.handleDropdownActionClick(item, true),
                    })
                }
                if (action.confirmWithBody) {
                    const confirmationCheck = (Modal as any).confirmWithCustomBody({
                        okText: 'Confirm',
                        ...action.confirmWithBody,
                        onOk: async () => this.handleDropdownActionClick(item, true),
                    })
                }
            }

            this.setState({ isLoading: false })
        }
    }

    private preventDefault(ev?: any) {
        if (!ev || !ev.metaKey) {
            if (ev && ev.preventDefault) {
                ev.preventDefault()
            }
        }
    }

    @autobind
    private async onClick(ev?: any, confirmed?: boolean) {
        const shouldPerformNative = this.props.altHref && (ev || {}).metaKey

        if (!shouldPerformNative) {
            if (ev) this.preventDefault(ev)
            if (this.state.isLoading) {
                return
            }

            if (!this.props.confirm || confirmed === true) {
                this.setState({ isLoading: true })
                await this.props.onClick()
                !this.unmounting && this.setState({ isLoading: false })
            } else {
                if (this.props.confirm) {
                    const confirmationCheck = Modal.confirm({
                        okText: 'Confirm',
                        ...this.props.confirm,
                        onOk: async () => this.onClick(null, true),
                    })
                }
            }
        }
    }
}
