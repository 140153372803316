import { IUserChannelPreferences } from './interfaces/user-channel-preference'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export const APPLICATION_VERSION = process.env.APPLICATION_VERSION || 'local'
export const STRIPE_API_KEY: string = process.env.STRIPE_API_KEY!

export const APP_BASE_ROUTE = '/'
export const AUTH_REDIRECT_PARAM = 'redirect'
export const REF_QUERY_PARAM = 'ref'
export const AUTH0_PLATFORM_REDIRECT_PATHNAME = '/authorize'
export const AUTH0_STATE_SESSION_KEY = 'sw_a0st'

export const SHORT_DATE_FORMAT = 'll'
export const BASE_DATE_FORMAT = 'LL'
export const BASE_TIME_FORMAT = 'll LT z'
export const BASE_TIME_ONLY_FORMAT = 'LT z'
export const BASE_TIME_ONLY_FORMAT_WITHOUT_TZ = 'LT'
export const BASE_TIME_FORMAT_WITHOUT_TZ = 'll LT'

export const INSIGHTS_DELIVERY_COLOR = '#555'
export const INSIGHTS_IMPS_COLOR = '#0077a0'
export const INSIGHTS_DR_COLOR = '#6aa7cc'
export const INSIGHTS_CLICKS_COLOR = '#722ED1'
export const INSIGHTS_CTR_COLOR = '#AC99D1'
export const INSIGHTS_SUBS_COLOR = '#3DBD7D'
export const INSIGHTS_SR_COLOR = '#7ebd93'
export const INSIGHTS_UNSUBS_COLOR = '#FFBF00'
export const INSIGHTS_IOS_COLOR = '#4f4f4f'
export const INSIGHTS_ANDROID_COLOR = '#63c1a7'
export const INSIGHTS_WEB_COLOR = '#f38454'

// FLAGS
export const DEMO = 'DEMO'
export const FEAT_ABANDONED_VIEW_CAMPAIGNS = 'FEAT_ABANDONED_VIEW_CAMPAIGNS'
export const FEAT_ALLOW_TIMEZONE_DELIVERY = 'FEAT_ALLOW_TIMEZONE_DELIVERY'
export const FEAT_AMPLY_DEFAULT_AD_COLLAPSE_KEY = 'FEAT_AMPLY_DEFAULT_AD_COLLAPSE_KEY'
export const FEAT_ACCOUNT_SECURITY_TAB = 'FEAT_ACCOUNT_SECURITY_TAB'
export const FEAT_AUTO_KW_DISCOVERY = 'FEAT_AUTO_KW_DISCOVERY'
export const FEAT_CALCULATE_PAGE_AUDIENCE_VISITS = 'FEAT_CALCULATE_PAGE_AUDIENCE_VISITS'
export const FEAT_CAMPAIGN_ITEM_GROUPS = 'FEAT_CAMPAIGN_ITEM_GROUPS'
export const FEAT_CAMPAIGN_WEBHOOK_ACTIONS = 'FEAT_CAMPAIGN_WEBHOOK_ACTIONS'
export const FEAT_CUSTOM_VAPID = 'FEAT_CUSTOM_VAPID'
export const FEAT_DEFAULT_TIMEZONE_DELIVERY = 'FEAT_DEFAULT_TIMEZONE_DELIVERY'
export const FEAT_DOMAIN_FALLBACK_NOTIFICATION = 'FEAT_DOMAIN_FALLBACK_NOTIFICATION'
export const FEAT_DOMAIN_INTEGRATIONS = 'FEAT_DOMAIN_INTEGRATIONS'
export const FEAT_DOMAIN_INTEGRATIONS_ECOMM = 'FEAT_DOMAIN_INTEGRATIONS_ECOMM'
export const FEAT_DOMAIN_INTEGRATIONS_SLACK = 'FEAT_DOMAIN_INTEGRATIONS_SLACK'
export const FEAT_DOMAIN_INTEGRATIONS_PUB_NETWORK = 'FEAT_DOMAIN_INTEGRATIONS_PUB_NETWORK'
export const FEAT_IMM_NOTIF_CONFIRM = 'FEAT_IMM_NOTIF_CONFIRM'
export const FEAT_INFORMED_CONTENT = 'FEAT_INFORMED_CONTENT'
export const FEAT_INLINE_SEG = 'FEAT_INLINE_SEG'
export const FEAT_NOTIF_ACTIONS = 'FEAT_NOTIF_ACTIONS'
export const FEAT_NOTIF_DUPLICATE_CHECKS = 'FEAT_NOTIF_DUPLICATE_CHECKS'
export const FEAT_NOTIF_FIELD_SUGGESTIONS = 'FEAT_NOTIF_FIELD_SUGGESTIONS'
export const FEAT_NOTIF_INF_DELIVERY = 'FEAT_NOTIF_INF_DELIVERY'
export const FEAT_NOTIF_REQUIRE_INTERACTION_TOGGLE = 'FEAT_NOTIF_REQUIRE_INTERACTION_TOGGLE'
export const FEAT_NOTIF_SOURCE_FILTERING = 'FEAT_NOTIF_SOURCE_FILTERING'
export const FEAT_MULTI_DOMAIN_NOTIFICATIONS = 'FEAT_MULTI_DOMAIN_NOTIFICATIONS'
export const FEAT_PREFERENCES = 'FEAT_PREFERENCES'
export const FEAT_RESTRICT_ALL_SUB_NOTIFS = 'FEAT_RESTRICT_ALL_SUB_NOTIFS'
export const FEAT_SCHED_NOTIF_CONFIRM = 'FEAT_SCHED_NOTIF_CONFIRM'
export const FEAT_SCHED_CAMPAIGNS = 'FEAT_SCHED_CAMPAIGNS'
export const FEAT_SDK_EVENT_ONLY_DOMAINS = 'FEAT_SDK_EVENT_ONLY_DOMAINS'
export const EXTERNAL_ORG_API_KEY = 'ORG_API_KEY'
export const FEAT_CHANNEL_WEB = 'FEAT_CHANNEL_WEB'
export const FEAT_CHANNEL_ANDROID = 'FEAT_CHANNEL_ANDROID'
export const FEAT_CHANNEL_IOS = 'FEAT_CHANNEL_IOS'
export const NATIVE_IOS_INTERRUPTION_LEVEL_CRITICAL = 'NATIVE_IOS_INTERRUPTION_LEVEL_CRITICAL'
export const FEAT_SILENT_DELIVERY_NOTIF = 'FEAT_SILENT_DELIVERY_NOTIF'

export const VALID_DOMAIN_REGEX =
    /^(?:http[s]?:\/\/)?(?:localhost|(?:(?:[a-z](?:(?:[a-z0-9]*?[-_]?[a-z0-9]*)?[a-z0-9]){0,4})\.){1,4}[a-z]{2,24})$/i

export const TZ_PLATFORM_DEFAULT = 'us/eastern'
export const TZ_UTC = 'utc'

export const SUPPORTED_BILLING_CURRENCIES = [
    { label: 'Canadian Dollars', value: 'CAD', symbol: 'C$' },
    { label: 'Euros', value: 'EUR', symbol: '€' },
    { label: 'British Pounds', value: 'GBP', symbol: '£' },
    { label: 'US Dollars', value: 'USD', symbol: '$' },
]

export const DEFAULT_DRAWER_FORM_ITEM_LAYOUT = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
}

export const DEFAULT_HIGHCHARTS_CONFIG = {
    credits: {
        enabled: false,
    },
}

export const HIGHCHARTS_CHANNEL_COLOR_MAP = {
    [DeliveryChannel.WEB]: INSIGHTS_WEB_COLOR,
    [DeliveryChannel.NATIVE_ANDROID]: INSIGHTS_ANDROID_COLOR,
    [DeliveryChannel.NATIVE_IOS]: INSIGHTS_IOS_COLOR,
}

export const NON_ACTIONABLE_ERRORS = [/Network Error/]

export const DEFAULT_USER_PREFERENCES: IUserChannelPreferences = {
    org: {},
    domain: {},
}
