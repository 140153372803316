import * as deepEqual from 'react-fast-compare'

export function arrayUnqiue<T extends unknown>(array: T[]): T[] {
    return Array.from(new Set(array))
}

export function flatMap<T extends unknown, R extends unknown>(array: T[], map: (item: T, idx: number) => R[]): R[] {
    const interim = array.map(map)
    return interim.reduce((a, b) => a.concat(b), [])
}

export function chunkArray<T extends unknown, L extends number>(array: T[], chunks: L): T[][] {
    const source = [...array]
    const chunkSize = source.length < chunks ? source.length : Math.round(source.length / chunks)
    const chunked: T[][] = []

    for (let i = 0; i < chunks; i++) {
        const start = i * chunkSize
        const end = source.length <= chunkSize ? undefined : start + chunkSize
        chunked.push(source.slice(start, end))
    }

    return chunked
}

export function equalArrays(arrA: any[], arrB: any[], orderSensitive: boolean = false): boolean {
    const aArr = [...arrA]
    const bArr = [...arrB]

    if (!orderSensitive) {
        aArr.sort()
        bArr.sort()
    }

    return aArr.length === bArr.length && deepEqual(aArr, bArr)
}

export function parseArrayOrNull<T>(from: ArrayLike<T> | null): T[] | null {
    return from ? Array.from(from) : null
}
