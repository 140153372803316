import React from 'react'
import classnames from 'classnames'
import { useService } from '../../../hooks/use-service'
import { AppState } from '../../../stores/app'
import { Switch } from 'antd'
import { NotificationDto } from '../../../features/notifications/index'
import { useLoadableDataState } from '../../../hooks/use-loadable-data-state'
import NotificationBuilder from '../../notification-builder/notification-builder'
import NotificationBuilderAside from '../../notification-builder/elements/notification-builder-aside'
import NotificationBuilderPreview from '../../notification-builder/elements/notification-builder-preview'
import {
    buildPreviewOptions,
    buildSubmittedNotificationPackages,
    parseLoadedVariant,
} from '../../notification-builder/helpers'
import { NotificationService } from '../../../services'
import NotificationBuilderMain from '../../notification-builder/elements/notification-builder-main'
import NotificationVariantBuilder from '../../notification-builder/elements/notification-variant-builder'
import { useRefEffect } from '../../../hooks/use-ref-effect'
import { getCustomMacroFields, getDomainKeywords } from '../../../_utils/domain'
import { NotificationBuilderLevel } from '../../notification-builder/enums'
import { INotificationBuilderState } from '../../notification-builder/interfaces/notification-builder-state.interface'
import { DomainDto } from '../../../dtos/domain'
import { noop } from '../../../_utils/utils'
import NotificationEcommOptions from '../../notification-builder/elements/notification-ecomm-options'
import { EcommItemPickStrategy } from '../../../enums/ecomm-item-pick-strategy'
import { ActionType } from '../enums'
import NotificationWebhookOptions from '../../notification-builder/elements/notification-webhook-options'
import { getDomainFallbackNotification } from '../../domain-notification-settings/helpers'
import { Container } from 'typescript-ioc'
import FlagList from '../../../structs/flags-list'
import { ApiVersion } from '../../../enums/api-version.enum'

interface IProps {
    className: string
    actionType: ActionType
    actionIndex: number
    campaign: any
    domain: DomainDto
    template: any
    showWebhookOptions: boolean
    showEcommStrategy: boolean
    defaultEcommStrategy: EcommItemPickStrategy
    hideImageMacroToggle: boolean
    previewMeta: any
    onSubmit: (notification: any, builder: INotificationBuilderState) => any
    [key: string]: any
    readonly?: boolean
}

interface ISubmitMethodProxyProps {
    domain: DomainDto
    builder: INotificationBuilderState
    validateSubmit: (
        builder: INotificationBuilderState,
        domain: DomainDto,
        flags: FlagList,
        isPreviewSend?: boolean,
    ) => any
    onSubmit: (notification: any, builder: INotificationBuilderState) => any
}

/**
 * The node editors were built around class references that expose _processSubmitRequest
 * to adhere to this we added a proxy class that handles submitting the current
 * notification builder state
 *
 * We should consider the work to remove the dependency on classes that expose a public method
 */

class SubmitMethodProxy extends React.Component<ISubmitMethodProxyProps> {
    private appState = Container.get(AppState)

    public render() {
        return null
    }

    public async _processSubmitRequest(...args: any[]) {
        const { domain, builder, validateSubmit } = this.props

        // reference to node-editor.tsx#handleSubmit
        // expects boolean response
        let proxyResponse = false

        const valid = await validateSubmit(builder, domain, this.appState.flags)
        if (valid) {
            const build = buildSubmittedNotificationPackages(domain, builder, this.appState.flags)[0]

            // pass back action updates to action.editor.tsx
            await this.props.onSubmit(build, builder)

            proxyResponse = true
        }

        // pass back proxyResponse to node-editor.tsx
        return proxyResponse
    }
}

const parseInitialNotificationFromTemplate = (template: NotificationDto | any, domain: DomainDto) => {
    let res = template

    // back-fill default abandoned cart details
    if (!!res && !res.deliverySpec) {
        res.deliverySpec = {
            isSilent: false,
            requireInteraction: true,
            ttlSeconds: domain.defaultNotificationTtlSeconds,
            ttlMetric: 'days',
        }
    }

    return res
}

const CampaignNotificationEditor = React.forwardRef<any, IProps>((props, forwardedRef) => {
    const appState = useService<AppState>(AppState)
    const notifService = useService<NotificationService>(NotificationService)

    const {
        className,
        actionType,
        actionIndex,
        domain,
        template,
        showWebhookOptions,
        defaultEcommStrategy,
        hideImageMacroToggle,
        previewMeta,
        onSubmit,
    } = props

    const isSendCartNotification = actionType === ActionType.SEND_CART_NOTIFICATION
    const isSendContentWebhookNotification = actionType === ActionType.SEND_CONTENT_WEBHOOK_NOTIFICATION

    const [notifState, setNotifState] = useLoadableDataState<NotificationDto | undefined>({ loading: true })
    const [keywordOptions] = useRefEffect(() => getDomainKeywords(domain.id), [domain.id])
    const [customMacroOptions] = useRefEffect(() => getCustomMacroFields(domain.id), [domain.id])
    const [showBuilder, setShowBuilder] = React.useState<boolean>(!isSendContentWebhookNotification)

    // run once on mount
    React.useEffect(() => {
        let fallback
        if (isSendContentWebhookNotification) {
            fallback = getDomainFallbackNotification(domain)
        }

        const notification = parseInitialNotificationFromTemplate(template ?? fallback, domain)
        if (notification?.template?.channels?.default?.overrideFallbackTemplate) {
            setShowBuilder(true)
        }

        setNotifState({
            loading: false,
            data: notification,
        })
    }, [])

    return notifState.loading ? (
        <></>
    ) : (
        <NotificationBuilder
            className={classnames(className, {
                'builder-hidden': !showBuilder,
            })}
            level={NotificationBuilderLevel.CAMPAIGN}
            mode={'create'}
            domain={domain}
            notifications={[notifState.data!]}
            defaultChannels={props.campaign.channels}
            defaultSelectedId={notifState.data?.id}
            showWebhookOptions={showWebhookOptions}
            showEcommStrategy={isSendCartNotification}
            defaultEcommStrategy={!isSendCartNotification ? undefined : defaultEcommStrategy}
        >
            {(builderProps) => {
                const { builder, dispatchChanges, validateSubmit, variants, onVariantChange, variantManager } =
                    builderProps
                const activeVariant = variants[builder.selectedVariantIdx]
                const options = buildPreviewOptions(builder.channels)

                return (
                    <>
                        <NotificationBuilderAside>
                            <NotificationBuilderPreview
                                loading={builder.loading}
                                domain={domain}
                                source={activeVariant}
                                onPreviewRequest={async (type) => {
                                    const valid = await validateSubmit(builder, domain, appState.flags, true)

                                    if (valid) {
                                        const builds = buildSubmittedNotificationPackages(
                                            domain,
                                            builder,
                                            appState.flags,
                                            type,
                                            previewMeta,
                                        )
                                        const previewBuild = builds[builder.selectedVariantIdx]

                                        await notifService.sendNotification(domain.id, previewBuild, {
                                            cancellationKey: `notif-send-preview`,
                                            version: ApiVersion.V4,
                                        })
                                    }
                                }}
                                options={options}
                            />
                        </NotificationBuilderAside>

                        {!props.readonly && (
                            <>
                                <NotificationBuilderMain>
                                    {builder.showEcommStrategy && (
                                        <NotificationEcommOptions
                                            loading={builder.loading}
                                            builder={builder}
                                            setBuilder={dispatchChanges}
                                            showPreviouslyChosenItemOption={actionIndex !== 0}
                                        />
                                    )}

                                    {isSendContentWebhookNotification && (
                                        <>
                                            <NotificationWebhookOptions
                                                loading={builder.loading}
                                                value={activeVariant.getContent().getDefaultContent()}
                                                onChange={(contentUpdate) => {
                                                    activeVariant.getContent().setDefaultContent(contentUpdate)
                                                    onVariantChange(activeVariant, builder.selectedVariantIdx)
                                                }}
                                                customMacroOptions={customMacroOptions.current}
                                            />

                                            <div className="switch-row show-builder-toggle">
                                                <div className="switch-left">
                                                    <span>Customize Fallback Notification</span>
                                                </div>
                                                <div className="switch-right">
                                                    <Switch
                                                        size="small"
                                                        checked={showBuilder}
                                                        onChange={(checked) => {
                                                            const activeContent = activeVariant.getContent()

                                                            const update = activeVariant.clone()
                                                            let contentUpdate = update.getContent().getDefaultContent()

                                                            if (!checked) {
                                                                const fallback = getDomainFallbackNotification(domain)
                                                                const fallbackModel = parseLoadedVariant(fallback)

                                                                // overwrite content with domain fallback and reset update ref
                                                                update.setContent(fallbackModel.getContent())
                                                                contentUpdate = update.getContent().getDefaultContent()

                                                                // re-attach webhook url from current editor state
                                                                contentUpdate.setContentWebhookUrl(
                                                                    activeVariant
                                                                        .getContent()
                                                                        .getDefaultContent()
                                                                        .getContentWebhookUrl(),
                                                                )

                                                                // re-attach ttl from current editor state
                                                                const expOpts = contentUpdate.getExperienceOptions()
                                                                expOpts.setTtlSeconds(
                                                                    activeContent
                                                                        .getDefaultContent()
                                                                        .getExperienceOptions()
                                                                        .getTtlSeconds(),
                                                                )
                                                                expOpts.setTtlMetric(
                                                                    activeContent
                                                                        .getDefaultContent()
                                                                        .getExperienceOptions()
                                                                        .getTtlMetric(),
                                                                )
                                                            }

                                                            // update overrideFallbackTemplate flag
                                                            contentUpdate.setOverrideFallbackTemplate(checked)

                                                            onVariantChange(update, builder.selectedVariantIdx)
                                                            setShowBuilder(checked)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <NotificationVariantBuilder
                                        key={activeVariant.getId()}
                                        id={builder.selectedVariantIdx}
                                        domain={domain}
                                        builder={builder}
                                        variant={activeVariant}
                                        manager={variantManager}
                                        hideDelivery={true}
                                        hideImageMacroToggle={hideImageMacroToggle}
                                        onChange={onVariantChange}
                                        keywordOptions={keywordOptions.current}
                                        customMacroOptions={customMacroOptions.current}
                                        // campaign submit is handled through <SubmitMethodProxy ... />
                                        onSubmit={noop}
                                    />
                                </NotificationBuilderMain>

                                <SubmitMethodProxy
                                    ref={forwardedRef}
                                    domain={domain}
                                    builder={builder}
                                    validateSubmit={validateSubmit}
                                    onSubmit={onSubmit}
                                />
                            </>
                        )}
                    </>
                )
            }}
        </NotificationBuilder>
    )
})

export default CampaignNotificationEditor
