import * as React from 'react'
import { BetterComponent } from '../../better-component/better-component'
import { Well } from '@pushly/aqe/lib/components'
import { AppState } from '../../../stores/app'
import { AppService, DomainService } from '../../../services/index'
import { Container } from 'typescript-ioc/es5'
import { NdfFormMode } from '../enums/ndf-form-mode.enum'
import { NdfFormTheme } from '../enums/ndf-form-theme.enum'
import * as clone from 'clone'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Radio, Skeleton, Tooltip, Switch, Popover, InputNumber } from 'antd'
import autobind from 'autobind-decorator'
import { INdfSectionValidationResponse } from '../interfaces/ndf-section-validation-response'
import { INdfTestValue } from '../interfaces/ndf-test-value'
import { DistributionSlider } from '../../distribution-slider/distribution-slider'
import { INotificationTreatment } from '../../../interfaces/notification-treatment'
import { RadioChangeEvent } from 'antd/lib/radio'
import { NdfTestType } from '../enums/ndf-test-type.enum'
import { NdfTestScheduleType } from '../enums/ndf-test-schedule-type.enum'
import * as moment from 'moment-timezone'
import { Moment } from 'moment'
import { BASE_TIME_FORMAT_WITHOUT_TZ } from '../../../constants'
import { INdfSchedulingValue } from '../interfaces/ndf-scheduling-value'
import { NotificationDeliveryType } from '../../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../../enums/notification-delivery-window'
import { INdfAudienceValue } from '../interfaces/ndf-audience-value'
import { SegmentDto } from '../../../dtos/segment'
import { parseFloatTo } from '../../../_utils/math'

interface INdfTestSectionProps {
    className?: string
    mode: NdfFormMode
    theme: NdfFormTheme
    value: INdfTestValue
    onChange: (value: any) => any
    treatments: INotificationTreatment[]
    reachEstimate?: number
    schedule: INdfSchedulingValue
    audience: INdfAudienceValue
    segmentOptions: SegmentDto[]

    loading?: boolean
    deliveryInProgress?: boolean
}

interface INdfTestSectionState {
    ctrThresholdValue?: number | string
}

export class TestSection extends BetterComponent<INdfTestSectionProps, INdfTestSectionState> {
    public readonly defaultClassName: string = 'sw-v2-ndf-section'

    protected appState: AppState
    protected appService: AppService
    protected domainService: DomainService

    protected distributionRef: any
    protected debounceTimer: any
    protected debounceValue: 320

    public constructor(props: INdfTestSectionProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)

        this.state = {}
    }

    public render(): React.ReactNode {
        let ctrThresholdValue: number | string | undefined
        if (this.currentCtrThresholdValue !== undefined) {
            try {
                ctrThresholdValue = (this.currentCtrThresholdValue * 100).toFixed(2)
            } catch (_) {}
        }

        return (
            <div className={this.buildRootClassNames('testing')}>
                <div className={this.buildClassName('wrapper')}>
                    <Well className="nested" title="Test Configuration" hideFooter={true}>
                        <Skeleton
                            active={true}
                            title={false}
                            avatar={false}
                            paragraph={{ rows: 3 }}
                            loading={this.isLoading}
                        >
                            <Well title="Test Name" mode="ghost" hideFooter={true}>
                                <Input
                                    placeholder="Name your test"
                                    value={this.currentValue.name}
                                    onChange={this.handleNameChange}
                                    disabled={this.deliveryInProgress}
                                />
                            </Well>

                            <Well
                                title={
                                    <>
                                        <span>Distribution</span>
                                        <Tooltip
                                            title={
                                                <>
                                                    <p>
                                                        <b>Split Delivery:</b> Each variation will be sent to a
                                                        specified percentage of your audience.
                                                    </p>
                                                    <p>
                                                        <b>Sample:</b> All variations in the test will be sent to a
                                                        sample percentage of your audience with the remaining audience
                                                        receiving the winning variation once it has been chosen.
                                                    </p>
                                                </>
                                            }
                                        >
                                            <InfoCircleOutlined className="info-icon" />
                                        </Tooltip>
                                    </>
                                }
                                mode="ghost"
                                hideFooter={true}
                            >
                                <Form.Item
                                    className={`distribution-form-item type-${this.currentTypeValue.toLowerCase()}`}
                                >
                                    <Radio.Group
                                        size="small"
                                        onChange={this.handleTypeChange}
                                        disabled={this.deliveryInProgress}
                                        value={this.currentTypeValue}
                                    >
                                        <Radio.Button value={NdfTestType.SPLIT}>Split Delivery</Radio.Button>
                                        <Radio.Button value={NdfTestType.SAMPLE} disabled={!!this.sampleDisabledReason}>
                                            {this.sampleDisabledReason ? (
                                                <Tooltip title={this.sampleDisabledReason}>Sample</Tooltip>
                                            ) : (
                                                <span>Sample</span>
                                            )}
                                        </Radio.Button>
                                    </Radio.Group>

                                    <DistributionSlider
                                        ref={(el) => (this.distributionRef = el)}
                                        className="dist-slider"
                                        type={this.currentTypeValue === NdfTestType.SPLIT ? 'split' : 'sample'}
                                        totalDistributions={this.props.treatments.length}
                                        disabled={this.deliveryInProgress}
                                        value={this.currentValue.distributions}
                                        onChange={this.handleDistributionChange}
                                        stepSize={1}
                                        distributionStyles={['variant-a', 'variant-b', 'variant-c', 'variant-d']}
                                    />
                                </Form.Item>
                            </Well>

                            {this.currentTypeValue === NdfTestType.SAMPLE && (
                                <Well title="Additional Options" mode="ghost" hideFooter={true}>
                                    <div className="switch-row">
                                        <div className="switch-left">
                                            <Switch
                                                size="small"
                                                checked={this.currentCtrThresholdValue !== undefined}
                                                onChange={this.handleCtrThresholdToggle}
                                            />
                                        </div>
                                        <div className="switch-right">
                                            <span className="switch-row-label">
                                                <span>Enable performance constraint </span>
                                                <Popover
                                                    content={
                                                        <div style={{ maxWidth: 300 }}>
                                                            When a Sample Test has a performance constraint the winning
                                                            variant will only be sent if it meets or exceeds the
                                                            specified CTR.
                                                        </div>
                                                    }
                                                >
                                                    <InfoCircleOutlined className="info-icon" />
                                                </Popover>
                                            </span>

                                            {this.currentCtrThresholdValue !== undefined && (
                                                <>
                                                    <span>Minimum CTR </span>
                                                    <Input
                                                        className="ctr-threshold-input"
                                                        size="small"
                                                        defaultValue={ctrThresholdValue as any}
                                                        addonAfter="%"
                                                        onChange={this.handleCtrThresholdChange}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Well>
                            )}
                        </Skeleton>
                    </Well>
                </div>
            </div>
        )
    }

    // All of this logic sits within the API as well
    public validate(): INdfSectionValidationResponse {
        const response: INdfSectionValidationResponse = { ok: true }

        if (this.props.treatments.length < 2) {
            response.ok = false
            response.error = 'Notification Tests require at least two variations.'
        } else if (!this.currentValue.name) {
            response.ok = false
            response.error = 'Please provide a name for your Notification Test.'
        } else if (this.currentTypeValue === NdfTestType.SAMPLE) {
            if ('ctrThreshold' in this.currentValue) {
                const ctrThreshold: any = this.currentCtrThresholdValue
                if (isNaN(ctrThreshold as any) || ctrThreshold * 100 < 0 || ctrThreshold * 100 > 100) {
                    response.ok = false
                    response.error = 'Performance constraint is invalid. Must be a numerical value between 0% and 100%'
                }
            }

            if (response.ok) {
                let audienceHasSegmentWithTreatment = false
                let audienceSegmentWithTreatment: SegmentDto | undefined

                if (!!this.props.audience) {
                    if (!!this.props.audience.includedSegmentIds) {
                        audienceHasSegmentWithTreatment = this.props.audience.includedSegmentIds.some((id) => {
                            const segment = this.props.segmentOptions.find((s) => s.id === id)
                            const invalid = !!segment && !!segment.treatmentSpec
                            if (invalid) audienceSegmentWithTreatment = segment
                            return invalid
                        })
                    }
                    if (!audienceHasSegmentWithTreatment && !!this.props.audience.excludedSegmentIds) {
                        audienceHasSegmentWithTreatment = this.props.audience.excludedSegmentIds.some((id) => {
                            const segment = this.props.segmentOptions.find((s) => s.id === id)
                            const invalid = !!segment && !!segment.treatmentSpec
                            if (invalid) audienceSegmentWithTreatment = segment
                            return invalid
                        })
                    }
                }

                if (audienceHasSegmentWithTreatment && !!audienceSegmentWithTreatment) {
                    response.ok = false
                    response.error = `Segment ${audienceSegmentWithTreatment.name} contains a custom treatment. Segments containing custom treatments cannot be used with Sample Distribution.`
                } else if (this.props.schedule.window === NotificationDeliveryWindow.TIMEZONE) {
                    response.ok = false
                    response.error = 'Time Zone Delivery cannot be used with Sample Distribution.'
                } else if (!this.currentScheduleValue) {
                    response.ok = false
                    response.error = 'Please provide a valid value for the Notification Test winner schedule.'
                } else if (this.currentScheduleTypeValue === NdfTestScheduleType.ELAPSED) {
                    if (this.currentScheduleValue < 1) {
                        response.ok = false
                        response.error = 'Notification Test winner send date must be at least 1 hour from initial send.'
                    }
                } else if (this.currentScheduleTypeValue === NdfTestScheduleType.SPECIFIC) {
                    const winnerDate = this.currentScheduleValue.startOf('minute')
                    const sendDate =
                        this.props.schedule.type === NotificationDeliveryType.IMMEDIATE
                            ? moment().startOf('minute')
                            : moment(this.props.schedule.sendDate).startOf('minute')
                    const dateDiff = winnerDate.diff(sendDate, 'm')

                    if (dateDiff < 1) {
                        response.ok = false
                        response.error =
                            'Notification Test winner send date must be at least 1 hour after initial send.'
                    }
                }
            }
        }

        return response
    }

    public clear(): void {
        if (!!this.distributionRef) this.distributionRef.reset()
    }

    protected get isLoading(): boolean {
        return this.props.loading || false
    }

    protected get currentMode(): NdfFormMode {
        return this.props.mode
    }

    protected get currentTheme(): NdfFormTheme {
        return this.props.theme
    }

    protected get currentValue(): INdfTestValue {
        return clone({
            ...this.props.value,
        })
    }

    protected get currentTypeValue(): NdfTestType {
        return this.currentValue.type || NdfTestType.SPLIT
    }

    protected get currentCtrThresholdValue(): number | undefined {
        return this.currentValue.ctrThreshold
    }

    protected get currentScheduleTypeValue(): NdfTestScheduleType {
        return (this.currentValue.schedule || {}).type || NdfTestScheduleType.ELAPSED
    }

    protected get currentScheduleValue(): any {
        let value = (this.currentValue.schedule || {}).value

        if (!!value && this.currentScheduleTypeValue === NdfTestScheduleType.SPECIFIC) {
            value = moment(value, BASE_TIME_FORMAT_WITHOUT_TZ)
        }

        return value
    }

    protected get deliveryInProgress(): boolean {
        return this.props.deliveryInProgress || false
    }

    protected get sampleDisabledReason(): string | null {
        if (!this.props.reachEstimate || this.props.reachEstimate < 5000) {
            return 'Sampling cannot be enabled for audiences smaller than 5,000 subscribers.'
        } else if (
            (this.props.value && this.props.value.isTimeVariant) ||
            (this.props.schedule && this.props.schedule.window === NotificationDeliveryWindow.TIMEZONE)
        ) {
            return 'All variants must have the same delivery time when using the Sample distribution method.'
        }

        return null
    }

    protected get defaultElapsedSchedule(): any {
        return {
            type: NdfTestScheduleType.ELAPSED,
            value: 1,
        }
    }

    protected get defaultSpecificSchedule(): any {
        return {
            type: NdfTestScheduleType.SPECIFIC,
            value: !!this.props.schedule.sendDate
                ? moment(this.props.schedule.sendDate).add(1, 'd').format(BASE_TIME_FORMAT_WITHOUT_TZ)
                : moment().add(1, 'd').format(BASE_TIME_FORMAT_WITHOUT_TZ),
        }
    }

    protected getDefaultSchedule(type: NdfTestScheduleType): any {
        return type === NdfTestScheduleType.ELAPSED ? this.defaultElapsedSchedule : this.defaultSpecificSchedule
    }

    protected handleCtrThresholdToggle = (enabled: boolean): void => {
        const changes: Partial<INdfTestValue> = {
            ctrThreshold: !enabled ? (null as any) : 0.03,
        }

        this.emitChange(changes)
    }

    protected handleCtrThresholdChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
        let value: any = ev.target.value
        if (!isNaN(value)) {
            value = parseFloat(value)
        }

        const changes: Partial<INdfTestValue> = {
            ctrThreshold: value === undefined ? undefined : isNaN(value) ? value : parseFloatTo(value / 100, 4),
        }

        this.debounce(() => {
            this.emitChange(changes)
        }, 750)
    }

    @autobind
    protected handleTypeChange(ev: RadioChangeEvent): void {
        const changes: Partial<INdfTestValue> = {
            type: ev.target.value,
        }

        if (changes.type === NdfTestType.SAMPLE) {
            changes.schedule = this.defaultElapsedSchedule
        } else {
            changes.schedule = undefined
            changes.ctrThreshold = undefined
        }

        this.emitChange(changes)
    }

    @autobind
    protected handleNameChange(ev: React.ChangeEvent<HTMLInputElement>): void {
        const name = ev.target.value

        this.emitChange({
            name,
        })
    }

    @autobind
    protected handleDistributionChange(distributions: number[]): void {
        this.emitChange({
            distributions,
        })
    }

    @autobind
    protected handleScheduleTypeChange(ev: RadioChangeEvent): void {
        const changes: Partial<INdfTestValue> = {
            schedule: {
                ...this.currentValue.schedule,
                ...this.getDefaultSchedule(ev.target.value),
            },
        }

        this.emitChange(changes)
    }

    @autobind
    protected handleScheduleElapsedValueChange(elapsedValue: number): void {
        const value = elapsedValue

        const changes: Partial<INdfTestValue> = {
            schedule: {
                ...this.currentValue.schedule,
                value,
            },
        }

        this.emitChange(changes)
    }

    @autobind
    protected handleScheduleSpecificValueChange(dateValue: Moment): void {
        const value = !dateValue ? undefined : dateValue.format(BASE_TIME_FORMAT_WITHOUT_TZ)

        const changes: Partial<INdfTestValue> = {
            schedule: {
                ...this.currentValue.schedule,
                value,
            },
        }

        this.emitChange(changes)
    }

    protected emitChange(changes: Partial<INdfTestValue>): void {
        const change = {
            ...this.currentValue,
            ...changes,
        }

        // enforce ctrThreshold removal
        if (change.ctrThreshold === null) {
            delete change.ctrThreshold
        }

        this.props.onChange(change)
    }

    protected buildClassName(className: string, extras?: string): string {
        className = `${this.defaultClassName}-${className}`
        if (!!extras) className = `${className} ${extras}`
        return className
    }

    protected buildRootClassNames(sectionName: string): string {
        const classNames: string[] = [
            this.defaultClassName,
            `section-${sectionName}`,
            `theme-${this.currentTheme.toLowerCase()}`,
            `mode-${this.currentMode.toLowerCase()}`,
        ]

        if (!!this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }

    protected debounce(fn: Function, timeout?: number) {
        if (!!this.debounceTimer) clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(fn, timeout ?? this.debounceValue)
    }
}
