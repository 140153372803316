import { IRuleBuilderField, IRuleBuilderSelectField } from '../../rule-builder/rule-builder'
import { baseOperators } from '../../rule-builder/operators'
import { segmentCountryOptions } from '../../../enums/segment-countries'
import { segmentContinentOptions } from '../../../enums/segment-continents'
import {
    ensureSelectMetaDisplayNameTransformIn,
    ensureSelectMetaDisplayNameTransformOut,
} from '../../rule-builder-v2/utils'

export const CAMPAIGN_DEFAULT_ABANDONMENT_SECONDS = 3600
export const CAMPAIGN_DEFAULT_ABANDONMENT_METRIC = 'hours'

export const CAMPAIGN_CRITERIA_FIELDS: IRuleBuilderField[] = [
    {
        property: 'page_url',
        display: 'Behavior: Allow URL',
        type: 'string',
    },
    {
        property: 'prompt_id',
        display: 'Behavior: Allow Prompt',
        type: 'prompt',
        operators: [baseOperators.bool_eq, baseOperators.bool_neq],
    },
    {
        property: 'navigator',
        display: 'Device: Browser',
        type: 'select',
        options: [
            { name: 'Chrome', value: 'Chrome' },
            { name: 'Edge', value: 'Edge' },
            { name: 'Firefox', value: 'Firefox' },
            { name: 'Opera', value: 'Opera' },
            { name: 'Safari', value: 'Safari' },
        ],
        operators: [baseOperators.eq, baseOperators.neq],
    },
    {
        property: 'placement',
        display: 'Device: Type',
        type: 'device-placement',
        operators: [baseOperators.bool_eq, baseOperators.bool_neq],
    },
    {
        property: 'device_os',
        display: 'Device: Operating System',
        type: 'select',
        options: [
            { name: 'Android', value: 'Android' },
            { name: 'Mac', value: 'Mac' },
            { name: 'Linux', value: 'Linux' },
            { name: 'Windows', value: 'Windows' },
        ],
        operators: [baseOperators.contains, baseOperators.not_contains],
    },
    {
        property: 'city',
        display: 'Location: City',
        type: 'string',
    },
    {
        property: 'continent_code',
        display: 'Location: Continent',
        type: 'select',
        options: segmentContinentOptions,
        displayValueProp: 'name',
        operators: [baseOperators.eq, baseOperators.neq],
        ruleTransformIn: ensureSelectMetaDisplayNameTransformIn,
        ruleTransformOut: ensureSelectMetaDisplayNameTransformOut,
    } as IRuleBuilderSelectField,
    {
        property: 'country_code',
        display: 'Location: Country',
        type: 'select',
        options: segmentCountryOptions,
        displayValueProp: 'name',
        enableOptionsSearch: true,
        extra: {
            optionFilterProp: 'label',
        },
        operators: [baseOperators.eq, baseOperators.neq],
        ruleTransformIn: ensureSelectMetaDisplayNameTransformIn,
        ruleTransformOut: ensureSelectMetaDisplayNameTransformOut,
    } as IRuleBuilderSelectField,
    {
        property: 'location',
        display: 'Location: Custom Selection',
        type: 'custom-location',
    },
    {
        property: 'postal_code',
        display: 'Location: Postal Code',
        type: 'string',
    },
    {
        property: 'province',
        display: 'Location: State / Province',
        type: 'string',
    },
]
