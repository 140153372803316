import * as React from 'react'
import { BetterComponent } from '../../better-component/better-component'
import { Well } from '../../well/well'
import { AppState } from '../../../stores/app'
import { AppService, DomainService } from '../../../services/index'
import { Container } from 'typescript-ioc/es5'
import { NdfFormMode } from '../enums/ndf-form-mode.enum'
import { NdfFormTheme } from '../enums/ndf-form-theme.enum'
import { SegmentService } from '../../../services/segment'
import * as clone from 'clone'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Radio, Select, Skeleton, Switch } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { SegmentDto } from '../../../dtos/segment'
import { INdfAudienceValue } from '../interfaces/ndf-audience-value'
import { NdfAudienceType } from '../enums/ndf-audience-type.enum'
import { arrayContains } from '../../../_utils/utils'
import { INdfSectionValidationResponse } from '../interfaces/ndf-section-validation-response'
import { getClassNames } from '../../../_utils/classnames'
import { Drawer } from '../../drawer/drawer'
import { SegmentSource } from '../../../enums/segment-source.enum'
import { EventBus } from '../../campaign-builder/events'
import { generateUID } from '../../campaign-builder/helpers/uid'
import { FEAT_RESTRICT_ALL_SUB_NOTIFS, FEAT_INLINE_SEG } from '../../../constants'
import SegmentBuilder from '../../segment-builder/segment-builder'
import { AbilityAction } from '../../../enums/ability-action.enum'

interface INdfAudienceSectionProps {
    className?: string
    mode: NdfFormMode
    theme: NdfFormTheme
    value: INdfAudienceValue
    onChange: (value: any) => any
    deliveryInProgress?: boolean

    loading?: boolean
    options?: SegmentDto[]
}

interface INdfAudienceSectionState {
    exclusionsEnabled?: boolean

    showSegmentDrawer: boolean
    segmentDrawerId?: string
    newSegmentType?: string
    newSegmentName?: string
}

export class AudienceSection extends BetterComponent<INdfAudienceSectionProps, INdfAudienceSectionState> {
    public readonly defaultClassName: string = 'sw-v2-ndf-section'

    protected appState: AppState
    protected appService: AppService
    protected domainService: DomainService
    protected segmentService: SegmentService

    protected segmentBuilderRef: any
    protected includedSegmentsRef: any
    protected excludedSegmentsRef: any

    public constructor(props: INdfAudienceSectionProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)
        this.segmentService = Container.get(SegmentService)

        this.state = {
            showSegmentDrawer: false,
        }
    }

    public componentDidMount() {
        if (this.shouldBlockAllSend) {
            this.setAudienceType(NdfAudienceType.SUBSCRIBER_SEGMENTS)
        }
    }

    public render(): React.ReactNode {
        const domainFlags = [...(this.globalDomain?.flags ?? []), ...(this.globalDomain?.accountFlags ?? [])]
        const inlineSegmentationEnabled = domainFlags.includes(FEAT_INLINE_SEG)

        return (
            <div className={this.buildRootClassNames('audience')}>
                <div className={this.buildClassName('wrapper')}>
                    <Well title="Audience" showFooter={false}>
                        <Skeleton
                            active={true}
                            title={false}
                            avatar={false}
                            paragraph={{ rows: 3 }}
                            loading={this.isLoading}
                        >
                            {!this.shouldBlockAllSend && (
                                <Form.Item label="Who do you want to send to?">
                                    <Radio.Group
                                        buttonStyle="solid"
                                        size="small"
                                        onChange={this.handleTypeChange}
                                        value={this.currentTypeValue}
                                    >
                                        <Radio.Button value="all" defaultChecked={true}>
                                            All Subscribers
                                        </Radio.Button>
                                        <Radio.Button value="segments">Specific Segments</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            )}

                            {this.showInclusionOptions && (
                                <Form.Item label="Include subscribers in any of the following segments::">
                                    <div className={'inclusion-options'}>
                                        <Select
                                            ref={(el) => (this.includedSegmentsRef = el)}
                                            mode="multiple"
                                            placeholder="Select at least one segment to include"
                                            onChange={this.handleIncludedSegmentsChange}
                                            onSearch={this.handleSegmentsSearch}
                                            value={this.currentInclusionNames}
                                            dropdownClassName="ndf-audience-inclusion-options-dropdown"
                                            notFoundContent={
                                                inlineSegmentationEnabled &&
                                                this.renderNewSegmentOption('included', true)
                                            }
                                        >
                                            {inlineSegmentationEnabled && (
                                                <Select.Option
                                                    key="new"
                                                    value="new"
                                                    className="ndf-audience-new-segment-option"
                                                >
                                                    {this.renderNewSegmentOption('included')}
                                                </Select.Option>
                                            )}

                                            {this.nonDefaultSegments.map((segment) => (
                                                <Select.Option
                                                    key={segment.id}
                                                    value={segment.name}
                                                    disabled={arrayContains(this.currentExclusionsValue, segment.id)}
                                                >
                                                    {segment.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </Form.Item>
                            )}

                            <Form.Item>
                                <div className="switch-row">
                                    <div className="switch-left">
                                        <Switch
                                            checked={this.currentExlcusionsEnabledValue}
                                            onChange={this.handleExclusionsEnabledChange}
                                        />
                                    </div>
                                    <div className="switch-right">
                                        <span>Exclude Segments</span>
                                    </div>
                                </div>

                                {this.showExclusionOptions && (
                                    <div className={'exclusion-options'}>
                                        <Select
                                            ref={(el) => (this.excludedSegmentsRef = el)}
                                            mode="multiple"
                                            placeholder="Select at least one segment to exclude"
                                            onChange={this.handleExcludedSegmentsChange}
                                            onSearch={this.handleSegmentsSearch}
                                            value={this.currentExclusionNames}
                                            dropdownClassName="ndf-audience-exclusion-options-dropdown"
                                            notFoundContent={
                                                inlineSegmentationEnabled &&
                                                this.renderNewSegmentOption('excluded', true)
                                            }
                                        >
                                            {inlineSegmentationEnabled && (
                                                <Select.Option
                                                    key="new"
                                                    value="new"
                                                    className="ndf-audience-new-segment-option"
                                                >
                                                    {this.renderNewSegmentOption('excluded')}
                                                </Select.Option>
                                            )}

                                            {this.nonDefaultSegments.map((segment) => (
                                                <Select.Option
                                                    key={segment.id}
                                                    value={segment.name}
                                                    disabled={arrayContains(this.currentInclusionsValue, segment.id)}
                                                >
                                                    {segment.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                            </Form.Item>
                        </Skeleton>
                    </Well>
                </div>

                <Drawer
                    title="Create New Segment"
                    visible={this.state.showSegmentDrawer}
                    onSubmit={this.handleSegmentCreation}
                    onClose={this.closeDrawer}
                    className="ndf-audience-new-segment-drawer"
                >
                    <SegmentBuilder
                        key={this.state.segmentDrawerId ?? 'unknown'}
                        ref={(el) => (this.segmentBuilderRef = el)}
                        mode="drawer"
                        level="domain"
                        domainId={this.appState.currentDomain!.id}
                        source={SegmentSource.STANDARD}
                        name={this.state.newSegmentName}
                    />
                </Drawer>
            </div>
        )
    }

    public validate(): INdfSectionValidationResponse {
        const response: INdfSectionValidationResponse = { ok: true }

        if (this.currentTypeValue !== NdfAudienceType.ALL_SUBSCRIBERS && this.currentInclusionsValue.length === 0) {
            response.ok = false
            response.error = 'Please select at least one valid segment.'
        } else if (this.currentExlcusionsEnabledValue && this.currentExclusionsValue.length === 0) {
            response.ok = false
            response.error = 'Please select at least one valid excluded segment.'
        }

        return response
    }

    protected get eventBus() {
        return EventBus.get('ndf-event-bus')
    }

    protected get isLoading(): boolean {
        return this.props.loading === true
    }

    protected get currentMode(): NdfFormMode {
        return this.props.mode
    }

    protected get currentTheme(): NdfFormTheme {
        return this.props.theme
    }

    protected get currentValue(): INdfAudienceValue {
        return clone({
            ...this.props.value,
        })
    }

    protected get currentTypeValue(): NdfAudienceType {
        const defaultValue = NdfAudienceType.ALL_SUBSCRIBERS
        return !this.props.value ? defaultValue : this.props.value.type || defaultValue
    }

    protected get currentExlcusionsEnabledValue(): boolean {
        const defaultValue = false
        return !this.props.value ? defaultValue : this.props.value.exclusionsEnabled || defaultValue
    }

    protected get currentInclusionsValue(): number[] {
        const defaultValue: number[] = []
        return !this.props.value ? defaultValue : this.props.value.includedSegmentIds || defaultValue
    }

    protected get currentInclusionNames(): string[] {
        return this.getSegmentNamesFromIds(this.currentInclusionsValue)
    }

    protected get currentExclusionsValue(): number[] {
        const defaultValue: number[] = []
        return !this.props.value ? defaultValue : this.props.value.excludedSegmentIds || defaultValue
    }

    protected get currentExclusionNames(): string[] {
        return this.getSegmentNamesFromIds(this.currentExclusionsValue)
    }

    protected get showInclusionOptions(): boolean {
        return this.currentTypeValue === NdfAudienceType.SUBSCRIBER_SEGMENTS
    }

    protected get showExclusionOptions(): boolean {
        return this.currentExlcusionsEnabledValue
    }

    protected get allSegments(): SegmentDto[] {
        return this.props.options || []
    }

    protected get nonDefaultSegments(): SegmentDto[] {
        const currentSelections = [...this.currentInclusionsValue, ...this.currentExclusionsValue]

        return this.allSegments.filter((s) => {
            const isSelectedOrIsNotAdhoc = s.source !== SegmentSource.ADHOC || currentSelections.includes(s.id)
            return !s.isDefault && isSelectedOrIsNotAdhoc
        })
    }

    protected get shouldBlockAllSend(): boolean {
        const domainFlags = [...(this.globalDomain?.flags ?? []), ...(this.globalDomain?.accountFlags ?? [])]
        const domainHasNoAllFlag = domainFlags.includes(FEAT_RESTRICT_ALL_SUB_NOTIFS)
        const userIsAdmin = this.appState.abilityStore.can(AbilityAction.MANAGE, this.appState.currentDomain!)
        console.debug(
            'should-block-all-send',
            domainFlags,
            FEAT_RESTRICT_ALL_SUB_NOTIFS,
            userIsAdmin,
            this.appState.abilityStore,
        )
        return domainHasNoAllFlag && !userIsAdmin
    }

    protected renderNewSegmentOption(type: 'included' | 'excluded', noData?: boolean) {
        return (
            <div
                className={getClassNames(null, 'ndf-audience-new-segment', {
                    [`type-${type}`]: true,
                    ['no-data']: noData,
                })}
                onClick={() => this.handleNewSegmentClick(type)}
            >
                Create New Segment
            </div>
        )
    }

    protected handleSegmentsSearch = (search: string): void => {
        this.setState({ newSegmentName: search })
    }

    protected handleNewSegmentClick = async (type: 'included' | 'excluded') => {
        return this.setState({
            showSegmentDrawer: true,
            segmentDrawerId: generateUID(),
            newSegmentType: type,
        })
    }

    protected handleTypeChange = (ev: RadioChangeEvent): void => {
        this.setAudienceType(ev.target.value)
    }

    protected setAudienceType(type: NdfAudienceType): void {
        const change: Partial<INdfAudienceValue> = { type }
        if (change.type === NdfAudienceType.ALL_SUBSCRIBERS) {
            change.includedSegmentIds = undefined
        }

        this.emitChange(change)
    }

    protected handleExclusionsEnabledChange = (exclusionsEnabled: boolean): void => {
        const change: Partial<INdfAudienceValue> = {
            exclusionsEnabled,
        }

        if (!change.exclusionsEnabled) {
            change.excludedSegmentIds = undefined
        }

        this.emitChange(change)
    }

    protected handleIncludedSegmentsChange = (includedSegmentNames: string[]): void => {
        const includedSegmentIds = this.getSegmentIdsFromNames(includedSegmentNames)
        this.emitChange({
            includedSegmentIds,
        })

        this.setState({ newSegmentName: undefined })
    }

    protected handleExcludedSegmentsChange = (excludedSegmentNames: string[]): void => {
        const excludedSegmentIds = this.getSegmentIdsFromNames(excludedSegmentNames)
        this.emitChange({
            excludedSegmentIds,
        })

        this.setState({ newSegmentName: undefined })
    }

    protected handleSegmentCreation = async () => {
        const segment = await this.segmentBuilderRef?._submit()
        const type = this.state.newSegmentType

        if (!!segment) {
            this.closeDrawer()
            this.eventBus.dispatch('new-segment-created', {
                cb: () => {
                    const change: any = {}

                    if (type === 'included') {
                        change.includedSegmentIds = [...(this.props.value.includedSegmentIds ?? []), segment.id as any]
                    } else {
                        change.excludedSegmentIds = [...(this.props.value.excludedSegmentIds ?? []), segment.id]
                    }

                    if (Object.keys(change).length > 0) {
                        this.emitChange(change)
                    }
                },
            })
        }
    }

    protected closeDrawer = () => {
        this.setState({
            showSegmentDrawer: false,
            newSegmentType: undefined,
            newSegmentName: undefined,
        })
    }

    protected emitChange(changes: Partial<INdfAudienceValue>): void {
        this.props.onChange({
            ...this.currentValue,
            ...changes,
        })
    }

    protected getSegmentNamesFromIds(segmentIds: any[]): string[] {
        const segmentsToSearch = this.allSegments
        const ids: number[] = (segmentIds as number[]) || []

        return ids
            .map((id: number) => {
                const segment = segmentsToSearch.find((seg) => seg.id.toString() === id.toString())
                return segment ? segment.name : undefined
            })
            .filter((name) => {
                return name !== undefined
            }) as any
    }

    protected getSegmentIdsFromNames(segmentNames: any[]): number[] {
        const segmentsToSearch = this.allSegments
        const names: string[] = segmentNames as string[]

        return names
            .map((name: any) => {
                if (typeof name === 'string') {
                    const segment = segmentsToSearch.find((seg) => seg.name === name)
                    return segment ? segment.id : undefined
                } else {
                    return name
                }
            })
            .filter((id) => {
                return id !== undefined
            }) as any
    }

    protected buildClassName(className: string, extras?: string): string {
        className = `${this.defaultClassName}-${className}`
        if (!!extras) className = `${className} ${extras}`
        return className
    }

    protected buildRootClassNames(sectionName: string): string {
        const classNames: string[] = [
            this.defaultClassName,
            `section-${sectionName}`,
            `theme-${this.currentTheme.toLowerCase()}`,
            `mode-${this.currentMode.toLowerCase()}`,
        ]

        if (!!this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
