import React from 'react'
import './style/app-menu.scss'
import classnames from 'classnames'
import { Menu } from 'antd'
import {
    AppstoreFilled,
    AreaChartOutlined,
    BuildFilled,
    SelectOutlined,
    SettingFilled,
    SolutionOutlined,
    WifiOutlined,
} from '@ant-design/icons'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import { Link } from 'react-router-dom'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { getPathClassName } from '../../_utils/get-path-classname'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { asCaslSubject } from '../../stores/app-ability'
import { observer } from 'mobx-react'
import { useService } from '../../hooks/use-service'

interface IAppMenuItem extends MenuItemProps {
    linkTo: string
    condition?: () => boolean
}
const AppMenuItem: React.FunctionComponent<IAppMenuItem> = ({ linkTo, condition, ...props }) => {
    const isExternalLink = /^http/i.test(linkTo)
    const canRender = !!condition ? condition() : true

    return !canRender ? null : (
        <Menu.Item
            onClick={({ domEvent }) => {
                if (!isExternalLink) {
                    domEvent.stopPropagation()
                }
            }}
            {...props}
            className={classnames('app-menu-item', props.className)}
        >
            {!isExternalLink ? (
                <Link to={linkTo}>{props.title}</Link>
            ) : (
                <a href={linkTo} target="_blank" rel="noopener noreferrer">
                    {props.title}
                </a>
            )}
        </Menu.Item>
    )
}

@observer
class AppMenu extends React.Component {
    protected readonly appState: AppState
    protected readonly appService: AppService

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        const { sidebar } = this.appState
        const inOrgContext = this.appState.inOrgContext
        const routeEntity = inOrgContext ? 'org' : 'domain'

        const pathClassName = getPathClassName(location.pathname)
        const module = pathClassName.replace(/^(domains|organizations)-/, '')
        const moduleRoot = module.split('-')[0]

        let selectedKey: string | undefined
        switch (moduleRoot) {
            case 'root':
            case 'dashboard':
                selectedKey = 'dashboard'
                break
            case 'notifications':
                selectedKey = 'notifications'
                break
            case 'campaigns':
                selectedKey = 'campaigns'
                break
            case 'segments':
                selectedKey = 'segments'
                break
            case 'prompts':
                selectedKey = 'prompts'
                break
            case 'insights':
                selectedKey = 'insights'
                break
            case 'domains':
            case 'organizations':
                selectedKey = 'settings'
                break
        }

        const mdNotifFlag = this.appState.flags.findActive('FEAT_MULTI_DOMAIN_NOTIFICATIONS')?.getKey()

        // access observable selected domain/account in render fn to ensure redraw
        const activeAcctId = this.appState.currentDomain?.accountId ?? 0
        const activeDomainId = this.appState.currentDomain?.id ?? 0

        if (this.appState?.abilityStore?.acs?.state && !this.appState.abilityStore.abilities.rules.length) {
            Promise.resolve(this.appState.abilityStore.acs.getAbilities())
        }

        return (
            <Menu
                className={classnames(
                    'app-menu',
                    `active-account-${activeAcctId}`,
                    `active-domain-${activeDomainId}`,
                    this.appState.abilityStore.acs.state,
                    {
                        collapsed: sidebar.collapsed,
                        open: !sidebar.collapsed,
                    },
                )}
                mode="inline"
                inlineCollapsed={sidebar.collapsed}
                selectedKeys={!selectedKey ? [] : [selectedKey]}
                onClick={() => {
                    const newPathClassName = getPathClassName(location.pathname)
                    if (newPathClassName !== pathClassName) {
                        this.forceUpdate()
                    }
                }}
            >
                <AppMenuItem
                    key="dashboard"
                    disabled={!this.appState.currentDomain?.id}
                    icon={<AppstoreFilled />}
                    title="Dashboard"
                    linkTo={this.appService.routeWithin(routeEntity, '/dashboard', true)}
                    condition={() =>
                        this.appState.abilityStore.can(
                            AbilityAction.READ,
                            this.appState.abilityStore.currentDomainIdentity,
                        ) && this.appState.inDomainContext
                    }
                />
                <AppMenuItem
                    key="notifications"
                    disabled={!this.appState.currentDomain?.id}
                    icon={<WifiOutlined />}
                    title="Notifications"
                    linkTo={this.appService.routeWithin(routeEntity, '/notifications', true)}
                    condition={this.buildMultiTierConditon(
                        SubjectEntity.NOTIFICATION,
                        SubjectEntity.ORG_NOTIFICATION,
                        mdNotifFlag,
                    )}
                />
                <AppMenuItem
                    key="campaigns"
                    disabled={!this.appState.currentDomain?.id}
                    icon={<BuildFilled />}
                    title="Campaigns"
                    linkTo={this.appService.routeWithin(routeEntity, '/campaigns', true)}
                    condition={() =>
                        this.appState.abilityStore.can(
                            AbilityAction.READ,
                            this.appState.abilityStore.currentDomainIdentity,
                        ) &&
                        this.appState.abilityStore.can(
                            AbilityAction.READ,
                            this.appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.CAMPAIGN),
                        ) &&
                        this.appState.inDomainContext
                    }
                />
                {/*
                 Org Segments will not have a separate flag for multi-domain level.
                 If an org has access to multi-domain notifications they will
                 also implicitly have access to segmentation.
                 */}
                <AppMenuItem
                    key="segments"
                    disabled={!this.appState.currentDomain?.id}
                    icon={<SolutionOutlined />}
                    title="Segments"
                    linkTo={this.appService.routeWithin(routeEntity, '/segments', true)}
                    condition={this.buildMultiTierConditon(
                        SubjectEntity.SEGMENT,
                        SubjectEntity.ORG_SEGMENT,
                        mdNotifFlag,
                    )}
                />
                <AppMenuItem
                    key="prompts"
                    disabled={!this.appState.currentDomain?.id}
                    icon={<SelectOutlined />}
                    title="Prompts"
                    linkTo={this.appService.routeWithin(routeEntity, '/prompts', true)}
                    condition={() =>
                        this.appState.abilityStore.can(
                            AbilityAction.READ,
                            this.appState.abilityStore.currentDomainIdentity,
                        ) &&
                        this.appState.abilityStore.can(
                            AbilityAction.READ,
                            this.appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.PROMPT),
                        ) &&
                        this.appState.inDomainContext
                    }
                />
                <AppMenuItem
                    key="insights"
                    disabled={!this.appState.currentDomain?.id}
                    icon={<AreaChartOutlined />}
                    title="Insights"
                    linkTo={this.appService.routeWithin(routeEntity, '/insights', true)}
                    condition={() =>
                        this.appState.abilityStore.can(
                            AbilityAction.READ,
                            this.appState.abilityStore.currentDomainIdentity,
                        ) && this.appState.inDomainContext
                    }
                />
                <AppMenuItem
                    key="settings"
                    disabled={!this.appState.currentDomain?.id}
                    icon={<SettingFilled />}
                    title="Settings"
                    linkTo={this.appService.routeWithin(routeEntity, '/#settings', true)}
                    condition={() => {
                        const entity = this.appState.inDomainContext
                            ? this.appState.abilityStore.currentDomainIdentity
                            : this.appState.abilityStore.currentOrgIdentity

                        return this.appState.abilityStore.can(AbilityAction.UPDATE, entity)
                    }}
                />
            </Menu>
        )
    }

    protected buildMultiTierConditon(domainEntity: SubjectEntity, orgEntity: SubjectEntity, orgFlag?) {
        return () => {
            const entity = this.appState.inDomainContext ? domainEntity : orgEntity
            const abilityConstraints = this.appState.inDomainContext
                ? { domainId: this.appState.currentDomain?.id }
                : { accountId: this.appState.currentDomain?.accountId }

            /**
             * Menu Visibility:
             * Domain Context  - user has read access to domain
             * Org Context     - org has key & user has read access to org
             */
            let can = this.appState.abilityStore.can(AbilityAction.READ, asCaslSubject(entity, abilityConstraints))
            if (can && this.appState.inOrgContext && orgFlag) {
                can = this.appState.currentDomain?.accountFlags?.includes(orgFlag) ?? false
            }

            return can ?? false
        }
    }
}

export default AppMenu
