export enum RefreshTimeout {
    FIVE_SECONDS = 5_000,
    TEN_SECONDS = 10_000,
    FIFTEEN_SECONDS = 15_000,
    THIRTY_SECONDS = 30_000,
    ONE_MINUTE = 60_000,
    FIVE_MINUTES = 300_000,
    TEN_MINUTES = 600_000,
    FIFTEEN_MINUTES = 900_000,
}

export enum NotificationListTypes {
    DRAFT = 'draft',
    NOTIFICATION = 'notification',
}
