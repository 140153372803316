import React from 'react'
import './segment-list.scss'
import { Container } from 'typescript-ioc/es5'
import classnames from 'classnames'
import * as clone from 'clone'
import * as moment from 'moment-timezone'
import * as deepEqual from 'react-fast-compare'
import { Well } from '@pushly/aqe/lib/components'
import { Modal, Popover, Table, Tooltip } from 'antd'
import SegmentListHeader from './segment-list-header'
import { SegmentListState } from './interfaces'
import { SegmentListContext } from './segment-list-context'
import { AppState } from '../../stores/app'
import { AccountService, AppService, DomainService } from '../../services'
import { TablePaginationConfig } from 'antd/lib/table'
import { getTablePaginationConfig, itemRender, showTotal } from './helpers'
import { Key, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { AntdTableEmptyPlaceholder } from '../aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { TableRowEntityDisplay } from '../table-row-entity-display/table-row-entity-display'
import { InfoCircleOutlined, LoadingOutlined, SolutionOutlined } from '@ant-design/icons'
import { stripUndefined } from '../../_utils/strip-undefined'
import { AsyncButton } from '../async-button/async-button.component'
import { tryParseInt } from '../../_utils/try-parse'
import { getQueryStringParam } from '../../_utils/get-query-string'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { onAccountIdChange } from '../../_utils/account'
import { OrgSegmentService } from '../../services/org-segment'
import { OrgSegmentModel } from '../../models/segments/org-segment.model'
import { numberWithCommas, simpleNotification, simpleSort } from '../../_utils/utils'
import { getUnsafeExecutionText } from '../segment-builder/helpers'
import { INestedBatchRequest, INestedBatchResponse } from '../../interfaces/batch-requests'
import { BatchService } from '../../services/batch'
import { onDomainIdChange } from '../../_utils/domain'
import { SegmentModel } from '../../models/segments/segment.model'
import { SegmentService } from '../../services/segment'
import { SegmentSource } from '../../enums/segment-source.enum'
import { FEAT_CHANNEL_ANDROID, FEAT_CHANNEL_IOS, FEAT_INLINE_SEG, TZ_PLATFORM_DEFAULT } from '../../constants'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { asCaslSubject } from '../../stores/app-ability'
import SwSimpleLinkPagination from '../sw-simple-link-pagination/sw-simple-link-pagination'
import { IApiResponsePaginationLinks } from '../../interfaces/api-response-pagination-links'
import { NoTranslate } from '../no-translate/no-translate'
import { determineChannelBadge } from '../badges/_utils'
import { RouteComponentProps } from 'react-router-dom'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

type FilterKey = keyof SegmentListState['filters']

const COLUMN_KEYS = {
    name: {
        org: 'segment_group.name',
        domain: 'segment.name',
    },
    'estimated-reach': {
        org: 'sum(segment.estimated_reach)',
        domain: 'segment.estimated_reach',
    },
    'last-targeted': {
        org: 'segment_group.last_targeted_date_utc',
        domain: 'segment.last_targeted_date_utc',
    },
    'created-at': {
        org: 'segment_group.created_at',
        domain: 'segment.created_at',
    },
    channels: {
        domain: 'segment.channels',
        org: 'segment_group.channels',
    },
}

function getColumnKey(level: 'org' | 'domain', alias: keyof typeof COLUMN_KEYS): string {
    return COLUMN_KEYS[alias][level]
}

interface IOrgSegmentListProps {
    level: 'org'
    orgId: number
}

interface IDomainSegmentListProps {
    level: 'domain'
    domainId: number
}

type SegmentListProps = Partial<RouteComponentProps<any>> &
    (IOrgSegmentListProps | IDomainSegmentListProps) & {
        title?: string
        filterSize?: SizeType
        filtersAddonBefore?: React.ReactNode
        filtersAddonAfter?: React.ReactNode
        pageSize?: number
        hidePagination?: boolean
        hideRowActions?: boolean
        hideRefresh?: boolean
        hideFilters?: FilterKey | FilterKey[] | boolean
        filtersDisabled?: FilterKey | FilterKey[] | boolean
        defaultFilters?: {
            search?: string
        }
    }

type State = SegmentListState & {
    dataSourcePreviousQuery?: any
    showFailedDialog: boolean
    failedBulkResponses?: INestedBatchResponse[]
    failedBulkRetryAction?: () => any
    failedBulkMessage?: string
    processingBulkOperation: boolean
    paginationLinks?: IApiResponsePaginationLinks
}

type TabbedViewProps = SegmentListProps & {
    isActiveTab: boolean
}

class SegmentList extends React.Component<SegmentListProps, State> {
    protected readonly appState: AppState
    protected readonly appSvc: AppService
    protected readonly orgSvc: AccountService
    protected readonly domainSvc: DomainService
    protected readonly orgSegmentSvc: OrgSegmentService
    protected readonly segmentSvc: SegmentService
    protected readonly batchSvc: BatchService

    protected unmounting: boolean = false
    protected disposeObservers: (() => void)[] = []

    public constructor(props: SegmentListProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appSvc = Container.get(AppService)
        this.orgSvc = Container.get(AccountService)
        this.domainSvc = Container.get(DomainService)
        this.orgSegmentSvc = Container.get(OrgSegmentService)
        this.segmentSvc = Container.get(SegmentService)
        this.batchSvc = Container.get(BatchService)

        // initialize state with defaults from path/query
        this.state = this.applyRequestDefaults({
            level: this.props.level,
            levelDependenciesLoaded: false,
            org: null!, // loaded on mount
            domain: null!, // loaded on mount

            dataSourceLoaded: false,
            dataSource: [],

            refreshing: false,
            refreshEnabled: true,

            sortedInfo: {
                columnKey: getColumnKey(this.props.level, 'name'),
                order: 'ascend',
            },
            paginationConfig: getTablePaginationConfig({
                pageSize: this.props.pageSize ?? 20,
            }),
            selectedIds: [],
            filters: {
                search: this.props.defaultFilters?.search,
                source: [SegmentSource.STANDARD],
                showAllSegments: this.props.level !== 'org',
                channels: [DeliveryChannel.WEB, DeliveryChannel.NATIVE_IOS, DeliveryChannel.NATIVE_ANDROID],
            },

            showFailedDialog: false,
            processingBulkOperation: false,
        })
    }

    public async componentDidMount() {
        let entityIdChangeHandler = this.props.level === 'domain' ? onDomainIdChange : onAccountIdChange
        this.disposeObservers.push(
            entityIdChangeHandler(this.appState, async () => {
                await this.fetchOrganizationalDependencies()
                this.resetList(true)
            }),
        )

        await this.fetchOrganizationalDependencies()
        this.fetchSegments()
    }

    public componentWillUnmount() {
        this.unmounting = true
        this.disposeObservers.forEach((fn) => fn())
    }

    public render() {
        const {
            levelDependenciesLoaded,
            dataSourceLoaded,
            refreshing,
            processingBulkOperation,
            showFailedDialog,
            failedBulkResponses,
        } = this.state

        const currDataSource: (OrgSegmentModel | SegmentModel)[] = this.state.dataSource
        const showTableLoadingAnimation =
            !levelDependenciesLoaded || (!dataSourceLoaded && !refreshing) || processingBulkOperation

        const filtersHidden: FilterKey[] = !this.props.hideFilters
            ? []
            : this.props.hideFilters === true
            ? ['search', 'source', 'showAllSegments']
            : Array.isArray(this.props.hideFilters)
            ? this.props.hideFilters
            : [this.props.hideFilters]

        const forceHideShowAllFilter = this.props.level === 'domain'
        if (!filtersHidden.includes('showAllSegments') && forceHideShowAllFilter) {
            filtersHidden.push('showAllSegments')
        }

        let viewHasNativePush

        const nativeIOSFlag = this.appState.flags.findActive(FEAT_CHANNEL_IOS)?.getKey()
        const nativeAndroidFlag = this.appState.flags.findActive(FEAT_CHANNEL_ANDROID)?.getKey()
        if (this.props.level === 'domain') {
            const domain = this.appState.currentDomain!
            const domainHasNativeIOS = domain.flags.includes(nativeIOSFlag!)
            const domainHasNativeAndroid = domain.flags.includes(nativeAndroidFlag!)

            viewHasNativePush =
                (domainHasNativeIOS && domain.nativeApnsConfiguration?.isActive) ||
                (domainHasNativeAndroid && domain.nativeFcmConfiguration?.isActive)
        } else {
            const accountFlags = this.appState.currentDomain?.accountFlags
            const accountHasNativeIOS = accountFlags?.includes(nativeIOSFlag!)
            const accountHasNativeAndroid = accountFlags?.includes(nativeAndroidFlag!)

            viewHasNativePush = accountHasNativeIOS || accountHasNativeAndroid
        }

        if (!viewHasNativePush) {
            filtersHidden.push('channels')
        }

        const visibleIds = currDataSource.map((segment) => segment.getId())
        const selectedVisibleIds = this.state.selectedIds.filter((id) => visibleIds.includes(id))

        return !this.canExecute ? null : (
            <SegmentListContext.Provider
                value={{
                    ...this.state,
                    onSearchClick: this.handleSearchClick,
                    onFilterChange: this.handleFilterChange,
                    onRefreshClick: this.handleRefreshClick,
                    onRefreshEnabledChange: this.handleRefreshEnabledChange,
                }}
            >
                <Well
                    className={classnames('segment-list', 'table-well', 'nested')}
                    header={
                        <SegmentListHeader
                            title={this.props.title}
                            filterSize={this.props.filterSize}
                            filtersAddonBefore={this.props.filtersAddonBefore}
                            filtersAddonAfter={this.props.filtersAddonAfter}
                            hideFilters={filtersHidden}
                            hideRefresh={this.props.hideRefresh}
                            filtersDisabled={this.props.filtersDisabled}
                            onArchiveClick={this.handleBulkArchive}
                            onAddDomains={this.handleBulkAddDomains}
                        />
                    }
                    hideFooter={true}
                >
                    <div className={classnames('segment-list-content')}>
                        <Table
                            className={classnames('segment-list-table', {
                                'hide-title': !this.props.title,
                                'hide-pagination': this.props.hidePagination,
                                'hide-actions': this.props.level === 'org' && !this.currentUserHasWriteAccess,
                            })}
                            rowClassName="segment-list-row"
                            size="small"
                            dataSource={currDataSource}
                            rowKey={(r) => r.getId()}
                            pagination={false}
                            footer={
                                this.props.hidePagination
                                    ? undefined
                                    : (currentTableData) => (
                                          <SwSimpleLinkPagination
                                              currentTableData={currentTableData}
                                              links={this.state.paginationLinks}
                                              onPrev={async (_ev, _link, config) => {
                                                  await this.setCurrentPage(config.current)
                                                  this.fetchSegments()
                                              }}
                                              onNext={async (_ev, _link, config) => {
                                                  await this.setCurrentPage(config.current)
                                                  this.fetchSegments()
                                              }}
                                          />
                                      )
                            }
                            onChange={this.handleTableChange}
                            rowSelection={
                                !this.appState.abilityStore.can(AbilityAction.UPDATE, SubjectEntity.SEGMENT)
                                    ? undefined
                                    : {
                                          selectedRowKeys: selectedVisibleIds,
                                          onSelectAll: (
                                              selectAll: boolean,
                                              _selectedRows: OrgSegmentModel[],
                                              changeRows: OrgSegmentModel[],
                                          ) => {
                                              const changedIds = changeRows.map((s) => s.getId())

                                              if (selectAll) {
                                                  this.setState(({ selectedIds }) => ({
                                                      selectedIds: [...selectedIds, ...changedIds],
                                                  }))
                                              } else {
                                                  this.setState(({ selectedIds }) => ({
                                                      selectedIds: selectedIds.filter((id) => !changedIds.includes(id)),
                                                  }))
                                              }
                                          },
                                          onChange: (_selectedKeys: React.Key[], selectedRows: OrgSegmentModel[]) => {
                                              const nextSelectedIds = selectedRows.map((s) => s.getId())

                                              this.setState(({ selectedIds }) => {
                                                  const newIds = nextSelectedIds
                                                      .filter((id) => !selectedIds.includes(id as number))
                                                      .map((id) => parseInt(id.toString(), 10))

                                                  return {
                                                      selectedIds: [
                                                          ...selectedIds.filter(
                                                              (id) =>
                                                                  nextSelectedIds.includes(id) ||
                                                                  !visibleIds.includes(id),
                                                          ),
                                                          ...newIds,
                                                      ],
                                                  }
                                              })
                                          },
                                      }
                            }
                            locale={{
                                emptyText: <AntdTableEmptyPlaceholder text="No Segments" icon={<SolutionOutlined />} />,
                            }}
                            loading={
                                showTableLoadingAnimation && {
                                    indicator: <LoadingOutlined spin={true} />,
                                }
                            }
                        >
                            <Table.Column
                                key={getColumnKey(this.props.level, 'name')}
                                sorter={true}
                                defaultSortOrder="ascend"
                                className="segment"
                                dataIndex={['id']}
                                title="Name"
                                render={this.renderSegmentDisplay}
                            />

                            <Table.Column
                                key={getColumnKey(this.props.level, 'estimated-reach')}
                                sorter={true}
                                className="estimated-reach"
                                dataIndex={['estimatedReach']}
                                align="right"
                                title={
                                    <React.Fragment>
                                        <span>Audience Size </span>
                                        <Popover
                                            overlayClassName="segment-list-estimated-reach-popover"
                                            trigger="hover"
                                            content={
                                                <span>
                                                    Subscriber audience size is updated once a day at midnight in your
                                                    domain's time zone. Real-time subscriber size can be obtained by
                                                    viewing the audience size on the segment page.
                                                    <br />
                                                    <br />A value of -- indicates that audience size has yet to be
                                                    computed for this segment but will be available shortly.
                                                </span>
                                            }
                                        >
                                            <InfoCircleOutlined className="info-icon" />
                                        </Popover>
                                    </React.Fragment>
                                }
                                render={(reach, segment: OrgSegmentModel | SegmentModel) => {
                                    const estimatedReach =
                                        segment instanceof SegmentModel ? reach : segment.getAggregateReach()

                                    if (segment.getComputedStatus() === 'READY') {
                                        return estimatedReach === null ? '--' : numberWithCommas(estimatedReach)
                                    } else {
                                        return (
                                            <span className="reach-tooltip segment-procesing">
                                                <Tooltip title={getUnsafeExecutionText()}>
                                                    <InfoCircleOutlined className="info-icon" />
                                                </Tooltip>
                                                <span className="label">Processing</span>
                                            </span>
                                        )
                                    }
                                }}
                            />

                            {this.props.level === 'org' && (
                                <Table.Column
                                    sorter={false}
                                    className="reach-breakdown"
                                    dataIndex={['estimatedReach']}
                                    title="Domains"
                                    align="right"
                                    render={this.renderReachBreakdown}
                                />
                            )}

                            <Table.Column
                                key={getColumnKey(this.props.level, 'channels')}
                                sorter={false}
                                className="delivery-channel"
                                dataIndex={['channels']}
                                title="Delivery Channels"
                                align="center"
                                render={(channels: DeliveryChannel[]) =>
                                    channels
                                        .filter((val) => (!viewHasNativePush ? val === DeliveryChannel.WEB : true))
                                        .map((ch) => <span key={ch}>{determineChannelBadge(ch)}</span>)
                                }
                            />

                            {this.props.level === 'domain' && (
                                <Table.Column
                                    key={getColumnKey(this.props.level, 'last-targeted')}
                                    sorter={true}
                                    className="last-targeted"
                                    dataIndex={['lastTargetedDateUtc']}
                                    title="Last Targeted"
                                    align="right"
                                    render={(lastTargetedDateUtc) => {
                                        const levelEntity =
                                            this.state.level === 'org' ? this.state.org : this.state.domain
                                        const tz = levelEntity.timezone ?? TZ_PLATFORM_DEFAULT

                                        const dt = moment.tz(lastTargetedDateUtc, tz)
                                        return !lastTargetedDateUtc ? '--' : dt.format('LL')
                                    }}
                                />
                            )}

                            <Table.Column
                                key="actions"
                                className="actions"
                                title="Actions"
                                align="right"
                                render={this.renderRowActions}
                            />
                        </Table>
                    </div>
                </Well>

                <Modal
                    visible={showFailedDialog}
                    className="segment-list-failed-bulk-dialog"
                    title="Failed Bulk Actions"
                    okText="Retry"
                    onOk={this.handleFailedDialogOk}
                    confirmLoading={processingBulkOperation}
                    cancelText="Dismiss"
                    onCancel={this.handleFailedDialogDismiss}
                >
                    {!!failedBulkResponses && (
                        <div>
                            <p>
                                {failedBulkResponses.length} segment(s) {this.state.failedBulkMessage}. Please try again
                                or contact your account manager for more information.
                            </p>
                        </div>
                    )}
                </Modal>
            </SegmentListContext.Provider>
        )
    }

    protected get canExecute(): boolean {
        const tabbedViewProps = this.props as TabbedViewProps
        return !('isActiveTab' in tabbedViewProps) || tabbedViewProps.isActiveTab
    }

    protected get currentUserHasWriteAccess(): boolean {
        let userHasWriteAccess
        if (this.props.level === 'domain') {
            // Can user write to segments at the domain level?
            userHasWriteAccess = this.appState.abilityStore.can(AbilityAction.CREATE, SubjectEntity.SEGMENT)
        } else {
            const orgId = this.props.orgId
            const accessibleDomains =
                this.appState.currentUserDomains?.filter((d) => d.accountId?.toString() === orgId.toString()) ?? []

            // Can user write to segments at the org level?
            userHasWriteAccess =
                this.appState.abilityStore.can(
                    AbilityAction.UPDATE,
                    this.appState.abilityStore.getOrgOwnedIdentityFor(SubjectEntity.ORG_SEGMENT),
                ) && accessibleDomains.length > 1
        }

        return userHasWriteAccess
    }

    protected renderSegmentDisplay = (id: string, model: OrgSegmentModel | SegmentModel): React.ReactNode => {
        return (
            <TableRowEntityDisplay
                title={
                    <a href={this.buildSegmentEditUrl(model)}>
                        <NoTranslate>{model.getName()}</NoTranslate>
                    </a>
                }
            />
        )
    }

    protected renderReachBreakdown = (id: string, model: OrgSegmentModel): React.ReactNode => {
        const isMultiDomain = model.getIsMultiDomain() ?? false
        const title = isMultiDomain ? 'Domains / Audience Size' : 'Domain'

        const childSegments = model.getSegments()
        childSegments.sort((a, b) => simpleSort(a.domain_display_name, b.domain_display_name))

        return (
            <Popover
                key="domains-badge"
                overlayClassName="segment-list-domains-popover"
                title={title}
                content={childSegments.map((segment) => (
                    <div key={segment.id} className="segment-list-domains-item">
                        <span className="domains-item-label">{segment.domain_display_name}</span>

                        {isMultiDomain && (
                            <span className="domains-item-reach">
                                {!segment.estimated_reach ? '--' : numberWithCommas(segment.estimated_reach)}
                            </span>
                        )}
                    </div>
                ))}
            >
                <span className="segment-list-domains-badge">
                    {isMultiDomain ? <span>{model.getDomainIds().length} Domains</span> : <span>1 Domain</span>}
                </span>
            </Popover>
        )
    }

    protected renderRowActions = (_, model: OrgSegmentModel | SegmentModel): React.ReactNode => {
        let abilityEntity = SubjectEntity.SEGMENT
        let abilityConstraint: any = {}
        if (this.props.level === 'org') {
            abilityEntity = SubjectEntity.ORG_SEGMENT
            abilityConstraint.accountId = this.props.orgId
        } else {
            abilityConstraint.domainId = this.props.domainId
        }

        const canMutate = this.appState.abilityStore.can(
            AbilityAction.UPDATE,
            asCaslSubject(abilityEntity, abilityConstraint),
        )

        let actions: any = []

        if (canMutate) {
            if (this.props.level === 'domain') {
                actions.push({
                    text: 'Edit',
                    icon: 'edit',
                    onClick: () => this.handleEditSegmentAction(model),
                    altHref: () => this.buildSegmentEditUrl(model),
                })
            }

            actions.push({
                text: 'Duplicate',
                icon: 'copy',
                onClick: () => this.jumpToDuplicateSegment(model),
                altHref: () => this.buildDuplicateSegmentUrl(model),
            })

            // multi-domain segments cannot be archived
            // from the domain level list view
            const canArchive = this.props.level === 'org' || !model.getIsMultiDomain()
            const baseArchiveAction = {
                text: 'Archive',
                icon: 'folder',
            }

            if (canArchive) {
                const showAddtOrgCopy =
                    this.props.level === 'org' &&
                    (model as OrgSegmentModel).getIsMultiDomain() &&
                    (model as OrgSegmentModel).getSegments().length > 1

                actions.push(
                    {
                        divider: true,
                    },
                    {
                        ...baseArchiveAction,
                        onClick: () => this.handleArchive(model),
                        confirm: {
                            okText: 'Yes, Archive',
                            title: 'Archive Segment',
                            content: (
                                <div>
                                    Are you sure you want to archive the following segment?
                                    <p>
                                        <b>{model.getName()}</b>
                                    </p>
                                    <p>
                                        This segment will no longer be available to target during notification creation.
                                    </p>
                                    {showAddtOrgCopy && (
                                        <React.Fragment>
                                            <p>
                                                This action will also remove the ability for the segment to be targeted
                                                on the following domains:
                                            </p>

                                            <ul>
                                                {(model as OrgSegmentModel).getSegments().map((s) => (
                                                    <li key={s.id}>{s.domain_display_name}</li>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    )}
                                </div>
                            ),
                        },
                    },
                )
            } else {
                this.currentUserHasWriteAccess &&
                    actions.push({
                        ...baseArchiveAction,
                        onClick: () => true,
                        confirm: {
                            title: 'Multi-Domain Segment',
                            okText: 'Close',
                            cancelButtonProps: {
                                className: 'display-none',
                            },
                            content: (
                                <div>
                                    This is a multi-domain segment and can only be archived from the organization's
                                    multi-domain segments page.
                                </div>
                            ),
                        },
                    })
            }
        }

        if (this.props.hideRowActions === true) {
            actions = undefined
        }

        if (!this.currentUserHasWriteAccess) {
            actions = undefined
        }

        return this.props.level === 'org' && canMutate ? (
            <AsyncButton
                actions={actions}
                onClick={() => this.handleEditSegmentAction(model)}
                size="small"
                altHref={this.buildSegmentEditUrl(model)}
            >
                <span>Edit</span>
            </AsyncButton>
        ) : this.props.level === 'domain' ? (
            <AsyncButton
                actions={actions}
                onClick={() => this.handlePrimaryActionClick(model)}
                size="small"
                altHref={this.buildSegmentViewUrl(model)}
            >
                <span>View</span>
            </AsyncButton>
        ) : null
    }

    protected buildSegmentEditUrl(model: OrgSegmentModel | SegmentModel): string {
        return this.appSvc.routeWithin(this.props.level, `/segments/${model.getId()}`, true)
    }

    protected buildSegmentViewUrl(model: OrgSegmentModel | SegmentModel): string {
        let route = `/segments/${model.getId()}`
        if (this.props.level === 'domain') {
            route = `${route}/summary`
        }

        return this.appSvc.routeWithin(this.props.level, route, true)
    }

    protected buildDuplicateSegmentUrl(model: OrgSegmentModel | SegmentModel): string {
        return this.appSvc.routeWithin(this.props.level, `/segments/new?template=${model.getId()}`, true)
    }

    protected jumpToDuplicateSegment = (model: OrgSegmentModel | SegmentModel): void => {
        this.appSvc.route(this.buildDuplicateSegmentUrl(model))
    }

    protected async setStateAsync<K extends keyof State>(
        state:
            | ((prevState: Readonly<State>, props: Readonly<IOrgSegmentListProps>) => Pick<State, K> | State | null)
            | (Pick<State, K> | State | null),
    ): Promise<void> {
        return new Promise((res) => {
            if (!this.unmounting) {
                this.setState(state as State, res)
            }
        })
    }

    protected applyRequestDefaults(state: State): State {
        // default query
        const query = getQueryStringParam('query')
        if (!!query?.trim()) {
            state.filters = {
                ...state.filters,
                search: query.trim(),
            }
        }

        // ensure default page
        const currentPage = getQueryStringParam('page', tryParseInt)
        if (currentPage && currentPage !== 1) {
            state.paginationConfig = {
                ...state.paginationConfig,
                current: currentPage,
            }
        }

        return state
    }

    protected updatePathParams(): void {
        const {
            paginationConfig: { current: page },
        } = { ...this.state }

        let parsedUpdate: string | undefined

        if (page !== 1) {
            parsedUpdate = `&page=${page}`
        }

        this.props.history?.push?.({ search: parsedUpdate?.replace(/^&/, '') })
    }

    protected async resetList(clearAll?: boolean) {
        await this.setStateAsync({ dataSourceLoaded: false })

        const filtersOverride: any = {}
        if (clearAll) {
            filtersOverride.search = ''
        }

        await this.setStateAsync((curr) => ({
            selectedIds: [],
            paginationConfig: {
                ...curr.paginationConfig,
                current: 1,
            },
            filters: {
                ...curr.filters,
                ...filtersOverride,
            },
        }))

        this.fetchSegments(clearAll)
    }

    protected async setCurrentPage(page: number | undefined): Promise<void> {
        await this.setStateAsync(({ paginationConfig }) => ({
            paginationConfig: {
                ...paginationConfig,
                current: page ?? 1,
            },
        }))

        this.updatePathParams()
    }

    protected handleTableChange = async (
        pagination: TablePaginationConfig,
        _filters: Record<string, Key[] | null>,
        sorter: SorterResult<OrgSegmentModel | SegmentModel>,
        _extra: TableCurrentDataSource<OrgSegmentModel | SegmentModel>,
    ): Promise<void> => {
        let resolver = (cb: () => void) => cb()

        const sortedInfoData = { order: sorter.order, columnKey: sorter.columnKey }
        const sortChanged = !deepEqual(this.state.sortedInfo, sortedInfoData)
        if (sortChanged) {
            // ensure sorted info updated first
            resolver = (cb: () => void) => {
                this.setState({ sortedInfo: sortedInfoData }, () => cb())
            }
        }

        resolver(async () => {
            await this.setCurrentPage(pagination.current)
            this.fetchSegments()
        })
    }

    protected handleSearchClick = async (value: string) => {
        const prevFilter = this.state.filters.search

        await this.setStateAsync(({ filters }) => ({
            filters: {
                ...filters,
                search: value,
            },
        }))

        const emptyValues = !value.trim() && !prevFilter?.trim()
        if (!emptyValues) {
            await this.resetList()
            this.fetchSegments(true)
        }
    }

    protected handleFilterChange = async <FilterValue extends SegmentListState['filters'][FilterKey]>(
        key: FilterKey,
        value: FilterValue,
    ): Promise<void> => {
        const prevFilters = clone(this.state.filters)

        await this.setStateAsync(({ filters }) => ({
            filters: {
                ...filters,
                [key]: value,
            },
        }))

        if (!deepEqual(this.state.filters, prevFilters)) {
            await this.resetList()
            this.fetchSegments()
        }
    }

    protected handleRefreshClick = async (_ev: React.MouseEvent<HTMLElement>): Promise<void> => {
        // manual refresh click should not set refreshing
        // to ensure full table loading animation is shown
        this.fetchSegments(true)
    }

    protected handleRefreshEnabledChange = async (refreshEnabled: boolean) => {
        await this.setStateAsync({ refreshEnabled })
    }

    protected handleEditSegmentAction = (model: OrgSegmentModel | SegmentModel): void => {
        this.appSvc.route(this.buildSegmentEditUrl(model))
    }

    protected handlePrimaryActionClick = (model: OrgSegmentModel | SegmentModel): void => {
        this.appSvc.route(this.buildSegmentViewUrl(model))
    }

    protected handleFailedDialogOk = async () => {
        return this.state.failedBulkRetryAction?.()
    }

    protected handleFailedDialogDismiss = () => {
        return this.setState({ showFailedDialog: false })
    }

    protected handleArchive = async (segment: OrgSegmentModel | SegmentModel) => {
        let resolver
        if (this.props.level === 'domain') {
            resolver = this.segmentSvc
                .deleteSegmentById(this.props.domainId, segment.getId())
                .then((ok) => ({ ok }))
                .catch((error) => ({ ok: false, error }))
        } else {
            resolver = this.orgSegmentSvc.archive(this.props.orgId, {
                id: segment.getId(),
                name: segment.getName(),
            })
        }

        const res = await Promise.resolve(resolver)
        if (res.ok) {
            this.fetchSegments(true)
        }
    }

    protected handleBulkArchive = async () => {
        const state: any = { processingBulkOperation: true }
        this.setState({ ...state })

        let segmentsPath
        if (this.props.level === 'domain') {
            segmentsPath = `/domains/${this.props.domainId}/segments`
        } else {
            segmentsPath = `/accounts/${this.props.orgId}/segments`
        }

        const buildArchiveReq = (id: number): INestedBatchRequest => ({
            meta: {
                segmentId: id,
            },
            method: 'DELETE',
            relative_path: `${segmentsPath}/${id}`,
        })

        const failedBulkResponses: INestedBatchResponse[] = []
        const selectedIds = this.state.selectedIds
        const idsToArchive = Array.from(new Set(selectedIds))

        const res = await this.batchSvc.batch({
            requests: idsToArchive.map(buildArchiveReq),
        })

        if (res.ok && res.data) {
            res.data.forEach((batchRes) => {
                if (batchRes.code !== 200) {
                    failedBulkResponses.push(batchRes)
                } else {
                    const checkedIdx = selectedIds.findIndex(
                        (id) => id.toString() === batchRes.meta.segment_id.toString(),
                    )
                    if (checkedIdx !== -1) {
                        selectedIds.splice(checkedIdx, 1)
                    }
                }
            })
        }

        state.processingBulkOperation = false
        state.selectedDomainIds = selectedIds
        state.failedBulkResponses = failedBulkResponses
        state.showFailedDialog = failedBulkResponses.length > 0

        if (!state.showFailedDialog) {
            simpleNotification('success', `${idsToArchive.length} Segments successfully archived.`)
            state.selectedDomainIds = []
        } else {
            state.failedBulkRetryAction = this.handleBulkArchive
            state.failedBulkMessage = 'failed to archive'
        }

        this.setState(state)
        this.fetchSegments(true)
    }

    protected handleBulkAddDomains = async (domainIds: number[]) => {
        // this method is org level only
        const props = this.props as IOrgSegmentListProps

        const state: any = { processingBulkOperation: true }
        this.setState({ ...state })

        const failedBulkResponses: INestedBatchResponse[] = []
        const selectedIds = this.state.selectedIds
        const idsToUpdate = Array.from(new Set(selectedIds))

        const buildAddReq = (id: number): INestedBatchRequest => ({
            method: 'POST',
            relative_path: `/accounts/${props.orgId}/segments/${id}/domains`,
            body: { domainIds },
            meta: {
                segmentId: id,
            },
        })

        const res = await this.batchSvc.batch({
            requests: idsToUpdate.map(buildAddReq),
        })

        if (res.ok && res.data) {
            res.data.forEach((batchRes) => {
                if (batchRes.code < 200 || batchRes.code >= 300) {
                    failedBulkResponses.push(batchRes)
                } else {
                    const checkedIdx = selectedIds.findIndex(
                        (id) => id.toString() === batchRes.meta.segment_id.toString(),
                    )
                    if (checkedIdx !== -1) {
                        selectedIds.splice(checkedIdx, 1)
                    }
                }
            })
        }

        state.processingBulkOperation = false
        state.selectedDomainIds = selectedIds
        state.failedBulkResponses = failedBulkResponses
        state.showFailedDialog = failedBulkResponses.length > 0

        if (!state.showFailedDialog) {
            simpleNotification('success', `${idsToUpdate.length} Segments successfully updated.`)
            state.selectedDomainIds = []
        } else {
            state.failedBulkRetryAction = () => this.handleBulkAddDomains(domainIds)
            state.failedBulkMessage = 'could not be updated'
        }

        this.setState(state)
        this.fetchSegments(true)
    }

    protected async fetchSegments(forceFetch?: boolean) {
        if (!this.canExecute) {
            return
        }

        const { filters } = this.state

        const defaultSortInfo = { columnKey: getColumnKey(this.props.level, 'name'), order: 'asc' }
        const sortedInfo = this.state.sortedInfo ?? defaultSortInfo
        const orderConfig: any = {
            field: sortedInfo.columnKey,
            direction: sortedInfo.order,
            nulls: 'LAST',
        }

        const apiOptions = {
            showLoadingScreen: false,
            cancellationKey: 'segment-list.fetch',
            query: stripUndefined({
                page: this.state.paginationConfig.current,
                limit: this.state.paginationConfig.pageSize,
                include_default: false,
                order: JSON.stringify(orderConfig),
                source: [SegmentSource.STANDARD],
                search: filters.search ?? undefined,
                multi_domain_only: !filters.showAllSegments,
                channels: filters.channels.join(','),
            }),
        }

        // Domain level source filtering
        const inlineSegFlag = this.appState.flags.findActive(FEAT_INLINE_SEG)?.getKey()
        const hasInlineSegFeat =
            this.props.level === 'domain' &&
            'domain' in this.state &&
            !!inlineSegFlag &&
            this.state.domain?.flags?.includes(inlineSegFlag)
        if (hasInlineSegFeat && this.state.filters.source) {
            apiOptions.query.source = this.state.filters.source.join(',')
        }

        // Abort fetch if manual refresh and query has not changed
        const sameQuery =
            !this.state.refreshing &&
            this.state.dataSourcePreviousQuery &&
            deepEqual(apiOptions.query, this.state.dataSourcePreviousQuery)
        if (!forceFetch && sameQuery) {
            return
        }

        // Start loading animation
        await this.setStateAsync({
            dataSourceLoaded: false,
            dataSourcePreviousQuery: apiOptions.query,
        })

        const nextDataSource: (OrgSegmentModel | SegmentModel)[] = []
        const state: any = {
            ...this.state,
            refreshing: false,
            dataSourceLoaded: true,
        }

        let resolver
        if (this.props.level === 'domain') {
            resolver = this.domainSvc.fetchSegmentsByDomainId(this.props.domainId, apiOptions)
        } else {
            resolver = this.orgSegmentSvc.fetchAll(this.props.orgId, apiOptions)
        }

        const res = await Promise.resolve(resolver)
        if (!res.cancelled) {
            if (res.ok && res.data) {
                let modelKlass = this.props.level === 'domain' ? SegmentModel : OrgSegmentModel
                res.data.forEach((datum) => nextDataSource.push(modelKlass.build(datum)))

                state.paginationLinks = res.meta.links ?? state.paginationLinks
            } else if (res.error) {
                console.warn(res.error)
            }

            state.dataSource = nextDataSource
            await this.setStateAsync(state)
        }
    }

    protected async fetchOrganizationalDependencies() {
        if (!this.canExecute) {
            return
        }

        const state: any = { levelDependenciesLoaded: false }
        await this.setStateAsync({ ...state })

        if (this.props.level === 'domain') {
            const { ok, data } = await this.domainSvc.fetchById(this.props.domainId)
            if (ok && data) {
                state.domain = data
            }
        } else {
            state.org = await this.orgSvc.fetchById(this.props.orgId)
        }

        state.levelDependenciesLoaded = true
        await this.setStateAsync(state)
    }
}

export default SegmentList
