import * as React from 'react'
import styled from 'styled-components'
import { ILink as IFCLink, ILinkDefaultProps, LinkDefault } from '@mrblenny/react-flow-chart'
import Icon, {
    ApartmentOutlined,
    ClockCircleOutlined,
    PlusOutlined,
    ShoppingCartOutlined,
    WarningFilled,
    WifiOutlined,
} from '@ant-design/icons'
import { Button, Popover, Tooltip } from 'antd'
import { ActionType, CampaignEditableState, NodeType, TriggerType } from './enums'
import { EventBus } from './events'
import { CAMPAIGN_EVENT_BUS_NAME, CURRENT_BUILDER_ITERATION } from './constants'
import { getClassNames } from '../../_utils/classnames'
import { CampaignDto } from '../../features/campaigns/dtos/campaign-dto'
import AmplyIcon from '../icons/amply-icon'
import { DomainDto } from '../../dtos/domain'
import IconWebhooks from '../icons/icon-webhooks'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc'
import { arrayContains } from '../../_utils/utils'
import { FEAT_CAMPAIGN_WEBHOOK_ACTIONS } from '../../constants'

interface ILinkActions extends ILinkDefaultProps {
    onActionClick: (nodeType: string, link: IFCLink, actionType?: ActionType) => void
    getCampaign: () => CampaignDto
    getDomain: () => DomainDto
    getShowAmplyExperience: () => boolean
}

interface ILinkActionsState {
    expanded: boolean
}

const ActionToggleEvent = 'TOGGLE_LINK_ACTIONS'

class LinkActions extends React.Component<ILinkActions, ILinkActionsState> {
    private appState: AppState

    constructor(props) {
        super(props)

        this.appState = Container.get(AppState)

        this.state = {
            expanded: false,
        }
    }

    public componentDidMount(): void {
        this.eventBus.observe(ActionToggleEvent, this.collapseActions)
    }

    public componentWillUnmount(): void {
        this.eventBus.unobserve(ActionToggleEvent, this.collapseActions)
    }

    public render() {
        const campaign = this.props.getCampaign()
        const campaignType = campaign?.configuration?.computed_trigger_type
        const isAbandonedCartCampaign = campaignType === TriggerType.ABANDONED_CART
        const isEcommAbandonedCampaign =
            campaignType === TriggerType.ABANDONED_CART || campaignType === TriggerType.ABANDONED_VIEW

        const domain = this.props.getDomain()
        const showAmplyExperience = this.props.getShowAmplyExperience()

        let hasWebhookFlag = false
        const webHookFlag = this.appState.flags.findActive(FEAT_CAMPAIGN_WEBHOOK_ACTIONS)?.getKey()
        if (!!webHookFlag && domain.flags?.includes(webHookFlag)) {
            hasWebhookFlag = true
        }

        return (
            <div
                className={getClassNames('link-option-row', {
                    ['open']: this.state.expanded,
                })}
            >
                <Button
                    className="btn-toggle-options"
                    shape="circle"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={this.handleToggle}
                />

                <div className={getClassNames('expanded-options')}>
                    <div className={getClassNames('expanded-options-wrapper')}>
                        <Tooltip title="Add Delay">
                            <Button
                                className="btn-add-delay"
                                shape="circle"
                                size="small"
                                icon={<ClockCircleOutlined />}
                                onClick={this.handleAddDelay}
                            />
                        </Tooltip>

                        {CURRENT_BUILDER_ITERATION >= 3 && (
                            <Tooltip title="Add Condition">
                                <Button
                                    className="btn-add-filter"
                                    shape="circle"
                                    size="small"
                                    icon={<ApartmentOutlined />}
                                    onClick={this.handleAddFilter}
                                />
                            </Tooltip>
                        )}
                        <Tooltip title="Send Notification">
                            <Button
                                className="btn-add-action"
                                shape="circle"
                                size="small"
                                icon={isAbandonedCartCampaign ? <ShoppingCartOutlined /> : <WifiOutlined />}
                                onClick={() =>
                                    this.handleAddAction(
                                        isAbandonedCartCampaign
                                            ? ActionType.SEND_CART_NOTIFICATION
                                            : ActionType.SEND_NOTIFICATION,
                                    )
                                }
                            />
                        </Tooltip>

                        {hasWebhookFlag && !isAbandonedCartCampaign && (
                            <Tooltip title="Send Webhook Notification">
                                <Button
                                    className={`btn-add-action ${ActionType.SEND_CONTENT_WEBHOOK_NOTIFICATION}`}
                                    shape="circle"
                                    size="small"
                                    icon={<Icon component={IconWebhooks} />}
                                    onClick={() => this.handleAddAction(ActionType.SEND_CONTENT_WEBHOOK_NOTIFICATION)}
                                />
                            </Tooltip>
                        )}

                        {showAmplyExperience && !isAbandonedCartCampaign && (
                            <Tooltip title="Send Collection Notification">
                                <Button
                                    className={`btn-add-action ${ActionType.SEND_AMPLY_COLLECTION_NOTIFICATION}`}
                                    shape="circle"
                                    size="small"
                                    icon={<Icon component={AmplyIcon} />}
                                    onClick={() => this.handleAddAction(ActionType.SEND_AMPLY_COLLECTION_NOTIFICATION)}
                                />
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    public get eventBus() {
        return EventBus.get(CAMPAIGN_EVENT_BUS_NAME)
    }

    public get isBorderedByDelay(): boolean {
        return this.props.fromNode.type === NodeType.DELAY || this.props.toNode?.type === NodeType.DELAY
    }

    public handleToggle = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        await this.setState(({ expanded }) => ({ expanded: !expanded }))

        if (this.state.expanded) {
            this.eventBus.dispatch(ActionToggleEvent, this.props.link)
        }
    }

    public collapseActions = (link: IFCLink) => {
        if (link.id !== this.props.link.id) {
            this.setState({ expanded: false })
        }
    }

    public handleAddDelay = () => {
        this.props.onActionClick(NodeType.DELAY, this.props.link)
    }

    public handleAddFilter = () => {
        this.props.onActionClick(NodeType.CONDITION, this.props.link)
    }

    public handleAddAction = (actionType: ActionType) => {
        this.props.onActionClick(NodeType.ACTION, this.props.link, actionType)
    }
}

const LinkError = ({ link }: { link: IFCLink }) => {
    return (
        <div className={getClassNames('campaign-canvas-link-error')}>
            <Popover
                overlayClassName={getClassNames('campaign-canvas-link-error-popover')}
                title={false}
                content={link.properties?.error ?? 'An error has occurred.'}
            >
                <WarningFilled />
            </Popover>
        </div>
    )
}

interface ILink {
    getEditableState: () => CampaignEditableState
    onAddNode: (nodeType: string, link: IFCLink, actionType?: ActionType) => void
    getCampaign: () => CampaignDto
    getDomain: () => DomainDto
    getShowAmplyExperience: () => boolean
}

export const Link = (props: ILink) => {
    return (_props: ILinkDefaultProps, ..._args: any[]) => {
        const { startPos, endPos, link } = _props
        const optionsX = endPos.x + 10
        let optionsY = startPos.y + (endPos.y - startPos.y) / 2

        if (!!link.properties?.branch) {
            optionsY = endPos.y - 20
        }

        const CustomLink = styled.div.attrs({
            className: getClassNames('campaign-canvas-link', link.properties?.branch, {
                [`type-${link.properties?.type ?? 'default'}`]: true,
                [`from-type-${_props.fromNode.type}`]: true,
                [`to-type-${_props.toNode?.type ?? 'default'}`]: true,
                ['has-error']: link.properties?.hasError,
            }),
        })<any>``

        const CustomLinkOptions = styled.div.attrs({
            className: getClassNames('campaign-canvas-link-options'),
        })<any>``

        const fromNode = _props.fromNode.properties?.[_props.fromNode.type]
        const fromStats = fromNode?.stats

        return (
            <>
                <CustomLink>
                    <LinkDefault {..._props} />
                    <CustomLinkOptions style={{ left: optionsX, top: optionsY }}>
                        {props.getEditableState() === CampaignEditableState.EDITABLE && (
                            <LinkActions
                                {..._props}
                                onActionClick={props.onAddNode}
                                getCampaign={props.getCampaign}
                                getDomain={props.getDomain}
                                getShowAmplyExperience={props.getShowAmplyExperience}
                            />
                        )}
                        {link.properties?.hasError && <LinkError link={link} />}
                    </CustomLinkOptions>
                </CustomLink>
            </>
        )
    }
}
