import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'

export class NotificationNativeAndroidContentModel {
    public static build(props: NotificationNativeAndroidContentModel | any): NotificationNativeAndroidContentModel {
        const data = props instanceof NotificationNativeAndroidContentModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationNativeAndroidContentModel()
        model.setChannel(scData.channel)
        model.setPriority(scData.priority)

        return model
    }

    private channel: string | undefined
    private priority: string | undefined
    private title: string | undefined

    public clone(): NotificationNativeAndroidContentModel {
        return NotificationNativeAndroidContentModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            priority: this.getPriority(),
            channel: this.getChannel(),
        })
    }

    public getChannel(): string | undefined {
        return this.channel
    }
    public setChannel(channel: string | undefined) {
        this.channel = channel
    }

    public getPriority(): string | undefined {
        return this.priority
    }
    public setPriority(priority: string | undefined) {
        this.priority = priority
    }

    public getTitle(): string | undefined {
        return this.priority
    }
    public setTitle(priority: string | undefined) {
        this.priority = priority
    }
}
