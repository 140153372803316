export const DEFAULT_LEVEL = {
    value: 'DOMAIN',
    label: 'Domain',
}

export const LEVEL_DROPDOWN_OPTIONS = {
    DELIVERY: [
        DEFAULT_LEVEL,
        { value: 'NOTIFICATION', label: 'Notification' },
        { value: 'TEST', label: 'A/B Test' },
        { value: 'CAMPAIGN', label: 'Campaign' },
        { value: 'SEGMENT', label: 'Segment' },
        { value: 'KEYWORD', label: 'Keyword' },
    ],
    SUBSCRIBER: [DEFAULT_LEVEL, { value: 'PROMPT', label: 'Prompt' }, { value: 'TEST', label: 'A/B Test' }],
    PUB_NETWORK: [DEFAULT_LEVEL],
    ATTRITION: [DEFAULT_LEVEL],
    MONTHLY_UNIQUES: [DEFAULT_LEVEL],
}
