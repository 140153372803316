import { OrgNotificationModel } from '../../../models/notification/org-notification.model'
import { NotificationDto } from '../../notifications/index'
import { getDefaultNotificationActions } from '../../../_utils/domain'
import { BadgeBehaviorModel } from '../../../models/notification/notification-native-ios-content.model'

export const convertOrgNotificationModelToNotificationDto = (
    model: OrgNotificationModel,
): NotificationDto | undefined => {
    let dto: NotificationDto | undefined

    if (!!model?.getId()) {
        try {
            const variant = model.getVariant()
            const audience = variant?.getAudience()
            const content = variant?.getContent()
            const defaultContent = content?.getDefaultContent()
            const webContent = content?.getWebContent()
            const nativeIosContent = content?.getNativeIosContent()
            const expOpts = defaultContent?.getExperienceOptions()
            const delivery = variant?.getDelivery()

            let actions: any[] | undefined = defaultContent?.getActions()?.map((action) => ({
                id: action.getId(),
                ordinal: action.getOrdinal(),
                type: action.getType(),
                label: action.getLabel(),
                landingUrl: action.getLandingUrl(),
                usePrimaryLandingUrl: action.getUsePrimaryLandingUrl(),
            }))

            if (defaultContent?.getCustomActionsEnabled() && (actions?.length ?? 0) === 0) {
                actions = getDefaultNotificationActions(null)
            }

            const raw: any = {
                id: model.getId(),
                audience: {
                    domainIds: audience?.getDomainIds(),
                    segmentIds: audience?.getSegmentIds(),
                },
                template: {
                    channels: {
                        default: {
                            landingUrl: defaultContent?.getLandingUrl(),
                            altNativeUrl: defaultContent?.getNativeAltUrl(),
                            title: defaultContent?.getTitle(),
                            body: defaultContent?.getBody(),
                            imageUrl: defaultContent?.getImageUrl(),
                            iconUrl: defaultContent?.getIconUrl(),
                            isUsingDomainDefaultIcon: defaultContent?.getIsUsingDomainDefaultIcon(),
                            badgeUrl: defaultContent?.getBadgeUrl(),
                            isUsingDomainDefaultBadge: defaultContent?.getIsUsingDomainDefaultBadge(),
                            overrideDefaultActions: defaultContent?.getCustomActionsEnabled(),
                            actions,
                            isSilent: expOpts?.getIsSilent(),
                            requireInteraction: expOpts?.getRequireInteraction(),
                            keywords: defaultContent?.getKeywords(),
                        },
                        web: {
                            title: webContent?.getTitle(),
                            body: webContent?.getBody(),
                            landingUrl: webContent?.getLandingUrl(),
                            imageUrl: webContent?.getImageUrl(),
                            iconUrl: webContent?.getIconUrl(),
                        },
                        nativeIos: {
                            category: nativeIosContent?.getCategory(),
                            subtitle: nativeIosContent?.getSubtitle(),
                            sound: nativeIosContent?.getSound(),
                            interruptionLevel: nativeIosContent?.getInterruptionLevel(),
                            relevanceScore: nativeIosContent?.getRelevance(),
                            badgeBehavior: nativeIosContent?.getBadgeBehavior(),
                            targetContentId: nativeIosContent?.getTargetContentId(),
                        },
                    },
                },
                deliverySpec: {
                    type: delivery?.getType(),
                    window: delivery?.getWindow(),
                    timezone: delivery?.getTimeZone(),
                    sendDateUtc: delivery?.getSendDate(),
                    ttlSeconds: expOpts?.getTtlSeconds(),
                    ttlMetric: expOpts?.getTtlMetric(),
                },
                channels: variant?.getChannels(),
            }

            dto = NotificationDto.parse(raw)
        } catch (_) {}
    }

    return dto
}
