import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form'
import { ModalFuncProps } from 'antd/lib/modal'
import { ButtonProps } from 'antd/lib/button'

export async function validateFields(form: WrappedFormUtils<any>, excludeFields: string[] = []): Promise<any> {
    const fieldNames = Object.keys(form.getFieldsValue()).filter((f) => excludeFields.indexOf(f) === -1)

    return new Promise((resolve, reject) => {
        form.validateFields(fieldNames, { force: true }, (errors: any, values: any) => {
            if (errors) {
                reject(errors)
            } else {
                resolve(values)
            }
        })
    })
}

interface IModalInstance {
    destroy: () => void
    update: (newConfig: ModalFuncProps) => void
}

export function setModalOkButtonProps(modal: IModalInstance, props: ButtonProps) {
    modal.update({
        okButtonProps: props,
    })
}

export function setModalButtonLoading(modal: IModalInstance, loading?: boolean) {
    setModalOkButtonProps(modal, {
        loading: loading !== false,
    })
}
