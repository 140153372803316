import React, { useState } from 'react'
import './styles/notification-audience-builder.scss'
import { NotificationAudienceModel } from '../../../models/notification/notification-audience.model'
import { Well, ComboSegmentSelection } from '@pushly/aqe/lib/components'
import { ComboSegmentSelectionType } from '@pushly/aqe/lib/components/combo-segment-selection/combo-segment-selection'
import { Radio, Select, Skeleton, Tooltip } from 'antd'
import { getClassNames } from '../../../_utils/classnames'
import { SegmentDto } from '../../../dtos/segment'
import { useMountedRef } from '../../../_utils/use-mounted-ref'
import { DomainDto } from '../../../dtos/domain'
import { DispatchActionPack } from '../types'
import { useService } from '../../../hooks/use-service'
import { BatchService } from '../../../services/batch'
import { useLoadableDataRefState } from '../../../hooks/use-loadable-data-ref-state'
import { INestedBatchRequest } from '../../../interfaces/batch-requests'
import { AppState } from '../../../stores/app'
import { canCreateSendToAll } from '../helpers'
import SegmentBuilder from '../../segment-builder/segment-builder'
import { SegmentSource } from '../../../enums/segment-source.enum'
import { Drawer } from '../../drawer/drawer'
import { generateUID } from '../../campaign-builder/helpers/uid'
import { convertCase } from '../../../_utils/utils'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface INotificationAudienceBuilder {
    className?: string
    channels?: DeliveryChannel[]
    domain: DomainDto
    setBuilder: (action: DispatchActionPack) => void
    disabled?: boolean
    value: NotificationAudienceModel
    onChange?: (value: NotificationAudienceModel) => any
    loading?: boolean

    hideExclusion?: boolean
    inclusionLabel?: string | false
}

interface IDependencies {
    defaultSegment: SegmentDto | undefined
    initialSegments: SegmentDto[]
    availableSegments: SegmentDto[]
}

interface IDrawerState {
    visible: boolean
    key?: string
    newSegmentType?: ComboSegmentSelectionType
    newSegmentName?: string
}

const NotificationAudienceBuilder = (props: INotificationAudienceBuilder) => {
    const [_mounted, runIfMounted] = useMountedRef()
    const { className, disabled, loading, domain, setBuilder, value, onChange, hideExclusion, inclusionLabel } = props

    let segmentBuilderRef: SegmentBuilder | null = null

    const appState = useService(AppState)
    const batchSvc = useService(BatchService)
    const [dependencies, setDependencies] = useLoadableDataRefState<IDependencies>({ loading: false })
    const [forceReloadDependencies, setForceReloadDependencies] = React.useState<boolean>(false)
    const [segmentDrawerState, setSegmentDrawerState] = useState<IDrawerState>({
        visible: false,
        key: undefined,
        newSegmentName: undefined,
        newSegmentType: undefined,
    })
    const [segmentSearchTerm, setSegmentSearchTerm] = React.useState<string | undefined>(undefined)

    const workingModel = value.clone()

    const canTargetAllSubs = canCreateSendToAll(domain, appState)
    const isAllSubsAudience =
        canTargetAllSubs &&
        (!workingModel.getSegmentIds() ||
            (dependencies.current.data?.defaultSegment &&
                workingModel.getSegmentIds()?.[0]?.toString() ===
                    dependencies.current.data.defaultSegment.id?.toString()))

    /**
     * Load all audience dependencies
     *
     * Includes:
     * - Default Segments - used within the audience type <Radio.Group />
     * - Standard Segments
     * - Adhoc or Inline (if part of initial segment selections)
     *
     * Initial segment selections should be loaded separately by ID to ensure
     * they are not filtered out during the all segments API call. They should
     * then be de-duped and added to the available options.
     */
    React.useEffect(() => {
        const loadDependencies = async () => {
            // ensure that available segments is not null and either
            // the default segment or initial segments are not null
            // before showing the component
            const hasLoadedDeps =
                !!dependencies.current.data?.availableSegments &&
                (!!dependencies.current.data?.defaultSegment || !!dependencies.current.data?.initialSegments)

            // 1. wait until parent is done loading (loading)
            // 2. ensure dependencies are not loaded or currently loading
            if (forceReloadDependencies || (!loading && !hasLoadedDeps && !dependencies.current.loading)) {
                setForceReloadDependencies(false)
                setDependencies({ ...dependencies, loading: true })

                const segmentsApiPath = `/domains/${domain.id}/segments`

                /**
                 * the base segment load should only include standard and the default segment
                 * non-standard segments are handled below in initialSegments
                 *
                 * fields must include:
                 * - id: value
                 * - name: label
                 * - source: required for filtering out adhoc
                 * - isDefault: required to set domain default id
                 * - iconUrl: required for ensuring default icon override when single segment is selected
                 */
                const batchReqs: INestedBatchRequest[] = [
                    {
                        method: 'get',
                        relative_path: `${segmentsApiPath}?include_default=1&include_treated=0&pagination=0&source=standard&fields=id,name,source,isDefault,iconUrl,computedStatus`,
                        meta: { all_segments_req: true },
                    },
                ]

                const initialSegmentIds = [...(value.getSegmentIds() ?? []), ...(value.getExcludedSegmentIds() ?? [])]
                if (initialSegmentIds.length) {
                    // initial segment load should always include "inline", "adhoc", "deleted"
                    // in addition to standard to ensure all segments are loaded when a user
                    // attempts to duplicate an existing notification
                    batchReqs.push({
                        method: 'get',
                        relative_path: `${segmentsApiPath}?id=${initialSegmentIds.join(
                            ',',
                        )}&include_deleted=true&source=standard,inline,adhoc`,
                        meta: { init_segments_req: true },
                    })
                }

                const res = await batchSvc.batch(
                    { requests: batchReqs },
                    {
                        cancellationKey: 'nab-isl',
                    },
                )

                runIfMounted(() => {
                    if (res.ok) {
                        let allSegments: SegmentDto[] = []
                        let defaultSegment: SegmentDto | undefined

                        const allSegmentsReq = res.data.find((r) => r.meta?.all_segments_req)
                        if (allSegmentsReq?.code === 200) {
                            // batch requests return string body that must be parsed and case-converted
                            const allSegmentsRaw = JSON.parse(allSegmentsReq.body)?.data ?? []
                            allSegments = convertCase(allSegmentsRaw, 'camel').map(SegmentDto.parse)

                            defaultSegment = allSegments.find((s) => s.isDefault)
                            if (defaultSegment) {
                                // if current user can target all subs and there are
                                // currently no initialized segment ids then set the
                                // default all segment value
                                if (canTargetAllSubs && initialSegmentIds.length === 0) {
                                    const update = workingModel.clone()
                                    update.setSegmentIds([defaultSegment.id])
                                    onChange?.(update)
                                }
                            }
                        }

                        const initialSegments: SegmentDto[] = []
                        const initSegmentsReq = res.data.find((r) => r.meta?.init_segments_req)
                        if (initSegmentsReq?.code === 200) {
                            const rawSegments = JSON.parse(initSegmentsReq.body)?.data ?? []
                            if (rawSegments) {
                                convertCase(rawSegments, 'camel').forEach((rs) => {
                                    const segment = SegmentDto.parse(rs)
                                    initialSegments.push(segment)

                                    // re-attach initial segments into the all segment array
                                    if (!allSegments.find((s) => s.id === segment.id)) {
                                        allSegments.push(segment)
                                    }
                                })
                            }
                        }

                        setDependencies({
                            data: {
                                defaultSegment,
                                initialSegments,
                                availableSegments: allSegments,
                            },
                            loading: false,
                        })

                        setBuilder({ type: 'patch', entity: 'available-segments', data: allSegments })
                    } else {
                        setDependencies({
                            data: {
                                defaultSegment: undefined,
                                initialSegments: [],
                                availableSegments: [],
                            },
                            loading: false,
                        })
                    }
                })
            }
        }

        loadDependencies().then()
    }, [loading, !!value.getSegmentIds(), !!value.getExcludedSegmentIds(), forceReloadDependencies])

    const handleNewSegmentClick = React.useCallback((type: ComboSegmentSelectionType, name: string | undefined) => {
        setSegmentDrawerState({
            ...segmentDrawerState,
            visible: true,
            key: generateUID(),
            newSegmentType: type,
            newSegmentName: name,
        })
        setSegmentSearchTerm(undefined)
    }, [])

    const handleSegmentCreation = async () => {
        const segment = await segmentBuilderRef?._submit()
        const type = segmentDrawerState.newSegmentType

        if (segment?.id) {
            closeDrawer()
            const audienceValue = workingModel.clone()

            if (type === 'included') {
                const segmentIds = audienceValue.getSegmentIds() ?? []
                segmentIds.push(segment.id)
                audienceValue.setSegmentIds(segmentIds)
            } else {
                const segmentIds = audienceValue.getExcludedSegmentIds() ?? []
                segmentIds.push(segment.id)
                audienceValue.setExcludedSegmentIds(segmentIds)
            }

            onChange?.(audienceValue)
            setForceReloadDependencies(true)
        }
    }

    const closeDrawer = () => {
        setSegmentDrawerState({
            ...segmentDrawerState,
            visible: false,
            key: undefined,
            newSegmentType: undefined,
            newSegmentName: undefined,
        })
    }

    const renderNewSegmentOption = React.useCallback(
        (type: ComboSegmentSelectionType, isEmptyMode: boolean) => {
            return (
                <div
                    className={getClassNames(null, 'new-segment', {
                        [`type-${type}`]: true,
                        ['no-data']: isEmptyMode,
                    })}
                    onClick={() => handleNewSegmentClick(type, segmentSearchTerm)}
                >
                    Create New Segment
                </div>
            )
        },
        [segmentSearchTerm],
    )

    const extractSelectOptionProps = React.useCallback(
        (segment: SegmentDto) => ({
            value: segment.id,
            label: segment.name,
            title: segment.name,
        }),
        [],
    )

    const isLoading = loading || dependencies.current.loading
    const availableSegments = dependencies.current.data?.availableSegments ?? []

    /**
     * Visible Segments cannot be:
     * 1. isDefault
     * 2. source=adhoc unless they are selected in either included or excluded
     */
    const visibleSegments = availableSegments.filter((segment) => {
        return (
            !segment.isDefault &&
            (segment.source !== SegmentSource.ADHOC ||
                workingModel.getSegmentIds()?.includes(segment.id) ||
                workingModel.getExcludedSegmentIds()?.includes(segment.id))
        )
    })

    const AllSubscribersWrapper = canTargetAllSubs ? (_: any) => _.children : Tooltip

    return (
        <Well
            className={getClassNames('notification-audience-builder', className, {
                'hide-exclusions': hideExclusion,
            })}
            title="Audience"
            hideFooter={true}
        >
            <Skeleton loading={isLoading} active={true} avatar={false} title={false}>
                {isLoading ? (
                    <></>
                ) : (
                    <>
                        <Radio.Group
                            className="audience-builder-type-radio"
                            size="small"
                            buttonStyle="solid"
                            disabled={disabled}
                            value={!canTargetAllSubs ? 'segments' : isAllSubsAudience ? 'all' : 'segments'}
                            onChange={(ev) => {
                                const v = ev.target.value
                                let segmentIds
                                let isAll = false
                                if (v !== 'all') {
                                    segmentIds = []
                                } else if (!!dependencies.current.data?.defaultSegment) {
                                    segmentIds = [dependencies.current.data.defaultSegment.id]
                                    isAll = true
                                }

                                workingModel.setIsAll(isAll)
                                workingModel.setSegmentIds(segmentIds)
                                workingModel.setDefaultIconUrl(undefined)

                                onChange?.(workingModel)
                            }}
                        >
                            <AllSubscribersWrapper title="Targeting all subscribers has been disabled on this domain">
                                <Radio.Button value="all" disabled={!canTargetAllSubs}>
                                    All Subscribers
                                </Radio.Button>
                            </AllSubscribersWrapper>
                            <Radio.Button value="segments">Specific Segments</Radio.Button>
                        </Radio.Group>

                        <ComboSegmentSelection
                            switchSize="small"
                            disabled={disabled}
                            hideIncluded={canTargetAllSubs && isAllSubsAudience}
                            includedLabel={inclusionLabel}
                            hideExcluded={hideExclusion}
                            value={{
                                allSubscribers: workingModel.getIsAll(),
                                segmentIds: workingModel.getSegmentIds(),
                                excludedSegmentIds: workingModel.getExcludedSegmentIds(),
                            }}
                            options={visibleSegments.map(extractSelectOptionProps)}
                            notFoundContent={(type) => renderNewSegmentOption(type, true)}
                            stickyOptionRenderer={(type) => (
                                <Select.Option
                                    key="new"
                                    value="new"
                                    className={getClassNames(null, 'new-segment-option')}
                                >
                                    {renderNewSegmentOption(type, false)}
                                </Select.Option>
                            )}
                            onChange={(v) => {
                                workingModel.setSegmentIds(v.segmentIds)
                                workingModel.setExcludedSegmentIds(v.excludedSegmentIds)

                                if (v.segmentIds && v.segmentIds.length === 1) {
                                    const segmentId = v.segmentIds[0]
                                    const segment = dependencies.current.data?.availableSegments.find(
                                        (s) => s.id.toString() === segmentId.toString(),
                                    )
                                    workingModel.setDefaultIconUrl(segment?.iconUrl)
                                } else {
                                    workingModel.setDefaultIconUrl(undefined)
                                }

                                onChange?.(workingModel)
                            }}
                            onSearch={(v) => setSegmentSearchTerm(v)}
                        />
                    </>
                )}
            </Skeleton>

            <Drawer
                className={getClassNames('combo-segment-select-new-segment-drawer')}
                title="Create New Segment"
                visible={segmentDrawerState.visible}
                onSubmit={handleSegmentCreation}
                onClose={closeDrawer}
            >
                <SegmentBuilder
                    key={segmentDrawerState.key ?? 'unknown'}
                    ref={(el) => (segmentBuilderRef = el)}
                    level="domain"
                    domainId={appState.currentDomain!.id}
                    mode="drawer"
                    name={segmentDrawerState.newSegmentName}
                    source={SegmentSource.STANDARD}
                    channels={props.channels}
                    hideChannelsSelector={true}
                />
            </Drawer>
        </Well>
    )
}

export default NotificationAudienceBuilder
