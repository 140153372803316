import React from 'react'
import classnames from 'classnames'
import { Input } from 'antd'
import { Form } from '@ant-design/compatible'
import { MacroManager, MacroOptionType } from '../macro-manager/macro-manager'
import EmojiManager from '../emoji-manager/emoji-manager'
import { useDebouncedValue } from '../../hooks/use-debounced-value'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

interface ILimits {
    min?: number
    max?: number
    aboveMaxMessage?: string
    belowMinMessage?: string
    messageOverride?: string
}

export interface ITemplatizedTextField {
    className?: string
    inputClassName?: string
    disabled?: boolean
    defaultValue?: string | null
    value?: string | null
    onChange?: (value: string) => void
    label?: string | React.ReactNode
    size?: SizeType
    hideMacros?: boolean
    macroTypes?: string[]
    customMacroOptions?: any
    validMacroOptionTypes?: MacroOptionType[]
    hideEmojis?: boolean
    placeholder?: string
    limits?: ILimits
    autoComplete?: string
}

const FragmentWrapper = (_: any) => _.children
const CharacterLimitWrapper: React.FunctionComponent<{ value: string; config: ILimits }> = (_) => {
    const { children, value, config, ...props } = _

    const currVal = (value || '').toString().trim()
    const currLen = currVal.length
    const aboveMax = !config.max ? false : config.max < currLen
    const belowMin = !config.min ? false : config.min > currLen

    let msg = `${currLen} ${currLen === 1 ? 'Character' : 'Characters'}`
    if (belowMin && !!config.belowMinMessage) {
        msg += `. ${config.belowMinMessage}`
    } else if (aboveMax && !!config.aboveMaxMessage) {
        msg += `. ${config.aboveMaxMessage}`
    }

    return (
        <span
            className={classnames('char-limit-wrapper', {
                'outside-limits': belowMin || aboveMax,
            })}
        >
            {children}
            <span className={classnames('char-limit-message')}>{config.messageOverride ?? msg}</span>
        </span>
    )
}

const TemplatizedTextField = (props: ITemplatizedTextField) => {
    const {
        disabled,
        onChange,
        label,
        size,
        hideEmojis,
        hideMacros,
        customMacroOptions,
        validMacroOptionTypes,
        placeholder,
        className,
        inputClassName,
    } = props
    const [value, setValue] = useDebouncedValue<string>(
        (v) => {
            onChange?.(v)
        },
        undefined,
        320,
    )

    const EmojiWrapper = !hideEmojis ? EmojiManager : FragmentWrapper
    const MacroWrapper = !hideMacros ? MacroManager : FragmentWrapper
    const LimitWrapper = !!props.limits ? CharacterLimitWrapper : FragmentWrapper

    const currVal = (value ?? props.value ?? '').toString().trim()
    const macroDetected = /{{/.test(currVal)
    const limits = props.limits ?? {}
    if (!hideMacros && !!customMacroOptions && macroDetected) {
        limits.messageOverride = 'Unable to calculate characters due to dynamic macro length'
    }

    /**
     * Handle external value overrides
     */
    React.useEffect(() => {
        if (!!props.value && props.value?.trim() !== value?.trim()) {
            setValue(props.value)
        }
    }, [props.value])

    return (
        <Form.Item className={className} label={label}>
            <LimitWrapper value={currVal} config={limits}>
                <EmojiWrapper disabled={disabled}>
                    <MacroWrapper
                        types={(props.macroTypes as any) ?? ['location', 'custom']}
                        customOptions={customMacroOptions}
                        disabled={disabled}
                        validOptionTypes={validMacroOptionTypes}
                    >
                        <Input
                            className={inputClassName}
                            disabled={disabled}
                            size={size}
                            placeholder={placeholder}
                            defaultValue={props.defaultValue ?? undefined}
                            value={value ?? props.value}
                            onChange={(ev) => setValue(ev.target.value)}
                            autoComplete={props.autoComplete}
                        />
                    </MacroWrapper>
                </EmojiWrapper>
            </LimitWrapper>
        </Form.Item>
    )
}

export default TemplatizedTextField
