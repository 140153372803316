import * as operatorOptions from '../rule-builder/operators'
import { baseOperators, RuleBuilderOperator } from '../rule-builder/operators'
import { IRuleBuilderField, IRuleBuilderSelectField } from '../rule-builder/rule-builder'
import * as CryptoJS from 'crypto-js'

export function stripDomainIdPattern(field: string) {
    return field.replace(new RegExp('\\.[\\d]+_', 'g'), '.')
}

export function getHashId(data: any, salt?: string): string {
    let dataString = JSON.stringify(data)
    if (!!salt) {
        dataString += salt
    }

    return CryptoJS.MD5(CryptoJS.enc.Latin1.parse(dataString)).toString()
}

export function getFieldOperators(field: IRuleBuilderField): RuleBuilderOperator[] {
    let operators: RuleBuilderOperator[] = []

    if (!!field.operators) {
        operators = field.operators
    } else {
        switch (field.type.toString().toLowerCase()) {
            case 'number':
                operators = operatorOptions.numericOperators
                break
            case 'range':
            case 'daterange':
                operators = operatorOptions.rangeOperators
                break
            case 'select':
            case 'multiselect':
                operators = operatorOptions.selectOperators
                break
            case 'boolean':
                operators = operatorOptions.booleanOperators
                break
            case 'custom':
                operators = operatorOptions.customOperators
                break
            case 'date':
                operators = operatorOptions.dateOperators
                break
            case 'array':
                operators = operatorOptions.stringArrOperators
                break
            case 'string':
            default:
                operators =
                    field.useArrayContains === true
                        ? operatorOptions.stringArrOperators
                        : operatorOptions.stringOperators
                break
        }
    }

    if (!!field.conditionalType) {
        operators = [...operators, ...field.conditionalType.operators]
    }

    if (field.isEsSegmentField) {
        operators = [...operators, ...operatorOptions.existenceOperators]
    }

    return operators
}

export function ensureSelectMetaDisplayNameTransformIn(rule, field) {
    if (rule.value && field.displayValueProp && !rule.meta?.displayValue) {
        const options = field.options ?? []
        const opt = options.find((o) => o.value === rule.value)

        if (opt) {
            rule.meta = {
                ...rule.meta,
                displayValue: opt?.[field.displayValueProp],
            }
        }
    }

    return rule
}

export function ensureSelectMetaDisplayNameTransformOut(value, rule, field) {
    const selectValue = value[rule.builderProperty][rule.operator]
    const displayValueProp = field.displayValueProp
    const options = field.options ?? []
    const opt = options.find((o) => o.value === selectValue)

    if (opt && displayValueProp && opt?.[displayValueProp]) {
        value[rule.builderProperty].meta = {
            ...value[rule.builderProperty].meta,
            displayValue: opt?.[displayValueProp],
        }
    }

    return value
}
