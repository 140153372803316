import * as clone from 'clone'
import { convertCase } from '../../_utils/utils'
import { stripUndefined } from '../../_utils/strip-undefined'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface IParentGroupDetails {
    id: number
    name: string
    is_multi_domain: boolean
}

export class SegmentModel {
    public static __caslSubjectType__ = SubjectEntity.SEGMENT

    public static build(props: SegmentModel | any): SegmentModel {
        const data = props instanceof SegmentModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new SegmentModel()
        model.id = scData.id
        model.domainId = scData.domain_id
        model.setName(scData.name)
        model.setIsDefault(scData.is_default)
        model.setFiltersJson(data.filtersJson ?? data.filters_json)
        model.setFiltersHash(scData.filters_hash)
        model.setIconUrl(scData.icon_url)
        model.setMeta(data.meta)
        model.estimatedReach = scData.estimated_reach
        model.computedStatus = scData.computed_status
        model.lastTargetedDateUtc = scData.last_targeted_date_utc
        model.setSource(scData.source)
        model.setTreatmentSpec(data.treatmentSpec ?? data.treatment_spec)
        model.createdAt = scData.created_at
        model.createdBy = scData.created_by
        model.createdByUserName = scData.created_by_user_name
        model.updatedAt = scData.updated_at
        model.updatedBy = scData.updated_by
        model.updatedByUserName = scData.updated_by_user_name
        model.group = scData.group ?? {}
        model.setChannels(data?.channels)

        return model
    }

    private id: number
    private domainId: number
    private name: string
    private isDefault: boolean
    private filtersJson: any
    private filtersHash: string
    private iconUrl: string | null
    private meta: any
    private estimatedReach: number | null
    private computedStatus: 'READY' | 'PROCESSING'
    private lastTargetedDateUtc: string | null
    private source: string
    private treatmentSpec: any
    private group: IParentGroupDetails
    private channels: DeliveryChannel[]
    private createdAt: string
    private createdBy: number
    private createdByUserName: string
    private updatedAt: string | null
    private updatedBy: number | null
    private updatedByUserName: string | null

    public clone(): SegmentModel {
        return SegmentModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            id: this.getId(),
            domain_id: this.getDomainId(),
            name: this.getName(),
            is_default: this.getIsDefault(),
            filters_json: this.getFiltersJson(),
            filters_hash: this.getFiltersHash(),
            icon_url: this.getIconUrl(),
            meta: this.getMeta(),
            estimated_reach: this.getEstimatedReach(),
            computed_status: this.getComputedStatus(),
            last_targeted_date_utc: this.getLastTargetedDateUtc(),
            source: this.getSource(),
            treatment_spec: this.getTreatmentSpec(),
            group: this.getGroup(),
            channels: this.getChannels(),
            created_at: this.getCreatedAt(),
            created_by: this.getCreatedBy(),
            created_by_user_name: this.getCreatedByUserName(),
            updated_at: this.getUpdatedAt(),
            updated_by: this.getUpdatedBy(),
            updated_by_user_name: this.getUpdatedByUserName(),
        })
    }

    public getId() {
        return this.id
    }
    public getDomainId() {
        return this.domainId
    }

    public getName() {
        return this.name
    }
    public setName(name: string) {
        this.name = name
    }

    public getIsDefault() {
        return this.isDefault
    }
    public setIsDefault(isDefault: boolean) {
        this.isDefault = isDefault
    }

    public getFiltersJson() {
        return this.filtersJson ?? {}
    }
    public setFiltersJson(filters: any) {
        this.filtersJson = filters
    }

    public getFiltersHash() {
        return this.filtersHash
    }
    public setFiltersHash(hash: string) {
        this.filtersHash = hash
    }

    public getIconUrl() {
        return this.iconUrl
    }
    public setIconUrl(url: string | null) {
        this.iconUrl = url
    }

    public getMeta() {
        return this.meta
    }
    public setMeta(meta: any) {
        this.meta = meta
    }

    public getEstimatedReach() {
        return this.estimatedReach ?? 0
    }
    public getComputedStatus(): 'READY' | 'PROCESSING' {
        return this.computedStatus ?? 'PROCESSING'
    }
    public getLastTargetedDateUtc() {
        return this.lastTargetedDateUtc
    }

    public getSource() {
        return this.source
    }
    public setSource(source: string) {
        this.source = source
    }

    public getTreatmentSpec() {
        return this.treatmentSpec
    }
    public setTreatmentSpec(spec: any) {
        this.treatmentSpec = spec
    }

    public getChannels() {
        return this.channels
    }
    public setChannels(deliveryChannels: DeliveryChannel[]): void {
        this.channels = deliveryChannels
    }

    public getGroup() {
        return this.group
    }
    public getIsMultiDomain() {
        return this.group.is_multi_domain ?? false
    }

    public getCreatedAt() {
        return this.createdAt
    }
    public getCreatedBy() {
        return this.createdBy
    }
    public getCreatedByUserName() {
        return this.createdByUserName
    }

    public getUpdatedAt() {
        return this.updatedAt
    }
    public getUpdatedBy() {
        return this.updatedBy
    }
    public getUpdatedByUserName() {
        return this.updatedByUserName
    }
}
