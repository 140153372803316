import React from 'react'
import { ILoadableDataState } from '../../hooks/use-loadable-data-ref-state'
import { OrgNotificationModel } from '../../models/notification/org-notification.model'
import { AccountDto } from '../../dtos/account.dto'
import { Well } from '../../components/well/well'
import { NotificationSummaryCard } from '@pushly/aqe/lib/components'
import { Button, Col, Divider, Popover, Row, Select, Tooltip } from 'antd'
import { ExclamationCircleOutlined, RetweetOutlined } from '@ant-design/icons'
import { StatusType } from '../../enums/status-type'
import { arrayContains, backfillStats, titleCase } from '../../_utils/utils'
import moment from 'moment-timezone'
import { DEFAULT_HIGHCHARTS_CONFIG, HIGHCHARTS_CHANNEL_COLOR_MAP } from '../../constants'
import { HighchartsUtils } from '../../_utils/highcharts-utils'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { arrayUnqiue } from '../../_utils/array'
import { InsightsService } from '../../services/insights'
import { Container } from 'typescript-ioc/es5'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'
import * as numeral from 'numeral'
import { AppState } from '../../stores/app'
import { extractSummaryNotification } from '../../_utils/notifications'
import { numberWithCommas } from '../../_utils/numeral'
import { reduceStatTotalsByChannel } from '../notifications/notification-details/helpers'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { DeliveryChannelSelector } from '../../components/delivery-channel-selector/delivery-channel-selector'

type ChartType = 'impressions' | 'clicks'

interface IOrgNotificationSummaryTabProps {
    notifLoader: React.MutableRefObject<ILoadableDataState<OrgNotificationModel | undefined>>
    orgLoader: React.MutableRefObject<ILoadableDataState<AccountDto | undefined>>
}

interface IState {
    notif?: OrgNotificationModel
    breakdownStats?: any
    loadingBreakdownStats?: boolean
    performanceChannel: DeliveryChannel[]
    statsType: 'day' | 'lifetime'
}

class OrgNotificationSummaryTab extends React.Component<IOrgNotificationSummaryTabProps, IState> {
    protected static tabName = 'summary'
    protected static tabLabel = 'Summary'

    protected readonly appState: AppState
    private readonly insightsService: InsightsService

    public constructor(props: IOrgNotificationSummaryTabProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            performanceChannel: [],
            statsType: 'lifetime',
        }
    }

    public componentDidUpdate(_prevProps, prevState, _) {
        const { notif } = this.state
        const { notifLoader } = this.props
        const channelOptions: any[] = notifLoader.current.data?.getVariant()?.getChannels() ?? []

        if (!notif && !!notifLoader.current.data) {
            this.setState({ notif: notifLoader.current.data!, performanceChannel: channelOptions }, () => {
                this.fetchBreakdownStats()
            })
        }

        if (prevState.statsType !== this.state.statsType) {
            this.fetchBreakdownStats()
        }
    }

    public render() {
        const { notifLoader, orgLoader } = this.props
        const org = orgLoader.current.data
        const notif = notifLoader.current.data

        return (
            <>
                <div className="outer-well-buttons">
                    <Button className="refresh-list" size="small" onClick={() => {}}>
                        <RetweetOutlined />
                        <span>Refresh</span>
                    </Button>
                </div>

                {this.renderGenericStats()}
                <br />

                <Row gutter={24} className="details-lower-row">
                    <Col span={14}>{this.renderBreakdownStats()}</Col>
                    <Col span={10}>
                        <NotificationSummaryCard
                            mode="light"
                            loading={notifLoader.current.loading}
                            notification={extractSummaryNotification(notif ?? new OrgNotificationModel())}
                            hideStats={true}
                            domain={this.appState.currentDomain!}
                            hideFooter={true}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    protected renderGenericStats() {
        const { notifLoader } = this.props
        const loading = notifLoader.current.loading
        const notif = notifLoader.current.data
        const actions =
            notif
                ?.getVariant()
                ?.getContent()
                ?.getDefaultContent()
                ?.getActions()
                ?.map((a) => a.serialize()) ?? []
        const hasActions = actions.length > 0

        actions.sort((a, b) => (a.ordinal < b.ordinal ? 1 : a.ordinal < b.ordinal ? -1 : 0))

        return (
            <Well className="thin gen-stats-well nested" loading={loading} showHeader={false} showFooter={false}>
                <Well ghost={true} showHeader={false} showFooter={false}>
                    <div className="chart-title">Status</div>
                    <div className="stat stat-status">{this.renderStatus()}</div>
                </Well>
                <Well ghost={true} showHeader={false} showFooter={false} disabled={this.isDisabled}>
                    <div className="chart-title">Impressions</div>
                    <div className="stat stat-impressions">{this.renderImpressions()}</div>
                </Well>
                <Well ghost={true} showHeader={false} showFooter={false} disabled={this.isDisabled}>
                    <div className="chart-title">{hasActions && 'Total '}Clicks (CTR)</div>
                    <div className="stat stat-clicks">
                        {this.renderClicks()}
                        <div className="stat stat-ctr sub-stat">({this.renderCTR()})</div>
                    </div>
                </Well>
            </Well>
        )
    }

    protected renderStatus() {
        const { notifLoader } = this.props
        const notif = notifLoader.current.data

        const notifStatus = notif?.getComputedStatus() ?? 'SCHEDULED'
        const completedStatuses = [StatusType.COMPLETED.name, StatusType.COMPLETED_WITH_FAILURES.name]
        const isPartialDelivery = notifStatus === StatusType.COMPLETED_WITH_FAILURES.name
        let statusText = arrayContains(completedStatuses, notifStatus) ? 'DELIVERED' : notifStatus
        let statusId = 0

        // Delivered
        let color: string = 'green'

        const statusLookup = StatusType[statusText]
        if (statusLookup) {
            statusId = statusLookup.id
        }

        switch (statusId) {
            case StatusType.SCHEDULED.id:
            case StatusType.SCHEDULING.id:
                statusText = 'SCHEDULED'
                color = 'purple'
                break
            case StatusType.QUEUED.id:
            case StatusType.DELIVERING.id:
                color = 'orange'
                break
            case StatusType.FAILED.id:
                color = 'red'
                break
            case -1:
                color = 'volcano'
                break
        }

        return (
            <div className={`color-${color}`}>
                {titleCase(statusText)}
                {isPartialDelivery && (
                    <Tooltip title="A portion of this notification's audience failed to deliver.">
                        <ExclamationCircleOutlined className="info-icon error-icon" />
                    </Tooltip>
                )}
            </div>
        )
    }

    protected renderBreakdownStats() {
        const { notifLoader } = this.props
        const { loadingBreakdownStats } = this.state
        const channelOptions = this.state.notif?.getVariant()?.getChannels() ?? []
        const channelsSelection: any = {}
        channelOptions.forEach((ch) => {
            channelsSelection[ch] = this.state.performanceChannel.includes(ch)
        })

        return (
            <Well
                className="thin primary-stats-well"
                showHeader={false}
                showFooter={false}
                loading={notifLoader.current.loading || loadingBreakdownStats}
                disabled={this.isDisabled}
            >
                <div className="chart-header">
                    <div className="chart-title">Performance Breakdown by Channel</div>
                    <div className="chart-actions">
                        <div>
                            <Select
                                className="stats-type-select"
                                size="small"
                                value={this.state.statsType}
                                onChange={(val) => {
                                    this.setState({ statsType: val })
                                }}
                            >
                                <Select.Option value="day">Daily</Select.Option>
                                <Select.Option value="lifetime">All Time</Select.Option>
                            </Select>
                        </div>
                        <div className="channel-selectors">
                            <DeliveryChannelSelector
                                type="multiple"
                                nested={true}
                                onChange={(change: DeliveryChannel[]) => {
                                    this.setState({ performanceChannel: change })
                                }}
                                visibleChannels={channelOptions}
                                disabledChannels={DeliveryChannel.getAllChannels().filter(
                                    (ch) => !channelOptions.includes(ch),
                                )}
                            />
                        </div>
                    </div>
                </div>

                {this.renderBreakdownStatsChart()}
            </Well>
        )
    }

    protected renderBreakdownStatsChart() {
        const { breakdownStats, notif } = this.state
        if (!breakdownStats) {
            return ''
        }

        const xAxisDateDataSet = new Set(
            breakdownStats.map((stat) => moment.tz(stat.event_date, 'UTC').format('MMM D')),
        )
        const xAxisChannelDataSet = new Set(notif?.getVariant()?.getChannels().map(DeliveryChannel.getShortName))

        const mapDataKeyFromObject = (key: string, obj: any, channel: DeliveryChannel) => {
            return obj.filter((val) => val.channel === channel).map((filtered) => filtered[key])
        }

        const buildSeries = (chartType: ChartType) => {
            const series: any[] = [{ keys: ['name', 'y'], visible: false, showInLegend: false }]

            if (this.state.statsType !== 'day') {
                series.push({
                    data: this.state.performanceChannel.map((channel) => {
                        const foundStat = breakdownStats.find((stat) => stat.channel === channel)
                        return {
                            name: DeliveryChannel.getShortName(channel),
                            color: HIGHCHARTS_CHANNEL_COLOR_MAP[channel],
                            y: foundStat?.[chartType] ?? 0,
                        }
                    }),
                })
            } else {
                series.push(
                    ...this.state.performanceChannel.map((channel) => ({
                        name: DeliveryChannel.getShortName(channel),
                        color: HIGHCHARTS_CHANNEL_COLOR_MAP[channel],
                        data: mapDataKeyFromObject(chartType, breakdownStats, channel),
                    })),
                )
            }

            return series
        }

        const options = (chartType: ChartType) => ({
            ...DEFAULT_HIGHCHARTS_CONFIG,
            swContext: (): IState => this.state,
            chart: {
                type: 'column',
                marginLeft: 70,
                marginRight: 50,
                spacingTop: 20,
            },
            title: {
                text: titleCase(chartType),
            },
            xAxis: {
                categories: this.state.statsType === 'day' ? [...xAxisDateDataSet] : [...xAxisChannelDataSet],
            },
            legend: {
                enabled: false,
            },
            yAxis: {
                title: { text: '' },
                allowDecimals: false,
                startOnTick: 0,
                minRange: 0,
            },
            tooltip: {
                pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
            },
            series: buildSeries(chartType),
        })

        const totalStatsByChannel = reduceStatTotalsByChannel(breakdownStats)
        let impressionsTotals = [
            <span key="impressions" className="ant-popover-title">
                Impressions:
            </span>,
        ]
        let clicksTotals = [
            <span key="clicks" className="ant-popover-title">
                Clicks:
            </span>,
        ]

        Object.keys(totalStatsByChannel).map((key) => {
            impressionsTotals.push(
                <div key={`${key}-impressions`} className="reach-breakdown-row">
                    <span className="reach-breakdown-label">{DeliveryChannel.getShortName(DeliveryChannel[key])}:</span>
                    <span className="reach-breakdown-value">
                        {numberWithCommas(totalStatsByChannel[key].impressions)}
                    </span>
                </div>,
            )

            clicksTotals.push(
                <div key={`${key}-clicks`} className="reach-breakdown-row">
                    <span className="reach-breakdown-label">{DeliveryChannel.getShortName(DeliveryChannel[key])}:</span>
                    <span className="reach-breakdown-value">{numberWithCommas(totalStatsByChannel[key].clicks)}</span>
                </div>,
            )
        })

        return (
            <Popover
                key={`popover-details`}
                overlayClassName="org-notif-channel-select-details-popover"
                content={
                    <div>
                        <div className="impressions-breakdown-stats">{impressionsTotals}</div>

                        <div className="clicks-breakdown-stats">{clicksTotals}</div>
                    </div>
                }
                placement="left"
            >
                <HighchartsReact highcharts={Highcharts} options={options('impressions')} />

                <Divider type="horizontal" />

                <HighchartsReact highcharts={Highcharts} options={options('clicks')} />
            </Popover>
        )
    }

    protected renderImpressions() {
        const stats = this.state.notif?.getStats()
        if (this.isDisabled || !stats) {
            return '--'
        }
        return numeral(stats.impressions).format('O,O')
    }

    protected renderClicks() {
        const stats = this.state.notif?.getStats()
        if (this.isDisabled || !stats) {
            return '--'
        }
        return numeral(stats.clicks).format('O,O')
    }

    protected renderCTR() {
        const stats = this.state.notif?.getStats()
        if (this.isDisabled || !stats) {
            return '--'
        }
        return numeral(stats.ctr_decimal).format('0.00%')
    }

    protected get isDelivered(): boolean {
        const { notif } = this.state
        const completedStatuses = [StatusType.COMPLETED.name, StatusType.COMPLETED_WITH_FAILURES.name]
        return (
            !!notif &&
            (arrayContains(completedStatuses, notif.getComputedStatus() ?? StatusType.SCHEDULED.name) ||
                this.isParitalFailure)
        )
    }

    protected get isParitalFailure(): boolean {
        const { notif } = this.state
        const stats = notif?.getStats()
        const hasStats = !!stats && stats.impressions > 0
        return !!notif && notif.getComputedStatus() === StatusType.FAILED.name && hasStats
    }

    protected get isDisabled(): boolean {
        const { notif } = this.state
        const status = notif?.getComputedStatus() ?? StatusType.SCHEDULED.name
        const delivery = notif?.getVariant()?.getDelivery()

        const isDelivered = this.isDelivered
        const isDelivering = status === StatusType.DELIVERING.name
        const isCancelled = status === StatusType.CANCELLED.name || status === StatusType.CANCELLING.name
        const isInf = delivery?.getWindow() === NotificationDeliveryWindow.INFORMED
        const isStz = delivery?.getWindow() === NotificationDeliveryWindow.TIMEZONE

        return !isDelivered && !((isInf || isStz) && (isDelivering || isCancelled))
    }

    protected async fetchBreakdownStats() {
        const { notifLoader } = this.props
        const notif = notifLoader.current.data

        if (!notif) {
            return
        }
        this.setState({ loadingBreakdownStats: true })

        const domainIds = arrayUnqiue(notif.getNotifications().map((n) => n.domainId))

        const insightsPkg = {
            datePreset: 'lifetime',
            dateIncrement: this.state.statsType,
            actionAttribution: 'event_time',
            fields: [
                'notification_group.id',
                'deliveries',
                'impressions',
                'clicks',
                'ctr',
                'ctr_decimal',
                'delivery_rate',
                'delivery_rate_decimal',
                'channel',
            ],
            entity: 'notifications',
            breakdowns: ['notification_group', 'channel'],
            filters: [
                {
                    field: 'domain.id',
                    operator: 'in',
                    value: domainIds,
                },
                {
                    field: 'notification_group.id',
                    operator: 'eq',
                    value: notif.getId(),
                },
                {
                    field: 'channel',
                    operator: 'in',
                    value: DeliveryChannel.getAllChannels(),
                },
            ],
        }

        const breakdownStats = await this.insightsService.fetch(insightsPkg, false, 'org-notif-stats', true)

        let backfilledStats: any[]
        if (this.state.statsType === 'day') {
            backfilledStats = backfillStats(
                breakdownStats,
                'day',
                {
                    deliveries: 0,
                    impressions: 0,
                    clicks: 0,
                    delivery_rate_decimal: 0,
                    ctr_decimal: 0,
                },
                true,
            )
        } else {
            backfilledStats = breakdownStats
        }

        this.setState({
            loadingBreakdownStats: false,
            breakdownStats: backfilledStats,
        })
    }
}

export default OrgNotificationSummaryTab
