import * as React from 'react'
import { Form, Input, Popover, Select, Switch } from 'antd'
import { getClassNames } from '../../../_utils/classnames'
import {
    BadgeBehaviorModel,
    IBadgeBehavior,
    NotificationNativeIosContentModel,
} from '../../../models/notification/notification-native-ios-content.model'
import TemplatizedTextField from '../../templatized-text-field/templatized-text-field'
import { InfoCircleOutlined } from '@ant-design/icons'
import { DomainDto } from '../../../dtos/domain'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc'
import { NATIVE_IOS_INTERRUPTION_LEVEL_CRITICAL } from '../../../constants'
import { IosBadgeBehaviorAction, IosInterruptionLevel, IosRelevanceScore } from '../enums'

interface INotificationIosContent {
    value: NotificationNativeIosContentModel
    domain: DomainDto
    onChange: (content: NotificationNativeIosContentModel) => void
}

export const NotificationIosContent = (props: INotificationIosContent) => {
    const { value, onChange, domain } = props
    const appState: AppState = Container.get(AppState)
    const [tmpVal, setTmpVal] = React.useState<NotificationNativeIosContentModel>(
        NotificationNativeIosContentModel.build(value ?? {}),
    )

    const workingModel = tmpVal.clone()
    const iosCriticalFlag = appState.flags.findActive(NATIVE_IOS_INTERRUPTION_LEVEL_CRITICAL)
    const domainHasCriticalFlag = iosCriticalFlag && domain.flags.includes(iosCriticalFlag.getKey())

    return (
        <div className={getClassNames('native-ios-content')}>
            <Form.Item className="native-ios-category" label="Category">
                <Select
                    defaultValue="GENERAL"
                    value={workingModel?.getCategory()}
                    size="middle"
                    onChange={(v) => {
                        const update = workingModel?.clone()
                        update?.setCategory(v)

                        setTmpVal(update)
                        onChange(update)
                    }}
                >
                    <Select.Option value="GENERAL">General</Select.Option>
                </Select>
            </Form.Item>

            <TemplatizedTextField
                disabled={value.getSubtitle() === undefined}
                label={
                    <span className="native-ios-subtitle-switch">
                        <Switch
                            size="small"
                            defaultChecked={false}
                            checked={value.getSubtitle() !== undefined}
                            onChange={(v) => {
                                const update = value.clone()
                                if (v) {
                                    update?.setSubtitle(tmpVal.getSubtitle() ?? '')
                                } else {
                                    update?.setSubtitle(undefined)
                                }

                                onChange(update)
                            }}
                        />
                        <span>Subtitle</span>
                        <Popover
                            overlayClassName={getClassNames('notification-builder-popover', 'is-silent-popover')}
                            content={
                                <span>
                                    Specify additional context about the purpose of the notification. Subtitles offer
                                    additional context in cases where the title alone isn’t clear. Subtitles aren’t
                                    displayed in all cases.
                                </span>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </span>
                }
                hideMacros={true}
                placeholder="Enter a subtitle"
                onChange={(v) => {
                    const update = workingModel?.clone()
                    update?.setSubtitle(v)

                    setTmpVal(update)
                    onChange(update)
                }}
                value={workingModel?.getSubtitle()}
            />

            <TemplatizedTextField
                disabled={value?.getSound() === undefined}
                label={
                    <span className="native-ios-custom-alert-switch">
                        <Switch
                            size="small"
                            checked={value?.getSound() !== undefined}
                            onChange={(v) => {
                                const update = value.clone()
                                if (v) {
                                    update?.setSound(tmpVal.getSound() ?? '')
                                } else {
                                    update?.setSound(undefined)
                                }
                                onChange(update)
                            }}
                        />
                        <span>Custom Alert Sound</span>
                        <Popover
                            overlayClassName={getClassNames('notification-builder-popover', 'is-silent-popover')}
                            content={
                                <span>
                                    If your application is bundled with a custom sound file for alerts you can enter the
                                    filename of that sound to use it for this notification.
                                </span>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </span>
                }
                hideMacros={true}
                value={value?.getSound()}
                placeholder="Enter the name of a sound file provided by your application"
                onChange={(v) => {
                    const update = workingModel?.clone()
                    update?.setSound(v)

                    setTmpVal(update)
                    onChange(update)
                }}
            />

            <Form.Item
                className="native-ios-priority"
                label={
                    <>
                        <span>Interruption Level</span>
                        <Popover
                            overlayClassName={getClassNames('notification-builder-popover', 'is-silent-popover')}
                            content={
                                <span>
                                    Choose the level of interruption this notification should have on the subscriber’s
                                    device:
                                    <ul>
                                        <li>
                                            <b>Active</b>: The system presents the notification immediately, lights up
                                            the screen, and can play a sound.
                                        </li>
                                        <li>
                                            <b>Time Sensitive</b>: The system presents the notification immediately,
                                            lights up the screen, and bypasses the mute switch to play a sound. This
                                            level breaks through focus mode so should be used sparingly and only when
                                            the notification requires immediate attention.
                                        </li>
                                        <li>
                                            <b>Passive</b>: The system adds the notification to the notification list
                                            without lighting up the screen or playing a sound.
                                        </li>
                                        {domainHasCriticalFlag && (
                                            <li>
                                                <b>Critical (Requires Apple Approval)</b>: The system presents the
                                                notification immediately, lights up the screen, and can play a sound,
                                                but won’t break through system notification controls.
                                            </li>
                                        )}
                                    </ul>
                                </span>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </>
                }
            >
                <Select<IosInterruptionLevel>
                    value={IosInterruptionLevel[workingModel?.getInterruptionLevel().toUpperCase()!]}
                    size="middle"
                    onChange={(v) => {
                        const update = workingModel?.clone()
                        update?.setInterruptionLevel(v)

                        setTmpVal(update)
                        onChange(update)
                    }}
                >
                    <Select.Option value={IosInterruptionLevel.ACTIVE}>Active</Select.Option>
                    <Select.Option value={IosInterruptionLevel.TIME_SENSITIVE}>Time Sensitive</Select.Option>
                    <Select.Option value={IosInterruptionLevel.PASSIVE}>Passive</Select.Option>
                    {domainHasCriticalFlag && (
                        <Select.Option value={IosInterruptionLevel.CRITICAL}>Critical</Select.Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                className="native-ios-relevance"
                label={
                    <>
                        <span>Relevance Score</span>
                        <Popover
                            overlayClassName={getClassNames('notification-builder-popover', 'is-silent-popover')}
                            content={
                                <span>
                                    The relevance score helps determine the importance of your notifications and display
                                    them accordingly in the iOS Notification Summary. Note that other factors, including
                                    adding an image, will also impact the importance.
                                </span>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </>
                }
            >
                <Select
                    value={value?.getRelevance()}
                    size="middle"
                    onChange={(v) => {
                        const update = workingModel?.clone()
                        update?.setRelevance(v)

                        setTmpVal(update)
                        onChange(update)
                    }}
                >
                    <Select.Option value={IosRelevanceScore.LOW}>Low</Select.Option>
                    <Select.Option value={IosRelevanceScore.NORMAL}>Normal</Select.Option>
                    <Select.Option value={IosRelevanceScore.HIGH}>High</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                className="native-ios-badge-behavior"
                label={
                    <>
                        <span>Badge Behavior</span>
                        <Popover
                            overlayClassName={getClassNames('notification-builder-popover', 'is-silent-popover')}
                            content={
                                <span>
                                    The iOS badge count displays the number of unread notifications within your
                                    application. Choose how the badge count should update when this notification is
                                    received:
                                    <ul>
                                        <li>
                                            <b>Increment</b>: The current badge count will be incremented by the
                                            provided number.
                                        </li>
                                        <li>
                                            <b>Set To</b>: The badge count will be set to the exact number provided. Set
                                            to 0 to clear the badge count.
                                        </li>
                                        <li>
                                            <b>Do Nothing</b>: The current badge count will not be affected.
                                        </li>
                                    </ul>
                                </span>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </>
                }
            >
                <Input
                    disabled={tmpVal.getBadgeBehavior()?.getValue() === null}
                    type="number"
                    value={value.getBadgeBehavior().getValue()!}
                    onChange={(ev) => {
                        const update = workingModel?.clone()

                        const badgeBehavior = {
                            action: tmpVal.getBadgeBehavior()?.getAction(),
                            value: parseInt(ev.target.value, 10),
                        }

                        update?.setBadgeBehavior(badgeBehavior)
                        setTmpVal(update)
                        onChange(update)
                    }}
                    addonBefore={
                        <Select
                            value={workingModel?.getBadgeBehavior()?.getAction()}
                            size="middle"
                            onChange={(v: IosBadgeBehaviorAction) => {
                                const update = workingModel?.clone()
                                const badgeBehavior: IBadgeBehavior = {
                                    action: v ?? IosBadgeBehaviorAction.INCREMENT,
                                    value: workingModel.getBadgeBehavior().getValue(),
                                }

                                switch (badgeBehavior.action) {
                                    case IosBadgeBehaviorAction.INCREMENT:
                                    case IosBadgeBehaviorAction.SET:
                                        badgeBehavior.value = 1
                                        break
                                    case IosBadgeBehaviorAction.DO_NOTHING:
                                        badgeBehavior.value = null
                                        break
                                    default:
                                        badgeBehavior.value = 1
                                }

                                update?.setBadgeBehavior(badgeBehavior)
                                setTmpVal(update)
                                onChange(update)
                            }}
                        >
                            <Select.Option value={IosBadgeBehaviorAction.INCREMENT}>Increment</Select.Option>
                            <Select.Option value={IosBadgeBehaviorAction.SET}>Set To</Select.Option>
                            <Select.Option value={IosBadgeBehaviorAction.DO_NOTHING}>Do Nothing</Select.Option>
                        </Select>
                    }
                />
            </Form.Item>

            <Form.Item>
                <TemplatizedTextField
                    disabled={value?.getTargetContentId() === undefined}
                    label={
                        <span className="native-ios-target-content-id-switch">
                            <Switch
                                size="small"
                                defaultChecked={false}
                                checked={value.getTargetContentId() !== undefined}
                                onChange={(v) => {
                                    const update = value.clone()

                                    update?.setTargetContentId(v ? tmpVal.getTargetContentId() ?? '' : undefined)

                                    onChange(update)
                                }}
                            />
                            <span>Target Content ID</span>
                            <Popover
                                overlayClassName={getClassNames('notification-builder-popover', 'is-silent-popover')}
                                content={
                                    <span>
                                        The Target Content ID is used to target a specific window in a multi-scene app
                                        or a specific experience in an App Clip using an invocation URL.
                                    </span>
                                }
                            >
                                <InfoCircleOutlined className="info-icon" />
                            </Popover>
                        </span>
                    }
                    hideEmojis={true}
                    hideMacros={true}
                    placeholder="Enter the Target Content ID"
                    value={value.getTargetContentId()}
                    onChange={(v) => {
                        const update = workingModel?.clone()
                        update?.setTargetContentId(v)

                        setTmpVal(update)
                        onChange(update)
                    }}
                />
            </Form.Item>
        </div>
    )
}
