import React from 'react'
import './subscribers.widget.scss'
import { numberWithCommas, numberWithPercent } from '../../../_utils/utils'
import { Tag, Descriptions, Tooltip } from 'antd'

const PropsFragment = (props: any) => <>{props.children}</>

const SubscribersWidget = ({ total, yesterday }: any) => {
    const HoverWrapper = total <= 9999 ? PropsFragment : Tooltip

    return (
        <div className="dashboard-widget subs-widget vsplit">
            <div className="dashboard-widget-content">
                <div className="widget-title">
                    <label>Subscribers</label>
                </div>
                <div className="widget-body today">
                    <div className="widget-sub-title">
                        <label>Total</label>
                    </div>
                    <HoverWrapper title={numberWithCommas(total)}>
                        <span className="total-subs">
                            {!!total && <span className="indicator">~</span>}
                            <span>{!total ? '--' : numberWithCommas(total, total > 9999 ? '0.0a' : undefined)}</span>
                        </span>
                    </HoverWrapper>
                </div>
                <div className="widget-body yesterday">
                    <div className="widget-sub-title">
                        <label>Yesterday</label>
                    </div>
                    <Descriptions layout="horizontal" column={1}>
                        <Descriptions.Item label="New" className="new">
                            <Tag>
                                {yesterday.new === undefined
                                    ? '--'
                                    : numberWithCommas(yesterday.new, yesterday.new > 9999 ? '0.0a' : undefined)}
                            </Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Attrition" className="attrition">
                            <Tag>{yesterday.attrition === '--' ? '--' : numberWithPercent(yesterday.attrition)}</Tag>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </div>
        </div>
    )
}

export default SubscribersWidget
