import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { PageHeader } from '../../components/page-header/page-header'
import './create-notification.scss'
import { INotificationTreatment } from '../../interfaces/notification-treatment'
import { observer } from 'mobx-react'
import { observe } from 'mobx'
import { DomainDto } from '../../dtos/domain'
import { NotificationDataFormV2 } from '../../components/notification-data-form/notification-data-form'
import { NdfFormTheme } from '../../components/notification-data-form/enums/ndf-form-theme.enum'
import { NdfFormMode } from '../../components/notification-data-form/enums/ndf-form-mode.enum'
import { EditNotification2 } from '../../features/notifications/edit-notification/edit-notification-2'

interface ICreateNotificationProps {}

interface ICreateNotificationState {
    value?: INotificationTreatment
    domain?: DomainDto
}

@observer
export class CreateNotification extends BetterComponent<ICreateNotificationProps, ICreateNotificationState> {
    public readonly defaultClassName: string = 'sw-mv-create-notification'

    protected appState: AppState
    protected appService: AppService

    protected disposeObservers: any[] = []

    public constructor(props: ICreateNotificationProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        this.state = {
            domain: this.appState.currentDomain,
        }
    }

    public UNSAFE_componentWillMount(): void {
        this.disposeObservers.push(observe(this.appState, 'currentDomainJsonData', () => this.ensureSameDomain()))

        this.appService.resetContentScrollPosition()
    }

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.disposeObservers.forEach((fn) => fn())
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <PageHeader title="Send Notification" />

                <NotificationDataFormV2
                    theme={NdfFormTheme.MOBILE}
                    mode={NdfFormMode.CREATE}
                    completionRedirect={this.dashboardUrl}
                />
            </div>
        )
    }

    protected get dashboardUrl(): string {
        return this.appService.routeWithinDomain('/dashboard', true)
    }

    protected ensureSameDomain(): void {
        if (!!this.state.domain && !!this.appState.currentDomain) {
            if (this.state.domain.id !== this.appState.currentDomain.id) {
                this.appService.routeWithinDomain('/dashboard')
            }
        }

        this.setState({ domain: this.appState.currentDomain })
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName, 'page-view']

        return classNames.join(' ')
    }
}
