import * as React from 'react'
import './emoji-manager.scss'
import 'emoji-picker-element'
import { BetterInput } from '../better-input/better-input'
import { SmileOutlined } from '@ant-design/icons'
import { Input, Popover } from 'antd'
import { generateShortID } from '../campaign-builder/helpers/uid'
import { getClassNames } from '../../_utils/classnames'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { MacroManager } from '../macro-manager/macro-manager'
import { useListenerEvent } from '../../hooks/use-listener-event'
import { useRefState } from '../../hooks/use-ref-state'

const splitter = new GraphemeSplitter()

function fireNativeInputChangeEvent(el, value) {
    const nativeSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')!.set!
    nativeSetter.call(el, value)

    const fauxEvent = new Event('input', { bubbles: true })
    el.dispatchEvent(fauxEvent)
}

/**
 * Returns the actual html input element
 * whether nested or direct
 *
 */
function getInputElement(ref: React.MutableRefObject<any>): HTMLInputElement {
    const hasMacroManager = childHasMacroManager(ref)
    const current = hasMacroManager ? ref.current?.inputComponentRef : ref.current
    const inputConstructor = current?.constructor
    const isHtmlInput = inputConstructor !== Input && inputConstructor !== BetterInput

    return isHtmlInput ? current : current?.input
}

function childHasMacroManager(ref: React.MutableRefObject<any>): boolean {
    return ref.current?.constructor === MacroManager
}

const BASE_CLASSNAME = 'emoji-manager'

const EmojiManager: React.FunctionComponent<any> = ({ children, hideToggle, disabled }) => {
    const inputRef = React.useRef<any>(undefined)
    const componentId = React.useRef<string>(`em-${generateShortID()}`)
    const [showEmojiOptions, setShowEmojiOptions] = useRefState(false)

    const InputComponent = React.Children.only(children)

    /**
     * Determines whether click is blur or focus.
     * Uses component unique id to prevent collisions.
     *
     */
    const handleDocumentClick = (ev: any) => {
        const toEl = ev.target
        if (toEl) {
            // determine if clicked element is inside
            // the current macro manager scope
            // only true external clicks should close
            const masterEl = toEl.closest(`#${componentId.current}`)
            const overlayEl = toEl.closest(`.${componentId.current}`)
            const shouldClose = !masterEl && !overlayEl
            // computed blur events should clear
            // input state and hide macro options
            if (shouldClose) {
                setShowEmojiOptions(false)
            }
        }
    }

    /**
     * Handles focus, keyup, and click events for the
     * inputComponent.
     *
     * Gathers current inputContext and updates state
     *
     */
    const handleInputInteraction = (ev: any) => {
        ev.stopPropagation?.()
        ev.stopImmediatePropagation?.()
        let shouldShowEmojiOptions = false

        try {
            const evType = ev.type
            shouldShowEmojiOptions = evType === 'mousedown' && showEmojiOptions.current
        } catch (err) {
            console.warn('Error computing input state', err)
        }
        setShowEmojiOptions(shouldShowEmojiOptions)
    }

    const handleEmojiSelection = (ev: any, emoji: any) => {
        const element = getInputElement(inputRef)
        if (element) {
            const selectionStart = element.selectionStart ?? 0
            let value = element.value
            value = value || ''
            const splitValue = splitter.splitGraphemes(value)
            let expandedLength = 0
            const insertIdx = splitValue.findIndex((g) => {
                expandedLength += g.length
                return expandedLength >= selectionStart
            })

            splitValue.splice(
                insertIdx === 0 && insertIdx === selectionStart ? insertIdx : insertIdx + 1,
                0,
                emoji.emoji,
            )

            fireNativeInputChangeEvent(element, splitValue.join(''))
            const selection = selectionStart + (emoji.emoji?.length ?? 0)
            element.selectionStart = selection
            element.selectionEnd = selection
            element.focus()
        }
    }

    const clickCallback = React.useCallback(
        (ev: any) => {
            handleDocumentClick(ev)
        },
        [componentId.current],
    )

    const inputCallback = React.useCallback(
        (ev: any) => {
            handleInputInteraction(ev)
        },
        [showEmojiOptions.current],
    )

    const handlePickerClick = React.useCallback(
        (ev: any) => {
            if (ev.detail) {
                handleEmojiSelection(ev, { emoji: ev.detail.unicode })
            }
        },
        [componentId.current],
    )

    const addPickerListener = React.useCallback(() => {
        setTimeout(() => {
            const picker = document.querySelector(`emoji-picker.${componentId.current}`)
            picker?.addEventListener('emoji-click', handlePickerClick)
        }, 10)
    }, [componentId.current])

    const removePickerListener = React.useCallback(() => {
        const picker = document.querySelector(`emoji-picker.${componentId.current}`)
        picker?.removeEventListener('emoji-click', handlePickerClick)
    }, [componentId.current])

    useListenerEvent('click', clickCallback, document.body)
    useListenerEvent('mousedown', inputCallback, getInputElement(inputRef))
    useListenerEvent('keydown', inputCallback, getInputElement(inputRef))

    return (
        <div
            key={componentId.current}
            id={componentId.current}
            className={getClassNames(BASE_CLASSNAME, componentId.current, {
                'overlay-visible': showEmojiOptions.current,
                'hide-toggle': hideToggle,
                disabled,
            })}
        >
            <div className={getClassNames(`${BASE_CLASSNAME}-input-wrapper`)}>
                <InputComponent.type
                    {...InputComponent.props}
                    ref={(el) => {
                        // call initial ref method
                        const { ref } = InputComponent as any
                        if (ref && typeof ref === 'function') {
                            ref(el)
                        }

                        // set internal ref
                        inputRef.current = el
                    }}
                    className={getClassNames(`${BASE_CLASSNAME}-input`, InputComponent.props.className)}
                />
                <Popover
                    overlayClassName={getClassNames(`${BASE_CLASSNAME}-emoji-overlay`, componentId.current)}
                    trigger="click"
                    visible={showEmojiOptions.current}
                    onVisibleChange={(v) => {
                        if (!v) {
                            removePickerListener()
                        }
                    }}
                    placement="bottomLeft"
                    content={
                        <div className={getClassNames(`${BASE_CLASSNAME}-emoji-picker-react`)}>
                            <emoji-picker class={`light ${componentId.current}`} />
                        </div>
                    }
                >
                    <span className={getClassNames(`${BASE_CLASSNAME}-emoji-toggle`)}>
                        <SmileOutlined
                            onClick={(ev) => {
                                ev.stopPropagation()
                                ev.preventDefault()

                                const visible = !showEmojiOptions.current
                                setShowEmojiOptions(visible)

                                if (visible) {
                                    addPickerListener()
                                }
                            }}
                        />
                    </span>
                </Popover>
            </div>
        </div>
    )
}

export default EmojiManager
