import * as React from 'react'
import { BetterComponent } from '../../better-component/better-component'
import { AppState } from '../../../stores/app'
import { AppService, DomainService } from '../../../services/index'
import { Container } from 'typescript-ioc/es5'
import { Col, DatePicker, Row, Select } from 'antd'
import * as moment from 'moment-timezone'
import { Moment } from 'moment'
import { INdfScheduleTimepickerValue } from '../interfaces/ndf-schedule-timepicker-value'
import autobind from 'autobind-decorator'
import { getSupportedTimezones } from '../../../_utils/moment'
import { NotificationDeliveryWindow } from '../../../enums/notification-delivery-window'

interface INdfSchedulingSectionProps {
    label?: string
    labelClass?: string
    loading: boolean
    deliveryInProgress: boolean
    deliveryWindow: NotificationDeliveryWindow
    timezone: string
    sendDate: Moment
    onChange: (value: INdfScheduleTimepickerValue) => void
}

interface INdfSchedulingSectionState {
    exclusionsEnabled?: boolean
}

export class SchedulingTimepicker extends BetterComponent<INdfSchedulingSectionProps, INdfSchedulingSectionState> {
    public readonly defaultClassName: string = 'sw-v2-ndf-section'

    protected appState: AppState
    protected appService: AppService
    protected domainService: DomainService

    public constructor(props: INdfSchedulingSectionProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)

        this.state = {}
    }

    public render(): React.ReactNode {
        return (
            <Row gutter={8} className="schedule-details">
                {this.props.label && (
                    <Col className={'label ' + this.props.labelClass} span={this.labelSpanLength}>
                        <span className="label-icon" />
                        <span className="label-text">{this.props.label}</span>
                    </Col>
                )}
                <Col span={this.datePickerSpanLength}>
                    <DatePicker
                        showTime={{
                            format: 'HH:mm',
                            use12Hours: true,
                        }}
                        format="ddd, MMM DD, YYYY \a\t h:mm a"
                        placeholder="Select Time"
                        disabled={this.props.deliveryInProgress}
                        // @ts-ignore
                        value={this.currentSendDateValue}
                        onChange={this.handleSendDateChange}
                    />
                </Col>
                {this.props.deliveryWindow !== NotificationDeliveryWindow.TIMEZONE && (
                    <Col span={this.timezoneSpanLength}>
                        <Select
                            showSearch={true}
                            placeholder="Select a time zone"
                            disabled={this.props.deliveryInProgress}
                            value={this.props.timezone}
                            onChange={this.handleTimeZoneChange}
                        >
                            {this.availableTimezones.map((tz) => (
                                <Select.Option key={tz} value={tz}>
                                    {tz}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                )}
                <Col span={1}></Col>
            </Row>
        )
    }

    @autobind
    protected handleSendDateChange(value: Moment | null) {
        this.props.onChange({
            sendDate: value ?? undefined,
        })
    }

    @autobind
    protected handleTimeZoneChange(value: string) {
        this.props.onChange({
            timezone: value,
        })
    }

    protected get labelSpanLength(): number {
        return 3
    }

    protected get datePickerSpanLength(): number {
        return this.props.label ? 11 : 14
    }

    protected get timezoneSpanLength(): number {
        return 9
    }

    protected get suggestedTimezone(): string {
        return this.appState.currentDomain ? this.appState.currentDomain.timezone : moment.tz.guess()
    }

    protected get currentTimezoneValue(): string {
        const defaultValue = this.suggestedTimezone
        return this.props.timezone || defaultValue
    }

    protected get currentSendDateValue(): Moment | null | undefined {
        return moment.tz(this.props.sendDate, this.currentTimezoneValue)
    }

    protected get availableTimezones(): string[] {
        return getSupportedTimezones()
    }
}
