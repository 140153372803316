import * as React from 'react'
import { ConfigurationContext } from './configuration-context'
import { CampaignEditableState, CampaignType, EcommItemType, TriggerType } from '../enums'
import { getClassNames } from '../../../_utils/classnames'
import { Well } from '@pushly/aqe/lib/components'
import { Loading3QuartersOutlined, QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Radio, Select, Skeleton, Tooltip } from 'antd'
import { extractCampaignTrigger, isTriggerTypeOf } from '../../../_utils/campaigns'
import {
    ALL_SCHEDULE_DAYS,
    EveryNSchedule,
    EveryNScheduleType,
    IEveryNScheduleValue,
    DayPartSelect,
    IDayPartValue,
} from '@pushly/aqe/lib/components'
import { CampaignRecurrenceType, DayOrdinal } from '../../../models/campaign/recurrence-schedule'
import { numRange } from '../../../_utils/range'
import {
    ISchedulingDatetimePickerValue,
    SchedulingDatetimePicker,
} from '../../scheduling-datetime-picker/scheduling-datetime-picker'
import moment from 'moment-timezone'
import { RadioChangeEvent } from 'antd/lib/radio'
import { SimpleTimeframe } from '../../simple-timeframe/simple-timeframe'
import { titleCase } from '../../../_utils/utils'
import { strFormatAddAnOrA } from '../../../_utils/string'
import { FEAT_ABANDONED_VIEW_CAMPAIGNS } from '../../../constants'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { useLoadableDataRefState } from '../../../hooks/use-loadable-data-ref-state'
import { useService } from '../../../hooks/use-service'
import { CampaignV2Service } from '../../../services'
import { useAxiosCancelToken } from '../../../hooks/use-axios-cancel-token'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { useDebouncer } from '@pushly/aqe/lib/hooks'
import { generateDefaultSubscriberFcap } from '../helpers/campaign'
import { CAMPAIGN_DEFAULT_ABANDONMENT_METRIC, CAMPAIGN_DEFAULT_ABANDONMENT_SECONDS } from './constants'
import '../style/campaign-configuration-form.scss'
import '../style/trigger-editor.scss'

const DEFAULT_CART_ITEM_AGE_DAYS_MAX = 30

const fmtOccurrenceDate = (dateStr: string, tz: string) => {
    const mm = moment.tz(dateStr, 'UTC')
    if (tz !== 'STZ') {
        mm.tz(tz)
    }
    return mm.format(`LLLL ${tz === 'STZ' ? '[STZ]' : 'z'}`)
}

export const NextOccurrencesPreview = ({ campaign }: { campaign: CampaignModel | any }) => {
    const svc = useService<CampaignV2Service>(CampaignV2Service)
    const [cancelToken, refreshCancelToken] = useAxiosCancelToken()

    const dateStart = campaign.getDateStart()
    const dateEnd = campaign.getDateEnd()
    const config = campaign.getConfiguration()
    const schedule = config.getSchedule()
    const currTz = schedule?.getTimeZone() ?? 'UTC'

    const [debounce, clearDebounce] = useDebouncer()

    const [occurrences, setOccurrences] = useLoadableDataRefState<string[]>({ loading: true })

    React.useEffect(() => {
        setOccurrences({
            ...occurrences.current,
            loading: true,
        })

        refreshCancelToken()
        debounce(() =>
            svc
                .fetchNextOccurrencesByQuery(
                    {
                        dateStart,
                        dateEnd,
                        configuration: {
                            schedule: schedule?.serialize(),
                        },
                    },
                    {
                        cancellationToken: cancelToken.current,
                    },
                )
                .then((res) => {
                    setOccurrences({
                        loading: res.cancelled,
                        data: res.data ?? null,
                    })
                }),
        )
    }, [dateStart, dateEnd, JSON.stringify(schedule?.serialize() ?? {})])

    const nextOccurrences = occurrences.current.data ?? []
    const nextOccurrencesCount = occurrences.current.data?.length ?? 0
    const noOccurrencesMsg = 'No dates found for current schedule'

    return (
        <div className="recurrence-schedule-preview">
            <Well
                mode="ghost"
                title={`Next ${
                    nextOccurrencesCount === 0 || nextOccurrencesCount >= 7 ? 7 : nextOccurrencesCount
                } Occurrences`}
                hideFooter={true}
            >
                <div className="recurrence-schedule-preview-content">
                    {occurrences.current.loading ? (
                        <span className="loading-indicator">
                            <Loading3QuartersOutlined spin={true} />
                            <span>Loading</span>
                        </span>
                    ) : nextOccurrencesCount === 0 ? (
                        noOccurrencesMsg
                    ) : (
                        nextOccurrences.map((occurrence, idx) => (
                            <div key={idx}>{fmtOccurrenceDate(occurrence, currTz)}</div>
                        ))
                    )}
                </div>
            </Well>
        </div>
    )
}

interface IEntryWell {}

interface IState {}

export class EntryWell extends React.Component<IEntryWell, IState> {
    public static contextType = ConfigurationContext
    public context!: React.ContextType<typeof ConfigurationContext>

    public state: IState = {}

    private readonly appState: AppState

    public constructor(props: IEntryWell) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        const { mode, loading, domain, campaign, editableState } = this.context

        const campaignType = campaign.getType()
        const config = campaign.getConfiguration()
        const entryCap = config.getSubscriberFcap()

        const trigger = extractCampaignTrigger(campaign)
        const triggerConfig = trigger?.getConfiguration()
        const triggerType = triggerConfig?.type
        const isSubscribedTrigger = triggerType === TriggerType.SUBSCRIBED
        const isAbandonedCartTrigger = triggerType === TriggerType.ABANDONED_CART
        const isAbandonedViewTrigger = triggerType === TriggerType.ABANDONED_VIEW
        const isEcommAbandonedTrigger = isAbandonedCartTrigger || isAbandonedViewTrigger

        let abandonedItemName = 'cart'
        if (isAbandonedViewTrigger) {
            abandonedItemName = 'item'
        }

        const timeframeValue = {
            seconds: config.getAbandonmentSeconds() ?? 3600,
            metric: config.getAbandonmentMetric() ?? 'minutes',
        }

        const abandonedTimeFrameTitle = `Abandoned ${titleCase(abandonedItemName)} Time Frame`
        const abandonedTimeFrameLabel = `How long before ${strFormatAddAnOrA(
            abandonedItemName,
        )} is marked as abandoned?`

        return (
            <Well
                className={getClassNames(null, 'campaign-entry-well', 'nested', `mode-${mode}`, {
                    ['triggered']: campaignType === CampaignType.TRIGGERED,
                    ['recurring']: campaignType === CampaignType.RECURRING,
                })}
                title="Entry"
                hideFooter={true}
            >
                <Skeleton
                    loading={loading}
                    active={true}
                    title={false}
                    paragraph={{
                        rows: 1,
                        width: '100%',
                    }}
                >
                    {campaignType === CampaignType.TRIGGERED
                        ? this.renderTriggeredEntryConfiguration()
                        : this.renderRecurringEntryConfiguration()}

                    {isEcommAbandonedTrigger && (
                        <Well mode="ghost" title={abandonedTimeFrameTitle} hideFooter={true} className="entry-cap-well">
                            <Form.Item label={abandonedTimeFrameLabel}>
                                <SimpleTimeframe
                                    size="small"
                                    disabledMetrics={['seconds']}
                                    value={timeframeValue}
                                    onChange={this.handleAbandonedTimeframeChange}
                                />
                            </Form.Item>
                        </Well>
                    )}

                    {isAbandonedCartTrigger && (
                        <Well mode="ghost" title="Maximum Cart Item Age" hideFooter={true} className="entry-cap-well">
                            <Form.Item
                                label={
                                    <span>
                                        What is the maximum number of days after an item is carted to be eligible for
                                        this campaign?
                                    </span>
                                }
                            >
                                <div className={getClassNames('simple-timeframe')}>
                                    <Input
                                        className="max-cart-item-age"
                                        type="number"
                                        size="small"
                                        autoComplete="off"
                                        value={
                                            triggerConfig?.params?.max_item_age_days ??
                                            this.computedDefaultCartItemAgeDaysMax
                                        }
                                        onChange={this.handleMaxCartItemAgeChange}
                                        addonAfter={<label>days</label>}
                                    />
                                </div>
                            </Form.Item>
                        </Well>
                    )}

                    {!isSubscribedTrigger && (
                        <Well mode="ghost" title="Subscriber Entry Cap" hideFooter={true} className="entry-cap-well">
                            <Form.Item
                                className="campaign-rec-frequency-select-wrapper"
                                label="Subscribers who are not currently in this campaign"
                            >
                                <Select
                                    className="campaign-trigger-editor-rec-frequency-select"
                                    size="small"
                                    disabled={editableState === CampaignEditableState.COMPLETED}
                                    value={entryCap?.getType() ?? 'no-fcap'}
                                    onChange={this.handleEntryCapTypeChange}
                                >
                                    <Select.Option value="no-fcap">
                                        May enter this campaign every time it runs
                                    </Select.Option>
                                    <Select.Option value="once">
                                        May only enter this campaign once in their lifetime
                                    </Select.Option>
                                    <Select.Option value="once_per_criteria">
                                        May only enter this campaign once every X days/months
                                    </Select.Option>
                                </Select>
                            </Form.Item>

                            {entryCap?.getType() === 'once_per_criteria' && (
                                <div className="rcte-fcap-criteria-builder">
                                    <span className="once-per-label">Once every</span>
                                    <div className="rcte-fcap-frequency-wrapper">
                                        <Input
                                            className="rcte-fcap-frequency"
                                            size="small"
                                            disabled={editableState === CampaignEditableState.COMPLETED}
                                            defaultValue={entryCap?.getFrequency()}
                                            value={entryCap?.getFrequency()}
                                            onChange={this.handleEntryCapFreqChange}
                                            addonAfter={
                                                <Select
                                                    className="rcte-fcap-window-select"
                                                    disabled={editableState === CampaignEditableState.COMPLETED}
                                                    defaultValue={entryCap?.getWindow()}
                                                    value={entryCap?.getWindow()}
                                                    onChange={this.handleEntryCapWindowChange}
                                                >
                                                    <Select.Option value="days">day(s)</Select.Option>
                                                    <Select.Option value="months">month(s)</Select.Option>
                                                </Select>
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </Well>
                    )}
                </Skeleton>
            </Well>
        )
    }

    public renderTriggeredEntryConfiguration() {
        const { domain, campaign } = this.context
        const domainHasAbandonedViewFlag = (domain?.flags ?? []).includes(FEAT_ABANDONED_VIEW_CAMPAIGNS)

        const trigger = extractCampaignTrigger(campaign)
        const triggerConfig = trigger?.getConfiguration()
        const triggerType = triggerConfig?.type
        const isEcommAbandonedTrigger = isTriggerTypeOf(triggerType, [
            TriggerType.ABANDONED_CART,
            TriggerType.ABANDONED_VIEW,
        ])

        return (
            <>
                <Well mode="ghost" title="Trigger" hideFooter={true}>
                    <Form.Item label="Subscribers enter this campaign after::">
                        <Select
                            className="campaign-trigger-editor-type-selection"
                            dropdownClassName="campaign-trigger-editor-type-dropdown"
                            size="small"
                            placeholder="Select a Campaign Trigger"
                            disabled={!!campaign.getId()}
                            value={triggerType}
                            onChange={this.handleTriggerTypeChange}
                        >
                            <Select.OptGroup label="Push Notifications">
                                <Select.Option value={TriggerType.SUBSCRIBED}>
                                    Subscribing to Push Notifications
                                </Select.Option>
                                {/*<Select.Option value={TriggerType.NOTIFICATION_IMPRESSION}>Showing a Notification</Select.Option>*/}
                                {/*<Select.Option value={TriggerType.NOTIFICATION_CLICK}>Clicking a Notification</Select.Option>*/}
                            </Select.OptGroup>

                            {/*<Select.OptGroup label="Behavior">*/}
                            {/*    <Select.Option value={TriggerType.PAGE_VIEW}>Visiting a Page</Select.Option>*/}
                            {/*    <Select.Option value={TriggerType.PROFILE}>Updating Subscriber Profile</Select.Option>*/}
                            {/*</Select.OptGroup>*/}

                            <Select.OptGroup label="E-Commerce">
                                <Select.Option value={TriggerType.ABANDONED_CART}>Abandoning Cart</Select.Option>
                                {domainHasAbandonedViewFlag && (
                                    <Select.Option value={TriggerType.ABANDONED_VIEW}>Abandoning Browse</Select.Option>
                                )}
                                {/*<Select.Option value={TriggerType.ADD_TO_CART}>Adding to Cart</Select.Option>*/}
                            </Select.OptGroup>
                        </Select>

                        {isEcommAbandonedTrigger && (
                            <div className={getClassNames('form-item-sub')}>
                                <QuestionCircleOutlined />
                                <span>
                                    <a
                                        href={`${this.appState.documentationLink}/integration/e-commerce-support`}
                                        target="_blank"
                                    >
                                        E-Commerce Implementation Documentation
                                    </a>
                                </span>
                            </div>
                        )}
                    </Form.Item>
                </Well>
            </>
        )
    }

    public renderRecurringEntryConfiguration() {
        const { domain, campaign, editableState } = this.context

        const config = campaign.getConfiguration()
        const schedule = config.getSchedule()!
        const scheduleType = schedule?.getType()
        const scheduleSuperType = scheduleType?.replace(/_relative|_specific/, '')

        return (
            <div className="recurrence-schedule-wrapper">
                <div className="recurrence-schedule-builder">
                    <Well mode="ghost" title="Schedule Type" hideFooter={true}>
                        <div className="schedule-type-row">
                            <div className="schedule-type-wrapper">
                                <Select
                                    className="campaign-trigger-editor-rec-schedule-type-select"
                                    size="small"
                                    disabled={editableState === CampaignEditableState.COMPLETED}
                                    value={scheduleSuperType}
                                    onChange={this.handleScheduleTypeChange}
                                >
                                    <Select.Option value="daily">Daily</Select.Option>
                                    <Select.Option value="weekly">Weekly</Select.Option>
                                    <Select.Option value="monthly">Monthly</Select.Option>
                                </Select>
                            </div>
                            <div className="monthly-type-wrapper">
                                {/^monthly/.test(scheduleType) && (
                                    <Radio.Group
                                        size="small"
                                        className="campaign-trigger-editor-rec-schedule-monthly-type-select"
                                        disabled={editableState === CampaignEditableState.COMPLETED}
                                        value={schedule.getRelativeType() ?? scheduleType}
                                        onChange={this.handleMonthlyTypeChange}
                                    >
                                        <Tooltip title="The campaign will run once a month on the chosen day number of the month.">
                                            <Radio.Button value="monthly_specific">Specific Day</Radio.Button>
                                        </Tooltip>
                                        <Tooltip title="The campaign will run once a month on the first day of the month.">
                                            <Radio.Button value="first_day">First Day</Radio.Button>
                                        </Tooltip>
                                        <Tooltip title="The campaign will run once a month on the last day of the month.">
                                            <Radio.Button value="last_day">Last Day</Radio.Button>
                                        </Tooltip>
                                        <Tooltip title="The campaign will run once a month on the chosen relative date of the month.">
                                            <Radio.Button value="relative_day">Relative Day</Radio.Button>
                                        </Tooltip>
                                    </Radio.Group>
                                )}
                            </div>
                        </div>
                    </Well>

                    {scheduleSuperType !== 'monthly' && (
                        <Well
                            mode="ghost"
                            title={`${scheduleType === 'daily' ? 'Daily' : 'Weekly'} Schedule`}
                            hideFooter={true}
                        >
                            <EveryNSchedule
                                key={scheduleType}
                                type={scheduleType as EveryNScheduleType}
                                disabled={editableState === CampaignEditableState.COMPLETED}
                                value={schedule.serialize()}
                                onChange={this.handleMonthlyEveryNChange}
                            />
                        </Well>
                    )}

                    {scheduleType === 'monthly_specific' && (
                        <Well mode="ghost" title="Specific Day" hideFooter={true}>
                            <Select
                                className="specific-day-select"
                                size="small"
                                disabled={editableState === CampaignEditableState.COMPLETED}
                                defaultValue={1}
                                value={schedule.getSpecificDay() ?? 1}
                                onChange={this.handleMonthlySpecificDayChange}
                            >
                                {numRange(1, 31).map((day) => (
                                    <Select.Option key={day} value={day}>
                                        {day}
                                    </Select.Option>
                                ))}
                            </Select>
                            {(schedule.getSpecificDay() ?? 1) > 28 && (
                                <div className={getClassNames('form-layout-row', 'specific-day-warning')}>
                                    <div className={getClassNames('form-layout-row-sub')}>
                                        <WarningOutlined />
                                        <span>
                                            This campaign will not run in months that do not have the chosen number of
                                            days. To always target the last day of the month use the “Relative Day”
                                            option.
                                        </span>
                                    </div>
                                </div>
                            )}
                        </Well>
                    )}

                    {schedule.getRelativeType() === 'relative_day' && (
                        <Well mode="ghost" title="Relative Day" hideFooter={true}>
                            <div className="relative-day-wrapper">
                                <Select
                                    className="relative-day-ordinal-select"
                                    size="small"
                                    disabled={editableState === CampaignEditableState.COMPLETED}
                                    value={schedule.getRelativeDayOrdinal()}
                                    onChange={this.handleRelativeDayOrdinalChange}
                                >
                                    <Select.Option value="first">First</Select.Option>
                                    <Select.Option value="second">Second</Select.Option>
                                    <Select.Option value="third">Third</Select.Option>
                                    <Select.Option value="fourth">Fourth</Select.Option>
                                    <Select.Option value="fifth">Fifth</Select.Option>
                                </Select>

                                <DayPartSelect
                                    className="relative-day-select"
                                    size="small"
                                    mode="single"
                                    disabled={editableState === CampaignEditableState.COMPLETED}
                                    daysOnly={true}
                                    dayFormat={'ddd'}
                                    weekBeginsOnSunday={true}
                                    defaultValue={this.buildAllowedDayMap([schedule.getRelativeDay()!])}
                                    value={this.buildAllowedDayMap([schedule.getRelativeDay()!])}
                                    onChange={this.handleRelativeDayValueChange}
                                />
                            </div>
                        </Well>
                    )}

                    <Well mode="ghost" title="Schedule Time" hideFooter={true}>
                        <SchedulingDatetimePicker
                            size="small"
                            mode="time"
                            domain={domain}
                            disabled={editableState === CampaignEditableState.COMPLETED}
                            timeZone={campaign.getDateTimeZone()}
                            hideTimeZoneOptions={true}
                            value={{
                                timeZone: schedule.getTimeZone(),
                                time: !schedule.getSpecificTime()
                                    ? undefined
                                    : moment(schedule.getSpecificTime(), 'HH:mm'),
                            }}
                            onChange={this.handleScheduleTimeChange}
                        />
                    </Well>
                </div>

                <NextOccurrencesPreview campaign={campaign} />
            </div>
        )
    }

    protected get computedDefaultEcommItemType(): string {
        return this.context.domain.ecommConfig?.itemType ?? EcommItemType.PRODUCT
    }

    protected get computedDefaultCartItemAgeDaysMax(): number {
        return this.context.domain.ecommConfig?.maxAbandonedItemAgeDays ?? DEFAULT_CART_ITEM_AGE_DAYS_MAX
    }

    protected buildAllowedDayMap(days: string[]) {
        const map: any = {}
        days.filter((d) => !!d).forEach((d) => (map[d] = true))

        return map
    }

    protected handleTriggerTypeChange = (type: TriggerType) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const audience = config.getAudience()

        const trigger = extractCampaignTrigger(campaign)
        const ecommTriggerTypes = [TriggerType.ABANDONED_CART, TriggerType.ABANDONED_VIEW]
        const currTriggerConfig = trigger.getConfiguration()
        const currTriggerIsEcommAbandoned = isTriggerTypeOf(currTriggerConfig.type, ecommTriggerTypes)
        const nextTriggerConfig = { ...currTriggerConfig, type }
        const nextTriggerIsEcommAbandoned = isTriggerTypeOf(nextTriggerConfig.type, ecommTriggerTypes)

        if (type === TriggerType.SUBSCRIBED) {
            // reset audience to default/all
            audience.setSegmentIds(undefined)
            audience.setExcludedSegmentIds(undefined)

            nextTriggerConfig.params = {
                event_type: type,
            }

            trigger.setConfiguration(nextTriggerConfig)

            // reset ecomm specific values and flags
            config.setSubscriberFcap(undefined)
            config.setItemGroupTargeting(null)
            config.setAbandonmentSeconds(null)
            config.setAbandonmentMetric(null)

            this.context.setFlags({
                itemGroupsEnabled: false,
                quietHoursEnabled: false,
            })
        } else if (nextTriggerIsEcommAbandoned) {
            // if moving from subscribed to ecomm: add initial audience
            if (!currTriggerIsEcommAbandoned) {
                audience.setSegmentIds(
                    !this.context.domainDefaultSegment ? [] : [this.context.domainDefaultSegment?.id],
                )
            }

            nextTriggerConfig.params = {
                item_type: this.computedDefaultEcommItemType,
            }

            if (type === TriggerType.ABANDONED_CART) {
                nextTriggerConfig.params.max_item_age_days = this.computedDefaultCartItemAgeDaysMax
            }

            trigger.setConfiguration(nextTriggerConfig)

            // ensure default ecomm values
            config.setSubscriberFcap(generateDefaultSubscriberFcap())
            config.setAbandonmentSeconds(CAMPAIGN_DEFAULT_ABANDONMENT_SECONDS)
            config.setAbandonmentMetric(CAMPAIGN_DEFAULT_ABANDONMENT_METRIC)
        }

        this.context.setFlags({ criteriaEnabled: false })
        this.context.setCampaign(campaign)
    }

    protected handleScheduleTypeChange = (type: string) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const schedule = config.getSchedule()!
        const scheduleType = schedule?.getType()
        const scheduleSuperType = scheduleType?.replace(/_relative|_specific/, '')

        if (type !== 'monthly') {
            if (scheduleSuperType === 'monthly') {
                schedule.setFrequency(1)
            }
            schedule.setAllowedDays(type === 'daily' ? ALL_SCHEDULE_DAYS : [])
        } else {
            // Set default monthly type
            type = 'monthly_specific'
            schedule.setSpecificDay(1)
        }

        schedule.setType(type as CampaignRecurrenceType)

        this.context.setCampaign(campaign)
    }

    protected handleMonthlyTypeChange = (ev: RadioChangeEvent) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const schedule = config.getSchedule()!

        let type = ev.target.value
        if (type === 'monthly_specific') {
            schedule.setSpecificDay(1)
            schedule.setRelativeType(undefined)
        } else {
            schedule.setRelativeType(type)
            if (type === 'relative_day') {
                schedule.setRelativeDayOrdinal(DayOrdinal.FIRST)
            }

            type = 'monthly_relative'
        }

        schedule.setType(type)

        this.context.setCampaign(campaign)
    }

    protected handleMonthlyEveryNChange = (value: IEveryNScheduleValue | undefined) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const schedule = config.getSchedule()!

        schedule.setFrequency(value?.frequency)
        schedule.setAllowedDays(value?.allowed_days)

        this.context.setCampaign(campaign)
    }

    protected handleMonthlySpecificDayChange = (day: number) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const schedule = config.getSchedule()!

        schedule.setSpecificDay(day)

        this.context.setCampaign(campaign)
    }

    protected handleRelativeDayOrdinalChange = (ordinal: any) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const schedule = config.getSchedule()!

        schedule.setRelativeDayOrdinal(ordinal)

        this.context.setCampaign(campaign)
    }

    protected handleRelativeDayValueChange = async (days: IDayPartValue) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const schedule = config.getSchedule()!

        const dayKeys = Object.keys(days).filter((d) => !!days[d])
        schedule.setRelativeDay(dayKeys.length > 0 ? dayKeys[0] : undefined)

        this.context.setCampaign(campaign)
    }

    protected handleScheduleTimeChange = (dtpValue: ISchedulingDatetimePickerValue) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const schedule = config.getSchedule()!

        schedule.setTimeZone(dtpValue.timeZone)
        schedule.setSpecificTime(dtpValue.time?.format('HH:mm'))

        this.context.setCampaign(campaign)
    }

    protected handleEntryCapTypeChange = (type: any) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const entryCap = config.getSubscriberFcap()

        let entryCapUpdate: any
        if (type !== 'no-fcap') {
            entryCapUpdate = {
                ...entryCap?.serialize(),
                type,
            }
        }

        config.setSubscriberFcap(entryCapUpdate)

        if (type === 'once_per_criteria') {
            config.getSubscriberFcap()!.setFrequency(1)
            config.getSubscriberFcap()!.setWindow('days')
        }

        this.context.setCampaign(campaign)
    }

    protected handleEntryCapFreqChange = (ev: any) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        const frequency = ev.target.value
        config.setSubscriberFcap(config.getSubscriberFcap())
        config.getSubscriberFcap()!.setFrequency(frequency)

        this.context.setCampaign(campaign)
    }

    protected handleEntryCapWindowChange = (window: any) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const entryCap = config.getSubscriberFcap()

        config.setSubscriberFcap(entryCap)
        config.getSubscriberFcap()!.setWindow(window)

        this.context.setCampaign(campaign)
    }

    protected handleAbandonedTimeframeChange = (value: any) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        config.setAbandonmentSeconds(value.seconds)
        config.setAbandonmentMetric(value.metric)

        this.context.setCampaign(campaign)
    }

    protected handleMaxCartItemAgeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const campaign = this.context.campaign.clone()
        const trigger = extractCampaignTrigger(campaign)
        const triggerConfig = trigger.getConfiguration()

        trigger.setConfiguration({
            ...triggerConfig,
            params: {
                ...triggerConfig.params,
                max_item_age_days: parseInt(ev.target.value, 10) || 0,
            },
        })

        this.context.setCampaign(campaign)
    }
}
