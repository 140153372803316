import * as React from 'react'
import { Platform } from '@pushly/cuttlefish'
import { NdfFormTheme } from './enums/ndf-form-theme.enum'
import { NdfFormMode } from './enums/ndf-form-mode.enum'
import { DomainDto } from '../../dtos/domain'

interface IFormContext {
    theme: NdfFormTheme
    mode: NdfFormMode
    domain: DomainDto
    reachEstimate?: number
    inlinePreviewPlatform?: Platform
    useManualSubmit?: boolean
}

export const FormContext = React.createContext<IFormContext>({} as IFormContext)
