import React from 'react'
import './segment-builder.scss'
import classnames from 'classnames'
import * as deepEqual from 'react-fast-compare'
import aqe from '@pushly/aqe/lib/stores/aqe'
import { SegmentBuilderContext } from './segment-builder-context'
import { AccountService, AppService, DomainService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { AppState } from '../../stores/app'
import { NotFound404 } from '../404/404'
import { DomainDto } from '../../dtos/domain'
import { SegmentModel } from '../../models/segments/segment.model'
import { AccountDto } from '../../dtos/account.dto'
import { OrgSegmentModel } from '../../models/segments/org-segment.model'
import SegmentDetailsBuilder from './segment-details-builder'
import { SegmentService } from '../../services/segment'
import { OrgSegmentService } from '../../services/org-segment'
import SegmentRuleBuilder from './segment-rule-builder'
import { IPageCategory, IPageCategorySubscriberCount } from '@pushly/aqe/lib/interfaces'
import { convertCase } from '../../_utils/utils'
import { PageCategoriesService } from '@pushly/aqe/lib/services'
import { TypeaheadTransform } from '../rule-builder-v2/inputs/typeahead/typeahead'
import { ISegmentField } from './interfaces'
import { SegmentSource } from '../../enums/segment-source.enum'
import {
    getSegmentCreateDto,
    getSegmentUpdateDto,
    isBuilderSegmentValid,
    isOrgSegment,
    isSegment,
    isUnsafeExecution,
} from './helpers'
import { flatMap } from '../../_utils/array'
import { INestedBatchRequest } from '../../interfaces/batch-requests'
import { BatchService } from '../../services/batch'
import { stripUndefined } from '../../_utils/strip-undefined'
import { RuleBuilderV2 } from '../rule-builder-v2/rule-builder'
import { Modal } from 'antd'
import { DictionaryService } from '../../services/dictionary'
import { ReachByChannel } from '../notification-builder/elements/notification-builder-reach'
import { FEAT_CHANNEL_ANDROID, FEAT_CHANNEL_IOS } from '../../constants'
import { onResponseError403 } from '../../_utils/on-response-error-403'
import { DeliveryChannelSelector } from '../delivery-channel-selector/delivery-channel-selector'
import { getEnabledDeliveryChannels } from '../../_utils/domain'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { getAccountEnabledDeliveryChannels } from '../../_utils/account'
import classNames from 'classnames'

interface IOrgSegmentBuilderProps {
    level: 'org'
    orgId: number
}

interface IDomainSegmentBuilderProps {
    level: 'domain'
    domainId: number
}

type SegmentBuilderProps = (IOrgSegmentBuilderProps | IDomainSegmentBuilderProps) & {
    readonly?: boolean
    segmentId?: number
    templateId?: number
    mode?: 'standard' | 'drawer'
    name?: string
    source?: SegmentSource
    channels?: DeliveryChannel[]
    hideChannelsSelector?: boolean
}

interface IState {
    levelDependenciesLoaded: boolean
    org: AccountDto
    domain: DomainDto

    segmentDependenciesLoaded: boolean
    segmentOriginal: SegmentModel | OrgSegmentModel
    segment: SegmentModel | OrgSegmentModel

    selectedDomainIds: number[]
    fieldDependenciesLoaded: boolean

    languageCodesLoaded: boolean
    languageCodes: any[]

    keywordsLoaded: boolean
    keywords: string[]
    keywords_all: string[]

    calculatedFieldsLoaded: boolean
    calculatedFields: any[]
    calculatedFields_all: any[]

    segmentFieldsLoaded: boolean
    segmentFields: ISegmentField[]
    segmentFields_all: ISegmentField[]
    segmentFieldTypeaheadUrl: string | (() => string | null)
    segmentFieldTypeaheadTransform: TypeaheadTransform

    subscriberPreferences: string[]
    subscriberPreferences_all: any[]
    canShowSubscriberPreferences: boolean

    pageCategoriesLoaded: boolean
    pageCategories: IPageCategory[]
    pageCategories_all: IPageCategory[]
    pageCategorySubscriberCountsLoaded: boolean
    pageCategorySubscriberCounts: IPageCategorySubscriberCount[]
    pageCategorySubscriberCounts_all: IPageCategorySubscriberCount[]

    reachEstimateLoaded: boolean
    reachEstimate: ReachByChannel
    reachEstimatePreviousQuery?: any
    reachEstimatePreviousDomainIds?: number[]
    reachEstimateBreakdown: { [index: number]: number }

    readonly?: boolean
    defaultIconEnabled: boolean
    adhocEnabled: boolean
    builderDocumentIsValid: boolean
    confirmingDomainChange: boolean
}

class SegmentBuilder extends React.Component<SegmentBuilderProps, IState> {
    private readonly appState: AppState
    private readonly appSvc: AppService
    private readonly batchSvc: BatchService
    private readonly dictionarySvc: DictionaryService
    private readonly orgSvc: AccountService
    private readonly orgSegmentSvc: OrgSegmentService
    private readonly domainSvc: DomainService
    private readonly segmentSvc: SegmentService
    private readonly pageCategoriesSvc: PageCategoriesService

    private reachEstimateTimer: any
    private ruleBuilderRef: RuleBuilderV2 | null

    public constructor(props: SegmentBuilderProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appSvc = Container.get(AppService)
        this.batchSvc = Container.get(BatchService)
        this.dictionarySvc = Container.get(DictionaryService)
        this.orgSvc = Container.get(AccountService)
        this.orgSegmentSvc = Container.get(OrgSegmentService)
        this.domainSvc = Container.get(DomainService)
        this.segmentSvc = Container.get(SegmentService)
        this.pageCategoriesSvc = Container.get(PageCategoriesService)

        const initialBuild = stripUndefined({
            source: props.source ?? SegmentSource.STANDARD,
            name: props.name,
            channels: props.channels,
        })

        const segment = props.level === 'org' ? OrgSegmentModel.build(initialBuild) : SegmentModel.build(initialBuild)

        this.state = {
            levelDependenciesLoaded: false,
            org: null!, // set on mount
            domain: null!, // set on mount

            segmentDependenciesLoaded: false,
            segmentOriginal: segment,
            segment: segment.clone(),

            selectedDomainIds: [],
            fieldDependenciesLoaded: false,

            languageCodesLoaded: false,
            languageCodes: [],

            keywordsLoaded: false,
            keywords: [],
            keywords_all: [],

            calculatedFieldsLoaded: false,
            calculatedFields: [],
            calculatedFields_all: [],

            segmentFieldsLoaded: false,
            segmentFields: [],
            segmentFields_all: [],
            segmentFieldTypeaheadUrl: () => {
                let typeaheadUrl: string | null = null

                if (this.props.level === 'domain') {
                    typeaheadUrl = `${aqe.defaults.publicApiDomain}/domains/${this.props.domainId}/segment-fields/%p/values?search=%q`
                } else if (this.props.level === 'org') {
                    typeaheadUrl = `${aqe.defaults.publicApiDomain}/accounts/${this.props.orgId}/segment-fields/%p/values?search=%q`
                }

                return typeaheadUrl
            },
            segmentFieldTypeaheadTransform: (req: any) => {
                let data = req.data.data

                if (this.props.level === 'org' && Array.isArray(data)) {
                    const combinedResults = data.reduce((v, datum) => v.concat(datum.values), [])
                    if (combinedResults.length > 0) {
                        data = Array.from(new Set(combinedResults))
                    }
                }

                return data
            },

            subscriberPreferences: [],
            subscriberPreferences_all: [],
            canShowSubscriberPreferences: false,

            pageCategoriesLoaded: false,
            pageCategories: [],
            pageCategories_all: [],
            pageCategorySubscriberCountsLoaded: false,
            pageCategorySubscriberCounts: [],
            pageCategorySubscriberCounts_all: [],

            reachEstimateLoaded: true,
            reachEstimate: undefined!, // set on dependencies loaded
            reachEstimateBreakdown: {},

            defaultIconEnabled: false,
            adhocEnabled: false,
            builderDocumentIsValid: true,
            confirmingDomainChange: false,
            readonly: this.props.readonly,
        }
    }

    public render() {
        const baseContext = {
            mode: this.props.mode ?? 'standard',
            isDrawerMode: this.props.mode === 'drawer',
            readonly: this.state.readonly ?? false,

            loading: this.isLoading,
            levelDependenciesLoaded: this.state.levelDependenciesLoaded,
            segmentDependenciesLoaded: this.state.segmentDependenciesLoaded,
            fieldDependenciesLoaded: this.state.fieldDependenciesLoaded,

            selectedDomainIds: this.state.selectedDomainIds,

            languageCodesLoaded: this.state.languageCodesLoaded,
            languageCodes: this.state.languageCodes,

            keywordsLoaded: this.state.keywordsLoaded,
            keywords: this.state.keywords,

            calculatedFieldsLoaded: this.state.calculatedFieldsLoaded,
            calculatedFields: this.state.calculatedFields,

            segmentFieldsLoaded: this.state.segmentFieldsLoaded,
            segmentFields: this.state.segmentFields,
            segmentFieldTypeaheadUrl: this.state.segmentFieldTypeaheadUrl,
            segmentFieldTypeaheadTransform: this.state.segmentFieldTypeaheadTransform,

            pageCategoriesLoaded: this.state.pageCategoriesLoaded,
            pageCategories: this.state.pageCategories,
            pageCategorySubscriberCountsLoaded: this.state.pageCategorySubscriberCountsLoaded,
            pageCategorySubscriberCounts: this.state.pageCategorySubscriberCounts,

            reachEstimateLoaded: this.state.reachEstimateLoaded,
            reachEstimate: this.state.reachEstimate,
            reachEstimateBreakdown: this.state.reachEstimateBreakdown,

            defaultIconEnabled: this.state.defaultIconEnabled,
            adhocEnabled: this.state.adhocEnabled,
            canShowSubscriberPreferences: this.state.canShowSubscriberPreferences,
            builderDocumentIsValid: this.state.builderDocumentIsValid,
        }

        let enabledChannels: DeliveryChannel[] = []
        if (this.props.level === 'domain' && this.state.domain) {
            enabledChannels = getEnabledDeliveryChannels(this.state.domain, true)
        } else if (this.props.level === 'org' && this.state.org) {
            enabledChannels = getAccountEnabledDeliveryChannels(this.state.org)
        }
        const showChannelsWell = !this.props.hideChannelsSelector && enabledChannels.length > 1

        return (
            <SegmentBuilderContext.Provider
                value={
                    this.props.level === 'org'
                        ? {
                              level: 'org',
                              org: this.state.org,
                              segment: this.state.segment as OrgSegmentModel,
                              confirmingDomainChange: this.state.confirmingDomainChange,
                              ...baseContext,
                          }
                        : {
                              level: 'domain',
                              domain: this.state.domain,
                              segment: this.state.segment as SegmentModel,
                              ...baseContext,
                          }
                }
            >
                {this.state.segmentDependenciesLoaded && !this.state.segment ? (
                    <NotFound404 label="The requested segment could not be found" />
                ) : (
                    <div
                        className={classnames(
                            'segment-builder',
                            `level-${this.props.level}`,
                            `mode-${baseContext.mode}`,
                            {
                                loading: this.isLoading,
                                readonly: baseContext.readonly,
                            },
                        )}
                    >
                        <SegmentDetailsBuilder
                            onChange={(segment) => this.setState({ segment })}
                            onDefaultIconEnabledChange={(defaultIconEnabled) => this.setState({ defaultIconEnabled })}
                            onAdhocEnabledChange={(adhocEnabled) => this.setState({ adhocEnabled })}
                            onDomainIdsChange={this.handleDomainIdsChanged}
                        />

                        {/*
                            faux aqe-well wrapper to ensure proper
                            spacing even when "display: none"
                        */}
                        <div className={classNames('aqe-well', { hidden: !showChannelsWell })}>
                            <DeliveryChannelSelector
                                type="multiple"
                                value={this.state.segment.clone().getChannels()}
                                onChange={this.handleChannelChange}
                                loading={this.isLoading}
                                visibleChannels={this.activeEntityChannels}
                            />
                        </div>

                        <SegmentRuleBuilder
                            ref={(el) => (this.ruleBuilderRef = el)}
                            onChange={this.handleRuleBuilderChange}
                            onCancel={this.handleRuleBuilderCancel}
                            onSubmit={this.handleRuleBuilderSubmit}
                            onValidation={this.handleRuleBuilderValidation}
                            submitDisabled={this.submitDisabledState}
                        />
                    </div>
                )}
            </SegmentBuilderContext.Provider>
        )
    }

    public componentDidMount() {
        this.loadDependencies()
    }

    public componentDidUpdate(prevProps) {
        const levelChanged = prevProps.level !== this.props.level
        // @ts-ignore
        const domainIdChanged = prevProps.domainId !== this.props.domainId
        // @ts-ignore
        const orgIdChanged = prevProps.orgId !== this.props.orgId

        if (levelChanged || domainIdChanged || orgIdChanged) {
            this.loadDependencies()
        }
    }

    // exposed force submit for drawer handlers
    public _submit() {
        return this.handleRuleBuilderSubmit(this.state.segment)
    }

    protected get isLoading() {
        return (
            !this.state.levelDependenciesLoaded ||
            !this.state.segmentDependenciesLoaded ||
            !this.state.fieldDependenciesLoaded
        )
    }

    protected get isDomainLevel() {
        return this.props.level === 'domain'
    }

    protected get currentSelectedDomainIds() {
        return this.isDomainLevel ? [this.state.domain.id] : this.state.selectedDomainIds
    }

    protected get currentUserDomainIds() {
        return this.isDomainLevel
            ? [this.state.domain.id]
            : this.appState.currentUserDomains!.filter((d) => d.accountId === this.state.org.id).map((d) => d.id)
    }

    protected get activeEntityChannels() {
        return this.isDomainLevel && this.state.domain
            ? getEnabledDeliveryChannels(this.state.domain, true)
            : this.state.org
            ? getAccountEnabledDeliveryChannels(this.state.org)
            : []
    }

    /**
     * Caclulates details & rules changes
     * to determine if the form should be submit-able
     */
    protected get submitDisabledState() {
        let submitDisabled = false

        if (!this.state.segment) {
            submitDisabled = true
        } else if (this.state.segment.getId()) {
            const ogSegment = this.state.segmentOriginal
            const currSegment = this.state.segment

            let currentUpdates: any = {}
            if (isSegment(currSegment) && isSegment(ogSegment)) {
                currentUpdates = getSegmentUpdateDto(currSegment, ogSegment)
            } else if (isOrgSegment(currSegment) && isOrgSegment(ogSegment)) {
                currentUpdates = getSegmentUpdateDto(currSegment, ogSegment, this.state.selectedDomainIds)
            }

            submitDisabled = Object.keys(currentUpdates).length === 0
        }

        return submitDisabled
    }

    protected handleChannelChange = async (channels: DeliveryChannel[]) => {
        const segment = this.state.segment.clone()
        const curr = segment.getChannels()

        let channelChange: any
        if (curr.length !== channels.length) {
            channelChange = curr.filter((ch) => !channels.includes(ch))[0]
        }

        const channelRemoved = curr.length > channels.length

        if (channelRemoved && segment.getFiltersJson()?.and) {
            return await new Promise((res) => {
                Modal.confirm({
                    title: `Segment Delivery Channel: ${DeliveryChannel.getLongName(channelChange)} Removed`,
                    content: (
                        <>
                            <p>
                                Removing this channel will require resetting the segment and starting over. Are you sure
                                you wish to continue with this delivery channel change?
                            </p>
                        </>
                    ),
                    okText: 'Continue',
                    cancelText: 'Cancel',
                    onOk: () => {
                        segment.setFiltersJson({})
                        segment.setChannels(channels)

                        this.setState(
                            {
                                reachEstimate: { total: 0 },
                                reachEstimatePreviousQuery: undefined,
                                segment,
                            },
                            () => {
                                this.updateReachEstimate()
                            },
                        )

                        res(null)
                    },
                    onCancel: () => {
                        res(null)
                    },
                })
            })
        } else {
            segment.setChannels(channels)

            this.setState({ segment }, () => {
                this.updateReachEstimate()
            })
        }
    }

    protected handleDomainIdsChanged = async (selectedDomainIds: number[], requiresValidation: boolean) => {
        const prevDomainIds = Array.from(this.state.selectedDomainIds)
        const prevSegment = this.state.segment

        this.setState(
            {
                selectedDomainIds,
            },
            async () => {
                await this.loadFieldDependencies(true)

                if (requiresValidation) {
                    const stillValid = this.ruleBuilderRef?.validateDocumentPropertyNames?.(
                        prevSegment.getFiltersJson(),
                        false,
                    )

                    if (!stillValid) {
                        this.setState({ confirmingDomainChange: true })

                        await new Promise((res) => {
                            Modal.confirm({
                                title: 'Segment Fields Changed',
                                content:
                                    'Certain fields are no longer valid and have been removed. Are you sure you wish to continue with the domain changes?',
                                okText: 'Continue',
                                cancelText: 'Cancel',
                                onOk: () => {
                                    this.ruleBuilderRef?.purgeInvalidDocumentProperties?.()

                                    this.setState(
                                        {
                                            reachEstimate: { total: 0 },
                                            reachEstimatePreviousQuery: undefined,
                                        },
                                        () => {
                                            this.updateReachEstimate()
                                        },
                                    )

                                    res(null)
                                },
                                onCancel: () => {
                                    this.handleDomainIdsChanged(prevDomainIds, false)
                                    res(null)
                                },
                            })
                        })

                        this.setState({ confirmingDomainChange: false })
                    } else {
                        this.updateReachEstimate()
                    }
                } else {
                    this.updateReachEstimate()
                }
            },
        )
    }

    /**
     * Event emitted from SegmentRuleBuilder < RuleBuilderV2
     *
     * Invalid documents should not allow rules
     * to be edited
     */
    protected handleRuleBuilderValidation = (builderDocumentIsValid: boolean) => {
        this.setState({ builderDocumentIsValid })
    }

    /**
     * Event emitted from SegmentRuleBuilder < RuleBuilderV2
     *
     * Cancelling during create/edit should return user
     * to the associated list view
     */
    protected handleRuleBuilderCancel = () => {
        this.goBackToSegmentsList()
    }

    /**
     * Event emitted from SegmentRuleBuilder < RuleBuilderV2
     *
     * All rule changes should trigger an audience size
     * update unless the document is deemed unsafe
     */
    protected handleRuleBuilderChange = (segment: OrgSegmentModel | SegmentModel) => {
        this.setState({ segment }, () => {
            if (!isUnsafeExecution(this.state as any)) {
                this.updateReachEstimate()
            }
        })
    }

    /**
     * Event emitted from SegmentRuleBuilder < RuleBuilderV2
     *
     * Master (Details & Rules) submit button handler
     */
    protected handleRuleBuilderSubmit = async (segment: OrgSegmentModel | SegmentModel) => {
        this.state.adhocEnabled ? segment.setSource(SegmentSource.ADHOC) : segment.setSource(SegmentSource.STANDARD)

        if (!segment.getId()) {
            // CREATE

            let resolver
            if (this.props.level === 'domain' && isSegment(segment)) {
                const domainId = this.state.domain.id
                const dto = getSegmentCreateDto(segment)

                resolver = this.segmentSvc.createSegmentForDomainId(domainId, dto as any)
            } else if (this.props.level === 'org' && isOrgSegment(segment)) {
                const orgId = this.state.org.id
                const domainIds = this.state.selectedDomainIds
                const dto = {
                    ...getSegmentCreateDto(segment, domainIds),
                    // domainIds are required regardless of changes
                    domain_ids: domainIds,
                }

                const res = await this.orgSegmentSvc.create(orgId, dto, {
                    showLoadingScreen: true,
                })

                if (res.ok && res.data) {
                    resolver = res
                } else if (res.error && !res.cancelled) {
                    return
                }
            }

            const result = await resolver
            if (this.props.mode === 'drawer') {
                return result
            } else {
                if (result) this.appSvc.routeBack()
            }
        } else {
            // UPDATE
            const ogSegment = this.state.segmentOriginal

            let resolver
            if (this.props.level === 'domain' && isSegment(segment) && isSegment(ogSegment)) {
                const domainId = this.state.domain.id
                const dto = getSegmentUpdateDto(segment, ogSegment)

                resolver = this.segmentSvc.updateSegmentById(domainId, segment.getId(), dto as any)
            } else if (this.props.level === 'org' && isOrgSegment(segment) && isOrgSegment(ogSegment)) {
                const orgId = this.state.org.id
                const domainIds = this.state.selectedDomainIds
                const segmentIdentifiers = {
                    id: segment.getId(),
                    name: segment.getName(),
                }

                const dto = {
                    ...getSegmentUpdateDto(segment, ogSegment, domainIds),
                    // domainIds are required regardless of changes
                    domain_ids: domainIds,
                }

                const res = await this.orgSegmentSvc.update(orgId, segmentIdentifiers, dto, {
                    showLoadingScreen: true,
                })

                if (res.ok && res.data) {
                    resolver = res.data
                } else if (res.error && !res.cancelled) {
                    return
                }
            }

            const result = await resolver
            if (result) {
                // ensure original segment is updated to latest state
                const modelKlass = this.props.level === 'domain' ? SegmentModel : OrgSegmentModel
                this.setState({
                    segmentOriginal: modelKlass.build(result),
                })

                if (this.props.mode === 'drawer') {
                    return result
                }
            }
        }
    }

    protected async loadDependencies() {
        await this.loadLevelDependencies()
        await this.loadSegmentDependencies()
        this.loadFieldDependencies()
    }

    protected async loadLevelDependencies() {
        const state: any = { levelDependenciesLoaded: false }
        this.setState({ ...state })

        state.levelDependenciesLoaded = true
        let levelEntity
        let segment = this.state.segment.clone()

        if (this.props.level === 'domain') {
            const domainId = this.props.domainId
            levelEntity = this.appState.currentUserDomains?.find((d) => d.id === domainId)
            if (levelEntity) {
                state.domain = levelEntity

                // set default adhoc state
                if (this.props.mode === 'drawer' && state.domain.displayMeta?.default_inline_seg_source) {
                    state.adhocEnabled = true
                }

                // channels depend on entity existence
                segment.setChannels(getEnabledDeliveryChannels(state.domain))
                state.segment = segment
            }
        } else {
            const orgId = this.props.orgId
            levelEntity = await this.orgSvc.fetchById(orgId)
            if (levelEntity) {
                state.org = levelEntity

                // set default domain selection
                if (this.appState.currentUserDomains) {
                    const selectedDomainIds = this.appState.currentUserDomains
                        .filter((d) => d.accountId === state.org.id)
                        .map((d) => d.id)

                    state.selectedDomainIds = selectedDomainIds
                    state.reachEstimatePreviousDomainIds = selectedDomainIds
                }

                // channels depend on entity existence
                segment.setChannels(getAccountEnabledDeliveryChannels(state.org))
                state.segment = segment
            }
        }

        this.setState(state)
    }

    protected async loadSegmentDependencies() {
        const state: any = { segmentDependenciesLoaded: false }

        const segmentId = this.props.segmentId ?? this.props.templateId
        const isTemplateId = this.props.templateId && !this.props.segmentId

        if (segmentId) {
            this.setState({ ...state })

            if (this.props.level === 'domain') {
                const res = await this.segmentSvc.fetchSegmentById(
                    this.props.domainId,
                    segmentId,
                    false,
                    undefined,
                    onResponseError403(() => {
                        this.goBackToSegmentsList()
                    }),
                )

                if (res) {
                    const segment = SegmentModel.build(res)

                    // multi-domain segments are currently readonly at the domain level
                    if (!isTemplateId && segment.getIsMultiDomain()) {
                        state.readonly = true
                    }

                    state.segmentOriginal = segment
                }
            } else {
                const res = await this.orgSegmentSvc.fetch(this.props.orgId, segmentId, {
                    errorHandler: onResponseError403(() => {
                        this.goBackToSegmentsList()
                    }),
                })

                if (res.ok) {
                    const segment = OrgSegmentModel.build(res.data)

                    // set initial domain id selections
                    if (Array.isArray(segment?.getDomainIds())) {
                        state.selectedDomainIds = segment.getDomainIds()
                        state.reachEstimatePreviousDomainIds = segment.getDomainIds()
                    }

                    state.segmentOriginal = segment
                }
            }

            // create working clone
            state.segment = state.segmentOriginal?.clone?.()

            if (isTemplateId) {
                // revert original to empty state if duplicating
                delete state.segmentOriginal

                // update working for duplicate state
                state.segment.id = undefined
                state.segment.setName(`${state.segment.getName()} (Duplicate)`)
            }

            // set initial icon enabled state
            if (state.segment?.getIconUrl?.()) {
                state.defaultIconEnabled = true
            }
        }

        state.segmentDependenciesLoaded = true
        this.setState(state, () => {
            this.updateReachEstimate()
        })
    }

    protected async loadFieldDependencies(softReload: boolean = false) {
        const state: any = { fieldDependenciesLoaded: false }
        this.setState({ ...state })

        const levelEntity = this.props.level === 'org' ? this.state.org : this.state.domain

        this.loadKeywords(levelEntity, softReload)

        await Promise.all([
            this.loadLanguageCodes(softReload),
            this.loadCalculatedFields(levelEntity, softReload),
            this.loadSegmentFields(levelEntity, softReload),
            this.loadSubscriberPreferences(levelEntity, softReload),
        ])

        state.fieldDependenciesLoaded = true
        this.setState(state)
    }

    protected async loadLanguageCodes(softReload: boolean = false) {
        if (softReload && this.state.languageCodes) {
            return
        }

        const state: any = { languageCodesLoaded: false }
        this.setState(state)

        const { ok, data } = await this.dictionarySvc.fetchLanguageCodes()

        if (ok) {
            this.setState({
                languageCodesLoaded: true,
                languageCodes: data,
            })
        }
    }

    protected async loadCalculatedFields(entity: AccountDto | DomainDto, softReload: boolean = false) {
        const state: any = { calculatedFieldsLoaded: false }
        this.setState({ ...state })

        let resolver
        if (this.isDomainLevel) {
            resolver = this.segmentSvc.fetchCalculatedFieldsByDomainId(entity.id)
        } else if (softReload) {
            resolver = { ok: true, data: this.state.calculatedFields_all }
        } else {
            resolver = this.orgSegmentSvc.fetchCalculatedFields(entity.id)
        }

        const { ok, data } = await Promise.resolve(resolver)
        if (ok) {
            // set all only on initial load
            if (!this.state.calculatedFields_all?.length) {
                state.calculatedFields_all = data
            }

            const casedData = convertCase(data, 'snake')

            let filteredFields
            if (this.isDomainLevel) {
                filteredFields = casedData
            } else {
                /**
                 * Org segments should only show unique calculated fields across all selected domains
                 *
                 *   1. reduce to only unique (name + expression) combinations
                 */
                const uqSharedFields: {
                    name: string
                    expression: string
                    domainIds: number[]
                    lastCalculatedAt?: string | null
                }[] = []

                for (const field of casedData) {
                    if (!this.state.selectedDomainIds.includes(field.domain_id)) {
                        /**
                         * skip any fields not within the current domain selections
                         */
                        continue
                    }

                    const fieldLastCalculatedAt = field.lastCalculatedAt ?? field.last_calculated_at

                    let sharedField = uqSharedFields.find(
                        (f) => f.name === field.name && f.expression === field.expression,
                    )
                    if (!sharedField) {
                        sharedField = {
                            name: field.name,
                            expression: field.expression,
                            lastCalculatedAt: fieldLastCalculatedAt,
                            domainIds: [] as number[],
                        }

                        uqSharedFields.push(sharedField)
                    } else if (sharedField.lastCalculatedAt && !fieldLastCalculatedAt) {
                        /**
                         * org calculated fields should be null if any
                         * domain variants do not have a lastCalculatedAt
                         */
                        sharedField.lastCalculatedAt = null
                    }

                    sharedField.domainIds.push(field.domain_id)
                }

                /**
                 * ensure each field.lastCalculatedAt accounts for all selected domain ids
                 * if any ids missing from the field.domainIds then lastCalculatedAt should be null
                 */
                for (const sharedField of uqSharedFields) {
                    const sharedFieldMissingDomainIds = this.state.selectedDomainIds.some(
                        (id) => !sharedField.domainIds.includes(id),
                    )
                    if (sharedFieldMissingDomainIds) {
                        sharedField.lastCalculatedAt = null
                    }
                }

                filteredFields = uqSharedFields
            }

            state.calculatedFields = filteredFields
        }

        state.calculatedFieldsLoaded = true
        this.setState(state)
    }

    protected async loadKeywords(entity: AccountDto | DomainDto, softReload: boolean = false) {
        const state: any = { keywordsLoaded: false }
        this.setState({ ...state })

        let resolver
        if (this.isDomainLevel) {
            resolver = this.domainSvc.fetchKeywordsByDomainId(entity.id, {
                query: { pagination: 0 },
            })
        } else if (softReload) {
            resolver = { ok: true, data: this.state.keywords_all }
        } else {
            resolver = this.orgSvc.fetchKeywords(entity.id, {
                query: { pagination: 0 },
            })
        }

        const { ok, data } = await resolver
        if (ok && data) {
            // set all only on initial load
            if (!this.state.keywords_all?.length) {
                state.keywords_all = data
            }

            state.keywords = data.map((kw) => kw.name)
        }

        state.keywordsLoaded = true
        this.setState(state)
    }

    protected async loadSubscriberPreferences(entity: AccountDto | DomainDto, softReload: boolean = false) {
        let resolver
        if (this.isDomainLevel) {
            resolver = this.domainSvc
                .fetchSubscriberPreferencesByDomainId(entity.id)
                .then((preferences) => ({ ok: true, data: preferences }))
        } else if (softReload) {
            resolver = { ok: true, data: this.state.subscriberPreferences_all }
        } else {
            resolver = this.orgSvc.fetchSubscriberPreferences(entity.id)
        }

        const state: any = { canShowSubscriberPreferences: false }

        const { ok, data } = await Promise.resolve(resolver)
        if (ok && data) {
            // set all only on initial load
            if (!this.state.subscriberPreferences_all?.length) {
                state.subscriberPreferences_all = data
            }

            const casedData = convertCase(data ?? [], 'snake')

            const filteredPreferenceData = casedData.filter((d) => this.currentSelectedDomainIds.includes(d.domain_id))
            const filteredPreferences = filteredPreferenceData.map((d) => d.preference)

            if (this.isDomainLevel) {
                state.subscriberPreferences = filteredPreferences
            } else {
                /**
                 * Org segments should only show unique preference values across all selected domains
                 *
                 *   1. reduce to only unique field values
                 */
                state.subscriberPreferences = Array.from(new Set(filteredPreferences))
            }

            state.canShowSubscriberPreferences = state.subscriberPreferences.length > 0
        }

        this.setState(state)
    }

    protected async loadSegmentFields(entity: AccountDto | DomainDto, softReload: boolean = false) {
        const state: any = { segmentFieldsLoaded: false }
        this.setState({ ...state })

        state.segmentFieldsLoaded = true

        let segmentFields: any[] = []
        if (entity instanceof DomainDto) {
            const { ok, data } = await this.segmentSvc.fetchSegmentFieldsByDomainId(entity.id)
            if (ok && data) {
                state.segmentFields_all = data
                segmentFields = data
            }
        } else {
            let resolver
            if (softReload) {
                resolver = { ok: true, data: this.state.segmentFields_all }
            } else {
                resolver = this.orgSegmentSvc.fetchSegmentFields(entity.id)
            }

            const { ok, data } = await Promise.resolve(resolver)
            if (ok && data) {
                // set all only on initial load
                if (!this.state.segmentFields_all?.length) {
                    state.segmentFields_all = data
                }

                const currDomainIds = this.state.selectedDomainIds
                const relevantData = data.filter((domain) =>
                    currDomainIds.includes(domain.domain_id ?? domain.domainId),
                )
                const domainSeparatorRgx = new RegExp(`\\.(${currDomainIds.join('|')})_`)

                /**
                 * Org segments should only show unqiue fields across all selected domains
                 *
                 *   1. gather all fields across domains
                 *   2. reduce to only unique field values - excludes domain separator
                 */
                const allFieldKeys = flatMap<any, string>(relevantData, ({ fields }) => fields.map(({ key }) => key))
                const uqFieldKeys = Array.from(
                    new Set(allFieldKeys.map((field) => field.replace(domainSeparatorRgx, '.'))),
                )

                /**
                 * Once fields have been reduced to only shared items they are
                 * rebuilt from the common properties.
                 *
                 * If a field has suggestions they are also reduced to unique suggestions.
                 */
                segmentFields = uqFieldKeys
                    .map((key) => {
                        const matches = flatMap<any, any>(relevantData, (domain) =>
                            domain.fields.filter((f) => f.key.replace(domainSeparatorRgx, '.') === key),
                        )

                        let field: any
                        if (matches.length) {
                            // set initial common config
                            field = {
                                type: matches[0].type,
                                key,
                                name: matches[0].name,
                            }

                            // gather and filter suggestions
                            const suggestions = flatMap<any, string>(matches, (f) => f.suggestions)
                            const uqSuggestions = Array.from(new Set(suggestions))
                            const suggestionsIsUndef = uqSuggestions.length === 1 && uqSuggestions[0] === undefined
                            if (uqSuggestions.length > 0 && !suggestionsIsUndef) {
                                // ensure only unique shared items across domain fields
                                field.suggestions = uqSuggestions
                            }

                            // check and assign typeahead value
                            const typeaheadMap = flatMap<any, boolean>(matches, (f) => f.typeahead)
                            const hasTypeahead = typeaheadMap.find((v) => v !== undefined)
                            if (hasTypeahead !== undefined) {
                                field.typeahead = typeaheadMap.includes(true)
                            }
                        }

                        return field
                    })
                    .filter((field) => !!field)
            }
        }

        if (segmentFields.length > 0) {
            state.segmentFields = segmentFields
        }

        this.setState(state)
    }

    protected async loadPageCategories(entity: AccountDto | DomainDto, softReload: boolean = false) {
        const state: any = { pageCategoriesLoaded: false }
        this.setState({ ...state })

        let resolver
        if (softReload) {
            resolver = { ok: true, data: this.state.pageCategories_all }
        } else {
            resolver = this.pageCategoriesSvc.fetchAll({
                query: {
                    pagination: 0,
                    domainIds: this.currentUserDomainIds,
                },
            })
        }

        const { ok, data } = await Promise.resolve(resolver)
        if (ok) {
            // set all only on initial load
            if (!this.state.pageCategories_all?.length) {
                state.pageCategories_all = data
            }

            const casedData = convertCase(data, 'snake')
            if (this.isDomainLevel) {
                state.pageCategories = casedData
            } else {
                // page categories at the org level should only display global categories
                state.pageCategories = casedData.filter((d) => !d.domain_id)
            }
        }

        state.pageCategoriesLoaded = true
        this.setState(state)
    }

    protected async loadPageCategorySubscriberCounts(entity: AccountDto | DomainDto, softReload: boolean = false) {
        const state: any = { pageCategorySubscriberCountsLoaded: false }
        this.setState({ ...state })

        let resolver
        if (softReload) {
            resolver = { ok: true, data: this.state.pageCategorySubscriberCounts_all }
        } else {
            resolver = this.pageCategoriesSvc.getCounts({
                query: {
                    domainIds: this.currentUserDomainIds,
                    expandDomains: 1,
                },
            })
        }

        const { ok, data } = await Promise.resolve(resolver)
        if (ok) {
            // set all only on initial load
            if (!this.state.pageCategorySubscriberCounts_all?.length) {
                state.pageCategorySubscriberCounts_all = data
            }

            const casedData = convertCase(data, 'snake')
            state.pageCategorySubscriberCounts = casedData
        }

        state.pageCategorySubscriberCountsLoaded = true
        this.setState(state)
    }

    protected async updateReachEstimate(): Promise<void> {
        const segment = this.state.segment
        if (!segment) {
            return
        }

        let filters = segment.getFiltersJson()
        if (this.ruleBuilderRef) {
            filters = this.ruleBuilderRef.getCleanDocument(filters)
        }
        const targetedChannels = segment.getChannels()

        const sameQuery =
            this.state.reachEstimatePreviousQuery && deepEqual(filters, this.state.reachEstimatePreviousQuery)
        const sameDomainIds = deepEqual(this.currentSelectedDomainIds, this.state.reachEstimatePreviousDomainIds ?? [])

        if (!filters || Object.keys(filters).length === 0) {
            if (this.state.reachEstimate === undefined) {
                // ensure default 0 audience size on new segment
                this.setState({ reachEstimate: { total: 0 } })
            }

            // empty query - do not fetch
            return
        } else if (sameQuery && sameDomainIds) {
            // no query changes - do not fetch
            return
        }

        const state: any = { reachEstimateLoaded: false }
        this.setState({ ...state })

        state.reachEstimateLoaded = true
        state.reachEstimate = { total: 0 }
        state.reachEstimatePreviousQuery = { filters, targetedChannels }
        state.reachEstimatePreviousDomainIds = this.currentSelectedDomainIds

        if (this.reachEstimateTimer) {
            clearTimeout(this.reachEstimateTimer)
        }

        this.reachEstimateTimer = setTimeout(async () => {
            if (isBuilderSegmentValid(segment)) {
                const query: any = { filters }

                if (this.isDomainLevel) {
                    query.domainId = this.state.domain.id
                    if (segment.getId()) {
                        query.originSegmentId = segment.getId()
                    }

                    const res = await this.segmentSvc.estimateReachByQuery(
                        query.domainId,
                        query,
                        targetedChannels,
                        'count_by_channel',
                    )
                    let reachEstimate: any = { total: 0 }

                    if (typeof res.data === 'number') {
                        reachEstimate.total = res.data
                    } else {
                        for (const channel in res.data) {
                            if (channel) {
                                reachEstimate.total += res.data[channel]
                                reachEstimate = { ...reachEstimate, ...res.data }
                            }
                        }
                    }

                    state.reachEstimate = reachEstimate
                } else {
                    const res = await this.batchSvc.batch({
                        requests: this.currentSelectedDomainIds.map((domainId) =>
                            this.buildBatchedReachQueryRequest(domainId, query, targetedChannels),
                        ),
                    })

                    if (res.ok && res.data) {
                        state.reachEstimateBreakdown = {}

                        const estimates: number[] = []
                        for (const batchRes of res.data) {
                            const domainId = batchRes.meta.domain_id ?? batchRes.meta.domainId
                            let estimate = 0
                            if (batchRes.code >= 200 && batchRes.code < 300) {
                                const { data } = JSON.parse(batchRes.body)
                                estimate = data?.reach ?? 0
                            }

                            estimates.push(estimate)
                            state.reachEstimateBreakdown[domainId] = estimate
                        }

                        state.reachEstimate = { total: estimates.reduce((r, v) => (r += v), 0) }
                    }
                }
            }

            this.setState(state)
        }, 320)
    }

    protected buildBatchedReachQueryRequest = (
        domainId: number,
        query: any,
        targetedChannels: string[] = [],
    ): INestedBatchRequest => ({
        meta: { domainId },
        method: 'POST',
        relative_path: `/domains/${domainId}/segments/estimate-reach`,
        body: {
            query: {
                ...query,
                domainId,
            },
            targetedChannels,
            returnType: 'count',
        },
    })

    protected goBackToSegmentsList() {
        this.appSvc.routeWithin(this.props.level, '/segments')
    }
}

export default SegmentBuilder
