import * as React from 'react'
import axios, { CancelTokenSource } from 'axios'
import './input-page-visited.scss'
import { IRuleBuilderField } from './rule-builder'
import { BetterComponent } from '../better-component/better-component'
import { ChainSelect, IChainOption } from '../chain-select/chain-select-component'
import autobind from 'autobind-decorator'
import { Tooltip, Select, InputNumber as AntInputNumber, Empty } from 'antd'
import { InfoCircleOutlined, Loading3QuartersOutlined, SearchOutlined } from '@ant-design/icons'
import { TypeaheadTransform } from '../rule-builder-v2/inputs/typeahead/typeahead'
import { IApiResponsePaginationLinks } from '../../interfaces/api-response-pagination-links'
import { stripUndefined } from '../../_utils/strip-undefined'
import * as randomstring from 'randomstring'
import { NoTranslate } from '../no-translate/no-translate'

const TYPEAHEAD_DEBOUNCE_TIME = 300
const UNIQUE_SCROLL_DATA_ID = `data-${randomstring.generate()}`

type PageTagVisitedScope = '7d' | '14d' | '30d' | '60d'

const PageTagVisitedOptions: IChainOption[] = [
    {
        display: 'within the last',
        value: 'prev',
        options: [
            {
                display: '7 Days',
                value: '7d',
            },
            {
                display: '14 Days',
                value: '14d',
            },
            {
                display: '30 Days',
                value: '30d',
            },
            {
                display: '60 Days',
                value: '60d',
            },
            {
                display: '90 Days',
                value: '90d',
            },
        ],
    },
]

const operatorOptions = [
    {
        display: 'at least',
        value: 'gte',
    },
    {
        display: 'exactly',
        value: 'eq',
    },
    {
        display: 'at most',
        value: 'lte',
    },
]

interface IPageTagVisitedProps {
    mode: 'edit' | 'display'
    field: IRuleBuilderField
    value: any
    operator?: string
    onChange: any
    loading?: boolean
    pageTags?: string[]
    typeaheadURL?: string
    typeaheadTransform?: TypeaheadTransform
}

interface IPageTagVisitedState {
    field: string
    value: number
    operator: string
    meta: any

    pageTags_activeRequest?: CancelTokenSource

    // typeahead (search page-tags)
    typeahead_debounce?: any
    typeahead_pageTags?: string[]
    typeahead_pageTagsNextLink?: string | null
    typeahead_lastValue?: string

    // base infinite-scroll (non-search page tags)
    all_pageTags?: string[]
    all_pageTagsNextLink?: string | null

    activeWheelHandler?: any
}

export class InputPageTagVisited extends BetterComponent<IPageTagVisitedProps, IPageTagVisitedState> {
    private inputRef: any
    private scrollWindowRef: HTMLElement

    public constructor(props: IPageTagVisitedProps) {
        super(props)
    }

    public async UNSAFE_componentWillReceiveProps(prevProps: any) {
        if (this.props.mode === 'display') {
            if (
                prevProps.value.value !== this.state.value ||
                prevProps.operator !== this.state.operator ||
                prevProps.value.meta.scope !== this.state.meta.scope ||
                prevProps.value.meta.expression !== this.state.meta.expression
            ) {
                await this.initializeStateWithProps()
            }
        }
    }

    public async UNSAFE_componentWillMount() {
        await this.initializeStateWithProps()
        this.scrollPageTags()
    }

    public componentDidMount(): void {
        this.autofocus()
    }

    public componentWillUnmount() {
        super.componentWillUnmount()
        this.removeEventListeners()
    }

    public render() {
        return this.props.mode === 'edit' ? (
            <div className="input-page-visited">
                <div className="g">
                    <div className="g-label">equals</div>

                    <Select<string>
                        ref={(el) => (this.inputRef = el)}
                        className="value-edit value-select"
                        showSearch={true}
                        placeholder="Select a Page Tag"
                        loading={this.isLoading}
                        value={this.state.meta.expression}
                        allowClear={true}
                        onClear={this.clearTypeaheadState}
                        onChange={this.onExpressionChange}
                        onSearch={this.handlePageTagSearch}
                        onPopupScroll={this.handlePageTagsScroll}
                        onFocus={() => {
                            if (
                                this.state.meta.expression &&
                                this.state.meta.expression !== this.state.typeahead_lastValue
                            ) {
                                this.handlePageTagSearch(this.state.meta.expression, { bypassDebounce: true })
                            }
                        }}
                        notFoundContent={
                            this.isLoading ? (
                                <Empty
                                    className="ant-empty-normal ant-empty-small"
                                    image={<Loading3QuartersOutlined spin={true} />}
                                    imageStyle={{ height: 35, lineHeight: '35px', fontSize: 30 }}
                                    description="Searching"
                                />
                            ) : undefined
                        }
                    >
                        {this.getSelectOptions().map((pt) => (
                            <Select.Option key={pt} value={pt}>
                                <NoTranslate>{pt}</NoTranslate>
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div className="g">
                    <div className="g-label">was visited</div>

                    <Select className="operator-select" onChange={this.onOperatorChange} value={this.state.operator}>
                        {this.renderOperatorOptions(operatorOptions)}
                    </Select>

                    <AntInputNumber
                        onChange={this.onValueChange}
                        value={this.state.value}
                        min={0}
                        max={999}
                        type="number"
                    />

                    <div>time(s)</div>
                </div>

                <div className="g">
                    <ChainSelect
                        mode={this.props.mode}
                        value={this.state.meta.scope}
                        options={PageTagVisitedOptions}
                        onChange={this.onScopeChange}
                    />

                    <Tooltip title="Time frame does not include page visits from the current day.">
                        <span className="info-icon">
                            <InfoCircleOutlined />
                        </span>
                    </Tooltip>
                </div>
            </div>
        ) : (
            <div className="input-notification-clicks">
                <span className="highlight">equals</span>
                {'\u00A0' /* nbps */}
                <span>
                    “<NoTranslate>{this.state.meta.expression}</NoTranslate>”
                </span>
                {'\u00A0' /* nbps */}
                <span className="highlight">was visited {this.getOperatorDisplay()}</span>
                {'\u00A0' /* nbps */}
                <span>{this.state.value} time(s)</span>
                {'\u00A0' /* nbps */}
                <span className="highlight">
                    <ChainSelect
                        mode={this.props.mode}
                        value={this.state.meta.scope}
                        options={PageTagVisitedOptions}
                        onChange={this.onScopeChange}
                    />
                </span>

                <Tooltip title="Time frame does not include page visits from the current day.">
                    <span className="info-icon">
                        <InfoCircleOutlined />
                    </span>
                </Tooltip>
            </div>
        )
    }

    public get isLoading() {
        return this.props.loading ?? (!!this.state.typeahead_debounce || !!this.state.pageTags_activeRequest)
    }

    public renderOperatorOptions(options: any[]): React.ReactFragment[] {
        return options.map((o) => (
            <Select.Option key={o.value} value={o.value}>
                {o.display}
            </Select.Option>
        ))
    }

    private getSelectOptions() {
        return this.state.typeahead_pageTags ?? this.state.all_pageTags ?? this.props.pageTags ?? []
    }

    private async initializeStateWithProps() {
        const propsValue = this.props.value ? this.props.value : ({} as any)

        await this.setState({
            operator: this.props.operator || 'gte',
            value: propsValue.value !== undefined ? propsValue.value : 1,
            meta: propsValue.meta || {},
        })
    }

    private getOperatorDisplay(): string {
        if (this.state.operator) {
            return operatorOptions.filter((o) => o.value === this.state.operator)[0].display
        }
        return ''
    }

    private autofocus(): void {
        if (!!this.inputRef) {
            this.inputRef.focus()
        }
    }

    @autobind
    private async onOperatorChange(operator: string) {
        await this.setState({
            operator,
        })

        this.emitChangeEvent()
    }

    @autobind
    private async onExpressionChange(value: string) {
        await this.setState({
            meta: {
                scope: this.state.meta.scope,
                expression: value,
            },
        })

        this.emitChangeEvent()
    }

    @autobind
    private async onScopeChange(value: PageTagVisitedScope) {
        await this.setState({
            meta: {
                scope: value,
                expression: this.state.meta.expression,
            },
        })

        this.emitChangeEvent()
    }

    @autobind
    private async onValueChange(value: any) {
        await this.setState({
            value,
        })

        this.emitChangeEvent()
    }

    private emitChangeEvent() {
        this.props.onChange({
            field: this.props.field.property,
            operator: this.state.operator,
            value: this.state.value,
            meta: this.state.meta,
        })
    }

    /**
     * Infinite Scroll & Typeahead Methods
     */

    private removeEventListeners() {
        // see setActiveWheelHandler declarartion
        if (this.scrollWindowRef) {
            this.scrollWindowRef.removeEventListener('wheel', this.setActiveWheelHandler)
        }
    }

    private clearTypeaheadState = () => {
        this.setState({
            // reset typeahead_pageTags values
            typeahead_debounce: undefined,
            typeahead_lastValue: undefined,
            typeahead_pageTags: undefined,
            typeahead_pageTagsNextLink: null,
        })
    }

    private handlePageTagSearch = async (value: string, options?: { bypassDebounce?: boolean }) => {
        clearTimeout(this.state.typeahead_debounce)

        if (!value.trim()) {
            this.clearTypeaheadState()
            return
        }

        /**
         * if all_pageTagsNextLink is empty there is no
         * more data to be fetched from the API and the
         * default local search should be used instead
         */
        if (this.state.all_pageTagsNextLink) {
            this.setState({
                typeahead_debounce: setTimeout(() => this.searchPagTags(value), options?.bypassDebounce ? 0 : 320),
            })
        }
    }

    /**
     * antd select can trigger a scroll event when
     * when typing filters the results window.
     *
     * as we only want to a capture true wheel events
     * we use an associated event validation to verify
     * that a triggered scroll event on the antd component
     * happened within a reasonable window with the associated
     * true scroll event.
     */
    private setActiveWheelHandler = (ev: WheelEvent | React.KeyboardEvent<HTMLInputElement>) => {
        if (this.state.activeWheelHandler) {
            clearInterval(this.state.activeWheelHandler)
        }

        const isDownScrollEvent = 'deltaY' in ev && ev.deltaY > 0
        const isArrowDownEvent = 'key' in ev && ev.key === 'ArrowDown'

        if (isDownScrollEvent || isArrowDownEvent) {
            this.setState({
                activeWheelHandler: setInterval(() => {
                    clearInterval(this.state.activeWheelHandler)
                }, 5),
            })
        }
    }

    private handlePageTagsScroll = async (ev: any) => {
        // see setActiveWheelHandler declaration
        if (!this.scrollWindowRef) {
            this.scrollWindowRef = ev.target
            this.scrollWindowRef.addEventListener('wheel', this.setActiveWheelHandler)
        }

        if (this.state.activeWheelHandler) {
            const { height: listHeight } = ev.target.getBoundingClientRect()
            const scrollHeight = ev.target.scrollHeight
            const scrollTop = ev.target.scrollTop

            const depthTillEnd = scrollHeight - scrollTop - listHeight
            if (depthTillEnd <= 500 && !this.state.pageTags_activeRequest) {
                // handle typeahead mode scrolling
                if (this.state.typeahead_lastValue) {
                    if (this.state.typeahead_pageTagsNextLink) {
                        this.searchPagTags(this.state.typeahead_lastValue, true)
                    }
                }
                // handle non-search infinite scroll
                else if (this.state.all_pageTagsNextLink) {
                    this.scrollPageTags()
                }
            }
        }
    }

    private async searchPagTags(value: string, isScrollEvent?: boolean) {
        if (this.props.typeaheadURL) {
            const update = {
                typeahead_debounce: undefined,
                typeahead_lastValue: value,
                typeahead_pageTags: [],
            }

            await this.fetchPagTags({
                path: isScrollEvent ? this.state.typeahead_pageTagsNextLink : undefined,
                searchTerm: value,
                onComplete: (error, data, links) => {
                    if (error) {
                        this.setState(update)
                    } else {
                        this.setState(({ typeahead_pageTags }) => ({
                            ...update,
                            typeahead_pageTags: isScrollEvent ? [...(typeahead_pageTags ?? []), ...data] : data,
                            typeahead_pageTagsNextLink: links.next,
                        }))
                    }
                },
            })
        }
    }

    private async scrollPageTags() {
        // reset typeahead_pageTags values on error/completion
        const update = {
            typeahead_debounce: undefined,
            typeahead_lastValue: undefined,
            typeahead_pageTags: undefined,
            typeahead_pageTagsNextLink: null,
        }

        await this.fetchPagTags({
            path: this.state.all_pageTagsNextLink,
            onComplete: (error, data, links) => {
                if (error) {
                    this.setState(update)
                } else {
                    this.setState(({ all_pageTags }) => ({
                        ...update,
                        all_pageTags: [...(all_pageTags ?? []), ...data],
                        all_pageTagsNextLink: links.next,
                    }))
                }
            },
        })
    }

    private async fetchPagTags(config: {
        path?: string | null
        searchTerm?: string
        onComplete: (error: Error | null, data: string[], links: IApiResponsePaginationLinks) => void
    }) {
        let data: any[] = []
        let links: any = {}
        let error: Error | null = null
        let shouldClearActiveRequest = false

        if (this.props.typeaheadURL) {
            const isSearchTriggered = config.searchTerm !== undefined && config.searchTerm !== null

            if (this.state.pageTags_activeRequest) {
                this.state.pageTags_activeRequest.cancel()
            }

            // initialize new active request ref
            const pageTagsActiveRequest = axios.CancelToken.source()
            await this.setState({
                pageTags_activeRequest: pageTagsActiveRequest,
            })

            try {
                const serviceURL = config.path ?? this.props.typeaheadURL

                const { data: resData } = await axios.get(serviceURL, {
                    cancelToken: pageTagsActiveRequest.token,
                    params: config.path
                        ? undefined
                        : stripUndefined({
                              page: 1,
                              limit: 250, // max currently allowed via sailfish pagination
                              search: isSearchTriggered ? config.searchTerm : undefined,
                          }),
                })

                data = resData.data
                links = resData.links ?? {}

                if (data && this.props.typeaheadTransform) {
                    data = this.props.typeaheadTransform(data)
                }

                shouldClearActiveRequest = true
            } catch (err) {
                if (!axios.isCancel(err)) {
                    error = err
                    shouldClearActiveRequest = true
                }
            }
        }

        // active request should only be removed on success or non-cancellation
        if (shouldClearActiveRequest) {
            this.setState({ pageTags_activeRequest: undefined })
        }

        config.onComplete(error, data, links)
    }
}
