import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export const reduceStatTotalsByChannel = (breakdownStats: any[]) =>
    breakdownStats?.reduce(
        (prev, curr) => {
            const channel = curr.channel
            if (channel) {
                prev[channel].impressions += curr.impressions
                prev[channel].clicks += curr.clicks
            }
            return prev
        },
        {
            [DeliveryChannel.WEB]: { impressions: 0, clicks: 0 },
            [DeliveryChannel.NATIVE_IOS]: { impressions: 0, clicks: 0 },
            [DeliveryChannel.NATIVE_ANDROID]: { impressions: 0, clicks: 0 },
        },
    )
