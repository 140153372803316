import * as React from 'react'
import './notification-details.scss'
import { observer } from 'mobx-react'
import { observe } from 'mobx'
import * as moment from 'moment-timezone'
import * as numeral from 'numeral'
import { ClockCircleFilled, ExclamationCircleOutlined, InfoCircleOutlined, RetweetOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Popover, Row, Select, Skeleton, Tag, Tooltip } from 'antd'
import { Well } from '../../../components/well/well'
import { AppService, NotificationService } from '../../../services'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { DomainDto } from '../../../dtos/domain'
import { StatusType } from '../../../enums/status-type'
import {
    arrayContains,
    backfillStats,
    numberWithCommas,
    numberWithPercent,
    preventBubbling,
    titleCase,
} from '../../../_utils/utils'
import {
    BASE_TIME_FORMAT,
    BASE_TIME_FORMAT_WITHOUT_TZ,
    DEFAULT_HIGHCHARTS_CONFIG,
    HIGHCHARTS_CHANNEL_COLOR_MAP,
    INSIGHTS_ANDROID_COLOR,
    INSIGHTS_IOS_COLOR,
    INSIGHTS_WEB_COLOR,
} from '../../../constants'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { SegmentDto } from '../../../dtos/segment'
import { NotificationSummaryCard, PageHeader } from '@pushly/aqe/lib/components'
import { BaseNotificationDetails, IBaseNotificationDetailsState } from './base-notification-details-component'
import { NotificationDto } from '../'
import { InsightsService } from '../../../services/insights'
import { NotificationSilentBadge } from '../../../components/badges/notification-silent-badge'
import { NotificationStzBadge } from '../../../components/badges/notification-stz-badge'
import { NotificationAbBadge } from '../../../components/badges/notification-ab-badge'
import { NotificationAbWinnerBadge } from '../../../components/badges/notification-ab-winner-badge'
import { NotificationDeliveryWindow } from '../../../enums/notification-delivery-window'
import { NotificationDeliveryType } from '../../../enums/notification-delivery-type'
import { generateShortID } from '../../../components/campaign-builder/helpers/uid'
import { MultiDomainBadge } from '../../../components/badges/multi-domain-badge'
import { NotificationImageBadge } from '../../../components/badges/notification-image-badge'
import { AbilityAction } from '../../../enums/ability-action.enum'
import { SubjectEntity } from '../../../enums/ability-entity.enum'
import { extractSummaryNotification } from '../../../_utils/notifications'
import { WebBadge } from '../../../components/badges/web-badge'
import { NativeIosBadge } from '../../../components/badges/native-ios-badge'
import { NativeAndroidBadge } from '../../../components/badges/native-android-badge'
import { ApiVersion } from '../../../enums/api-version.enum'
import { NoTranslate } from '../../../components/no-translate/no-translate'
import { NotificationHoldOutBadge } from '../../../components/badges/notification-hold-out-badge'
import { HighchartsUtils } from '../../../_utils/highcharts-utils'
import { onResponseError403 } from '../../../_utils/on-response-error-403'
import { reduceStatTotalsByChannel } from './helpers'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { DeliveryChannelSelector } from '../../../components/delivery-channel-selector/delivery-channel-selector'
import { NotificationSource } from '../../../enums/notification-source'

Highcharts.setOptions({ lang: { thousandsSep: ',' } })

type ChartType = 'impressions' | 'clicks'

interface IHeaderProps {
    domain: DomainDto
    notification?: NotificationDto
}

class NotificationDetailsHeader extends React.Component<IHeaderProps, {}> {
    protected appState: AppState
    protected appService: AppService

    private unmounting: boolean = false

    public constructor(props: IHeaderProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public componentWillUnmount() {
        this.unmounting = true
    }

    public render(): React.ReactNode {
        return (
            <div className="details-header">
                <Skeleton title={false} paragraph={{ rows: 5 }} loading={!this.notif} active={true}>
                    <div className="notif-title">
                        <PageHeader
                            title={<NoTranslate>{this.notif.defaultTemplate.title}</NoTranslate>}
                            append={
                                <div className="notif-id">
                                    <span>
                                        #{this.notif.id}
                                        <span>
                                            {' '}
                                            | Created by <NoTranslate>{this.notif.createdByUserName}</NoTranslate>&nbsp;
                                        </span>
                                    </span>

                                    {this.buildOptions()}
                                </div>
                            }
                            onTitleSet={this.appService.customizeTabTitle}
                        />
                        <div className="append">
                            {this.notif.source !== NotificationSource.CAMPAIGN && (
                                <div className="notif-dates">{this.buildFlightDates()}</div>
                            )}
                            <div className="notif-strategy">{this.buildStrategy()}</div>

                            {this.notif.source !== NotificationSource.CAMPAIGN && (
                                <div className="notif-audience">{this.buildSegments()}</div>
                            )}
                        </div>
                    </div>
                </Skeleton>
            </div>
        )
    }

    protected get notif(): NotificationDto {
        return this.props.notification!
    }

    protected buildFlightDates(): React.ReactNode {
        const now = moment()
        const sendDate = moment.tz(this.notif.deliverySpec.sendDateUtc, this.props.domain.timezone)

        const isSTZ = this.notif.isTzDelivery()
        const sendDateFormat = isSTZ ? `${BASE_TIME_FORMAT_WITHOUT_TZ} [STZ]` : BASE_TIME_FORMAT

        const startDate = moment.tz(this.notif.deliverySpec.sendDateUtc, this.props.domain.timezone)
        const expiryDate = moment
            .tz(this.notif.deliverySpec.sendDateUtc, this.props.domain.timezone)
            .add(this.notif.deliverySpec.ttlSeconds, 'seconds')

        const flightDateStart = startDate.format(sendDateFormat)
        const flightDateEnd = expiryDate.format(sendDateFormat)
        const deliveryVerb = sendDate > now ? 'Delivers' : expiryDate > now ? 'Delivering' : 'Delivered'

        return (
            <span>
                <b>Delivery Date Range</b> {flightDateStart} through {flightDateEnd}
            </span>
        )
    }

    protected buildStrategy() {
        const type = this.notif.deliverySpec.type
        const window = this.notif.deliverySpec.window
        let strategyStr: any = ''

        if (this.notif.source === NotificationSource.CAMPAIGN) {
            strategyStr = 'campaign schedule'
        } else if (window === NotificationDeliveryWindow.HOLD_OUT) {
            strategyStr = 'hold out'
        } else if (window === NotificationDeliveryWindow.INFORMED) {
            strategyStr = 'informed'
        } else if (type === NotificationDeliveryType.IMMEDIATE) {
            strategyStr = 'immediate'
        } else if (window === NotificationDeliveryWindow.STANDARD) {
            strategyStr = 'fixed'
        } else if (window === NotificationDeliveryWindow.TIMEZONE) {
            strategyStr = 'subscriber time zone'
        }

        return (
            <span className="delivery-strategy">
                <b>Delivery Strategy</b> {titleCase(strategyStr)}
            </span>
        )
    }

    protected buildSegments(): React.ReactNode {
        let subscribers: React.ReactNode
        let excludedSubscribers: React.ReactNode
        const allSubscriberTag: React.ReactNode = (
            <a key="all-subs" className="no-click">
                <Tag>All Subscribers</Tag>
            </a>
        )
        if (this.notif.audience.segments) {
            let subscriberTags: React.ReactNode[] = [allSubscriberTag]
            const isAllSubscribers = this.notif.audience.segments.find((s: SegmentDto) => s.isDefault)

            if (!isAllSubscribers) {
                subscriberTags = this.notif.audience.segments
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((segment: any, idx: number) => (
                        <a
                            key={`i-${idx}`}
                            onClick={this.jumpToSegment.bind(this, segment)}
                            href={this.buildSegmentUrl(segment)}
                        >
                            <Tag>{segment.name}</Tag>
                        </a>
                    ))
            }

            subscribers = subscriberTags
        } else if (this.notif.audience.externalSubscriberIds) {
            subscribers = <Tag>Subscriber IDs</Tag>
        }

        if (this.notif.audience.excludedSegments && this.notif.audience.excludedSegments.length > 0) {
            excludedSubscribers = this.notif.audience.excludedSegments
                .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                .map((segment: any, idx: number) => (
                    <a
                        key={`x-${idx}`}
                        onClick={this.jumpToSegment.bind(this, segment)}
                        href={this.buildSegmentUrl(segment)}
                    >
                        <Tag>{segment.name}</Tag>
                    </a>
                ))
        }

        return (
            <>
                <div className="audience-section">
                    <div>
                        <b>Included Segments</b>
                    </div>
                    <div>{subscribers}</div>
                </div>
                {!!excludedSubscribers ? (
                    <div className="audience-section">
                        <div>
                            <b>Excluded Segments</b>
                        </div>
                        <div>{excludedSubscribers}</div>
                    </div>
                ) : (
                    ''
                )}
            </>
        )
    }

    protected buildOptions(): React.ReactNode {
        const options: any[] = []
        const isMultiDomain = this.props.notification?.notificationGroup?.isMultiDomain ?? false
        const showMultiDomainBadge =
            isMultiDomain &&
            this.appState.abilityStore.can(
                AbilityAction.READ,
                this.appState.abilityStore.getOrgOwnedIdentityFor(SubjectEntity.ORG_NOTIFICATION),
            )

        if (showMultiDomainBadge) {
            options.push(
                <span key="badge-multi-domain" className="option">
                    <MultiDomainBadge entityLabel="Notification" />
                </span>,
            )
        }

        if (this.notif.defaultTemplate.imageUrl) {
            options.push(
                <span key="badge-image" className="option">
                    <NotificationImageBadge />
                </span>,
            )
        }

        if (this.notif.defaultTemplate.isSilent) {
            options.push(
                <span key="badge-silent" className="option">
                    <NotificationSilentBadge />
                </span>,
            )
        }

        if (this.notif.isTzDelivery()) {
            options.push(
                <span key="badge-stz" className="option">
                    <NotificationStzBadge />
                </span>,
            )
        }

        if (!!this.notif.abTest) {
            options.push(
                <span key="badge-ab" className="option">
                    <a href={this.buildAbTestUrl(this.notif)}>
                        <NotificationAbBadge tooltip={<>Notification Test: {this.notif.abTest.name}</>} />
                    </a>
                </span>,
            )

            if (!!this.notif.abTest.winner) {
                options.push(
                    <span key="badge-ab-winner" className="option">
                        <NotificationAbWinnerBadge />
                    </span>,
                )
            }
        }

        if (this.notif.deliverySpec.window === NotificationDeliveryWindow.HOLD_OUT) {
            options.push(
                <span key="badge-hold-out" className="option">
                    <NotificationHoldOutBadge />
                </span>,
            )
        }

        if (this.notif.defaultTemplate.channels) {
            const channels = this.notif.defaultTemplate.channels
            channels.includes(DeliveryChannel.WEB) &&
                options.push(
                    <span key={DeliveryChannel.WEB} className="option">
                        <WebBadge />
                    </span>,
                )
            channels.includes(DeliveryChannel.NATIVE_IOS) &&
                options.push(
                    <span key={DeliveryChannel.NATIVE_IOS} className="option">
                        <NativeIosBadge />
                    </span>,
                )
            channels.includes(DeliveryChannel.NATIVE_ANDROID) &&
                options.push(
                    <span key={DeliveryChannel.NATIVE_ANDROID} className="option">
                        <NativeAndroidBadge />
                    </span>,
                )
        }

        if (options.length > 0) options.unshift(<span key={generateShortID()}>|</span>)

        return options
    }

    private buildSegmentUrl(segment: any): string {
        return this.appService.routeWithinDomain(`/segments/${segment.id}/summary`, true)
    }

    private buildAbTestUrl(notif: any): string {
        return this.appService.routeWithinDomain(`/notifications/test/${notif.abTest.id}/summary`, true)
    }

    private jumpToSegment = (segment: any, ev?: any): void => {
        if (!ev.metaKey) {
            preventBubbling(ev)
            this.appService.route(this.buildSegmentUrl(segment))
        }
    }
}

interface IProps {}

interface IState extends IBaseNotificationDetailsState {
    loadingSchedule: boolean
    loadingGenStats: boolean
    loadingBreakdownStats: boolean
    loadingActionStats: boolean
    domain: DomainDto
    notification?: NotificationDto
    stats?: any
    actionStats?: any[]
    breakdownStats?: any
    statsType: 'day' | 'lifetime'
    performanceMetric: ChartType
    performanceChannel: DeliveryChannel[]
}

@observer
export class NotificationDetails extends BaseNotificationDetails<IProps, IState> {
    protected appService: AppService
    private appState: AppState
    private notificationService: NotificationService
    private insightsService: InsightsService

    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.notificationService = Container.get(NotificationService)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            loadingSchedule: true,
            loadingGenStats: true,
            loadingBreakdownStats: true,
            loadingActionStats: true,
            domain: this.appState.currentDomain!,
            statsType: 'day',
            tabs: [],
            performanceMetric: 'impressions',
            performanceChannel: [],
        }
    }

    public async componentDidMount() {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        await this.setDomainState()

        const tabs = [
            {
                name: 'summary',
                label: 'Summary',
                render: this.renderSummaryTab,
            },
        ]

        const activeTab = this.determineActiveTab(tabs)
        if (!this.unmounting) {
            await this.setState({
                tabs,
                activeTab,
            })
        }
    }

    public async componentDidUpdate(prevProps, prevState, _) {
        if (prevState.statsType !== this.state.statsType) {
            await this.refreshStats()
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount()
        this.disposeObservers.forEach((f: any) => f())
    }

    public render(): React.ReactNode {
        let optionsWrapper: React.ReactNode
        let subscribers: React.ReactNode
        let excludedSubscribers: React.ReactNode
        const allSubscriberTag: React.ReactNode = (
            <a key="all-subs">
                <Tag color="gray">All Subscribers</Tag>
            </a>
        )

        let dateAppend: React.ReactNode = ''

        if (this.state.notification) {
            const sample = this.state.notification
            const sampleAudience = sample.audience

            const now = moment()
            const sendDate = moment.tz(sample.deliverySpec.sendDateUtc, this.localDomain!.timezone)
            sendDate.add(sample.deliverySpec.ttlSeconds, 'seconds')

            const isSTZ = sample.isTzDelivery()
            const sendDateFormat = isSTZ ? `${BASE_TIME_FORMAT_WITHOUT_TZ} [STZ]` : BASE_TIME_FORMAT

            dateAppend = (
                <span key="expiry">
                    {sendDate > now ? ' | Expires ' : ' | Expired '} {'on ' + sendDate.format(sendDateFormat)}
                </span>
            )

            if (sample.source !== NotificationSource.CAMPAIGN) {
                if (sampleAudience.segments) {
                    let subscriberTags: React.ReactNode[] = [allSubscriberTag]
                    const isAllSubscribers = sampleAudience.segments.find((s: SegmentDto) => s.isDefault)

                    if (!isAllSubscribers) {
                        subscriberTags = sampleAudience.segments.map((segment: any, idx: number) => (
                            <a
                                key={`i-${idx}`}
                                onClick={this.jumpToSegment.bind(this, segment)}
                                href={this.buildSegmentUrl(segment)}
                            >
                                <Tag color="gray">{segment.name}</Tag>
                            </a>
                        ))
                    }

                    subscribers = (
                        <div className="included-segments-header">
                            <span className="heading">Included Segments:</span>
                            <span className="segments">{subscriberTags}</span>
                        </div>
                    )
                } else if (sampleAudience.externalSubscriberIds) {
                    subscribers = <Tag color="gray">Subscriber IDs</Tag>
                }

                if (sampleAudience.excludedSegments && sampleAudience.excludedSegments.length > 0) {
                    excludedSubscribers = (
                        <div className="excluded-segments-header">
                            <span className="heading">Excluded Segments:</span>
                            <span className="segments">
                            {sampleAudience.excludedSegments.map((segment: any, idx: number) => (
                                <a
                                    key={`x-${idx}`}
                                    onClick={this.jumpToSegment.bind(this, segment)}
                                    href={this.buildSegmentUrl(segment)}
                                >
                                    <Tag color="gray">{segment.name}</Tag>
                                </a>
                            ))}
                        </span>
                        </div>
                    )
                }
            }

            const optionsArray: any[] = []
            if (this.state.notification.defaultTemplate.isSilent) {
                optionsArray.push(
                    <span key="silent" className="option">
                        <Tooltip title="Silent">
                            <i className="icon-muted" />
                        </Tooltip>
                    </span>,
                )
            }
            if (this.state.notification.isTzDelivery()) {
                optionsArray.push(
                    <span key="stz" className="option">
                        <Tooltip title="Delivered in Subscriber's Time Zone">
                            <ClockCircleFilled />
                        </Tooltip>
                    </span>,
                )
            }
            if (optionsArray.length > 0) {
                optionsWrapper = (
                    <div className="options-header">
                        <span className="heading">Options:</span>
                        <span className="options">{optionsArray}</span>
                    </div>
                )
            }
        }

        return (
            <div className="notification-details page">
                {!this.state.loadingSchedule && (
                    <React.Fragment>
                        <NotificationDetailsHeader
                            domain={this.appState.currentDomain!}
                            notification={this.state.notification}
                        />

                        {this.renderTabs()}
                    </React.Fragment>
                )}
            </div>
        )
    }

    public renderSummaryTab(): React.ReactNode {
        const summaryNotification = extractSummaryNotification(this.state.notification!)

        return (
            <React.Fragment>
                <div className="outer-well-buttons">
                    <Button className="refresh-list" size="small" onClick={this.refreshStats}>
                        <RetweetOutlined />
                        <span>Refresh</span>
                    </Button>
                </div>

                {this.renderGenericStats()}
                <br />

                <Row gutter={24} className="details-lower-row">
                    <Col span={14}>{this.renderBreakdownStats()}</Col>
                    <Col span={10}>
                        <NotificationSummaryCard
                            mode="dark"
                            loading={this.state.loadingGenStats}
                            domain={this.state.domain}
                            notification={
                                {
                                    ...summaryNotification,
                                    title: <NoTranslate>{summaryNotification.title}</NoTranslate>,
                                    body: <NoTranslate>{summaryNotification.body}</NoTranslate>,
                                    actions: summaryNotification.actions?.map((action) => ({
                                        ...action,
                                        label: <NoTranslate key={action.id}>{action.label}</NoTranslate>,
                                    })),
                                } as any
                            }
                            onTitleClick={this.handleSummaryTitleClick}
                            hideStats={true}
                            channels={this.state.notification?.channels}
                            hideFooter={true}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    private get notificationIdParam(): number {
        return this.injectedProps.match.params.notificationId
    }

    private get isDelivered(): boolean {
        const { notification } = this.state
        const completedStatuses = [StatusType.COMPLETED.name, StatusType.COMPLETED_WITH_FAILURES.name]
        return !!notification &&
            (
                arrayContains(completedStatuses, notification.status) ||
                this.isPartialFailure ||
                notification.source === NotificationSource.CAMPAIGN
            )
    }

    private get isPartialFailure(): boolean {
        const { notification, stats } = this.state
        const hasStats = !!stats && stats.impressions > 0
        return !!notification && notification.status === StatusType.FAILED.name && hasStats
    }

    private renderBreakdownStats(): React.ReactNode {
        const channelOptions = this.state.notification?.channels ?? []

        return (
            <Well
                className="thin primary-stats-well"
                showHeader={false}
                showFooter={false}
                loading={this.state.loadingBreakdownStats}
                disabled={this.isDisabled}
            >
                <div className="chart-header">
                    <div className="chart-title">Performance Breakdown by Channel</div>
                    <div className="chart-actions">
                        <Select
                            className="stats-type-select"
                            size="small"
                            value={this.state.statsType}
                            onChange={(val) => {
                                this.setState({ statsType: val })
                            }}
                        >
                            <Select.Option value="day">Daily</Select.Option>
                            <Select.Option value="lifetime">All Time</Select.Option>
                        </Select>
                        <div className="channel-selectors">
                            <DeliveryChannelSelector
                                type="multiple"
                                nested={true}
                                onChange={(change: DeliveryChannel[]) => {
                                    this.setState({ performanceChannel: change })
                                }}
                                value={this.state.performanceChannel}
                                visibleChannels={channelOptions}
                                allowEmptySelection={true}
                            />
                        </div>
                    </div>
                </div>

                {!this.state.loadingBreakdownStats && this.renderBreakdownStatsChart()}
            </Well>
        )
    }

    private get isDisabled(): boolean {
        const isDelivered = this.isDelivered
        const isDelivering = this.state.notification!.status === StatusType.DELIVERING.name
        const isCancelled =
            this.state.notification!.status === StatusType.CANCELLED.name ||
            this.state.notification!.status === StatusType.CANCELLING.name
        const isInf = this.state.notification!.deliverySpec.window === NotificationDeliveryWindow.INFORMED
        const isStz = this.state.notification!.deliverySpec.window === NotificationDeliveryWindow.TIMEZONE

        return !isDelivered && !((isInf || isStz) && (isDelivering || isCancelled))
    }

    private renderBreakdownStatsChart = (): React.ReactNode => {
        const { stats, breakdownStats, notification } = this.state
        if (!stats) {
            return
        }
        if (!breakdownStats) {
            return ''
        }

        const xAxisDateDataSet = new Set(
            breakdownStats.map((stat) => moment.tz(stat.event_date, 'UTC').format('MMM D')),
        )

        const mapDataKeyFromObject = (key: string, obj: any, channel: DeliveryChannel) => {
            return obj.filter((val) => val.channel === channel).map((filtered) => filtered[key] || 0)
        }

        const xAxisChannelDataSet = new Set(
            (notification?.channels ?? []).map((channel) => DeliveryChannel.getLongName(channel)),
        )
        const buildSeries = (chartType: ChartType) => {
            const series: any[] = [{ keys: ['name', 'y'], visible: false, showInLegend: false }]

            if (this.state.statsType !== 'day') {
                series.push({
                    data: this.state.performanceChannel.map((channel) => {
                        const foundStat = breakdownStats.find((stat) => stat.channel === channel)
                        return {
                            name: DeliveryChannel.getLongName(channel),
                            color: HIGHCHARTS_CHANNEL_COLOR_MAP[channel],
                            y: foundStat?.[chartType] ?? 0,
                        }
                    }),
                })
            } else {
                series.push(
                    ...this.state.performanceChannel.map((channel) => ({
                        name: DeliveryChannel.getLongName(channel),
                        color: HIGHCHARTS_CHANNEL_COLOR_MAP[channel],
                        data: mapDataKeyFromObject(chartType, breakdownStats, channel),
                    })),
                )
            }

            return series
        }

        const options = (chartType: ChartType) => ({
            ...DEFAULT_HIGHCHARTS_CONFIG,
            swContext: (): IState => this.state,
            chart: {
                type: 'column',
                marginLeft: 70,
                marginRight: 50,
                spacingTop: 20,
            },
            title: {
                text: titleCase(chartType),
            },
            xAxis: {
                categories: this.state.statsType === 'day' ? [...xAxisDateDataSet] : [...xAxisChannelDataSet],
            },
            legend: {
                enabled: false,
            },
            yAxis: [
                {
                    title: { text: '' },
                    allowDecimals: false,
                    startOnTick: 0,
                    minRange: 0,
                },
            ],
            tooltip: {
                pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
            },
            // plotOpt
            series: buildSeries(chartType),
        })

        let impressionsTotals = [
            <div key="impressions" className="ant-popover-title">
                Impressions:
            </div>,
        ]
        let clicksTotals = [
            <div key="clicks" className="ant-popover-title">
                Clicks:
            </div>,
        ]
        const totalStatsByChannel = reduceStatTotalsByChannel(breakdownStats)

        Object.keys(totalStatsByChannel).map((key) => {
            impressionsTotals.push(
                <div key={`${key}-impressions`} className="channel-totals-row">
                    <span className="channel-totals-label">{DeliveryChannel.getShortName(DeliveryChannel[key])}:</span>
                    <span className="channel-totals-value">
                        {numberWithCommas(totalStatsByChannel[key].impressions)}
                    </span>
                </div>,
            )

            clicksTotals.push(
                <div key={`${key}-clicks`} className="reach-breakdown-row">
                    <span className="reach-breakdown-label">{DeliveryChannel.getShortName(DeliveryChannel[key])}:</span>
                    <span className="reach-breakdown-value">{numberWithCommas(totalStatsByChannel[key].clicks)}</span>
                </div>,
            )
        })

        return (
            <Popover
                key={`popover-details`}
                overlayClassName="domain-notif-channel-select-details-popover"
                content={
                    <div>
                        <div className="impressions-breakdown-stats">{impressionsTotals}</div>

                        <div className="clicks-breakdown-stats">{clicksTotals}</div>
                    </div>
                }
                placement="left"
            >
                <HighchartsReact
                    className="impressions-chart"
                    highcharts={Highcharts}
                    options={options('impressions')}
                />

                <Divider type="horizontal" />

                <HighchartsReact highcharts={Highcharts} options={options('clicks')} />
            </Popover>
        )
    }

    private renderGenericStats(): React.ReactNode {
        const actions = this.state.notification?.template.channels.default?.actions ?? []
        const hasActions = actions.length > 0

        actions.sort((a, b) => (a.ordinal < b.ordinal ? 1 : a.ordinal < b.ordinal ? -1 : 0))

        return (
            <Well
                className="thin gen-stats-well nested"
                loading={this.state.loadingGenStats}
                showHeader={false}
                showFooter={false}
            >
                <Well ghost={true} showHeader={false} showFooter={false}>
                    <div className="chart-title">Status</div>
                    <div className="stat stat-status">{this.renderStatus()}</div>
                </Well>
                <Well ghost={true} showHeader={false} showFooter={false} disabled={this.isDisabled}>
                    <div className="chart-title">Impressions</div>
                    <div className="stat stat-impressions">{this.renderImpressions()}</div>
                </Well>
                <Well ghost={true} showHeader={false} showFooter={false} disabled={this.isDisabled}>
                    <div className="chart-title">{hasActions && 'Total '}Clicks (CTR)</div>
                    <div className="stat stat-clicks">
                        {this.renderClicks()}
                        <div className="stat stat-ctr sub-stat">({this.renderCTR()})</div>
                    </div>
                </Well>

                {hasActions && (
                    <>
                        {this.renderPrimaryActionGenStats()}
                        {actions.map((action, idx) => (
                            <React.Fragment key={idx}>{this.renderActionGenStats(action, idx)}</React.Fragment>
                        ))}
                    </>
                )}
            </Well>
        )
    }

    private renderPrimaryActionGenStats() {
        const stats: any = this.state.stats
        const actionStats: any = this.state.actionStats
        let primaryClicks: any
        let primaryCtr: any

        if (
            !this.isDisabled &&
            !this.state.loadingGenStats &&
            !this.state.loadingActionStats &&
            !!stats &&
            !!actionStats
        ) {
            primaryClicks = stats.primary_clicks
            primaryCtr = stats.impressions === 0 ? 0 : stats.primary_clicks / stats.impressions
        }

        return (
            <>
                <Well ghost={true} showHeader={false} showFooter={false} disabled={this.isDisabled}>
                    <div className="chart-title">
                        <span>Primary Clicks</span>
                        <Tooltip title="Primary Landing URL">
                            <InfoCircleOutlined className="info-icon" />
                        </Tooltip>
                    </div>
                    <div className="stat stat-clicks">
                        {primaryClicks === undefined ? '--' : numberWithCommas(primaryClicks)}
                        <div className="stat stat-ctr sub-stat">
                            ({!primaryCtr ? '--' : numberWithPercent(primaryCtr)})
                        </div>
                    </div>
                </Well>
            </>
        )
    }

    private renderActionGenStats(action: any, actionIdx: number) {
        const stats: any = this.state.actionStats
        let clicks: any
        let ctr: any

        if (!this.isDisabled && !this.state.loadingActionStats && !!stats) {
            const stat = stats.find((s) => s.button.id.toString() === action.id.toString())
            if (!!stat) {
                clicks = stat.clicks
                ctr = stat.ctr_decimal
            }
        }

        return (
            <React.Fragment key={actionIdx}>
                <Well ghost={true} showHeader={false} showFooter={false} disabled={this.isDisabled}>
                    <div className="chart-title">
                        <span>Button {actionIdx + 1} Clicks</span>
                        <Tooltip title={action.label}>
                            <InfoCircleOutlined className="info-icon" />
                        </Tooltip>
                    </div>
                    <div className="stat stat-clicks">
                        {clicks === undefined ? '--' : numberWithCommas(clicks)}
                        <div className="stat stat-ctr sub-stat">({!clicks ? '--' : numberWithPercent(ctr)})</div>
                    </div>
                </Well>
            </React.Fragment>
        )
    }

    private renderStatus = (): React.ReactNode => {
        const notification: any = this.state.notification
        if (!notification) return 'Pending'

        const completedStatuses = [StatusType.COMPLETED.name, StatusType.COMPLETED_WITH_FAILURES.name]
        const isPartialDelivery = notification.status === StatusType.COMPLETED_WITH_FAILURES.name
        let statusText
        if (notification.source === NotificationSource.CAMPAIGN) {
            statusText = 'DELIVERED'
        } else {
            statusText = arrayContains(completedStatuses, notification.status)
                ? notification.deliverySpec.window === NotificationDeliveryWindow.HOLD_OUT
                    ? 'HOLD OUT'
                    : 'DELIVERED'
                : notification.status
        }
        let statusId = 0

        // Delivered
        let color: string = 'green'

        const statusLookup = StatusType[statusText]
        if (statusLookup) {
            statusId = statusLookup.id
        }

        if (this.isPartialFailure) {
            statusText = 'PARTIAL_FAILURE'
            statusId = -1
        }

        switch (statusId) {
            case StatusType.SCHEDULED.id:
            case StatusType.SCHEDULING.id:
                statusText = 'SCHEDULED'
                color = 'purple'
                break
            case StatusType.QUEUED.id:
            case StatusType.DELIVERING.id:
                color = 'orange'
                break
            case StatusType.FAILED.id:
                color = 'red'
                break
            case -1:
                color = 'volcano'
                break
        }

        return (
            <div className={`color-${color}`}>
                {titleCase(statusText)}
                {isPartialDelivery && (
                    <>
                        <Tooltip title="A portion of this notification's audience failed to deliver.">
                            <ExclamationCircleOutlined className="info-icon error-icon" />
                        </Tooltip>
                    </>
                )}
            </div>
        )
    }

    private renderImpressions(): React.ReactNode {
        const stats: any = this.state.stats
        if (this.isDisabled || !stats) {
            return '--'
        }
        return numeral(stats.impressions).format('O,O')
    }

    private renderClicks(): React.ReactNode {
        const stats: any = this.state.stats
        if (this.isDisabled || !stats) {
            return '--'
        }
        return numeral(stats.clicks).format('O,O')
    }

    private renderCTR(): React.ReactNode {
        const stats: any = this.state.stats
        if (this.isDisabled || !stats) {
            return '--'
        }
        return numeral(stats.ctr_decimal).format('0.00%')
    }

    private goBackToNotificationsList() {
        this.appService.routeWithinDomain('/notifications')
    }

    private async setDomainState(): Promise<void> {
        const { domain, notification } = this.state
        const selectedDomain = this.appState.currentDomain

        if (selectedDomain) {
            if (selectedDomain.id !== domain.id) {
                this.goBackToNotificationsList()
            } else {
                if (!this.unmounting) {
                    if (!domain) {
                        await this.setState({ domain: selectedDomain })
                    }

                    if (!notification) {
                        await this.ensureNotificationDetails(selectedDomain)
                    }
                }
            }
        }
    }

    private async ensureNotificationDetails(domain: DomainDto): Promise<void> {
        const notification = await this.notificationService.fetchNotificationById(domain.id, this.notificationIdParam, {
            query: { includeSegments: true },
            cancellationKey: 'nd.ensureNotificationDetails',
            version: ApiVersion.V4,
            errorHandler: onResponseError403(() => {
                this.goBackToNotificationsList()
            }),
        })

        if (!this.unmounting && notification) {
            await this.setState({
                loadingSchedule: false,
                notification,
            })

            this.getNotificationGenStats()
            this.getNotificationBreakdownStats()
        }
    }

    private async getNotificationGenStats(): Promise<void> {
        const { notification, domain } = this.state
        const actions = notification?.template.channels.default?.actions ?? []
        const hasActions = actions.length > 0

        if (!this.unmounting) {
            await this.setState({ loadingGenStats: true })

            const insightsCalls: any[] = []
            insightsCalls.push(
                this.insightsService.fetch(
                    {
                        entity: 'notifications',
                        date_preset: 'lifetime',
                        date_increment: 'lifetime',
                        breakdowns: ['notification'],
                        fields: [
                            'notification_id',
                            'deliveries',
                            'impressions',
                            'clicks',
                            'primary_clicks',
                            'delivery_rate_decimal',
                            'ctr_decimal',
                        ],
                        filters: [
                            { field: 'domain.id', operator: 'eq', value: domain.id },
                            { field: 'notification.id', operator: 'eq', value: notification!.id },
                        ],
                    },
                    false,
                    'notifDetails.genStats',
                ),
            )

            if (hasActions) {
                insightsCalls.push(
                    this.insightsService.fetch(
                        {
                            entity: 'notifications',
                            date_preset: 'lifetime',
                            date_increment: 'lifetime',
                            action_attribution: 'event_time',
                            breakdowns: ['notification', 'button'],
                            fields: ['button.id', 'clicks', 'ctr_decimal'],
                            filters: [
                                { field: 'domain.id', operator: 'eq', value: domain.id },
                                { field: 'notification.id', operator: 'eq', value: notification!.id },
                            ],
                        },
                        false,
                        'notifButtonDetails.bdStats',
                    ),
                )
            }

            const results = await Promise.all(insightsCalls)

            if (!this.unmounting) {
                this.setState({
                    loadingGenStats: false,
                    loadingActionStats: hasActions,
                    stats: results[0][0],
                })

                if (hasActions) {
                    this.setState({
                        loadingActionStats: false,
                        actionStats: results[1],
                    })
                } else {
                    this.setState({
                        loadingActionStats: false,
                    })
                }
            }
        }
    }

    private refreshStats = async (): Promise<void> => {
        this.setState({ loadingBreakdownStats: true, loadingGenStats: true })
        this.getNotificationGenStats()
        this.getNotificationBreakdownStats()
    }

    private async getNotificationBreakdownStats(): Promise<void> {
        const { notification, domain, statsType } = this.state
        await this.setState({ loadingBreakdownStats: true })
        const contract: any = {
            entity: 'notifications',
            date_preset: 'lifetime',
            date_increment: statsType,
            action_attribution: 'event_time',
            breakdowns: ['notification', 'channel'],
            fields: [
                'notification_id',
                'deliveries',
                'impressions',
                'clicks',
                'delivery_rate_decimal',
                'ctr_decimal',
                'channel',
            ],
            filters: [
                { field: 'domain.id', operator: 'eq', value: domain.id },
                { field: 'notification.id', operator: 'eq', value: notification!.id },
                { field: 'channel', operator: 'in', value: DeliveryChannel.getAllChannels() },
            ],
        }

        const breakdownStats = await this.insightsService.fetch(contract, false, 'notifDetails.bdStats')

        let backfilledStats: any[]
        if (statsType === 'day') {
            backfilledStats = backfillStats(
                breakdownStats,
                statsType,
                {
                    clicks: 0,
                    impressions: 0,
                },
                true,
            )
        } else {
            backfilledStats = breakdownStats
        }

        this.setState({
            loadingBreakdownStats: false,
            breakdownStats: backfilledStats,
            performanceChannel: Array.from(new Set(notification?.channels ?? [])),
        })
    }

    private handleSummaryTitleClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
        this.stopPropagation(ev.nativeEvent)

        if (ev.metaKey) {
            window.open(this.buildScheduleDetailsUrl())
            return
        }

        this.appService.route(this.buildScheduleDetailsUrl())
    }

    private buildScheduleDetailsUrl(): string {
        const notification = this.state.notification
        if (!notification) {
            return this.appService.routeWithin('domain', `/notifications`, true)
        }

        return this.appService.routeWithin('domain', `/notifications/${notification.id}`, true)
    }

    private buildSegmentUrl(segment: any): string {
        return this.appService.routeWithinDomain(`/segments/${segment.id}/summary`, true)
    }

    private jumpToSegment = (segment: any, ev?: any): void => {
        if (!ev.metaKey) {
            this.stopPropagation(ev)
            this.appService.route(this.buildSegmentUrl(segment))
        }
    }

    private stopPropagation(ev: Event): void {
        ev.preventDefault()
        ev.stopPropagation()
    }
}
