import React from 'react'
import './styles/notification-audience-builder.scss'
import classnames from 'classnames'
import { NotificationAudienceModel } from '../../../models/notification/notification-audience.model'
import { Well } from '@pushly/aqe/lib/components'
import { Radio, Skeleton, Select } from 'antd'
import { getClassNames } from '../../../_utils/classnames'
import { useMountedRef } from '../../../_utils/use-mounted-ref'
import { DispatchActionPack } from '../types'
import { useService } from '../../../hooks/use-service'
import { AppState } from '../../../stores/app'
import { OrgSegmentService } from '../../../services/org-segment'
import { OrgSegmentModel } from '../../../models/segments/org-segment.model'
import { SegmentSource } from '../../../enums/segment-source.enum'
import { InfoCircleOutlined } from '@ant-design/icons'
import { INestedBatchRequest } from '../../../interfaces/batch-requests'
import { BatchService } from '../../../services/batch'
import { arrayUniqueObjects } from '../../../_utils/utils'
import { useLoadableDataRefState } from '../../../hooks/use-loadable-data-ref-state'
import DomainAudienceSelect from '../../domain-audience-select/domain-audience-select'

function getAvailableSegments(segments: OrgSegmentModel[] | undefined, domainIds: number[]) {
    const available =
        segments?.filter((s) => domainIds.length && domainIds.every((id) => s.getDomainIds().includes(id))) ?? []
    return arrayUniqueObjects(available, 'getId')
}

interface INotificationAudienceBuilder {
    className?: string
    account: {
        id: number
        name: string
        flags: string[]
    }
    setBuilder: (action: DispatchActionPack) => void
    disabled?: boolean
    segmentSelectDisabled?: boolean
    value: NotificationAudienceModel
    onChange?: (value: NotificationAudienceModel) => any
    loading?: boolean

    hideExclusion?: boolean
    inclusionLabel?: string | false
}

const OrgNotificationAudienceBuilder = (props: INotificationAudienceBuilder) => {
    const { className, disabled, segmentSelectDisabled, loading, account, value, onChange, hideExclusion, setBuilder } =
        props

    const appState = useService(AppState)
    const orgSegmentSvc = useService(OrgSegmentService)
    const [, runIfMounted] = useMountedRef()

    const batchSvc = useService(BatchService)

    const [initialSegments, setInitialSegments] = useLoadableDataRefState<OrgSegmentModel[]>({
        loading: false,
    })
    const [orgSegments, setOrgSegments] = useLoadableDataRefState<OrgSegmentModel[]>({
        loading: true,
        data: [],
    })

    /**
     * Load all audience dependencies
     *
     * default segment is used within the audience type <Radio.Group />
     *
     * initial segments from edit/duplicate loaded segments will be
     * passed to <ComboSegmentSelection /> as additionalOptions
     */
    React.useEffect(() => {
        const loadInitialSegments = async () => {
            // 1. wait until parent is done loading (loading)
            // 2. ensure dependencies are not loaded or currently loading
            if (!loading && !initialSegments.current.data && !initialSegments.current.loading) {
                let state: any = { ...initialSegments.current, loading: true }

                setInitialSegments({ ...state })

                state.loading = false
                state.data = []

                const initialSegmentIds = value.getSegmentIds() ?? []
                const segmentsPath = `/accounts/${account.id}/segments`

                if (initialSegmentIds.length > 0) {
                    const batchReqs: INestedBatchRequest[] = []

                    batchReqs.push({
                        method: 'get',
                        relative_path: `${segmentsPath}?id=${initialSegmentIds.join(
                            ',',
                        )}&include_deleted=true&source=standard,inline,adhoc`,
                        meta: { init_segments_req: true },
                    })

                    const res = await batchSvc.batch(
                        { requests: batchReqs },
                        {
                            cancellationKey: 'nab-isl',
                        },
                    )

                    if (res.ok) {
                        const segments: OrgSegmentModel[] = []
                        const initSegementsReq = res.data.find((r) => r.meta?.init_segments_req)
                        if (initSegementsReq?.code === 200) {
                            const rawSegments = JSON.parse(initSegementsReq.body)?.data ?? []
                            if (rawSegments) {
                                rawSegments.forEach((rs) => segments.push(OrgSegmentModel.build(rs)))
                            }
                        }

                        state.data = segments
                    }
                }

                runIfMounted(() => {
                    setInitialSegments(state)

                    setBuilder({
                        type: 'patch',
                        entity: 'available-segments',
                        data: arrayUniqueObjects(
                            [...state.data, ...(orgSegments.current.data ?? [])],
                            'getId',
                        ) as any[],
                    })
                })
            }
        }

        loadInitialSegments()
    }, [loading, !!value.getSegmentIds()])

    React.useEffect(() => {
        value.setIsAll(true)

        const loadOrgSegments = async () => {
            const { ok, data } = await orgSegmentSvc.fetchAll(account.id, {
                query: {
                    pagination: 0,
                    source: SegmentSource.STANDARD,
                },
            })
            if (ok && data) {
                runIfMounted(() => {
                    const segmentModels = data.map(OrgSegmentModel.build)

                    setOrgSegments({
                        loading: false,
                        data: segmentModels,
                    })

                    setBuilder({
                        type: 'patch',
                        entity: 'available-segments',
                        data: arrayUniqueObjects(
                            [...segmentModels, ...(initialSegments.current.data ?? [])],
                            'getId',
                        ) as any[],
                    })
                })
            }
        }

        loadOrgSegments()
    }, [])

    const workingModel = value.clone()
    const currentUserOrgDomains =
        appState.currentUserDomains?.filter((d) => d.accountId?.toString() === account.id?.toString()) ?? []
    const currentUserOrgDomainIds = currentUserOrgDomains.map((d) => d.id)

    const selectedDomainIds = workingModel.getIsAll() ? currentUserOrgDomainIds : workingModel.getDomainIds() ?? []

    const availableSegments = getAvailableSegments(
        [...(orgSegments.current.data ?? []), ...(initialSegments.current.data ?? [])],
        selectedDomainIds,
    )
    const showSegmentSelection = workingModel.getSegmentIds() && availableSegments.length
    const showNoSegmentsAvailableMsg = !orgSegments.current.loading && !availableSegments.length

    const isLoading = loading || orgSegments.current.loading || initialSegments.current.loading
    const handleDomainIdsChange = (selectValue: number[]) => {
        const update = workingModel.clone()

        update.setIsAll(false)
        update.setDomainIds(selectValue)

        const nextAvailableSegmentIds = getAvailableSegments(orgSegments.current.data, selectValue).map((s) =>
            s.getId(),
        )
        const segmentIds = update.getSegmentIds()
        if (segmentIds && nextAvailableSegmentIds.length) {
            if (!segmentIds.every((id) => nextAvailableSegmentIds.includes(id))) {
                update.setSegmentIds([])
            }
        } else {
            update.setSegmentIds(undefined)
        }

        onChange?.(update)
    }

    const domainSelectorOptions = currentUserOrgDomains.map((domain) => ({
        label: domain.displayName,
        title: domain.displayName,
        value: domain.id,
    }))

    return (
        <Well
            className={getClassNames('notification-audience-builder', 'level-account', 'nested', className, {
                'hide-exclusions': hideExclusion,
            })}
            title="Audience"
            hideFooter={true}
        >
            <Skeleton loading={isLoading} active={true} avatar={false} title={false}>
                {isLoading ? (
                    <React.Fragment />
                ) : (
                    <React.Fragment>
                        <Well
                            className={getClassNames('notification-audience-domains')}
                            title="Domains"
                            mode="ghost"
                            hideFooter={true}
                        >
                            {currentUserOrgDomainIds.length === 1 ? (
                                <React.Fragment>
                                    <span>{currentUserOrgDomains[0].displayName}</span>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Radio.Group
                                        className="audience-builder-type-radio"
                                        size="small"
                                        buttonStyle="solid"
                                        disabled={disabled}
                                        value={workingModel.getIsAll() ? 'all' : 'domains'}
                                        onChange={(ev) => {
                                            const update = workingModel.clone()

                                            const v = ev.target.value
                                            let domainIds
                                            let isAll = false
                                            if (v !== 'all') {
                                                domainIds = []
                                            } else {
                                                isAll = true
                                                domainIds = undefined
                                            }

                                            update.setIsAll(isAll)
                                            update.setDomainIds(domainIds)
                                            update.setSegmentIds(undefined)
                                            update.setDefaultIconUrl(undefined)

                                            onChange?.(update)
                                        }}
                                    >
                                        <Radio.Button value="all">All Domains</Radio.Button>
                                        <Radio.Button value="domains">Specific Domains</Radio.Button>
                                    </Radio.Group>

                                    {!workingModel.getIsAll() && (
                                        <DomainAudienceSelect
                                            value={workingModel.getDomainIds()}
                                            domainSelectorOptions={domainSelectorOptions}
                                            onChange={handleDomainIdsChange}
                                            onClear={() => handleDomainIdsChange([])}
                                            handleDomainIdsChange={handleDomainIdsChange}
                                            domainIdChangeRequiresValidation={false}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </Well>

                        <Well
                            className={getClassNames('notification-audience-segmentation')}
                            title="Segmentation"
                            mode="ghost"
                            hideFooter={true}
                        >
                            <div className="audience-builder-segmentation">
                                <Radio.Group
                                    className="audience-builder-segmentation-radio"
                                    size="small"
                                    buttonStyle="solid"
                                    disabled={segmentSelectDisabled}
                                    value={workingModel.getSegmentIds() ? 'segments' : 'all'}
                                    onChange={(ev) => {
                                        const update = workingModel.clone()

                                        const v = ev.target.value
                                        let segmentIds
                                        if (v !== 'all') {
                                            segmentIds = []
                                        } else {
                                            segmentIds = undefined
                                        }

                                        update.setSegmentIds(segmentIds)
                                        update.setDefaultIconUrl(undefined)

                                        onChange?.(update)
                                    }}
                                >
                                    <Radio.Button value="all">All Subscribers</Radio.Button>

                                    <Radio.Button
                                        value="segments"
                                        disabled={orgSegments.current.loading || !availableSegments.length}
                                    >
                                        Specific Segments
                                    </Radio.Button>
                                </Radio.Group>

                                {showNoSegmentsAvailableMsg ? (
                                    <div className="no-available-org-segments-message">
                                        <InfoCircleOutlined />
                                        <span>
                                            No segments are available to target for the currently selected domain
                                            audience.
                                        </span>
                                    </div>
                                ) : (
                                    showSegmentSelection && (
                                        <Select
                                            className={classnames(
                                                'org-notification-segment-audience-select',
                                                'included-audience-select',
                                            )}
                                            disabled={segmentSelectDisabled}
                                            value={workingModel.getSegmentIds()}
                                            mode="multiple"
                                            placeholder={`Select at least one segment to include`}
                                            optionFilterProp="title"
                                            optionLabelProp="title"
                                            filterOption={true}
                                            onChange={(v) => {
                                                const update = workingModel.clone()
                                                update.setSegmentIds(v)

                                                onChange?.(update)
                                            }}
                                        >
                                            {availableSegments.map((segment) => (
                                                <Select.Option
                                                    key={segment.getId()}
                                                    value={segment.getId()}
                                                    title={segment.getName()}
                                                >
                                                    {segment.getName()}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )
                                )}
                            </div>
                        </Well>
                    </React.Fragment>
                )}
            </Skeleton>
        </Well>
    )
}

export default OrgNotificationAudienceBuilder
