import React from 'react'
import classnames from 'classnames'
import { OrgSegmentModel } from '../../models/segments/org-segment.model'
import { SegmentModel } from '../../models/segments/segment.model'
import { SegmentBuilderContext } from './segment-builder-context'
import { getSegmentBuilderFields } from './segment-builder-fields'
import { IRuleBuilderField } from '../rule-builder/rule-builder'
import {
    domainOptionsSort,
    getCustomSegmentFieldDerivedType,
    getUnsafeExecutionText,
    isBuilderSegmentValid,
    isUnsafeExecution,
} from './helpers'
import { RuleBuilderV2 } from '../rule-builder-v2/rule-builder'
import { Well } from '@pushly/aqe/lib/components'
import { useService } from '@pushly/aqe/lib/hooks'
import { Skeleton, Spin, Tooltip, Popover } from 'antd'
import { numberWithCommas, simpleNotification } from '../../_utils/utils'
import { LoadingOutlined, WarningOutlined, InfoCircleOutlined, PartitionOutlined } from '@ant-design/icons'
import { AppState } from '../../stores/app'
import { buildLanguageCustomField } from './custom-fields/build-language.custom-field'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

const ReachDisplay = () => {
    const context = React.useContext(SegmentBuilderContext)
    const isUnsafe = isUnsafeExecution(context)

    const appState = useService(AppState)

    const breakdown = context.reachEstimateBreakdown
    const domainIds = Object.keys(breakdown)
    const breakdownMap = domainIds
        .map((domainId) => {
            const domain = appState.currentUserDomains?.find?.((d) => d.id.toString() === domainId.toString())

            return {
                id: domain?.id,
                label: domain?.displayName ?? 'unknown',
                reachEstimate: breakdown[domainId],
            }
        })
        .filter((v) => !!v.id)
    breakdownMap.sort(domainOptionsSort)

    const showBreakdown =
        !isUnsafe && context.level === 'org' && context.reachEstimate?.total !== 0 && breakdownMap.length > 1

    return (
        <div key="reach" className="reach-column">
            {!context.reachEstimateLoaded ? (
                <span>
                    Loading <Spin indicator={<LoadingOutlined spin={true} />} />
                </span>
            ) : context.reachEstimate === undefined ? (
                <span>Reach is currently not available</span>
            ) : (
                <React.Fragment>
                    {isUnsafe && (
                        <span className="reach-tooltip">
                            <Tooltip title={getUnsafeExecutionText(context)}>
                                <WarningOutlined className="info-icon error-icon" />
                            </Tooltip>
                        </span>
                    )}

                    {showBreakdown && (
                        <Popover
                            overlayClassName="reach-breakdown-popover"
                            title="Reach Estimates by Domain"
                            content={breakdownMap.map((value) => (
                                <div key={value.id} className="reach-breakdown-row">
                                    <span className="reach-breakdown-label">{value.label}</span>
                                    <span className="reach-breakdown-value">
                                        {isUnsafe ? '--' : numberWithCommas(value.reachEstimate)}
                                    </span>
                                </div>
                            ))}
                        >
                            <InfoCircleOutlined className="info-icon reach-breakdown-icon" />
                        </Popover>
                    )}

                    <span className="reach-estimate">
                        {isUnsafe ? '--' : numberWithCommas(context.reachEstimate.total ?? 0)}
                    </span>
                    <span className="reach-label">Subscribers</span>
                    {Object.keys(context.reachEstimate!).length > 2 && (
                        <Popover
                            title="Reach Estimates by Channel"
                            className="reach-tooltip channel-breakdown"
                            overlayClassName="audience-channel-breakdown-popover"
                            content={Object.keys(context.reachEstimate ?? {})
                                .filter((key) => key !== 'total')
                                .map((ch) => {
                                    return (
                                        <div key={ch} className="reach-breakdown-row">
                                            <span className="reach-breakdown-label">
                                                {DeliveryChannel.getLongName(DeliveryChannel[ch.toUpperCase()])}:
                                            </span>
                                            <span className="reach-breakdown-value">
                                                {isUnsafe
                                                    ? '--'
                                                    : numberWithCommas(context.reachEstimate[ch.toLowerCase()] ?? 0)}
                                            </span>
                                        </div>
                                    )
                                })}
                            placement="left"
                        >
                            <PartitionOutlined />
                        </Popover>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

interface IRuleBuilderProps {
    onChange: (segment: OrgSegmentModel | SegmentModel) => void
    onCancel: () => void
    onSubmit: (segment: OrgSegmentModel | SegmentModel) => void
    onValidation: (valid: boolean) => void
    submitDisabled: boolean
}

const SegmentRuleBuilder = React.forwardRef<RuleBuilderV2, IRuleBuilderProps>((props, ref) => {
    const context = React.useContext(SegmentBuilderContext)
    const fields = getSegmentBuilderFields(context)
    const builderRef = React.createRef<RuleBuilderV2>()

    if (context.languageCodes.length) {
        fields.push(buildLanguageCustomField('profile.language', context.languageCodes))
    }

    if (context.segmentFields.length) {
        const sanitizeName = (name: string) =>
            name
                .toLowerCase()
                .replace(/:[\s]?/g, '.')
                .replace(/\s/g, '_')

        const sortedSegmentFields = context.segmentFields.sort((a, b) => {
            const aName = sanitizeName(a.name)
            const bName = sanitizeName(b.name)
            return aName > bName ? 1 : aName < bName ? -1 : 0
        })

        sortedSegmentFields.forEach((segmentField) => {
            const type = getCustomSegmentFieldDerivedType(segmentField)

            const field: IRuleBuilderField = {
                property: segmentField.key,
                display: `Custom: ${segmentField.name}`,
                type,
                isEsSegmentField: true,
            }

            if (segmentField.suggestions && segmentField.suggestions.length) {
                field.suggestions = segmentField.suggestions
            } else if (segmentField.typeahead) {
                const typeaheadUrl =
                    typeof context.segmentFieldTypeaheadUrl === 'string'
                        ? context.segmentFieldTypeaheadUrl
                        : context.segmentFieldTypeaheadUrl()
                if (typeaheadUrl) {
                    field.typeaheadUrl = typeaheadUrl
                    field.typeaheadTransform = context.segmentFieldTypeaheadTransform
                }
            }

            fields.push(field)
        })

        fields.push({
            property: 'divider',
            type: 'divider',
        })
    }

    fields.push({
        property: 'custom_profile',
        display: 'Custom: Free Input',
        type: 'custom',
        ignoreExistenceVisibilityCheck: true,
    })

    const handleSubmit = async () => {
        const validation = (await builderRef.current?.validate?.()) ?? {
            ok: false,
            error: 'We were unable to save the segment at this time.',
        }

        if (!context.segment.getName()) {
            return simpleNotification('error', 'Segment name is required')
        }

        if (!validation.ok) {
            simpleNotification('error', validation.error)
            return
        }

        if (context.isDrawerMode && isUnsafeExecution(context)) {
            simpleNotification('error', getUnsafeExecutionText(context))
            return
        }

        // Need to support multi-variant segments which consist of only a treatmenmt
        const segmentHasTreatment = context.level === 'domain' && !!context.segment.getTreatmentSpec()
        if (!segmentHasTreatment && !isBuilderSegmentValid(context.segment)) {
            return simpleNotification('error', 'Segment must contain at least one rule')
        }

        props.onSubmit(context.segment.clone())
    }

    return (
        <Well
            className={classnames('builder-well', 'segment-builder-rule-builder', {
                blur: context.level === 'org' && context.confirmingDomainChange,
            })}
            title="Builder"
            hideFooter={context.isDrawerMode}
            onSubmit={handleSubmit}
            onCancel={props.onCancel}
            hideSubmit={context.readonly}
            disableSubmit={props.submitDisabled}
            cancelText={context.readonly ? 'Go Back' : 'Cancel'}
            actions={
                <div className="action-row">
                    <ReachDisplay />
                </div>
            }
        >
            <Skeleton active={true} loading={context.loading} title={false} paragraph={{ rows: 8 }}>
                {!context.loading && context.segment && (
                    <RuleBuilderV2
                        ref={(el) => {
                            if (typeof ref === 'function') {
                                ref?.(el)
                            } else {
                                // @ts-ignore
                                ref.current = el
                            }

                            // @ts-ignore
                            builderRef.current = el
                        }}
                        value={context.segment.getFiltersJson()}
                        onChange={(filters) => {
                            const segment = context.segment.clone()
                            segment.setFiltersJson(filters)
                            props.onChange(segment)
                        }}
                        onValidation={({ ok }) => props.onValidation(ok)}
                        fields={fields}
                        disabled={context.readonly}
                        showCode={!context.builderDocumentIsValid}
                    />
                )}
            </Skeleton>
        </Well>
    )
})

export default SegmentRuleBuilder
