import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Radio, Switch, Tooltip } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/es/form'
import { AppService } from '../../../services/app'
import { DomainService } from '../../../services/domain'
import { TranslationService } from '../../../services/translation'
import { BetterFormComponent } from '../../../components/better-component/better-component'
import { Well } from '../../../components/well/well'
import { DomainDto } from '../../../dtos/domain'
import { UserDto } from '../../../dtos/user'
import { AppState } from '../../../stores/app'
import { arrayContains } from '../../../_utils/utils'
import { TypedTagSelect } from '../../sandbox/components/typed-tag-select/typed-tag-select'
import { AccountService, UserService } from '../../../services'
import { observe } from 'mobx'
import { IAccountUser } from '../../../interfaces/account-user'
import { AbilityAction } from '../../../enums/ability-action.enum'
import { PasswordForm } from 'components/password-form/password-form'
import { validateFields } from '../../../_utils/antd'

export enum UserDataFormStyle {
    INVITE,
    CONFIRM,
    PASSWORD_RESET,
    PROFILE,
}

export interface INewUserFormOptions {
    isInternalUser: boolean
    email: string
    accounts?: IAccountUser[]
}

interface IProps extends FormComponentProps {
    readonly user?: any
    readonly style?: UserDataFormStyle
    readonly className?: string
    readonly onSubmit: (error: any, values: any) => any
    readonly submitText?: string
    readonly onCancel?: () => void
    readonly cancelText?: string
    readonly hideWell?: boolean
}

interface IState {
    readonly isInternalUserSwitch?: boolean
    readonly domains: DomainDto[]
    readonly userDomains?: DomainDto[]
    userPermissions: IAccountUser[]
}

export const UserDataForm = Form.create<IProps>()(
    class extends BetterFormComponent<IProps, IState> {
        protected appState: AppState
        protected appService: AppService
        protected domainService: DomainService
        protected accountService: AccountService
        protected translationService: TranslationService
        protected userService: UserService

        protected domainAccessFlagRef: any
        protected disposeObservers: any

        public constructor(props: IProps) {
            super(props)

            this.appState = Container.get(AppState)
            this.appService = Container.get(AppService)
            this.translationService = Container.get(TranslationService)
            this.domainService = Container.get(DomainService)
            this.accountService = Container.get(AccountService)
            this.userService = Container.get(UserService)

            this.state = {
                domains: [],
                userPermissions: [],
            }

            this.fetchUserDependencies()
        }

        public async componentDidMount() {
            this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.ensureDomainsForList())]

            this.ensureDomainsForList()
        }

        public async componentDidUpdate() {
            if (this.domainsShouldLoad) {
                this.appService.setModuleLoading()

                if (!!this.props.user && !this.state.userDomains) {
                    const userDomains = await this.domainService.fetchAllForUserId(this.props.user.id)

                    this.setState({ userDomains })
                }

                this.appService.unsetModuleLoading()
            }
        }

        public async ensureDomainsForList(): Promise<any> {
            if (this.domainsShouldLoad) {
                this.appService.setModuleLoading()

                const domains = await this.accountService.fetchAccountDomains(this.appState.currentDomain!.accountId)
                this.setState({ domains })

                this.appService.unsetModuleLoading()
            }
        }

        public render() {
            return (
                <div className={this.buildClassNames('wrapper')}>
                    <Well
                        ghost={this.props.hideWell}
                        showHeader={false}
                        onSubmit={this.handleSubmit}
                        submitText={this.props.submitText}
                        onCancel={this.props.onCancel}
                        cancelText={this.props.submitText}
                    >
                        {this.renderForm()}
                    </Well>
                </div>
            )
        }

        protected get propsUserIsInternalUser() {
            if (this.state.isInternalUserSwitch !== undefined) {
                return this.state.isInternalUserSwitch
            }

            return this.props.user?.isInternalUser ?? false
        }

        protected get propsUserEmail(): string | undefined {
            return !this.props.user ? undefined : this.props.user.email
        }

        protected get propsUserName(): string | undefined {
            return !this.props.user ? undefined : this.props.user.name
        }

        protected suppressEmailSymbol = (e: any): any => {
            e = e || window.event
            const charCode = typeof e.which === undefined ? e.keyCode : e.which
            const char = String.fromCharCode(charCode)
            if (/@/.test(char)) {
                e.preventDefault()
                return false
            }
        }

        protected suppressEmailSymbolOnPaste = (e: any): any => {
            const { setFieldsValue } = this.props.form
            const value = e.clipboardData.getData('Text')

            if (/@/.test(value)) {
                e.preventDefault()
                setFieldsValue({
                    email: value.replace(/@.+$/, ''),
                })
            }
        }

        protected renderForm() {
            const { getFieldDecorator } = this.props.form

            const isInternalUserProps: any = {
                disabled: !!this.props.user || !this.appUserIsInternalUser,
                checked: this.propsUserIsInternalUser,
            }

            let emailProps: any = this.propsUserIsInternalUser
                ? {
                      addonAfter: !!this.props.user ? undefined : '@pushly.com',
                      onKeyPress: this.suppressEmailSymbol,
                      onPaste: this.suppressEmailSymbolOnPaste,
                  }
                : {}
            emailProps = {
                ...emailProps,
                disabled: !!this.props.user,
            }

            const emailRules: any = this.propsUserIsInternalUser
                ? []
                : [
                      {
                          type: 'email',
                      },
                  ]

            const currentAccountId = this.appState.currentDomain ? this.appState.currentDomain.accountId : 0

            let availableDomains = this.state.domains
            // Constrain to domains that current platform user can administer
            if (!this.isPasswordResetStyle && !this.isConfirmStyle && !this.appUserIsInternalUser) {
                const administerableDomainIds = this.appState.abilityStore.acs.domainIdsWhereIsAdmin

                availableDomains = availableDomains.filter((dm) => arrayContains(administerableDomainIds, dm.id))
            }

            let defaultDomainSelections: any[] = []
            // Constrain to domains that current props user has access (inside available domains)
            if (this.state.userPermissions) {
                const account = this.state.userPermissions.find((au) => au.accountId === currentAccountId)

                if (account) {
                    defaultDomainSelections = Object.keys(account.domainAccess).map((domainId) => {
                        const config = account.domainAccess[domainId]
                        const domain = availableDomains.find((d) => d.id.toString() === domainId.toString())!

                        return {
                            value: (domain || {}).displayName || 'Loading...',
                            type: config.role === 90 ? 'Admin' : config.role === 10 ? 'Read Only' : 'User',
                        }
                    })
                }
            }

            if (this.props.style === UserDataFormStyle.INVITE && this.appState.currentDomain) {
                defaultDomainSelections = [this.appState.currentDomain!.displayName]
            }

            return (
                <Form className={this.buildClassNames()}>
                    {this.userTypeFieldShouldShow && (
                        <Form.Item label="Internal User">
                            {getFieldDecorator('isInternalUser', {
                                initialValue: this.propsUserIsInternalUser,
                                rules: [{ required: true }],
                            })(
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={this.toggleInternalUserState}
                                    {...isInternalUserProps}
                                />,
                            )}
                        </Form.Item>
                    )}
                    {this.emailFieldShouldShow && (
                        <Form.Item label="Email">
                            {getFieldDecorator('email', {
                                initialValue: this.propsUserEmail,
                                normalize: (value: any) => {
                                    if (value) return value.trim()
                                },
                                rules: [
                                    {
                                        required: true,
                                        message: 'Email is required',
                                    },
                                    ...emailRules,
                                ],
                            })(<Input size="large" placeholder="User email address" {...emailProps} />)}
                        </Form.Item>
                    )}
                    {this.nameFieldShouldShow && (
                        <Form.Item label="Name">
                            {getFieldDecorator('name', {
                                initialValue: this.propsUserName,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Name is required',
                                    },
                                    {
                                        pattern: new RegExp('^(?!.*<.*>).*'),
                                        message: 'Name cannot contain HTML tags.',
                                    },
                                    { min: 2 },
                                    { max: 70 },
                                ],
                            })(<Input size="large" placeholder="Name" />)}
                        </Form.Item>
                    )}
                    {this.domainsFieldShouldShow && (
                        <Form.Item
                            label={
                                <Tooltip
                                    title={
                                        <div>
                                            <p>Domain access is separated into two types: User & Admin.</p>

                                            <p>
                                                <b>Admins</b> have the ability to invite, edit, and disable other users
                                                within the domain.
                                            </p>
                                        </div>
                                    }
                                >
                                    Domain Access
                                    <span className="info-icon">
                                        <InfoCircleOutlined />
                                    </span>
                                </Tooltip>
                            }
                        >
                            {availableDomains.length === 1
                                ? getFieldDecorator('domainAccess', {
                                      initialValue: this.props.user
                                          ? this.isPropsUserAdminOnCurrentDomain()
                                              ? 'admin'
                                              : this.isPropsUserReadOnlyOnCurrentDomain()
                                              ? 'read only'
                                              : 'standard'
                                          : 'standard',
                                      rules: [
                                          {
                                              required: true,
                                              message: 'At least one domain is required',
                                          },
                                      ],
                                  })(
                                      <Radio.Group>
                                          <Radio.Button value="standard">User</Radio.Button>
                                          <Radio.Button value="read only">Read Only</Radio.Button>
                                          <Radio.Button value="admin">Admin</Radio.Button>
                                      </Radio.Group>,
                                  )
                                : getFieldDecorator('domainSelections', {
                                      initialValue: defaultDomainSelections,
                                      rules: [
                                          {
                                              required: true,
                                              message: 'At least one domain is required',
                                          },
                                      ],
                                  })(
                                      <TypedTagSelect
                                          className="referrer-allow"
                                          types={['User', 'Read Only', 'Admin']}
                                          placeholder="Select user domains"
                                          options={availableDomains.map((d) => ({
                                              value: d.displayName,
                                          }))}
                                      />,
                                  )}
                        </Form.Item>
                    )}
                    {this.passwordFieldsShouldShow && <PasswordForm form={this.props.form} user={this.props.user} />}
                </Form>
            )
        }

        protected buildClassNames(suffix?: string): string {
            suffix = suffix === undefined ? '' : `-${suffix}`

            const classNames: string[] = [`user-data-form${suffix}`]
            if (this.props.className) {
                classNames.push(`${this.props.className}${suffix}`)
            }
            return classNames.join(' ')
        }

        protected get isInviteStyle(): boolean {
            return this.props.style === UserDataFormStyle.INVITE
        }

        protected get isConfirmStyle(): boolean {
            return this.props.style === UserDataFormStyle.CONFIRM
        }

        protected get isPasswordResetStyle(): boolean {
            return this.props.style === UserDataFormStyle.PASSWORD_RESET
        }

        protected get isProfileStyle(): boolean {
            return this.props.style === UserDataFormStyle.PROFILE
        }

        protected get domainsShouldLoad(): boolean {
            return this.isInviteStyle || this.isProfileStyle
        }

        protected get userTypeFieldShouldShow(): boolean {
            return (this.isInviteStyle || this.isProfileStyle) && this.appUserIsInternalUser
        }

        protected get emailFieldShouldShow(): boolean {
            return this.isInviteStyle || this.isProfileStyle
        }

        protected get nameFieldShouldShow(): boolean {
            return this.isConfirmStyle || this.isProfileStyle
        }

        protected isPropsUserAdminOnCurrentDomain(): boolean {
            const { abilityStore } = this.appState

            return abilityStore.can(AbilityAction.UPDATE, abilityStore.currentDomainIdentity)
        }

        protected isPropsUserReadOnlyOnCurrentDomain(): boolean {
            const { abilityStore } = this.appState

            return (
                abilityStore.can(AbilityAction.READ, abilityStore.currentDomainIdentity) &&
                abilityStore.cannot(AbilityAction.UPDATE, abilityStore.currentDomainIdentity)
            )
        }

        protected get domainsFieldShouldShow(): boolean {
            if (this.isConfirmStyle) {
                return false
            }

            const currentUser = this.appState.currentUser
            const currentDomain = this.appState.currentDomain!
            if (!currentDomain || !currentUser) {
                return false
            }

            const currentUserIsInternal = currentUser.isInternalUser
            let currentUserIsPropsUser = false
            let currentUserIsDomainAdmin = false

            if (this.props.user) {
                currentUserIsPropsUser = currentUser.id.toString() === this.props.user.id.toString()
            } else {
                if (this.props.style !== UserDataFormStyle.INVITE) {
                    return false
                }
            }

            if (!currentUserIsInternal) {
                currentUserIsDomainAdmin = this.appState.abilityStore.can(
                    AbilityAction.MANAGE,
                    this.appState.currentDomain!,
                )
            }

            return (
                !this.state.isInternalUserSwitch &&
                (this.isInviteStyle || this.isProfileStyle) &&
                !currentUserIsPropsUser &&
                (currentUserIsInternal || currentUserIsDomainAdmin)
            )
        }

        protected get passwordFieldsShouldShow(): boolean {
            const styleAllowed = this.isConfirmStyle || this.isPasswordResetStyle || this.isProfileStyle

            if (this.isProfileStyle && (!this.props.user || this.props.user.id !== this.appUser.id)) {
                return false
            }

            return styleAllowed
        }

        protected get appUser(): UserDto {
            return this.appState.currentUser!
        }

        protected get appUserIsInternalUser() {
            return this.appState.currentUser?.isInternalUser === true
        }

        protected toggleInternalUserState = (value: boolean): void => {
            this.setState({ isInternalUserSwitch: value })
        }

        protected handleSubmit = async (): Promise<any> => {
            let values: any
            let error: any
            try {
                values = await validateFields(this.props.form)
                if (values && 'domainSelections' in values && (values?.domainSelections || []).length > 0) {
                    const accounts: IAccountUser[] = []

                    values.domainSelections.forEach((opt: any) => {
                        const domain = this.state.domains.find((dm) => dm.displayName === opt.value)

                        if (domain) {
                            const role =
                                opt.type.toLowerCase() === 'admin'
                                    ? 90
                                    : opt.type.toLowerCase() === 'read only'
                                    ? 10
                                    : 50

                            const access = {
                                role,
                                flags: [],
                            }

                            const accountIdx = accounts.findIndex((a) => a.accountId === domain.accountId)
                            if (accountIdx === -1) {
                                accounts.push({
                                    accountId: domain.accountId,
                                    role: 0,
                                    flags: [],
                                    domainAccess: { [domain.id]: access },
                                })
                            } else {
                                const account = accounts.find((a) => a.accountId === domain.accountId)!
                                account.domainAccess[domain.id] = access
                                accounts.splice(accountIdx, 1, account)
                            }
                        }
                    })

                    if (accounts.length > 0) {
                        values.accounts = accounts
                    }
                }

                if (values && 'domainAccess' in values) {
                    const currentDomain = this.appState.currentDomain!
                    const accountId = currentDomain.accountId

                    const role = values.domainAccess === 'admin' ? 90 : values.domainAccess === 'read only' ? 10 : 50

                    const access = {
                        role,
                        flags: [],
                    }

                    const accounts: IAccountUser[] = []

                    if (this.state.userPermissions) {
                        const accountIdx = this.state.userPermissions.findIndex(
                            (ua) => ua.accountId.toString() === accountId.toString(),
                        )!

                        if (accountIdx === -1) {
                            accounts.push({
                                accountId,
                                role: 0,
                                flags: [],
                                domainAccess: { [currentDomain.id]: access },
                            })
                        } else {
                            const account = this.state.userPermissions.find(
                                (ua) => ua.accountId.toString() === accountId.toString(),
                            )!
                            account.domainAccess[currentDomain.id] = access
                            accounts.splice(accountIdx, 1, account)
                        }
                    }

                    if (accounts.length > 0) {
                        values.accounts = accounts
                    }
                }
            } catch (err) {
                error = err
            }

            return await this.props.onSubmit(error, values)
        }

        protected async fetchUserDependencies(): Promise<void> {
            if (this.props.style === UserDataFormStyle.PROFILE) {
                if (!this.props.user) {
                    setTimeout(() => this.fetchUserDependencies(), 50)
                    return
                }

                const { ok, data: userPermissions } = await this.userService.fetchUserPermissions(this.props.user.id)

                if (ok) {
                    this.setState({ userPermissions })
                }
            }
        }
    },
)
