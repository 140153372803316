import * as React from 'react'
import './styles/notification-builder-preview.scss'
import { Button, Well } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import { extractVariantPreview } from '../helpers'
import { DomainDto } from '../../../dtos/domain'
import { UserDto } from '../../../dtos/user'
import PreviewSDK, { Platform } from '@pushly/cuttlefish'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { getPlatformNotifications } from '../../../_utils/platform'
import { NotificationVariantModel } from '../../../models/notification/notification-variant.model'
import { NotificationBuilderSubmitType } from '../enums'
import { getPushlySdk } from '../../../_utils/pushly-sdk'
import { pause, simpleNotification } from '../../../_utils/utils'
import { UserService } from '../../../services'
import { Container } from 'typescript-ioc/es5'
import { useService } from '../../../hooks/use-service'
import { AppState } from '../../../stores/app'
import { AbilityAction } from '../../../enums/ability-action.enum'
import { SubjectEntity } from '../../../enums/ability-entity.enum'
import { BetterSelect } from '../../better-select/better-select'
import { IBetterSelectOption } from '../../better-select/interfaces'

declare const PushlySDK: any

interface INotificationBuilderPreview {
    domain: DomainDto
    source: NotificationVariantModel
    options?: IBetterSelectOption[]
    loading?: boolean
    hideInlinePreview?: boolean
    hideDevicePreview?: boolean
    chromeless?: boolean
    onPreviewRequest?: (type: NotificationBuilderSubmitType) => void
}

async function registerUserPuuid(user: UserDto, domain: DomainDto): Promise<void> {
    try {
        const sdkUser: any = PushlySDK.context.user
        const sdkDomain: any = PushlySDK.context.domain

        const currentRegistrations: any[] = user.puuids || []

        const permission = await new Promise<string>(async (res) => {
            // this is referring to system prompts / cannot use PromptStyle enum due to SDK style implementation
            const sdkSystemPromptStyle = 'native'
            const nativePrompt = sdkDomain?.prompts?.find((p) => p.style?.toLowerCase() === sdkSystemPromptStyle)
            if (!nativePrompt) {
                res('denied')
            } else {
                if (!(await sdkUser.isSubscribed({ websitePushId: sdkDomain.getWebsitePushId() }))) {
                    PushlySDK.on('subscribed', async () => {
                        // Allow 3 secs for ES doc to be updated
                        await pause(3000)
                        res('granted')
                    })

                    PushlySDK.on('permission_dismissed', () => res('dismissed'))
                    PushlySDK.on('permission_denied', () => res('denied'))
                    PushlySDK.push(['show_prompt', { id: nativePrompt.id }])
                } else {
                    res('granted')
                }
            }
        })

        if (permission === 'granted') {
            const userSvc: UserService = Container.get(UserService)

            const existingRegistration = currentRegistrations.find(
                (r) => r.puuid === sdkUser.puuid && r.domainId === domain.id,
            )

            if (!existingRegistration) {
                await userSvc.registerPuuid(user.id, domain.id, sdkUser.puuid)
            }
        } else if (permission === 'denied') {
            simpleNotification(
                'error',
                `We are unable to send you a preview 
                    of this notification because you have denied notification 
                    permissions. Please manually reset your browser permissions 
                    and attempt to send the preview again.`,
            )
        }
    } catch (_) {}
}

export enum PreviewState {
    COLLAPSED = 'collapsed',
    EXPANDED = 'expanded',
}

const NotificationBuilderPreview = (props: INotificationBuilderPreview) => {
    const appState = useService<AppState>(AppState)
    const { currentUser, currentDomain } = appState
    const { domain, source, options, loading, hideInlinePreview, hideDevicePreview, chromeless, onPreviewRequest } =
        props

    const [previewPlatform, setPreviewPlatform] = React.useState(Platform.ANDROID)
    const [previewState, setPreviewState] = React.useState<PreviewState>(PreviewState.COLLAPSED)
    const activePreviewConfig = extractVariantPreview(domain, source, previewPlatform, previewState, false)

    let userSubStatus = 'none'
    const platformNotifications = getPlatformNotifications()
    const sdk = getPushlySdk()
    if (sdk) {
        userSubStatus = sdk.context?.user?.subscriberState
    }

    const canSendPreview =
        platformNotifications.supported &&
        appState.abilityStore.can(
            AbilityAction.CREATE,
            appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.NOTIFICATION),
        )

    const SubStatusWhitelist = ['granted', 'none', 'subscribed', 'dismissed']
    const dismissPreviewAction = userSubStatus === 'dismissed'
    const disablePreviewAction = SubStatusWhitelist.indexOf(userSubStatus) === -1

    const defaultOptions = [
        {
            value: Platform.ANDROID,
            label: 'Android',
        },
        {
            value: Platform.WINDOWS,
            label: 'Web / Windows',
        },
        {
            value: Platform.MAC_OS,
            label: 'MacOS',
        },
    ]

    React.useEffect(() => {
        if (options) {
            const optVals = options.map((opt) => opt.value)
            if (!optVals.includes(previewPlatform)) {
                setPreviewPlatform((options![0]?.value as Platform) ?? Platform.ANDROID)
            }
        }
    }, [options])

    return (
        <>
            <Well
                className={getClassNames('notification-builder-preview', 'nested', {
                    'no-inline-preview': hideInlinePreview,
                    'no-device-preview': hideDevicePreview,
                })}
                disabled={loading}
                hideFooter={true}
                mode={chromeless ? 'ghost' : 'default'}
                title={
                    <span>
                        <span>Preview</span>
                        <Popover
                            overlayClassName={getClassNames('notification-builder-popover', 'inline-preview-popover')}
                            content={
                                <>
                                    <p>
                                        The platform preview is a best-guess at how this notification will look across
                                        the most common devices for the selected operating system.
                                    </p>

                                    <p>
                                        Due to variability between devices this preview may not accurately depict title
                                        & body length limitations or image crop & placement. A more accurate preview may
                                        be obtained via a notification preview on your browser or mobile device by using
                                        the Device Preview.
                                    </p>
                                </>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </span>
                }
            >
                <div className="preview-select">
                    <BetterSelect
                        prefix="Platform"
                        disableSearch={true}
                        onChange={(val: Platform) => {
                            switch (val) {
                                case Platform.MAC_OS:
                                    setPreviewState(PreviewState.COLLAPSED)
                                    break
                                case Platform.ANDROID:
                                    setPreviewState(PreviewState.EXPANDED)
                                    break
                            }
                            setPreviewPlatform(val)
                        }}
                        defaultValue={[Platform.ANDROID]}
                        value={[previewPlatform as string]}
                        options={options?.length ? options : defaultOptions}
                    />

                    <BetterSelect
                        prefix="State"
                        disableSearch={true}
                        defaultValue={[PreviewState.EXPANDED]}
                        value={[previewState]}
                        onChange={(v: PreviewState) => {
                            setPreviewState(v)
                        }}
                        options={[
                            {
                                value: PreviewState.EXPANDED,
                                label: 'Expanded',
                            },
                            {
                                value: PreviewState.COLLAPSED,
                                label: 'Collapsed',
                            },
                        ]}
                    />
                </div>
                {!hideInlinePreview && (
                    <Well
                        className="preview-wrapper"
                        disabled={loading}
                        hideHeader={true}
                        hideFooter={true}
                        mode="ghost"
                    >
                        <PreviewSDK.Preview config={activePreviewConfig} />
                    </Well>
                )}

                {!hideDevicePreview && (
                    <Well
                        className={getClassNames('notification-builder-device-preview', {
                            unsupported: !platformNotifications.supported,
                        })}
                        disabled={loading}
                        hideFooter={true}
                        mode="ghost"
                        title={
                            <span>
                                Device Preview
                                {!platformNotifications.supported ? (
                                    <WarningOutlined className="info-icon" />
                                ) : (
                                    <Popover
                                        overlayClassName={getClassNames(
                                            'notification-builder-popover',
                                            'device-preview-popover',
                                        )}
                                        content={
                                            <>
                                                <p>
                                                    A device preview will immediately deliver the selected notification
                                                    to all registered devices for the chosen preview destination.
                                                </p>
                                                <p>
                                                    You may choose to deliver a preview of the currently selected
                                                    notification to your registered personal devices (Personal Preview)
                                                    or to all internal <span>{domain.displayName} </span>
                                                    users that have registered devices for preview notifications (Team
                                                    Preview).
                                                </p>

                                                {platformNotifications.supported ? (
                                                    platformNotifications.configured ? (
                                                        !dismissPreviewAction ? (
                                                            !disablePreviewAction ? (
                                                                <>
                                                                    <p>
                                                                        To receive previews on multiple devices you may
                                                                        <br />
                                                                        <a
                                                                            className="register-preview-device-link"
                                                                            href={`${appState.documentationLink}/platform/notifications/notification-previews#registering-additional-preview-devices`}
                                                                            target="_blank"
                                                                        >
                                                                            associate additional devices
                                                                        </a>{' '}
                                                                        with your user.
                                                                    </p>

                                                                    <p>
                                                                        Any additional devices you add will be added to
                                                                        your list of personal devices and will receive a
                                                                        notification preview when you choose the
                                                                        'Personal Preview' destination or someone else
                                                                        sends a 'Team Preview'.
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <p>
                                                                        Notifications are blocked in the current
                                                                        browser.
                                                                    </p>
                                                                    <p>
                                                                        Please manually reset your browser permissions,
                                                                        reload the page, and re-register this device for
                                                                        notification previews.
                                                                    </p>
                                                                </>
                                                            )
                                                        ) : (
                                                            <>
                                                                <p>
                                                                    Notifications have been dismissed in the current
                                                                    browser.
                                                                </p>
                                                                <p>
                                                                    Please reload the page, and re-register this device
                                                                    for notification previews.
                                                                </p>
                                                            </>
                                                        )
                                                    ) : (
                                                        <p>Notifications are not configured in the current browser.</p>
                                                    )
                                                ) : (
                                                    <p>Notifications are not supported in the current browser.</p>
                                                )}
                                            </>
                                        }
                                    >
                                        <InfoCircleOutlined className="info-icon" />
                                    </Popover>
                                )}
                            </span>
                        }
                        actions={
                            canSendPreview && (
                                <div>
                                    <Button
                                        size="small"
                                        onClick={async () => {
                                            await registerUserPuuid(currentUser!, currentDomain!)
                                            return onPreviewRequest?.(NotificationBuilderSubmitType.PERSONAL_PREVIEW)
                                        }}
                                        disabled={false}
                                        actions={[
                                            {
                                                text: 'Send Team Preview',
                                                onClick: async () => {
                                                    await registerUserPuuid(currentUser!, currentDomain!)
                                                    return onPreviewRequest?.(
                                                        NotificationBuilderSubmitType.TEAM_PREVIEW,
                                                    )
                                                },
                                                disabled: false,
                                                disabledMessage:
                                                    'At least one team member must be registered to send a team preview.',
                                            },
                                        ]}
                                    >
                                        <span>Send Personal Preview</span>
                                    </Button>
                                </div>
                            )
                        }
                    >
                        {!platformNotifications.supported && (
                            <p>
                                Device previews are currently unavailable. Push Notifications are not supported in the
                                current browser session. Please ensure you are not running in incognito or private mode
                                and that you are not running any ad-blockers.
                            </p>
                        )}
                    </Well>
                )}
            </Well>
        </>
    )
}

export default NotificationBuilderPreview
