import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { SolutionOutlined, TeamOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import { NotificationDto } from '../../features/notifications/index'
import { NotificationScheduleDto } from '../../features/notifications/dtos/notification-schedule-dto'
import { SwLink } from '../sw-link/sw-link'
import './notification-audience-badge.scss'

interface IProps extends IBadgeProps {
    source: NotificationDto | NotificationScheduleDto
}

export class NotificationAudienceBadge extends React.PureComponent<IProps> {
    protected appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render() {
        const { source } = this.props

        const allDisplay = (
            <span className="included-badge">
                <span className="included-icon">
                    <SolutionOutlined />
                </span>
                <span>All</span>
            </span>
        )

        const isUsingSubscriberIds =
            Array.isArray(source.audience.subscriberIds) || Array.isArray(source.audience.externalSubscriberIds)
        const isUsingDefaultAll =
            !isUsingSubscriberIds && source.audience.segments!.length === 1 && source.audience.segments![0].isDefault
        const isUsingExcludedSegments =
            !isUsingSubscriberIds && !!source.audience.excludedSegments && source.audience.excludedSegments.length > 0

        let badgeSet: React.ReactNode = allDisplay

        if (isUsingSubscriberIds) {
            badgeSet = (
                <span className="included-badge">
                    <span className="included-icon">
                        <TeamOutlined />
                    </span>
                    <span>Subscriber IDs</span>
                </span>
            )
        } else if (!isUsingDefaultAll || isUsingExcludedSegments) {
            const segments = (source.audience.segments || []).slice()
            const excludedSegments = (source.audience.excludedSegments || []).slice()
            const nonDefaultSegments = isUsingDefaultAll ? segments : segments.filter((s) => !s.isDefault)

            let includedBadge: any
            let excludedBadge: any

            if (nonDefaultSegments.length > 0) {
                includedBadge = isUsingDefaultAll ? (
                    allDisplay
                ) : (
                    <span className="included-badge">
                        <span className="included-icon">
                            <SolutionOutlined />
                        </span>
                        <span>{nonDefaultSegments.length}</span>
                        <span>Included</span>
                    </span>
                )
            }

            if (excludedSegments.length > 0) {
                excludedBadge = (
                    <span className="excluded-badge">
                        <span className="excluded-icon">
                            <SolutionOutlined />
                        </span>
                        <span>{excludedSegments.length}</span>
                        <span>Excluded</span>
                    </span>
                )
            }

            badgeSet = (
                <>
                    {!!includedBadge &&
                        (isUsingDefaultAll || this.props.hideTooltip ? (
                            includedBadge
                        ) : (
                            <Popover
                                getPopupContainer={() => this.appService.getAppContainer()}
                                overlayClassName="nd-excluded-popover"
                                title="Included Segments"
                                content={nonDefaultSegments.map((s) => (
                                    <div key={s.id}>
                                        <SwLink
                                            href={this.appService.routeWithinDomain(`/segments/${s.id}/summary`, true)}
                                        >
                                            {/^all$/i.test(s.name) ? 'All Subscribers' : s.name}
                                        </SwLink>
                                    </div>
                                ))}
                            >
                                {includedBadge}
                            </Popover>
                        ))}
                    {!!excludedBadge && (
                        <>
                            <span className="details-divider">|</span>

                            {this.props.hideTooltip ? (
                                excludedBadge
                            ) : (
                                <Popover
                                    getPopupContainer={() => this.appService.getAppContainer()}
                                    overlayClassName="nd-excluded-popover"
                                    title="Excluded Segments"
                                    content={excludedSegments.map((s) => (
                                        <div key={s.id}>
                                            <SwLink
                                                href={this.appService.routeWithinDomain(
                                                    `/segments/${s.id}/summary`,
                                                    true,
                                                )}
                                            >
                                                {s.name}
                                            </SwLink>
                                        </div>
                                    ))}
                                >
                                    {excludedBadge}
                                </Popover>
                            )}
                        </>
                    )}
                </>
            )
        }

        return <span className="notification-audience-badge">{badgeSet}</span>
    }
}
