import * as React from 'react'
import './style/user-access-view.scss'
import { DomainDto } from '../../dtos/domain'
import { UserDto } from '../../dtos/user'
import { AccountService, AppService, DomainService, UserService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { getClassNames } from '../../_utils/classnames'
import { AntdTableEmptyPlaceholder } from '../aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { CompoundTable, CompoundTableHeader } from '../aqe/compound-table/compound-table'
import { TableRowEntityDisplay } from '../table-row-entity-display/table-row-entity-display'
import { Loading3QuartersOutlined, WarningOutlined } from '@ant-design/icons'
import { Modal, Popover, Radio, Select, Space, Table } from 'antd'
import { AbilityAction } from '../../enums/ability-action.enum'
import { AppState } from '../../stores/app'
import AccessControlService from '../../services/access-control.service'
import { AccessRoleId, ExternalAccessRoleIds, InternalAccessRoleIds } from '../../enums/access-role.enum'
import { AccessRoleMap } from '../../models/access-role'
import DomainUserModel from '../../models/access-control/domain-user.model'
import { observer } from 'mobx-react'
import AccountUserModel from '../../models/access-control/account-user.model'

export interface IUserAccountAccessView {
    user: UserDto & any
    acs: AccessControlService
    account: { id: number }
    domainId?: number
    singleDomain?: boolean
    onChange?: (acs: AccessControlService) => any
    mode?: 'default' | 'stand-alone' | 'nested' | 'read-only' | 'add-user'
    disabled?: boolean
}

export interface IUserAccountAccessViewState {
    domains: DomainDto[]
    loadingDomains: boolean
    selectedAccessRole: number | undefined
}

@observer
export class UserAccountAccessView extends React.Component<IUserAccountAccessView, IUserAccountAccessViewState> {
    protected appState: AppState
    protected appService: AppService
    protected userService: UserService
    protected domainService: DomainService
    protected accountService: AccountService

    public constructor(props: IUserAccountAccessView) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.userService = Container.get(UserService)
        this.domainService = Container.get(DomainService)
        this.accountService = Container.get(AccountService)

        this.state = {
            loadingDomains: true,
            domains: [],
            selectedAccessRole: undefined,
        }
    }

    public componentDidMount(): void {
        this.fetchDomains()
    }

    public render() {
        const { mode } = this.props
        const isNestedMode = mode === 'nested' || mode === 'add-user'

        return (
            <div
                className={getClassNames('user-access-view', `mode-${this.props.mode ?? 'default'}`, {
                    ['disabled']: this.props.disabled,
                    ['mode-nested']: isNestedMode,
                    ['has-changes']: this.props.acs.hasChanges,
                })}
            >
                {this.renderAccessTables()}
            </div>
        )
    }

    protected renderAccessTables() {
        const modeIsAddUser = this.props.mode === 'add-user'
        const platformUserIsOrgAdmin = this.appState.abilityStore.can(
            AbilityAction.MANAGE,
            this.appState.abilityStore.currentOrgIdentity,
        )

        const allDomains = this.state.domains ?? []
        const hasMultipleDomains = allDomains.length > 1
        const hasDomainFilter = !!this.props.domainId

        const disabledRoleIds: AccessRoleId[] = []
        let altDomainDefaultRoleId: AccessRoleId | undefined
        // default to external no-access (internal users can only be created in halo)
        const currDomainDefaultRoleId = this.props.user?.isInternalUser
            ? AccessRoleId.INTERNAL_USER
            : AccessRoleId.EXTERNAL_USER

        if (modeIsAddUser) {
            disabledRoleIds.push(
                AccessRoleId.INTERNAL_NO_ACCESS,
                AccessRoleId.EXTERNAL_API_USER,
                AccessRoleId.INTERNAL_API_USER,
            )
            altDomainDefaultRoleId = AccessRoleId.EXTERNAL_NO_ACCESS
        }

        const primaryDomain = allDomains.find((domain) => domain.id.toString() === this.props.domainId?.toString())
        const altDomains = allDomains.filter((d) => d.id.toString() !== this.props.domainId?.toString())

        let display
        if (!hasDomainFilter) {
            display = (
                <>
                    {this.selectAccessLevel()}
                    {this.renderAccessTable(allDomains)}
                </>
            )
        } else if (this.isLoading || !primaryDomain) {
            display = <AntdTableEmptyPlaceholder text={`Loading Domain Access`} icon="global" />
        } else if (platformUserIsOrgAdmin && hasMultipleDomains) {
            display = (
                <React.Fragment>
                    {this.selectAccessLevel()}
                    {this.renderAccessTable(primaryDomain, 'Current Domain', {
                        disabledRoleIds,
                        defaultRoleId: currDomainDefaultRoleId,
                    })}
                    {this.renderAccessTable(altDomains, 'Other Domains in your Organization', {
                        defaultRoleId: altDomainDefaultRoleId,
                    })}
                </React.Fragment>
            )
        } else {
            display = this.renderAccessTable(primaryDomain, 'Current Domain', {
                disabledRoleIds,
                defaultRoleId: currDomainDefaultRoleId,
            })
        }
        return display
    }

    protected renderAccessTable(
        domains: DomainDto | DomainDto[],
        title?: string,
        opts: {
            disabledRoleIds?: AccessRoleId[]
            defaultRoleId?: AccessRoleId
        } = {},
    ) {
        domains = Array.isArray(domains) ? domains : [domains]

        return (
            <CompoundTable
                className={getClassNames(null, `mode-${this.props.mode ?? 'default'}`, {
                    ['disabled']: this.props.disabled,
                })}
            >
                {title && <CompoundTableHeader className="other-domains-header">{title}</CompoundTableHeader>}

                <Table<DomainDto>
                    rowKey={(domain) => domain.name}
                    className={getClassNames('user-domain-access')}
                    dataSource={domains}
                    loading={
                        this.isLoading && {
                            indicator: <Loading3QuartersOutlined spin={true} />,
                        }
                    }
                    locale={{
                        emptyText: <AntdTableEmptyPlaceholder text="No Domains" icon="global" />,
                    }}
                    pagination={false}
                    showHeader={false}
                >
                    <Table.Column key="name" className="name" title="Domain" render={this.renderDomain} />
                    <Table.Column
                        key="access"
                        className="access"
                        title="Access"
                        render={(_, domain: DomainDto) => this.renderAccessPanel(_, domain, opts)}
                    />
                </Table>
            </CompoundTable>
        )
    }

    protected renderDomain = (_: any, domain: DomainDto) => {
        return <TableRowEntityDisplay title={domain.displayName} status={domain.name} />
    }

    protected selectAccessLevel = () => {
        if (this.props.mode !== 'read-only') {
            let availableRoleIds = this.props.user.isInternalUser ? InternalAccessRoleIds : ExternalAccessRoleIds

            const apiRoleIds = [AccessRoleId.INTERNAL_API_USER, AccessRoleId.EXTERNAL_API_USER]

            if (this.props.user?.id && !this.props.user?.isApiUser) {
                availableRoleIds = availableRoleIds.filter((id) => !apiRoleIds.includes(id))
            }

            return (
                <Space className={getClassNames('user-access-view-bulk-row')} size="large">
                    <div>
                        <b>Bulk Apply User Domain Access</b>
                    </div>
                    <Select
                        size="small"
                        disabled={this.props.user?.isApiUser || this.props.user?.isInternalDevUser}
                        onChange={this.renderConfirmModal}
                        value={this.state.selectedAccessRole}
                        placeholder="Access Level"
                    >
                        {availableRoleIds.map((id) => (
                            <Select.Option key={id} value={id}>
                                {AccessRoleMap.get(id)?.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Space>
            )
        } else return
    }

    protected clearSelectedAccessRole = () => {
        this.setState({ selectedAccessRole: undefined })
    }

    protected renderConfirmModal = (roleId: number) => {
        this.setState({ selectedAccessRole: roleId })
        const roleName = AccessRoleMap.get(roleId)?.label

        return Modal.confirm({
            okText: 'Yes, Apply',
            okType: 'primary',
            cancelText: 'Cancel',
            title: 'Bulk Domain Access Assignment',
            content: (
                <div>
                    <p>
                        Are you sure you want to apply <b>{roleName?.toLowerCase()}</b> access to all domains?
                    </p>
                </div>
            ),
            onOk: () => {
                this.clearSelectedAccessRole()
                return this.updateAccountDomainRolesFromSelect(roleId)
            },
            onCancel: this.clearSelectedAccessRole,
        })
    }

    protected renderAccessPanel = (
        _: any,
        domain: DomainDto,
        opts: {
            disabledRoleIds?: AccessRoleId[]
            defaultRoleId?: AccessRoleId
        } = {},
    ) => {
        const { mode } = this.props
        const modeIsDefault = !mode || mode === 'default'
        const modeIsReadOnly = mode === 'read-only'
        const disabledRoleIds = opts?.disabledRoleIds ?? [
            AccessRoleId.INTERNAL_API_USER,
            AccessRoleId.EXTERNAL_API_USER,
        ]
        let roleId = opts?.defaultRoleId ?? AccessRoleId.EXTERNAL_NO_ACCESS
        const domainRecord = this.props.acs.getDomainRecord(domain.id)
        if (domainRecord) {
            roleId = domainRecord.getRoleId()
        }

        const roleIdOptions = this.props.user.isInternalUser ? InternalAccessRoleIds : ExternalAccessRoleIds
        const visibleRoleIdOptions = roleIdOptions.filter((id) => !disabledRoleIds.includes(id))
        const invalidRoleTypeCombination = !visibleRoleIdOptions.includes(roleId)

        // ensure invalid assignments show as no assignment
        const currentRoleIdValue = invalidRoleTypeCombination ? undefined : roleId

        let roleWarning
        if (invalidRoleTypeCombination) {
            roleWarning = (
                <Popover content={'Invalid role selection'}>
                    <WarningOutlined className="access-warning" />
                </Popover>
            )
        }

        let display
        if (modeIsReadOnly) {
            display = (
                <span className="ro-mode-role-name">
                    {roleWarning}
                    {AccessRoleMap.get(roleId)?.label}
                </span>
            )
        } else if (modeIsDefault) {
            display = (
                <React.Fragment>
                    {roleWarning}
                    <Radio.Group
                        value={currentRoleIdValue}
                        size="small"
                        onChange={(ev) => this.updateAccountDomainRole(domain, ev.target.value)}
                    >
                        {visibleRoleIdOptions.map((key) => (
                            <Radio key={key} value={key}>
                                {AccessRoleMap.get(key)?.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                </React.Fragment>
            )
        } else {
            display = (
                <React.Fragment>
                    {roleWarning}
                    <Select<AccessRoleId>
                        value={currentRoleIdValue}
                        size="small"
                        onChange={(value) => this.updateAccountDomainRole(domain, value)}
                    >
                        {visibleRoleIdOptions.map((key) => (
                            <Select.Option key={key} value={key}>
                                {AccessRoleMap.get(key)?.label}
                            </Select.Option>
                        ))}
                    </Select>
                </React.Fragment>
            )
        }

        return display
    }

    protected get isLoading(): boolean {
        return this.state.loadingDomains ?? false
    }
    protected async updateAccountDomainRole(domain: DomainDto, roleId: AccessRoleId) {
        const shouldAutoPersist = this.props.mode !== 'nested' && this.props.mode !== 'add-user'

        const permissionRecords = this.props.acs.getCachedUserRecords()
        const noAccessRole = AccessRoleMap.get(AccessRoleId.EXTERNAL_NO_ACCESS)
        const accessRole = AccessRoleMap.get(roleId)

        if (accessRole) {
            let orgRecord = permissionRecords.get(domain.accountId)
            if (!orgRecord) {
                orgRecord = new AccountUserModel({
                    accountId: domain.accountId,
                    roleId: noAccessRole!.id,
                    roleName: noAccessRole!.name,
                    flags: [],
                })
            }

            let domainRecord = this.props.acs
                .getCachedUserRecords()
                .get(domain.accountId)
                ?.getDomainRecord(domain.id)
                ?.clone()

            if (domainRecord) {
                domainRecord.setRole(accessRole)
            } else {
                domainRecord = new DomainUserModel({
                    accountUserId: orgRecord.getId(),
                    domainId: domain.id,
                    roleId: accessRole.id,
                    roleName: accessRole.name,
                    flags: [],
                })
            }

            await this.props.acs.updateCachedRecord(orgRecord.getAccountId(), orgRecord, domainRecord)

            if (shouldAutoPersist) {
                await this.props.acs.persistChanges()
            }

            this.props.onChange?.(this.props.acs)
        }
    }

    protected async updateAccountDomainRolesFromSelect(roleId: AccessRoleId) {
        const shouldAutoPersist = this.props.mode !== 'nested' && this.props.mode !== 'add-user'

        const permissionRecords = this.props.acs.getCachedUserRecords()
        const noAccessRole = AccessRoleMap.get(AccessRoleId.EXTERNAL_NO_ACCESS)
        const accessRole = AccessRoleMap.get(roleId)

        const firstDomain = this.state.domains[0] ? this.state.domains[0] : null
        const domainRecords: DomainUserModel[] = []

        if (accessRole && firstDomain) {
            let orgRecord = permissionRecords.get(firstDomain.accountId)
            if (!orgRecord) {
                orgRecord = new AccountUserModel({
                    accountId: firstDomain.accountId,
                    roleId: noAccessRole!.id,
                    roleName: noAccessRole!.name,
                    flags: [],
                })
            }

            this.state.domains.forEach((domain) => {
                let domainRecord = this.props.acs
                    .getCachedUserRecords()
                    .get(domain.accountId)
                    ?.getDomainRecord(domain.id)
                    ?.clone()

                if (domainRecord) {
                    domainRecord.setRole(accessRole)
                } else {
                    domainRecord = new DomainUserModel({
                        accountUserId: orgRecord?.getId(),
                        domainId: domain.id,
                        roleId: accessRole.id,
                        roleName: accessRole.name,
                        flags: [],
                    })
                }
                domainRecords.push(domainRecord)
            })

            await this.props.acs.updateCachedRecords(orgRecord.getAccountId(), orgRecord, domainRecords)

            if (shouldAutoPersist) {
                await this.props.acs.persistChanges()
            }

            this.props.onChange?.(this.props.acs)
        }
    }

    protected async fetchDomains(): Promise<void> {
        if (!this.props.account) {
            setTimeout(() => this.fetchDomains(), 50)
            return
        }

        let domains = this.appState.currentUserDomains?.filter((d) => d.accountId === this.props.account.id) ?? []
        this.setState({
            loadingDomains: !domains.length,
            domains,
        })

        if (!domains.length) {
            const newState = {
                loadingDomains: false,
                domains,
            }

            const { ok, data } = await this.domainService.fetchByAccountId(this.props.account.id, {
                query: { pagination: false },
                showLoadingScreen: false,
                cancellationKey: `user-view-fetch-account-${this.props.account.id}-domains`,
            })

            if (ok) {
                newState.domains = data
            }

            this.setState(newState)
        }
    }
}
