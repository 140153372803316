import * as React from 'react'
import { observe } from 'mobx'
import { observer } from 'mobx-react'
import autobind from 'autobind-decorator'
import Picker from 'emoji-picker-react'
import { Container } from 'typescript-ioc/es5'
import { InfoCircleOutlined, ShoppingCartOutlined, UndoOutlined, SmileOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Popover, Select, Switch, Table, Tooltip } from 'antd'
import { getWithDefault, noop, simpleNotification } from '../../../_utils/utils'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Well } from '../../../components/well/well'
import { DomainDto } from '../../../dtos/domain'
import { AppState } from '../../../stores/app'
import './notification-data-form.scss'
import { AppService, DomainService } from '../../../services'
import { DomainKeyword } from '../../../dtos/domain'
import { BetterInput } from '../../../components/better-input/better-input'
import * as randomstring from 'randomstring'
import * as clone from 'clone'
import { getClassNames } from '../../../_utils/classnames'
import { INotificationAction } from '../../../interfaces/notification-action'
import { INotificationTreatment } from '../../../interfaces/notification-treatment'
import { getCustomMacroFields, parseDefaultNotificationAction } from '../../../_utils/domain'
import { NdfFormMode } from '../../../components/notification-data-form/enums/ndf-form-mode.enum'
import { NdfFormTheme } from '../../../components/notification-data-form/enums/ndf-form-theme.enum'
import { NotificationActionBuilder } from '../../../components/notification-action-builder/notification-action-builder'
import { MacroManager } from '../../../components/macro-manager/macro-manager'
import { waitForDefined } from '../../../_utils/wait-for-defined'
import { isMacroMatch } from '../../../_utils/macros'
import { FEAT_NOTIF_REQUIRE_INTERACTION_TOGGLE } from '../../../constants'
import { default as GraphemeSplitter } from 'grapheme-splitter'
import { FileUploader } from '@pushly/aqe/lib/components'
import classnames from 'classnames'

type UploadType = 'icon' | 'badge' | 'image'

interface IProps {
    mode: NdfFormMode
    theme: NdfFormTheme
    treatment: INotificationTreatment
    domainKeywords: DomainKeyword[]
    onChange: (value: INotificationTreatment) => void
    isWelcomeNotificationForm: boolean
    defaultDomainIconUrl?: string
    defaultSegmentIconUrl?: string
    showEcommElements?: boolean

    errors?: ReadonlyArray<{ field: string; message: string }>
}

interface IState {
    domain?: DomainDto
    domainKeywords?: DomainKeyword[]

    landingUrlEnabled: boolean
    ttlMetric: string
    titleEmojiEditorVisible: boolean
    bodyEmojiEditorVisible: boolean
    lastDefaultSegmentIconUrl?: string
    isSilent: boolean
    requireInteraction: boolean
    advancedActionsEnabled: boolean
    actions?: INotificationAction[]
    customMacroOptions?: any[]

    uploads: {
        image?: any
        icon?: any
        badge?: any
    }

    value: INotificationTreatment

    renderKey: string
}

@observer
export class NotificationDataMessage extends BetterComponent<IProps, IState> {
    private readonly appState: AppState
    private readonly appService: AppService
    private readonly domainService: DomainService

    private containerRef: any
    private titleField: any
    private bodyField: any
    private landingUrlField: any
    private keywordsField: any
    private ttlSecondsField: any
    private isSilentField: any
    private reqInteractionField: any
    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)

        this.state = {
            value: props.treatment ?? {},
            landingUrlEnabled: true,
            ttlMetric: props.treatment.ttlMetric!,
            titleEmojiEditorVisible: false,
            bodyEmojiEditorVisible: false,
            isSilent: props.treatment.isSilent ?? false,
            requireInteraction: props.treatment.requireInteraction ?? true,
            uploads: {
                image: props.treatment.image,
                icon: props.treatment.icon,
                badge: props.treatment.badge,
            },
            renderKey: randomstring.generate(),
            advancedActionsEnabled: props.treatment.advancedActionsEnabled ?? false,
            actions: props.treatment.actions,
        }
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        if (isMacroMatch(this.props.treatment.image, 'item.image')) {
            this.handleUseEcommItemImageToggle(true)
        }

        this.bodyField.input.parentNode.addEventListener('mousedown', (ev) => this.handleInputInteraction(ev, 'body'))
        this.titleField.input.parentNode.addEventListener('mousedown', (ev) => this.handleInputInteraction(ev, 'title'))
        this.bodyField.input.parentNode.addEventListener('keydown', (ev) => this.handleInputInteraction(ev, 'body'))
        this.titleField.input.parentNode.addEventListener('keydown', (ev) => this.handleInputInteraction(ev, 'title'))

        this.setDomainState()

        this.updateTreatment()
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount()
        this.disposeObservers.forEach((f: any) => f())

        this.bodyField.input.parentNode.removeEventListener('mousedown', (ev) =>
            this.handleInputInteraction(ev, 'body'),
        )
        this.titleField.input.parentNode.removeEventListener('mousedown', (ev) =>
            this.handleInputInteraction(ev, 'title'),
        )
        this.bodyField.input.parentNode.removeEventListener('keydown', (ev) => this.handleInputInteraction(ev, 'body'))
        this.titleField.input.parentNode.removeEventListener('keydown', (ev) =>
            this.handleInputInteraction(ev, 'title'),
        )
    }

    public componentDidUpdate(prevProps: IProps): void {
        if (
            !!this.props.defaultSegmentIconUrl &&
            this.props.defaultSegmentIconUrl !== this.state.lastDefaultSegmentIconUrl
        ) {
            this.setState({ lastDefaultSegmentIconUrl: this.props.defaultSegmentIconUrl })
        }

        if (prevProps.treatment?.id !== this.props.treatment?.id) {
            // reset actions when treatment changes
            this.setState({ actions: this.props.treatment.actions })
        }
    }

    public render(): React.ReactNode {
        return (
            <div ref={(element) => (this.containerRef = element)} className="treatment-form">
                {this.renderDesignPanel()}
                {this.renderDestinationPanel()}
                {!this.props.isWelcomeNotificationForm && this.renderTrackingPanel()}
                {!this.props.isWelcomeNotificationForm && this.renderOptionsPanel()}
            </div>
        )
    }

    private renderDesignPanel(): React.ReactNode {
        const errors = this.props.errors
        const titleHasError = errors && errors.find((err: any) => err.field === 'title')
        const bodyHasError = errors && errors.find((err: any) => err.field === 'body')

        const renderHeaderAction = () => (
            <React.Fragment>
                {!this.props.isWelcomeNotificationForm && (
                    <React.Fragment>
                        <div className="clear-message" onClick={this.clearTreatment}>
                            Clear Form
                            <UndoOutlined className="info-icon" />
                        </div>
                        <div className="flex-div" />
                    </React.Fragment>
                )}
                <div className="message-info-toggle">
                    <Popover
                        overlayClassName="message-info-overlay"
                        content={this.renderCharacterLimitTooltip()}
                        trigger="click"
                        getPopupContainer={() => this.containerRef}
                    >
                        <a onClick={() => {}}>
                            Character Limits
                            <InfoCircleOutlined className="info-icon" />
                        </a>
                    </Popover>
                </div>
            </React.Fragment>
        )

        return (
            <Well className="design-well" title="Design" showFooter={false} ghost={true} action={renderHeaderAction}>
                <Form.Item label="Title" className={titleHasError ? 'has-error' : ''}>
                    <div className="emoji-input-wrapper">
                        <Popover
                            overlayClassName="message-info-overlay"
                            content={
                                <div className="emoji-picker-react">
                                    <Picker
                                        onEmojiClick={(ev, emoji) => this.addEmoji(ev, emoji, 'title')}
                                        disableSkinTonePicker={true}
                                        preload={true}
                                    />
                                </div>
                            }
                            trigger="click"
                            visible={this.state.titleEmojiEditorVisible}
                            onVisibleChange={(visible) => this.setEmojiFieldVisibleState('title', visible)}
                            getPopupContainer={this.appService.getAppContainer}
                        >
                            <span className="emojiTrigger">
                                <SmileOutlined />
                            </span>
                        </Popover>

                        <MacroManager
                            types={
                                this.currentThemeIsCampaign ? ['location', 'ecomm', 'custom'] : ['location', 'custom']
                            }
                            customOptions={waitForDefined(() => this.state.customMacroOptions!)}
                            validOptionTypes={
                                this.currentThemeIsCampaign ? [this.context.domain.ecommConfig.itemType] : undefined
                            }
                        >
                            <BetterInput
                                ref={(el) => (this.titleField = el)}
                                placeholder="Notification title"
                                onChange={() => this.updateTreatment()}
                                value={this.props.treatment.title}
                                maxChars={40}
                                charExceededMessage={
                                    'Title may be cut off or prevent the Body from showing on some devices.'
                                }
                            />
                        </MacroManager>
                    </div>
                </Form.Item>
                <Form.Item label="Body" className={bodyHasError ? 'has-error' : ''}>
                    <div className="emoji-input-wrapper">
                        <Popover
                            overlayClassName="message-info-overlay"
                            content={
                                <div className="emoji-picker-react">
                                    <Picker
                                        onEmojiClick={(ev, emoji) => this.addEmoji(ev, emoji, 'body')}
                                        disableSkinTonePicker={true}
                                    />
                                </div>
                            }
                            trigger="click"
                            visible={this.state.bodyEmojiEditorVisible}
                            onVisibleChange={(visible) => this.setEmojiFieldVisibleState('body', visible)}
                            getPopupContainer={this.appService.getAppContainer}
                        >
                            <span className="emojiTrigger">
                                <SmileOutlined />
                            </span>
                        </Popover>

                        <MacroManager
                            types={
                                this.currentThemeIsCampaign ? ['location', 'ecomm', 'custom'] : ['location', 'custom']
                            }
                            customOptions={waitForDefined(() => this.state.customMacroOptions!)}
                            validOptionTypes={
                                this.currentThemeIsCampaign ? [this.context.domain.ecommConfig.itemType] : undefined
                            }
                        >
                            <BetterInput
                                ref={(el) => (this.bodyField = el)}
                                placeholder="Notification body"
                                onChange={() => this.updateTreatment()}
                                value={this.props.treatment.body}
                                maxChars={140}
                                charExceededMessage={'Body may be cut off on some devices.'}
                            />
                        </MacroManager>
                    </div>
                </Form.Item>

                <div className="message-lower">
                    <div className="message-lower-left">
                        <div className="notification-artwork">
                            <div className="image-wrapper">
                                <Form.Item
                                    className={getClassNames(null, 'image-form-item', {
                                        ['show-ecomm-toggle']: this.currentThemeIsCampaign,
                                    })}
                                    label={
                                        <span>
                                            <Tooltip title="This image will be displayed underneath the title and body of the notification on Windows and Android. Recommended minimum size is 360x180 with an aspect ratio of 2:1.">
                                                Image:
                                                <InfoCircleOutlined className="info-icon" />
                                            </Tooltip>

                                            {this.currentThemeIsCampaign && !!this.props.showEcommElements && (
                                                <span className="ecomm-item-image-toggle-wrapper">
                                                    <Switch
                                                        size="small"
                                                        checked={isMacroMatch(this.props.treatment.image, 'item.image')}
                                                        onChange={this.handleUseEcommItemImageToggle}
                                                    />
                                                    <span>Use item image</span>
                                                </span>
                                            )}
                                        </span>
                                    }
                                >
                                    {isMacroMatch(this.props.treatment.image, 'item.image') ? (
                                        <button
                                            className="filestack-upload ant-upload ant-upload-select ant-upload-select-picture-card image-mode image-upload disabled"
                                            disabled={true}
                                        >
                                            <span className="ant-upload contents" role="button">
                                                <div className="faux-btn">
                                                    <ShoppingCartOutlined />
                                                    <span>
                                                        {'{{'}item.image{'}}'}
                                                    </span>
                                                </div>
                                            </span>
                                        </button>
                                    ) : (
                                        <FileUploader
                                            className="image-upload"
                                            modalClassName={classnames({ ['mv']: this.currentTheme === 'MOBILE' })}
                                            type="image"
                                            ownerType="domain"
                                            ownerId={this.appState.currentDomain!.id}
                                            defaultValue={
                                                !this.props.treatment.image
                                                    ? undefined
                                                    : {
                                                          url: this.props.treatment.image!,
                                                      }
                                            }
                                            // value={!this.props.treatment.image ? undefined : {
                                            //     url: this.props.treatment.image!,
                                            // }}
                                            imageConfig={{
                                                cropMax: [2048, 1024],
                                                cropMin: [360, 240],
                                                cropRatio: 2,
                                                pjpg: true,
                                            }}
                                            platformMode={this.currentTheme === 'MOBILE' ? 'MOBILE' : 'STANDARD'}
                                            onSuccess={({ url }) => {
                                                this.handleUploadSuccess(url, 'image')
                                            }}
                                            onRemove={() => {
                                                this.handleUploadRemoval('image')
                                            }}
                                            onError={(res: any) => {
                                                const error = res?.error
                                                if (error && error.error_type === 'invalid_file_type_error') {
                                                    return simpleNotification('error', error.message)
                                                }
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                            <div className="icon-wrapper">
                                <Form.Item
                                    label={
                                        <span>
                                            <Tooltip
                                                title={
                                                    <span>
                                                        <p>
                                                            This image will be displayed as a medium-size icon next to
                                                            the title and body of the notification on all platforms.
                                                            Recommended size is 512x512 and an aspect ratio of 1:1.
                                                        </p>

                                                        <p>
                                                            <strong>Note:</strong> Changing this icon has no effect for
                                                            Safari notifications. Instead, the subscriber will receive a
                                                            notification with the default icon that was set on your
                                                            domain when they subscribed.
                                                        </p>
                                                    </span>
                                                }
                                            >
                                                Icon:
                                                <InfoCircleOutlined className="info-icon" />
                                            </Tooltip>
                                        </span>
                                    }
                                >
                                    <FileUploader
                                        className="icon-upload"
                                        modalClassName={classnames({ ['mv']: this.currentTheme === 'MOBILE' })}
                                        type="image"
                                        ownerType="domain"
                                        ownerId={this.appState.currentDomain!.id}
                                        defaultValue={
                                            !this.props.treatment.icon
                                                ? undefined
                                                : {
                                                      url: this.props.treatment.icon,
                                                  }
                                        }
                                        platformMode={this.currentTheme === 'MOBILE' ? 'MOBILE' : 'STANDARD'}
                                        imageConfig={{
                                            cropMax: FileUploader.defaultDimensions.notificationIcon,
                                            cropRatio: 1,
                                        }}
                                        onSuccess={({ url }) => this.handleUploadSuccess(url, 'icon')}
                                        onRemove={() => this.handleUploadRemoval('icon')}
                                    />
                                </Form.Item>
                            </div>
                            <div className="badge-wrapper">
                                <Form.Item
                                    label={
                                        <span>
                                            <Tooltip title="This image will be displayed as a small black-and-white icon in the top left corner of an Android notification.">
                                                Badge:
                                                <InfoCircleOutlined className="info-icon" />
                                            </Tooltip>
                                        </span>
                                    }
                                >
                                    <FileUploader
                                        className="badge-upload"
                                        modalClassName={classnames({ ['mv']: this.currentTheme === 'MOBILE' })}
                                        type="image"
                                        ownerType="domain"
                                        ownerId={this.appState.currentDomain!.id}
                                        defaultValue={
                                            !this.props.treatment.badge
                                                ? undefined
                                                : {
                                                      url: this.props.treatment.badge!,
                                                  }
                                        }
                                        platformMode={this.currentTheme === 'MOBILE' ? 'MOBILE' : 'STANDARD'}
                                        imageConfig={{
                                            cropMax: FileUploader.defaultDimensions.notificationBadge,
                                            cropRatio: 1,
                                        }}
                                        onSuccess={({ url }) => this.handleUploadSuccess(url, 'badge')}
                                        onRemove={() => this.handleUploadRemoval('badge')}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </div>
            </Well>
        )
    }

    protected get currentTheme(): NdfFormTheme {
        return this.props.theme
    }

    private getCompiledActions(reset: boolean = false): INotificationAction[] {
        const domain = this.appState.currentDomain ?? ({} as any)

        const defaultPrimaryAction = parseDefaultNotificationAction(domain, 0)
        const defaultSecondaryAction = parseDefaultNotificationAction(domain, 1)

        const actions = reset ? [] : clone(this.state.actions ?? [])
        actions.forEach((a) => {
            if (!a.displayMeta || !a.displayMeta.eid) {
                a.displayMeta = {
                    ...a.displayMeta,
                    eid: randomstring.generate(),
                }
            }
        })

        if (reset || this.state.actions === undefined) {
            const primaryDefined = actions.find((a) => a.ordinal === 0)
            if (!primaryDefined) {
                actions.push(defaultPrimaryAction!)
            }

            const secondaryDefined = actions.find((a) => a.ordinal === 1)
            if (!secondaryDefined && !!defaultSecondaryAction) {
                actions.push(defaultSecondaryAction)
            }
        }

        return clone(actions)
    }

    private renderDestinationPanel(): React.ReactNode {
        const actions = this.getCompiledActions()
        const actionKeyPrefix = `${this.props.treatment.id ?? 'treatment'}-action`

        return (
            <>
                <Well
                    key={actionKeyPrefix}
                    className="destination-well action-well"
                    title="Interactions"
                    showFooter={false}
                    ghost={true}
                >
                    <Form.Item label="Primary Landing URL">
                        <MacroManager
                            types={
                                this.currentThemeIsCampaign
                                    ? ['location', 'ecomm', 'notification', 'custom']
                                    : ['location', 'notification', 'custom']
                            }
                            customOptions={waitForDefined(() => this.state.customMacroOptions!)}
                            validOptionTypes={
                                this.currentThemeIsCampaign ? [this.context.domain.ecommConfig.itemType] : undefined
                            }
                        >
                            <Input
                                ref={(el) => (this.landingUrlField = el)}
                                placeholder="https://my.landingpage.domain/my-article"
                                value={this.props.treatment.landingUrl}
                                onChange={() => this.updateTreatment()}
                            />
                        </MacroManager>
                    </Form.Item>

                    {this.currentTheme !== NdfFormTheme.MOBILE && (
                        <>
                            <div className="switch-row">
                                <div className="switch-left">
                                    <Switch
                                        onChange={this.setAdvancedActionsEnabledState}
                                        checked={this.props.treatment.advancedActionsEnabled}
                                    />
                                </div>
                                <div className="switch-right">
                                    <div className="silent-label">
                                        Customize Buttons
                                        <Popover
                                            getPopupContainer={this.appService.getAppContainer}
                                            overlayClassName="buttons-popover"
                                            content={
                                                <div>
                                                    <p>
                                                        By default, all notifications have a “Visit Site” button that,
                                                        when clicked, directs the user to the primary landing URL.
                                                        Enabling this setting allows you to customize the button
                                                        behavior, label, & landing URL and add an optional 2nd button.
                                                    </p>
                                                    <p>
                                                        Learn more about{' '}
                                                        <a
                                                            href={`${this.appState.documentationLink}/platform/notifications/action-buttons`}
                                                            target="_blank"
                                                        >
                                                            Custom Buttons
                                                        </a>
                                                        .
                                                    </p>
                                                </div>
                                            }
                                        >
                                            <InfoCircleOutlined className="info-icon" />
                                        </Popover>
                                    </div>
                                </div>
                            </div>

                            {this.props.treatment.advancedActionsEnabled && (
                                <div key={actionKeyPrefix} className={getClassNames('notif-action-builder-collection')}>
                                    {actions.map((a, idx) => (
                                        <NotificationActionBuilder
                                            key={`${actionKeyPrefix}-${idx}`}
                                            enabled={true}
                                            title={`Button ${idx + 1}`}
                                            primaryLandingUrl={this.props.treatment.landingUrl}
                                            config={a}
                                            onChange={this.handleActionUpdate}
                                            onRemove={this.handleActionRemove}
                                            rootActions={actions}
                                            macroTypes={
                                                this.currentThemeIsCampaign
                                                    ? [
                                                          'domain',
                                                          'device',
                                                          'location',
                                                          'ecomm',
                                                          'notification',
                                                          'custom',
                                                      ]
                                                    : ['domain', 'device', 'location', 'notification', 'custom']
                                            }
                                            customMacroOptions={waitForDefined(() => this.state.customMacroOptions)}
                                        />
                                    ))}
                                    {actions.length < 2 && (
                                        <NotificationActionBuilder
                                            enabled={false}
                                            title={`Button Placeholder`}
                                            primaryLandingUrl={this.props.treatment.landingUrl}
                                            config={{} as any}
                                            onChange={noop}
                                            onAdd={this.handleActionAdd}
                                        />
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </Well>
            </>
        )
    }

    protected get currentThemeIsCampaign(): boolean {
        return this.props.theme === NdfFormTheme.CAMPAIGN
    }

    private handleActionUpdate = async (action: INotificationAction) => {
        const actions = this.getCompiledActions()

        let actionIdx = actions.findIndex((a) => a.ordinal === action.ordinal)
        if (actionIdx < 0) {
            actionIdx = 0
        }

        actions.splice(actionIdx, 1, action)
        await this.setState({ actions })

        this.updateTreatment()
    }

    private handleActionAdd = async (action: INotificationAction) => {
        const actions = this.getCompiledActions()
        action.ordinal = 1

        actions.push(action)
        await this.setState({ actions })

        this.updateTreatment()
    }

    private handleActionRemove = async (action: INotificationAction) => {
        const actions = this.getCompiledActions()

        const actionIdx = actions.findIndex((a) => a.ordinal === action.ordinal)
        if (actionIdx !== -1) {
            actions.splice(actionIdx, 1)
            await this.setState({ actions })

            this.updateTreatment()
        }
    }

    private renderTrackingPanel(): React.ReactNode {
        let keywordsValue: string[] | undefined = this.state.value.keywords
        if (keywordsValue) {
            keywordsValue = keywordsValue.map((kw) => kw.toLowerCase().trim())
        }

        return (
            <Well className="tracking-well action-well" title="Tracking" ghost={true} showFooter={false}>
                <Form.Item
                    label={
                        <span className="keywords-label">
                            Keywords
                            <Popover
                                getPopupContainer={this.appService.getAppContainer}
                                overlayClassName="keywords-popover"
                                content={
                                    <div>
                                        <p>
                                            Every user who <strong>clicks</strong> on this notification will be
                                            automatically tagged with the provided keywords. You can then build user
                                            segments based on users tagged with specific keywords.
                                        </p>
                                        <p>
                                            You can add multiple keywords by pressing the enter/return key or entering a
                                            comma after each individual keyword.
                                        </p>
                                    </div>
                                }
                            >
                                <InfoCircleOutlined className="info-icon" />
                            </Popover>
                        </span>
                    }
                >
                    <Select
                        ref={(el) => (this.keywordsField = el)}
                        mode="tags"
                        placeholder="Notification keywords"
                        maxTagCount={5}
                        getPopupContainer={this.appService.getAppContainer}
                        tokenSeparators={[',']}
                        dropdownClassName="keywords-dropdown"
                        onChange={this.handleKeywordChange}
                        notFoundContent="No Previously Used Keywords"
                        value={keywordsValue}
                    >
                        {(this.props.domainKeywords || []).map((kw) => (
                            <Select.Option key={kw.id || randomstring.generate()} value={kw.name}>
                                {kw.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Well>
        )
    }

    private renderOptionsPanel(): React.ReactNode {
        const { isSilent, requireInteraction } = this.state
        const { treatment } = this.props
        const domain = this.appState.currentDomain ?? ({} as any)

        const errors = this.props.errors
        const ttlHasError = errors && errors.find((err: any) => err.field === 'ttlSeconds')
        const hasRequireInteractionFlag = (domain?.flags ?? []).includes(FEAT_NOTIF_REQUIRE_INTERACTION_TOGGLE)

        const { metric: ttlMetric, seconds: ttlSeconds } = this.getTtlMetricValueFromTtlSeconds(
            treatment.ttlSeconds || this.getCurrentDomainDefaultTtl(),
            (treatment.ttlMetric || this.getCurrentDomainDefaultTtlMetric() || 'days').toLowerCase(),
        )

        return (
            <Well className="delivery-well action-well" title="Delivery Options" ghost={true} showFooter={false}>
                <div className="switch-row is-silent-flag">
                    <div className="switch-left">
                        <Switch
                            ref={(el) => (this.isSilentField = el)}
                            className="silent-switch"
                            checked={isSilent}
                            onChange={this.setIsSilentState}
                        />
                    </div>
                    <div className="switch-right">
                        <div className="silent-label">
                            Silent Delivery
                            <Popover
                                getPopupContainer={this.appService.getAppContainer}
                                overlayClassName="silent-popover"
                                content={
                                    <div>
                                        <p>
                                            When enabled, this notification will be delivered with no alert sound or
                                            vibration on supported mobile devices.
                                        </p>
                                    </div>
                                }
                            >
                                <InfoCircleOutlined className="info-icon" />
                            </Popover>
                        </div>
                    </div>
                </div>

                {hasRequireInteractionFlag && (
                    <div className="switch-row req-interaction-flag">
                        <div className="switch-left">
                            <Switch
                                ref={(el) => (this.reqInteractionField = el)}
                                className="interaction-switch"
                                checked={requireInteraction}
                                onChange={this.setRequireInteraction}
                            />
                        </div>
                        <div className="switch-right">
                            <div className="interaction-label">
                                Require Interaction
                                <Popover
                                    getPopupContainer={this.appService.getAppContainer}
                                    overlayClassName="interaction-popover"
                                    content={
                                        <div>
                                            <p>
                                                When disabled, this notification will minimize to the notification
                                                center after approximately 8 seconds. Chrome for Android is not affected
                                                as all notifications are minimized to the notification tray regardless
                                                of this setting.
                                            </p>
                                        </div>
                                    }
                                >
                                    <InfoCircleOutlined className="info-icon" />
                                </Popover>
                            </div>
                        </div>
                    </div>
                )}

                <Form.Item
                    className={`lifespan ${ttlHasError ? 'has-error' : ''}`}
                    label={
                        <span className="lifespan-label">
                            Lifespan
                            <Popover
                                getPopupContainer={this.appService.getAppContainer}
                                overlayClassName="lifespan-popover"
                                content={
                                    <div>
                                        <p>
                                            Notification lifespan determines how long the notification will attempt to
                                            be delivered to the subscriber.
                                        </p>
                                        <p>
                                            For example: A notification with a lifespan of 1 day will not be delivered
                                            to a user who does not become eligible for delivery within 24 hours of the
                                            notification being sent.
                                        </p>
                                    </div>
                                }
                            >
                                <InfoCircleOutlined className="info-icon" />
                            </Popover>
                        </span>
                    }
                >
                    <Input
                        key={`${this.state.renderKey}-ttlSeconds-${ttlMetric}`}
                        ref={(el) => (this.ttlSecondsField = el)}
                        placeholder="0"
                        defaultValue={ttlSeconds as any}
                        onChange={() => this.updateTreatment()}
                        addonAfter={
                            <Select
                                getPopupContainer={this.appService.getAppContainer}
                                dropdownClassName="lifespan-metric-overlay"
                                value={ttlMetric}
                                onChange={async (selectedTtlMetric: string) => {
                                    await this.setState({ ttlMetric: selectedTtlMetric })
                                    this.updateTreatment()
                                }}
                            >
                                <Select.Option value="minutes">Minutes</Select.Option>
                                <Select.Option value="hours">Hours</Select.Option>
                                <Select.Option value="days">Days</Select.Option>
                            </Select>
                        }
                    />
                </Form.Item>
            </Well>
        )
    }

    private renderCharacterLimitTooltip(): React.ReactNode {
        const data = [
            {
                key: '1',
                platform: 'Mobile',
                title: '~50',
                body: '~150',
            },
            {
                key: '2',
                platform: 'Windows: Chrome',
                title: '~40',
                body: '~0-150',
            },
            {
                key: '3',
                platform: 'Windows: Firefox',
                title: '~40',
                body: '~140-190',
            },
            {
                key: '4',
                platform: 'Mac OS: Chrome, Firefox',
                title: '~20-40',
                body: '~20-50',
            },
        ]

        const columns = ['Platform', 'Title', 'Body']
        const columnDefinitions = columns.map((col: string) => ({
            title: col,
            dataIndex: col.toLowerCase(),
        }))

        return <Table dataSource={data} columns={columnDefinitions} pagination={false}></Table>
    }

    @autobind
    private async addEmoji(ev: any, emoji: any, field: string): Promise<void> {
        let element: HTMLInputElement | undefined

        if (field === 'title') element = this.titleField.input
        if (field === 'body') element = this.bodyField.input

        if (element) {
            const selectionStart = element.selectionStart ?? 0
            let value = element.value

            value = value || ''

            const splitter = new GraphemeSplitter()
            const splitValue = splitter.splitGraphemes(value)

            let expandedLength = 0
            const insertIdx = splitValue.findIndex((g) => {
                expandedLength += g.length
                return expandedLength >= selectionStart
            })

            splitValue.splice(
                insertIdx === 0 && insertIdx === selectionStart ? insertIdx : insertIdx + 1,
                0,
                emoji.emoji,
            )

            const native = Object.getOwnPropertyDescriptor(element, 'value')!.set!
            native.call(element, splitValue.join(''))

            const fauxEvent = new Event('input', { bubbles: true })
            element.dispatchEvent(fauxEvent)

            const selection = selectionStart + (emoji.emoji?.length ?? 0)
            element.selectionStart = selection
            element.selectionEnd = selection
            element.focus()
        }

        this.updateTreatment()
    }

    @autobind
    private handleInputInteraction(ev: any, field: string): void {
        ev.stopPropagation?.()
        ev.stopImmediatePropagation?.()

        let shouldShowEmojiOptions = false
        const inputEmojiEditor = field + 'EmojiEditorVisible'

        try {
            const evType = ev.type
            shouldShowEmojiOptions = evType === 'mousedown' && this.state[inputEmojiEditor]
        } catch (err) {
            console.warn('Error computing input state', err)
        }

        const state: any = {
            [inputEmojiEditor]: shouldShowEmojiOptions,
        }

        this.setState(state)
    }

    private async setDomainState(): Promise<void> {
        const state: any = {
            domain: this.appState.currentDomain,
        }

        if (state.domain) {
            state.customMacroOptions = await getCustomMacroFields(state.domain.id)
        }

        this.setState(state)
    }

    @autobind
    private setEmojiFieldVisibleState(field: string, visible: boolean) {
        const stateChange = { [`${field}EmojiEditorVisible`]: visible }
        this.setState(stateChange as any)
    }

    @autobind
    private async setLandingUrlEnabledState(landingUrlEnabled: boolean): Promise<void> {
        if (!landingUrlEnabled) {
            this.landingUrlField = ''
        }
        await this.setState({ landingUrlEnabled })
        this.updateTreatment()
    }

    @autobind
    private async setAdvancedActionsEnabledState(advancedActionsEnabled: boolean): Promise<void> {
        const update: any = {
            advancedActionsEnabled,
            actions: this.getCompiledActions(true),
        }

        await this.setState(update)
        this.updateTreatment()
    }

    @autobind
    private async setIsSilentState(isSilent: boolean): Promise<void> {
        await this.setState({ isSilent })

        this.updateTreatment()
    }

    private setRequireInteraction = async (requireInteraction: boolean): Promise<void> => {
        await this.setState({ requireInteraction })

        this.updateTreatment()
    }

    private handleUseEcommItemImageToggle = async (enabled: boolean) => {
        const itemImageMacro = '{{item.image}}'
        const update: any = {
            uploads: {
                ...this.state.uploads,
                image: enabled
                    ? {
                          aquariumUrl: itemImageMacro,
                      }
                    : undefined,
            },
            image: enabled ? itemImageMacro : undefined,
        }

        await this.setState(update)
        this.updateTreatment()
    }

    @autobind
    private async handleKeywordChange(keywords: string[]): Promise<void> {
        if (keywords.length > 5) {
            keywords.pop()
        }

        await this.setState(({ value }) => ({
            value: {
                ...value,
                keywords,
            },
        }))

        this.updateTreatment()
    }

    private async handleUploadSuccess(upload: any, type: UploadType): Promise<void> {
        await this.setState(({ uploads }) => ({
            uploads: {
                ...uploads,
                [type]: upload,
            },
        }))

        this.updateTreatment()
    }

    private async handleUploadRemoval(type: UploadType): Promise<void> {
        await this.setState(({ uploads }) => ({
            uploads: {
                ...uploads,
                [type]: undefined,
            },
        }))

        this.updateTreatment()
    }

    private getCurrentDomainDefaultTtl(): number {
        let defaultTtlSeconds = 7 * 60 * 60 * 24

        if (!!this.appState.currentDomain && this.appState.currentDomain.defaultNotificationTtlSeconds) {
            defaultTtlSeconds = this.appState.currentDomain.defaultNotificationTtlSeconds
        }

        return defaultTtlSeconds
    }

    private getCurrentDomainDefaultTtlMetric(): string {
        let defaultTtlMetric = 'days'

        if (!!this.appState.currentDomain && this.appState.currentDomain.displayMeta) {
            defaultTtlMetric =
                this.appState.currentDomain.displayMeta.default_notification_ttl_metric || defaultTtlMetric
        }

        return defaultTtlMetric
    }

    private getTtlMetricValueFromTtlSeconds(
        ttlSeconds: number,
        ttlMetric?: string,
    ): { metric: string; seconds: number } {
        ttlMetric = ttlMetric || 'days'
        ttlSeconds = this.ttlSecondsToMetricValue(ttlSeconds, ttlMetric) || 0

        if (!!ttlSeconds) {
            if (ttlMetric === 'days' && ttlSeconds < 1) {
                ttlMetric = 'hours'
                ttlSeconds *= 24
            }
            if (ttlMetric === 'hours' && ttlSeconds < 1) {
                ttlMetric = 'minutes'
                ttlSeconds *= 60
            }
        }

        return { metric: ttlMetric, seconds: ttlSeconds }
    }

    @autobind
    private async clearTreatment(): Promise<void> {
        const renderKey = randomstring.generate()
        const isSilent = false
        const requireInteraction = true
        let ttlMetric = 'days'

        const value: INotificationTreatment = {
            id: this.props.treatment.id,
            title: undefined,
            body: undefined,
            image: undefined,
            badge: undefined,
            landingUrlDisabled: !this.state.landingUrlEnabled,
            ttlSeconds: this.getCurrentDomainDefaultTtl(),
            ttlMetric,
            keywords: undefined,
            isSilent,
            requireInteraction,
        }

        // Clear if enabled
        if (!value.landingUrlDisabled) value.landingUrl = undefined

        const { metric } = this.getTtlMetricValueFromTtlSeconds(value.ttlSeconds!, value.ttlMetric)
        ttlMetric = metric
        value.ttlMetric = metric

        await this.setState(() => ({ value, ttlMetric, isSilent, requireInteraction, renderKey }))
        this.props.onChange(value)

        // Reset to defaults after full cycle has run
        value.icon = value.icon ?? this.props.treatment.icon // this.currentIconUrl(true);
        value.badge = value.badge ?? this.props.treatment.badge // this.currentBadgeUrl(true, true);

        value.actions = this.getCompiledActions(true)

        await this.setState(() => ({ value }))
        this.props.onChange(value)
    }

    @autobind
    private async updateTreatment(): Promise<void> {
        if (!this.titleField) {
            return
        }

        const value: INotificationTreatment = {
            id: this.props.treatment.id,
        }

        value.title = getWithDefault(this.titleField.input.value)
        value.body = getWithDefault(this.bodyField.input.value)
        value.keywords = getWithDefault(this.state.value.keywords)

        value.ttlMetric = this.state.ttlMetric || 'days'
        value.ttlSeconds = this.ttlMetricValueToSeconds(this.ttlSecondsField.input.value, value.ttlMetric!)
        // re-parse to ensure non decimal values
        const { metric } = this.getTtlMetricValueFromTtlSeconds(value.ttlSeconds!, value.ttlMetric)
        value.ttlMetric = metric

        value.isSilent = this.state.isSilent
        value.requireInteraction = this.state.requireInteraction

        value.landingUrlDisabled = !this.state.landingUrlEnabled
        if (this.state.landingUrlEnabled) {
            let landingUrl = this.landingUrlField.input.value
            if (landingUrl) {
                landingUrl = landingUrl.trim()
            }
            value.landingUrl = getWithDefault(landingUrl)
        } else {
            value.landingUrl = undefined
        }

        value.advancedActionsEnabled = this.state.advancedActionsEnabled
        value.actions = value.advancedActionsEnabled ? this.state.actions : undefined

        value.image = this.state.uploads?.image || undefined
        value.icon = this.state.uploads?.icon || undefined
        value.badge = this.state.uploads?.badge || undefined

        this.setState(() => ({ value }))
        this.props.onChange(value)
    }

    private ttlMetricValueToSeconds(metricValue: number | undefined, metric: string): number | undefined {
        if (metricValue === undefined || metricValue.toString().trim() === '') {
            return void 0
        }

        let secondsValue = 0

        switch (metric) {
            default:
            case 'days':
                secondsValue = metricValue * 24 * 60 * 60
                break
            case 'hours':
                secondsValue = metricValue * 60 * 60
                break
            case 'minutes':
                secondsValue = metricValue * 60
                break
        }

        return secondsValue
    }

    private ttlSecondsToMetricValue(secondsValue: number | undefined, metric: string): number | undefined {
        if (secondsValue === undefined) {
            return void 0
        }

        let metricValue = 0

        switch (metric) {
            default:
            case 'days':
                metricValue = secondsValue / 24 / 60 / 60
                break
            case 'hours':
                metricValue = secondsValue / 60 / 60
                break
            case 'minutes':
                metricValue = secondsValue / 60
                break
        }

        return metricValue
    }

    private currentIconUrl(excludePropsValue?: boolean): string | undefined {
        const { value, lastDefaultSegmentIconUrl } = this.state
        const { defaultDomainIconUrl, defaultSegmentIconUrl } = this.props

        let iconUrl: string | undefined
        const iconUrlValue = getWithDefault(this.props.treatment.icon)
        if (!excludePropsValue && iconUrlValue) {
            iconUrl = iconUrlValue
        } else {
            if (defaultDomainIconUrl) {
                iconUrl = defaultDomainIconUrl
            }
            if (value.icon) {
                iconUrl = value.icon
            }
        }

        if (!!this.queryString.template) {
            iconUrl = value.icon
        }

        if (defaultSegmentIconUrl) {
            const iconIsDomainDefault = iconUrl === defaultDomainIconUrl
            if (iconIsDomainDefault) {
                iconUrl = defaultSegmentIconUrl
            }
        } else {
            const iconIsLastSegmentDefault = iconUrl ? iconUrl === lastDefaultSegmentIconUrl : undefined

            if (!!defaultDomainIconUrl && iconIsLastSegmentDefault) {
                iconUrl = defaultDomainIconUrl
            }
        }

        if (iconUrl) {
            iconUrl = iconUrl.replace(/\s/, '+')
        }
        return iconUrl
    }

    private currentBadgeUrl(excludePreviousValue?: boolean, excludePropsValue?: boolean): string | undefined {
        const { domain, value } = this.state

        let badgeUrl: string | undefined
        const badgeUrlValue = getWithDefault(this.props.treatment.badge)
        if (!excludePropsValue && badgeUrlValue) {
            badgeUrl = badgeUrlValue
        } else {
            if (domain) {
                badgeUrl = domain.defaultBadgeUrl
            }
            if (excludePreviousValue !== true && value.badge) {
                badgeUrl = value.badge
            }
        }

        if (badgeUrl) {
            badgeUrl = badgeUrl.replace(/\s/, '+')
        }
        return badgeUrl
    }

    private currentImageUrl(excludePreviousValue?: boolean): string | undefined {
        const { value } = this.state

        let imageUrl: string | undefined
        const imageUrlValue = getWithDefault(this.props.treatment.image)
        if (imageUrlValue) {
            imageUrl = imageUrlValue
        } else {
            if (excludePreviousValue !== true && value.image) {
                imageUrl = value.image
            }
        }

        if (imageUrl) {
            imageUrl = imageUrl.replace(/\s/, '+')
        }
        return imageUrl
    }
}
