import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import './domain-selector.scss'
import autobind from 'autobind-decorator'
import { LowerDrawer } from '../lower-drawer/lower-drawer'
import { PageHeader } from '../../components/page-header/page-header'
import { DomainDto } from '../../dtos/domain'
import { SwPortal } from '../../components/sw-portal/sw-portal'

interface IDomainSelectorOptionProps {
    listId: number
    domain: DomainDto
    onClick: (domain: DomainDto) => any
    onMouseOver?: (listId: number) => any
    highlight?: boolean
}

export class DomainSelectorOption extends BetterComponent<IDomainSelectorOptionProps, {}> {
    private defaultClassName: string = 'sw-mv-domain-selector-option'

    private ref: any

    public constructor(props: IDomainSelectorOptionProps) {
        super(props)

        this.state = {}
    }

    public componentDidUpdate(prevProps: Readonly<IDomainSelectorOptionProps>): void {
        if (this.props.highlight && this.ref) {
            this.ref.focus()
        }
    }

    public render(): React.ReactNode {
        return (
            <div
                ref={(el) => (this.ref = el)}
                className={this.buildRootClassNames()}
                onClick={this.handleSelect}
                onMouseOver={this.handleMouseOver}
            >
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('content')}>
                        <div
                            className={this.buildClassName('icon')}
                            style={{ backgroundImage: `url(${encodeURI(this.props.domain.defaultIconUrl)})` }}
                        />
                        <div className={this.buildClassName('name-wrapper')}>
                            <div className={this.buildClassName('name')}>
                                {!!this.props.domain ? this.props.domain.displayName : 'Loading...'}
                            </div>
                            <div className={this.buildClassName('address')}>
                                {!!this.props.domain ? this.props.domain.name : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    @autobind
    protected handleSelect(): void {
        this.props.onClick(this.props.domain)
    }

    @autobind
    protected handleMouseOver(): void {
        if (this.props.onMouseOver) {
            this.props.onMouseOver(this.props.listId)
        }
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        if (this.props.highlight) classNames.push('highlight')

        return classNames.join(' ')
    }
}

interface IDomainSelectorProps {
    className?: string
}

interface IDomainSelectorState {
    drawerOpen?: boolean
}

export class DomainSelector extends BetterComponent<IDomainSelectorProps, IDomainSelectorState> {
    public readonly defaultClassName: string = 'sw-mv-domain-selector'

    protected appState: AppState
    protected appService: AppService

    public constructor(props: IDomainSelectorProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        this.state = {}
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('display')}>
                        <span className={this.buildClassName('selection')} onClick={this.handleDrawerOpen}>
                            {!!this.domain.id ? (
                                <>
                                    <span
                                        className={this.buildClassName('selection-icon')}
                                        style={{ backgroundImage: `url(${encodeURI(this.domain.defaultIconUrl)})` }}
                                    />
                                    <span className={this.buildClassName('selection-name')}>
                                        <span className="name">{this.domain.displayName}</span>
                                    </span>
                                </>
                            ) : (
                                <span className={this.buildClassName('selection-empty')}>Loading...</span>
                            )}
                        </span>
                    </div>

                    <SwPortal portalId={this.buildClassName('drawer')}>
                        <LowerDrawer
                            className={this.buildClassName('drawer')}
                            open={!!this.state.drawerOpen}
                            onClose={this.handleDrawerClose}
                        >
                            <PageHeader title="Your Domains" />

                            <div className={this.buildClassName('domain-options')}>
                                {this.currentDomainOptions.map((domain, idx) => (
                                    <DomainSelectorOption
                                        key={domain.id}
                                        listId={idx}
                                        domain={domain}
                                        onClick={this.handleDomainChange}
                                    />
                                ))}
                            </div>
                        </LowerDrawer>
                    </SwPortal>
                </div>
            </div>
        )
    }

    protected get domain(): DomainDto {
        return this.appState.currentDomain || ({} as any)
    }

    protected get currentDomainOptions(): DomainDto[] {
        let options: DomainDto[] = []

        if (!!this.appState.currentUser && !!this.appState.currentUserDomains) {
            options = this.appState.currentUserDomains
        }

        return options
    }

    @autobind
    protected async handleDrawerOpen(): Promise<void> {
        this.setState({ drawerOpen: true })
    }

    @autobind
    protected async handleDrawerClose(): Promise<void> {
        this.setState({ drawerOpen: false })
    }

    @autobind
    protected async handleDomainChange(domain: DomainDto): Promise<void> {
        this.appService.setCurrentDomain(domain)
        this.handleDrawerClose()
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
