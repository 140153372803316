import { pause } from './utils'

export const waitForDefined = async <T>(res: () => T, delay: number = 10): Promise<T> => {
    if (res() === undefined) {
        await pause(delay ?? 10, 'ms')
        return waitForDefined(res)
    }

    return res()
}
