import * as React from 'react'
import * as randomstring from 'randomstring'
import { observe } from 'mobx'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import { BellPrompt, SlidePrompt } from 'pushly-sdk-v2'
import { CloseOutlined, ExclamationCircleOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Alert, Col, Input, Popover, Radio, Row, Switch, Tooltip } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { convertCase, objectMapReduce, simpleFormErrorNotification, simpleNotification } from '../../../_utils/utils'
import { AppService, DomainService } from '../../../services'
import { PromptService } from '../../../services/prompt'
import { DomainDto } from '../../../dtos/domain'
import { PromptDto } from '../../../dtos/prompt'
import { BetterFormComponent } from '../../../components/better-component/better-component'
import { Sticky } from '../../../components/sticky/sticky'
import { Well } from '../../../components/well/well'
import { AppState } from '../../../stores/app'
import { SystemPromptPreviewBuilder } from '../system-prompt-data-form/system-prompt-preview-builder'
import { PromptTreatmentEditor } from './prompt-treatment-editor'
import 'pushly-sdk-v2/lib/pushly-sdk.min.css'
import './prompt-editor.scss'
import { PageHeader } from '../../../components/page-header/page-header'
import StackedTagSelect from '../../../components/stacked-tag-select/stacked-tag-select'
import { onResponseError403 } from '../../../_utils/on-response-error-403'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { DeliveryChannelSelector } from '../../../components/delivery-channel-selector/delivery-channel-selector'
import { getEnabledDeliveryChannels } from '../../../_utils/domain'

const MAX_PAGE_URLS_ALLOWED = 20

export enum PromptStyle {
    SYSTEM = 'SYSTEM',
    SLIDE = 'SLIDE',
    BELL = 'BELL',
    PUBLISHER_CUSTOM = 'PUBLISHER_CUSTOM',
}

interface IPromptTreatment extends PromptDto {}

interface IProps extends FormComponentProps {}

interface IState {
    showEditor: boolean
    domain?: DomainDto
    selectedStyle: string
    currentPrompt?: any
    isAutoShow: boolean

    slidePromptImageUpload?: any
    visitorCondition: string
    pageCondition: string
    displayPctToAllUsers: boolean

    abTestEnabled: boolean
    treatments: PromptDto[]
    currentTreatmentIndex: number

    desktopEnabled: boolean
    desktopBypassEnabled: boolean
    mobileEnabled: boolean
    mobileBypassEnabled: boolean
}

@observer
export class PromptEditorComponent extends BetterFormComponent<IProps, IState> {
    private appState: AppState
    private appService: AppService
    private domainService: DomainService
    private promptService: PromptService
    private disposeObservers: any[]
    private previewRef: any
    private previewStyleRef: any
    private validatorRef: Function

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)
        this.promptService = Container.get(PromptService)

        this.state = {
            showEditor: false,
            selectedStyle: PromptStyle.SYSTEM,
            visitorCondition: 'EVERYONE',
            pageCondition: 'ALL_PAGES',
            displayPctToAllUsers: true,
            isAutoShow: true,
            abTestEnabled: false,
            treatments: this.resolveTestTreatments(),
            currentTreatmentIndex: 0,
            desktopEnabled: true,
            desktopBypassEnabled: true,
            mobileEnabled: true,
            mobileBypassEnabled: true,
        }
    }

    public get groupIdFromPath(): number | void {
        if (this.injectedProps.match) {
            return this.injectedProps.match.params.promptId
        }
    }

    public get promptIdFromTemplateQuery(): number | void {
        if (this.queryString.template) {
            return this.queryString.template as any
        }
    }

    public get currentDomain(): DomainDto {
        let domain: DomainDto
        if (this.state && this.state.domain) domain = this.state.domain
        else domain = this.appState.currentDomain!
        return domain
    }

    public get hasEditorDependencies(): boolean {
        const hasEditId = !!this.groupIdFromPath
        const currentTreatment = this.state.treatments[this.state.currentTreatmentIndex]
        const hasNewDeps = !!this.state.domain && !hasEditId
        const hasEditDeps = !!this.state.domain && hasEditId && currentTreatment instanceof PromptDto

        return hasNewDeps || hasEditDeps
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.setDomainState()
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount()
        this.disposeObservers.forEach((f: any) => f())
    }

    public render(): React.ReactNode {
        const hasEditId = !!this.groupIdFromPath
        const canShowBuilder = this.props.form.getFieldValue('channel') === DeliveryChannel.WEB
        const domainActiveChannels = getEnabledDeliveryChannels(this.appState.currentDomain!)

        return (
            <div className="prompt-editor-page">
                <PageHeader
                    title={hasEditId ? 'Edit Prompt' : 'Create Prompt'}
                    append={hasEditId && !!this.state.currentPrompt && <span>#{this.state.currentPrompt!.id}</span>}
                    loading={hasEditId ? !this.state.currentPrompt : false}
                />

                <Form className="prompt-editor-form hide-inline-errors">
                    <Row className="page-content" gutter={18}>
                        <Col className="page-content-left" span={14}>
                            <div style={{ display: domainActiveChannels.length > 1 ? 'block' : 'none' }}>
                                <Well title="Channel" showFooter={false}>
                                    {this.getFieldDecorator('channel', {
                                        initialValue: this.state.currentPrompt?.channel ?? DeliveryChannel.WEB,
                                    })(
                                        <DeliveryChannelSelector
                                            type="single"
                                            visibleChannels={domainActiveChannels}
                                            onChange={(change) => this.props.form.setFieldsValue({ channels: change })}
                                            nested={true}
                                        />,
                                    )}
                                </Well>
                            </div>

                            {!canShowBuilder && (
                                <Alert
                                    className="safari-2step"
                                    type="warning"
                                    showIcon={true}
                                    message=""
                                    description={
                                        <span>
                                            At this time only Web Push prompts may be created. Custom Native prompt
                                            creation is not supported. The default Native prompts can be viewed and
                                            enabled / disabled in their respective lists.
                                        </span>
                                    }
                                />
                            )}

                            {canShowBuilder && this.renderDetailsWell()}
                            {canShowBuilder && this.renderPageConditionsWell()}
                            {canShowBuilder && this.renderAppearanceWell()}
                            {canShowBuilder && this.renderOptionsWell()}
                        </Col>
                        {canShowBuilder && (
                            <Col className="page-content-right" span={10}>
                                <Sticky threshold={28}>
                                    {this.state.selectedStyle === PromptStyle.PUBLISHER_CUSTOM
                                        ? this.renderCustomPreview()
                                        : this.renderPromptPreview()}
                                </Sticky>
                            </Col>
                        )}
                    </Row>
                </Form>
            </div>
        )
    }

    private renderPromptPreview(): React.ReactNode {
        return (
            <Well className="prompt-preview-well" showHeader={false} showFooter={false}>
                {this.state.selectedStyle === PromptStyle.BELL && (
                    <Form.Item label="Preview Style">
                        {this.getFieldDecorator('showSubscribedState', {
                            initialValue: false,
                        })(
                            <Radio.Group
                                size="small"
                                onChange={this.buildPreviewConfig}
                                ref={(el) => (this.previewStyleRef = el)}
                            >
                                <Radio.Button value={false}>Unsubscribed</Radio.Button>
                                <Radio.Button value={true}>Subscribed</Radio.Button>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                )}

                <Form.Item label="Prompt Preview">
                    {this.state.selectedStyle === PromptStyle.SYSTEM ? (
                        <SystemPromptPreviewBuilder />
                    ) : (
                        <div
                            className="prompt-preview pushly-prompt-window"
                            ref={(element) => (this.previewRef = element)}
                        />
                    )}
                </Form.Item>
            </Well>
        )
    }

    private renderCustomPreview(): React.ReactNode {
        return (
            <div className="snippet-wrapper">
                <Well className="custom-prompt-snippets" showFooter={false} showHeader={false}>
                    <h3>Instructions</h3>

                    <div>
                        For more information on how to set up your custom prompt please visit our{' '}
                        <a
                            href={`${this.appState.documentationLink}/#manually-triggering-the-opt-in-prompt`}
                            target="_blank"
                        >
                            Developer Documentation
                        </a>
                        .
                    </div>
                </Well>
            </div>
        )
    }

    private renderDetailsWell(): React.ReactNode {
        const { currentPrompt } = this.state

        return (
            <Well title="Details" showFooter={false} className="generic-well">
                <Form.Item label="Name">
                    {this.getFieldDecorator('name', {
                        initialValue: currentPrompt ? currentPrompt.name : undefined,
                        rules: [
                            {
                                required: true,
                                message: 'Name is required.',
                            },
                        ],
                    })(<Input placeholder={this.state.abTestEnabled ? 'New Prompt Test Name' : 'New Prompt Name'} />)}
                </Form.Item>
            </Well>
        )
    }

    private renderPageConditionsWell(): React.ReactNode {
        const { getFieldDecorator } = this.props.form

        let conditionValueEditor: any
        switch (this.state.pageCondition) {
            case 'PAGE_URLS':
                conditionValueEditor = this.renderUrlContainsCondition()
                break
            case 'META_KEYWORDS':
                conditionValueEditor = this.renderMetaKeywordsCondition()
                break
        }

        return (
            <Well title="Page Conditions" showFooter={false}>
                <p>
                    By default this prompt will be shown on all pages. You may choose to restrict this prompt to a
                    specific set of pages by their URL or meta keywords.
                </p>

                <Form.Item className="page-condition-type">
                    {getFieldDecorator('pageCondition', {
                        initialValue: this.state.pageCondition || 'ALL_PAGES',
                    })(
                        <Radio.Group size="small" onChange={this.setPageCondition}>
                            <Radio.Button value="ALL_PAGES" checked={true}>
                                All Pages
                            </Radio.Button>
                            <Radio.Button value="PAGE_URLS">URLs Containing</Radio.Button>
                            <Radio.Button value="META_KEYWORDS">Meta Keywords</Radio.Button>
                        </Radio.Group>,
                    )}
                    {conditionValueEditor}
                </Form.Item>
            </Well>
        )
    }

    private renderAppearanceWell(): React.ReactNode {
        const hasMaxTreatments = this.state.treatments.length >= 4
        const hasMultipleTreatments = this.state.treatments.length > 1
        const currentTreatment = this.state.treatments[this.state.currentTreatmentIndex]
        const wellProps: any = {}

        if (this.state.abTestEnabled) {
            wellProps.headerExtension = (
                <div className="well-tab-list">
                    {this.state.treatments.map((treatment, idx) => {
                        const isCurrentTreatment = idx === this.state.currentTreatmentIndex
                        const handleTreatmentSelect = this.handleTreatmentSelect.bind(this, treatment, idx)
                        const handleTreatmentDelete = this.handleTreatmentDelete.bind(this, treatment, idx)

                        return (
                            <div
                                key={idx}
                                className={`well-tab${isCurrentTreatment ? ' active' : ''}`}
                                onClick={handleTreatmentSelect}
                            >
                                {hasMultipleTreatments && (
                                    <span className="well-tab-close" onClick={handleTreatmentDelete}>
                                        <CloseOutlined />
                                    </span>
                                )}
                                <span className="well-tab-name">
                                    <span>Variant {idx + 1}</span>
                                    <span>({treatment.weight || 0}%)</span>
                                </span>
                            </div>
                        )
                    })}
                    {!hasMaxTreatments && (
                        <div className="well-tab well-tab-new" onClick={this.handleTreatmentAddition}>
                            <span>
                                <PlusOutlined />
                            </span>
                        </div>
                    )}
                </div>
            )
        }

        return (
            <Well
                {...wellProps}
                className="composite-prompt-well treatment-well"
                title="Appearance & Behavior"
                showFooter={false}
                action={
                    <Form.Item>
                        <div className="abenable-action-toggle">
                            <div className="abenable-left">
                                <span className="well-title">Testing</span>
                            </div>
                            <div className="abenable-right">
                                <Switch onChange={this.setABTestEnabledState} checked={this.state.abTestEnabled} />
                            </div>
                        </div>
                    </Form.Item>
                }
            >
                {this.hasEditorDependencies && (
                    <PromptTreatmentEditor
                        key={`treatment-${this.state.currentTreatmentIndex}`}
                        treatment={currentTreatment}
                        abTestEnabled={this.state.abTestEnabled}
                        validatorRef={(fn: any) => (this.validatorRef = fn)}
                        onChange={this.handleTreatmentUpdate}
                    />
                )}
            </Well>
        )
    }

    private renderOptionsWell(): React.ReactNode {
        const { currentPrompt, desktopEnabled, desktopBypassEnabled, mobileEnabled, mobileBypassEnabled } = this.state

        return (
            <Well
                className="generic-well"
                title="Additonal Options"
                submitText="Save Prompt"
                onSubmit={() => this.handleFormSubmit()}
                onCancel={this.handleCancel}
            >
                <div className="switch-row large with-divider">
                    <div className="switch-left">
                        {this.getFieldDecorator('isAutoShow', {
                            initialValue: this.state.isAutoShow,
                        })(
                            <Switch
                                checked={this.state.isAutoShow}
                                onChange={(isAutoShow) => {
                                    this.setState({ isAutoShow })
                                }}
                            />,
                        )}
                    </div>
                    <div className="switch-right">
                        <span>
                            Automatically prompt users
                            <Popover
                                getPopupContainer={this.appService.getAppContainer}
                                overlayClassName="pe-popover"
                                content={
                                    <div>
                                        By default this prompt will automatically show to your users if all conditions
                                        are met. You may choose to disable this behavior and trigger the prompt manually
                                        after the user has performed a specific action on your website via the Platform
                                        SDK. Contact your account manager for technical implementation information.
                                    </div>
                                }
                                trigger="hover"
                            >
                                <ExclamationCircleOutlined className="info-icon" />
                            </Popover>
                        </span>
                    </div>
                </div>

                <div className={`switch-row large ${desktopEnabled ? ' with-divider' : ''}`}>
                    <div className="switch-left">
                        {this.getFieldDecorator('desktopEnabled', {
                            initialValue: desktopEnabled,
                        })(<Switch checked={desktopEnabled} onChange={this.setDesktopEnabled} />)}
                    </div>
                    <div className="switch-right">Show prompt to desktop users</div>
                </div>

                {!desktopEnabled && (
                    <div className="switch-row large child-row with-divider">
                        <div className="switch-left">
                            {this.getFieldDecorator('desktopBypassEnabled', {
                                initialValue: desktopBypassEnabled,
                            })(<Switch checked={desktopBypassEnabled} onChange={this.setDesktopBypassEnabled} />)}
                        </div>
                        <div className="switch-right">Continue to next priority prompt</div>
                    </div>
                )}

                <div className="switch-row large">
                    <div className="switch-left">
                        {this.getFieldDecorator('mobileEnabled', {
                            initialValue: mobileEnabled,
                        })(<Switch checked={mobileEnabled} onChange={this.setMobileEnabled} />)}
                    </div>
                    <div className="switch-right">
                        Show prompt to mobile users
                        <Tooltip title="Mobile placement also includes tablet devices">
                            <InfoCircleOutlined className="info-icon" />
                        </Tooltip>
                    </div>
                </div>

                {!mobileEnabled && (
                    <div className="switch-row large child-row">
                        <div className="switch-left">
                            {this.getFieldDecorator('mobileBypassEnabled', {
                                initialValue: mobileBypassEnabled,
                            })(<Switch checked={mobileBypassEnabled} onChange={this.setMobileBypassEnabled} />)}
                        </div>
                        <div className="switch-right">Continue to next priority prompt</div>
                    </div>
                )}
            </Well>
        )
    }

    private renderUrlContainsCondition(): React.ReactNode {
        const { currentPrompt } = this.state

        return (
            <div>
                <p>
                    Enabling this condition will restrict the prompt to a specific group of pages determined by their
                    URL.
                </p>

                <Form.Item label="Show prompt on pages with URLs containing or equal to::">
                    {this.getFieldDecorator('pageUrls', {
                        initialValue: !currentPrompt ? [] : this.getCurrentPageConditions().pageUrls || [],
                        normalize: this.normalizeUrls,
                        rules: [
                            {
                                validator: (rule, value, callback) => {
                                    const rgx = /^[A-Za-z0-9-._~:/?#\[\]@!$&'()*+,;=]+$/i
                                    const valid = value.length === 0 || value.some((v: any) => rgx.test(v))
                                    valid ? callback() : callback(new Error(rule.message))
                                },
                                message: 'One or more page urls contain invalid characters.',
                            },
                        ],
                    })(
                        <StackedTagSelect<string[]>
                            mode="tags"
                            placeholder="Page URL or Fragment"
                            maxTagCount={MAX_PAGE_URLS_ALLOWED}
                            getPopupContainer={this.appService.getAppContainer}
                            tokenSeparators={[',']}
                            showOptions={false}
                            onChange={this.handlePageUrlsChange}
                            notFoundContent=""
                        />,
                    )}
                </Form.Item>

                <Form.Item label="Do not show prompt on pages with URLs containing or equal to::">
                    {this.getFieldDecorator('excludedPageUrls', {
                        initialValue: !currentPrompt ? [] : this.getCurrentPageConditions().excludedPageUrls || [],
                        normalize: this.normalizeUrls,
                        rules: [
                            {
                                validator: (rule, value, callback) => {
                                    const rgx = /^[A-Za-z0-9-._~:/?#\[\]@!$&'()*+,;=]+$/i
                                    const valid = value.length === 0 || value.some((v: any) => rgx.test(v))
                                    valid ? callback() : callback(new Error(rule.message))
                                },
                                message: 'One or more excluded page urls contain invalid characters.',
                            },
                        ],
                    })(
                        <StackedTagSelect<string[]>
                            mode="tags"
                            placeholder="Page URL or Fragment"
                            maxTagCount={MAX_PAGE_URLS_ALLOWED}
                            getPopupContainer={this.appService.getAppContainer}
                            tokenSeparators={[',']}
                            showOptions={false}
                            onChange={this.handlePageUrlsChange}
                            notFoundContent=""
                        />,
                    )}
                </Form.Item>
            </div>
        )
    }

    private normalizeUrls = (val: string[]) => {
        if (val) {
            if (val.length >= 1) {
                val = val.map((url) => url.trim())
            }
        }
        return val
    }

    private renderMetaKeywordsCondition(): React.ReactNode {
        const { currentPrompt } = this.state

        return (
            <div>
                <p>
                    Enabling this condition will restrict the prompt to a specific group of pages determined by each
                    page's meta keywords.
                </p>

                <Form.Item label="Show prompt on pages with the following meta-tag keywords::">
                    {this.getFieldDecorator('metaKeywords', {
                        initialValue: !currentPrompt ? [] : this.getCurrentPageConditions().keywords || [],
                        rules: [
                            {
                                required: true,
                                message: 'A Meta Keyword is required.',
                            },
                            {
                                validator: (rule, value, callback) => {
                                    const rgx = /^[A-Za-z0-9-._~:/?#\[\]@!$&'()*+,;=]+$/i
                                    const valid = value.some((v: any) => rgx.test(v))
                                    valid ? callback() : callback(new Error(rule.message))
                                },
                                message: 'Meta Keywords contains invalid characters.',
                            },
                        ],
                    })(
                        <StackedTagSelect<string[]>
                            mode="tags"
                            placeholder="Keywords"
                            maxTagCount={5}
                            getPopupContainer={this.appService.getAppContainer}
                            tokenSeparators={[',']}
                            showOptions={false}
                            onChange={this.handleMetaKeywordChange}
                            notFoundContent=""
                        />,
                    )}
                </Form.Item>
            </div>
        )
    }

    private resolveTestTreatments(): any[] {
        const defaultTreatment: Array<Partial<IPromptTreatment>> = [
            {
                id: randomstring.generate({ length: 5 }) as any,
                style: PromptStyle.SYSTEM,
                weight: 50,
                config: {
                    behavior: {},
                },
            } as any,
        ]
        const treatments = defaultTreatment

        // if (this.props.test && this.props.test.notifications) {
        //     const notifs: NotificationDto[] = this.props.test.notifications;
        //     treatments = notifs.map(this.extractNotificationTreatment);
        // }

        return treatments
    }

    private async setDomainState(): Promise<void> {
        const domain = this.appState.currentDomain

        if (domain) {
            if (this.state.domain && this.state.domain.id !== domain.id) {
                this.goBackToPromptsList()
            }

            await this.setState({ domain })

            let promptIdToLoad: any
            if (this.groupIdFromPath) {
                promptIdToLoad = this.groupIdFromPath
            } else if (this.promptIdFromTemplateQuery) {
                promptIdToLoad = this.promptIdFromTemplateQuery
            }

            if (promptIdToLoad) {
                const group = await this.promptService.fetchById(
                    domain.id,
                    promptIdToLoad,
                    false,
                    undefined,
                    onResponseError403(() => {
                        this.goBackToPromptsList()
                    }),
                )

                if (group) {
                    const selectedStyle = group.prompts[0].style
                    const isAutoShow = group.prompts[0].isAutoShow
                    const currentPrompt = group

                    const state: Partial<IState> = {
                        currentPrompt,
                        selectedStyle,
                        isAutoShow,
                    }

                    if (group.displayConditions) {
                        const conditions = group.displayConditions

                        if (conditions.mobile === 'disabled' || conditions.mobile === 'pass') {
                            state.mobileEnabled = false

                            if (conditions.mobile === 'disabled') {
                                state.mobileBypassEnabled = false
                            }
                        }

                        if (conditions.desktop === 'disabled' || conditions.desktop === 'pass') {
                            state.desktopEnabled = false

                            if (conditions.desktop === 'disabled') {
                                state.desktopBypassEnabled = false
                            }
                        }
                    }

                    if (group.pageCondtions) {
                        const conditions = group.pageCondtions

                        if (conditions.pageUrls) {
                            state.pageCondition = 'PAGE_URLS'
                        } else if (conditions.keywords) {
                            state.pageCondition = 'META_KEYWORDS'
                        }
                    }

                    if (group.prompts) {
                        state.treatments = group.prompts
                        state.selectedStyle = group.prompts[0].style

                        if (state.treatments.length > 1) {
                            state.abTestEnabled = true
                        }

                        if (group.prompts[0].visitorConditions) {
                            const conditions = group.prompts[0].visitorConditions

                            if (conditions.timeOnPageSeconds) {
                                state.visitorCondition = 'TIME_ON_PAGE'
                            } else if (conditions.sessionPageViews) {
                                state.visitorCondition = 'SESSION_PAGE_VIEWS'
                            } else if (conditions.sessionTimeOnSiteSeconds) {
                                state.visitorCondition = 'SESSION_TIME_ON_SITE'
                            }
                        }

                        // Backfill domain default image for default created slide prompts.
                        //  Default slide prompts are created before domain default icons
                        //  are uploaded and fall back to the domain icon unless one has
                        //  been manually uploaded to the prompt post domain creation.
                        if (state.selectedStyle === PromptStyle.SLIDE) {
                            group.prompts.forEach((prompt) => {
                                if (prompt.style === PromptStyle.SLIDE && !prompt.config.theme.image) {
                                    prompt.config.theme.image = domain.defaultIconUrl
                                }
                            })
                        }
                    }

                    await this.setState(state as any)
                    this.buildPreviewConfig()
                } else {
                    this.setState({
                        selectedStyle: domain.integrationTypeIsProxy ? PromptStyle.SLIDE : PromptStyle.SYSTEM,
                    })
                }
            } else {
                this.setState({
                    selectedStyle: domain.integrationTypeIsProxy ? PromptStyle.SLIDE : PromptStyle.SYSTEM,
                })
            }
        }
    }

    private setDesktopEnabled = async (desktopEnabled: boolean): Promise<void> => {
        this.setState({ desktopEnabled })
    }

    private setDesktopBypassEnabled = async (desktopBypassEnabled: boolean): Promise<void> => {
        this.setState({ desktopBypassEnabled })
    }

    private setMobileEnabled = async (mobileEnabled: boolean): Promise<void> => {
        this.setState({ mobileEnabled })
    }

    private setMobileBypassEnabled = async (mobileBypassEnabled: boolean): Promise<void> => {
        this.setState({ mobileBypassEnabled })
    }

    private setABTestEnabledState = async (abTestEnabled: boolean): Promise<void> => {
        this.setState(({ treatments, abTestEnabled: previousValue }) => {
            const state: any = {
                treatments,
                abTestEnabled,
            }

            if (!abTestEnabled) {
                state.treatments = [treatments[0]]
                state.currentTreatmentIndex = 0
            }

            if (!previousValue) {
                state.treatments[0].weight = 50
            }

            return state
        })
    }

    private setPageCondition = async (ev: RadioChangeEvent): Promise<void> => {
        await this.setState({ pageCondition: ev.target.value })
    }

    private setDisplayPctToAllUsersState = async (ev: RadioChangeEvent): Promise<void> => {
        const displayPctToAllUsers = ev.target.value === 'TRUE'

        await this.setState({ displayPctToAllUsers })
        if (displayPctToAllUsers) {
            this.props.form.setFields({ displayToPct: undefined })
        }
    }

    private getCurrentPageConditions(): any {
        const { currentPrompt } = this.state

        return currentPrompt ? (currentPrompt.conditions || {}).page || {} : {}
    }

    private handleTreatmentAddition = async (): Promise<void> => {
        const { treatments, currentTreatmentIndex } = this.state
        const currentTreatment = treatments[currentTreatmentIndex]
        const currentTotalWeight = objectMapReduce(treatments, (t) => parseInt(t.weight, 10))
        const remainingWeight = 100 - currentTotalWeight
        const newWeight = remainingWeight >= 0 ? remainingWeight : 0

        if (await this.validateTreatment(currentTreatment)) {
            const newTreatment: any = PromptDto.parse({
                ...currentTreatment,
                id: randomstring.generate({ length: 5 }),
                weight: newWeight,
            } as any)

            await this.setState(({ treatments: currentTreatments }) => {
                currentTreatments.push(newTreatment)

                return {
                    currentTreatmentIndex: currentTreatments.length - 1,
                    treatments: currentTreatments,
                    selectedStyle: currentTreatment.style,
                }
            })

            this.buildPreviewConfig()
        }
    }

    private handleTreatmentSelect = async (treatment: IPromptTreatment, idx: number): Promise<void> => {
        const { treatments, currentTreatmentIndex } = this.state
        const currentTreatment = treatments[currentTreatmentIndex]

        if (await this.validateTreatment(currentTreatment)) {
            await this.setState(() => ({
                currentTreatmentIndex: idx,
                selectedStyle: treatment.style,
            }))

            this.buildPreviewConfig()
        }
    }

    private handleTreatmentDelete = async (
        treatment: IPromptTreatment,
        idx: number,
        ev: React.MouseEvent<HTMLSpanElement>,
    ): Promise<void> => {
        ev.preventDefault()
        ev.stopPropagation()

        await this.setState(({ treatments, currentTreatmentIndex }) => {
            treatments.splice(idx, 1)
            let newTreatmentIndex = currentTreatmentIndex

            const lastIdx = treatments.length - 1
            if (newTreatmentIndex !== idx) {
                if (newTreatmentIndex > treatments.length - 1) {
                    newTreatmentIndex--
                }
            } else {
                newTreatmentIndex = idx < 0 ? 0 : idx > lastIdx ? lastIdx : idx
            }

            return {
                currentTreatmentIndex: newTreatmentIndex,
                treatments,
                selectedStyle: treatments[newTreatmentIndex].style,
            }
        })

        this.buildPreviewConfig()
    }

    private handleTreatmentUpdate = async (treatment: IPromptTreatment): Promise<void> => {
        await this.setState(({ treatments, currentTreatmentIndex }) => {
            treatments.splice(currentTreatmentIndex, 1, treatment)

            return {
                treatments,
                selectedStyle: treatment.style,
            }
        })

        this.buildPreviewConfig()
    }

    private handlePageUrlsChange = (pageurls: string[]): void => {
        if (pageurls.length > MAX_PAGE_URLS_ALLOWED) {
            pageurls.pop()
            this.props.form.setFieldsValue({ pageurls })
        }
    }

    private handleExcludedPageUrlsChange = (excludedPageurls: string[]): void => {
        if (excludedPageurls.length > MAX_PAGE_URLS_ALLOWED) {
            excludedPageurls.pop()
            this.props.form.setFieldsValue({ excludedPageurls })
        }
    }

    private handleMetaKeywordChange = (metaKeywords: string[]): void => {
        if (metaKeywords.length > 5) {
            metaKeywords.pop()
            this.props.form.setFieldsValue({ metaKeywords })
        }
    }

    private handleChannelsChange = (change: DeliveryChannel) => {}

    private handleFormSubmit = async (): Promise<any> => {
        const {
            currentPrompt: currentPromptGroup,
            treatments,
            abTestEnabled,
            desktopEnabled,
            desktopBypassEnabled,
            mobileEnabled,
            mobileBypassEnabled,
            isAutoShow,
        } = this.state

        try {
            const values = await this.validateFields()
            const testValues = await this.validatorRef()

            if (abTestEnabled) {
                if (treatments.length === 1) {
                    simpleNotification('error', 'Prompt testing requires at least two tests to be defined.')
                    return
                }

                this.validateTestWeights()
            }

            const groupDto: any = {}
            groupDto.channel = values.channel
            groupDto.name = values.name
            groupDto.isActive = currentPromptGroup ? currentPromptGroup.isActive : true
            groupDto.isAbTest = abTestEnabled
            groupDto.conditions = {
                display: {
                    desktop: desktopEnabled ? 'enabled' : desktopBypassEnabled ? 'pass' : 'disabled',
                    mobile: mobileEnabled ? 'enabled' : mobileBypassEnabled ? 'pass' : 'disabled',
                },
            }

            if (this.state.pageCondition !== 'ALL_PAGES') {
                groupDto.conditions.page = {}

                switch (this.state.pageCondition) {
                    case 'PAGE_URLS':
                        groupDto.conditions.page.pageUrls = values.pageUrls
                        groupDto.conditions.page.excludedPageUrls = values.excludedPageUrls
                        break
                    case 'META_KEYWORDS':
                        groupDto.conditions.page.keywords = values.metaKeywords
                        break
                }

                if (!this.validatePageUrls(values)) {
                    return
                }
            }

            groupDto.prompts = treatments
            if (Array.isArray(groupDto.prompts)) {
                groupDto.prompts.forEach((prompt: any) => (prompt.isAutoShow = isAutoShow))
            }

            let response: any = false
            if (this.groupIdFromPath) {
                response = await this.promptService.update(this.state.domain!.id, this.groupIdFromPath, groupDto)
            } else {
                response = await this.promptService.create(this.state.domain!.id, groupDto)
            }

            if (response) {
                simpleNotification(
                    'success',
                    `
                    Prompt "${groupDto.name}" was successfully saved. Changes
                    may take up to 10 minutes to take effect.
                `.trim(),
                )

                this.goBackToPromptsList()
            }
        } catch (error) {
            if (error.response?.data?.message) {
                simpleNotification('error', error.response.data.message)
            } else {
                if (error instanceof Error) {
                    simpleNotification('error', error.message)
                } else {
                    simpleFormErrorNotification(error)
                }
            }
        }
    }

    private handleCancel = (): void => {
        this.appService.routeBack()
    }

    private async validateTreatment(treatment: IPromptTreatment): Promise<boolean> {
        try {
            await this.validatorRef()
            return true
        } catch (error) {
            if (error instanceof Error) {
                simpleNotification('error', error.message)
            } else {
                simpleFormErrorNotification(error)
            }

            return false
        }
    }

    private validateTestWeights(): boolean {
        const sum = objectMapReduce(
            this.state.treatments,
            (t) => parseInt(t.weight, 10),
            (v, n) => (n += v),
        )

        if (sum !== 100) {
            throw new Error(`Variant weights must add up to and not exceed 100%. Current total weight is ${sum}%.`)
        }

        return true
    }

    private validatePageUrls(values: any): boolean {
        let valid = true
        const noUrlConditionSet =
            (!values.pageUrls || values.pageUrls.length === 0) &&
            (!values.excludedPageUrls || values.excludedPageUrls.length === 0)

        if (this.state.pageCondition === 'PAGE_URLS' && noUrlConditionSet) {
            valid = false

            simpleFormErrorNotification({
                pageUrls: {
                    errors: [
                        {
                            field: 'pageConditions',
                            message:
                                'At least one included page url or excluded page url must be set when the URLs Containing option page condition is selected.',
                        },
                    ],
                },
            })
        }

        return valid
    }

    private buildPreviewConfig = async (ev?: any): Promise<void> => {
        const { treatments, currentTreatmentIndex } = this.state

        const config: any = { ...treatments[currentTreatmentIndex].config }
        let component: any
        config.managed = true
        config.previewMode = true

        if (this.state.selectedStyle === PromptStyle.SLIDE) {
            component = SlidePrompt
        }
        if (this.state.selectedStyle === PromptStyle.BELL) {
            component = BellPrompt
        }

        const radioGroup = { value: false }
        if (ev?.target?.type === 'radio') {
            radioGroup.value = ev.target.value
        }

        const contextMock = {
            domain: {
                getWebsitePushId: () => null,
            },
            user: {
                isSubscribed: () => radioGroup.value,
                env: {
                    getCurrentDocument: () => document,
                },
            },
        }

        const settingsMock = { prompt: { config: convertCase(config, 'snake') } }

        if (component) {
            this.previewRef.innerHTML = ''

            if (config.theme) {
                const position = config.theme.position

                if (position) {
                    position.desktop = position.desktop === 'disabled' ? 'bottom-left' : position.desktop
                }
            }

            const comp = new component(contextMock, settingsMock, false)

            this.previewRef.innerHTML = ''
            await comp.attachToDom(this.previewRef)
        }
    }

    private goBackToPromptsList = (): void => {
        this.appService.routeWithinDomain('/prompts')
    }
}

export const PromptEditor = Form.create<IProps>()(PromptEditorComponent)
