import React from 'react'
import '../styles/media-field.scss'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Switch } from 'antd'
import { isMacroMatch } from '../../../../_utils/macros'
import { getClassNames } from '../../../../_utils/classnames'
import { FileUploader } from '@pushly/aqe/lib/components'
import { IFilePickerValue } from '@pushly/aqe/lib/components/file-uploader/interfaces'
import { simpleFormErrorNotification, simpleNotification } from '../../../../_utils/utils'

interface ITemplateMediaField {
    className?: string
    domainId: number
    accountId?: number
    disabled?: boolean
    defaultDomainMediaUrl?: string
    defaultCompositeValue?: IFilePickerValue
    defaultValue?: string
    value?: string | null
    onChange?: (value: string | undefined) => void
    label?: string | React.ReactNode
    size?: 'small' | 'large' | 'default'
    macroTag?: string
    macroTagLabel?: string
    hideMacroToggle?: boolean
    render?: () => JSX.Element

    icon?: string
    iconColor?: string
    pjpg?: boolean
    quality?: number
    cropRatio?: any
    resize?: number[]
    cropDim?: number[]
    cropMax?: number[]
    cropMin?: number[]
    forceCrop?: boolean
}

const TemplateMediaField = (props: ITemplateMediaField) => {
    const propsChangeTimeout = React.useRef<any>()
    const [tmpValue, setTmpValue] = React.useState<string | undefined>(props.value ?? props.defaultValue)
    const size = !props.size || props.size === 'default' ? 'small' : props.size

    const handleUploadSuccess = (upload?: any) => {
        const v = upload?.url
        if (v !== tmpValue) {
            setTmpValue(v)
            setTimeout(() => props.onChange?.(v), 50)
        }
    }

    const handleUploadRemoval = (upload?: any) => {
        const v = upload?.url
        if (!upload || v === tmpValue) {
            setTmpValue(undefined)
            setTimeout(() => props.onChange?.(undefined), 50)
        }
    }

    const handleError = (res: any) => {
        const error = res?.error
        if (error && error.error_type === 'invalid_file_type_error') {
            return simpleNotification('error', error.message)
        }
    }

    // Resets the media value when clear content
    // sets the value to undefined
    React.useEffect(() => {
        if ((props.value === undefined || props.value === null) && !!tmpValue) {
            setTmpValue(props.value!)
        }
    }, [props.value === undefined || props.value === null])

    // Handles external reset of value
    // current use case is for segment icon url overrides
    React.useEffect(() => {
        clearTimeout(propsChangeTimeout.current)
        propsChangeTimeout.current = setTimeout(() => {
            const valuesMatch = (tmpValue ?? undefined) === (props.value ?? undefined)
            if (props.value && !valuesMatch) {
                setTmpValue(props.value)
            }
        }, 50)
    }, [props.value])

    const valueIsMacroMatch = isMacroMatch(tmpValue, props.macroTag ?? '')

    return (
        <div
            className={getClassNames(null, 'media-field-wrapper', `size-${size}`, props.className, {
                'has-macro-toggle': !props.hideMacroToggle && !!props.macroTag,
            })}
        >
            <Form.Item
                label={
                    props.hideMacroToggle ? (
                        props.label
                    ) : (
                        <>
                            <span>{props.label}</span>
                            {!!props.macroTag && (
                                <span className="ecomm-item-image-toggle-wrapper">
                                    <Switch
                                        size="small"
                                        disabled={props.disabled}
                                        checked={isMacroMatch(tmpValue, props.macroTag)}
                                        onChange={(checked) => {
                                            if (checked) {
                                                handleUploadSuccess({ url: `{{${props.macroTag}}}` })
                                            } else {
                                                handleUploadRemoval()
                                            }
                                        }}
                                    />

                                    {!!props.macroTagLabel && <span>{props.macroTagLabel}</span>}
                                </span>
                            )}
                        </>
                    )
                }
            >
                {!!props.render ? (
                    props.render()
                ) : valueIsMacroMatch ? (
                    <button
                        name="filestack"
                        className={`filestack-upload ant-upload ant-upload-select ant-upload-select-picture-card image-mode disabled ${props.className}`}
                        disabled={true}
                    >
                        <span className="ant-upload contents" role="button">
                            <div className="faux-btn">
                                <ShoppingCartOutlined />
                                <span>{`{{${props.macroTag}}}`}</span>
                            </div>
                        </span>
                    </button>
                ) : (
                    <FileUploader
                        className={props.className}
                        type="image"
                        disabled={props.disabled}
                        ownerType={props.accountId ? 'org' : 'domain'}
                        ownerId={props.accountId ? props.accountId : props.domainId}
                        ownerIcon={props.defaultDomainMediaUrl}
                        defaultValue={
                            props.defaultCompositeValue ??
                            (!tmpValue
                                ? undefined
                                : {
                                      url: tmpValue,
                                  })
                        }
                        value={
                            props.defaultCompositeValue ??
                            (!tmpValue
                                ? props.defaultCompositeValue
                                : {
                                      url: tmpValue,
                                  })
                        }
                        imageConfig={{
                            resize: props.resize,
                            cropDim: props.cropDim,
                            cropMin: props.cropMin,
                            cropMax: props.cropMax,
                            cropRatio: props.cropRatio,
                            quality: props.quality,
                            pjpg: props.pjpg,
                        }}
                        onSuccess={(upload) => {
                            try {
                                handleUploadSuccess(upload)
                            } catch (err) {
                                if (err) {
                                    console.log(err)
                                }
                            }
                        }}
                        onRemove={(upload) => {
                            try {
                                handleUploadRemoval(upload)
                            } catch (err) {
                                if (err) {
                                    console.log(err)
                                }
                            }
                        }}
                        onError={handleError}
                    />
                )}
            </Form.Item>
        </div>
    )
}

export default TemplateMediaField
