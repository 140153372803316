import React from 'react'
import './color-picker-popover.scss'
import { Popover, Switch } from 'antd'
import { SketchPicker } from 'react-color'
import { preventBubbling } from '@pushly/aqe/lib/utils/prevent'

type Color = string | null | undefined

interface IColorPickerPopoverProps {
    defaultColor?: Color
    color: Color
    onChange: (color: Color) => void
}

const ColorPickerPopover = (props: IColorPickerPopoverProps) => {
    // @ts-ignore
    const popoverRef = React.createRef<Popover>()

    return (
        <Popover
            ref={popoverRef}
            className="color-picker-popover-trigger"
            overlayClassName="color-picker-popover"
            trigger="click"
            content={
                <SketchPicker
                    disableAlpha={true}
                    presetColors={[]}
                    color={props.color ?? '#FFF'}
                    onChangeComplete={(res) => {
                        const hex = res.hex?.trim()
                        props.onChange(!!hex ? hex : null)
                    }}
                />
            }
        >
            <div
                className="color-picker-popover-preview"
                style={{
                    backgroundColor: !props.color ? '#FFF' : props.color,
                }}
            >
                <div
                    className="meta"
                    onClick={(ev) => {
                        const isVisible = (popoverRef.current as any)?.tooltip?.state?.visible ?? false

                        if (!isVisible && !(ev.target as HTMLElement).classList.contains('ant-switch')) {
                            preventBubbling(ev)
                        }
                    }}
                >
                    <span className="toggle">
                        <Switch
                            size="small"
                            checked={!!props.color}
                            onChange={(checked) => {
                                props.onChange(checked ? props.defaultColor ?? '#FFF' : null)
                            }}
                            onClick={(checked, ev) => {
                                const isVisible = (popoverRef.current as any)?.tooltip?.state?.visible ?? false

                                if ((!checked && !isVisible) || (checked && isVisible)) {
                                    preventBubbling(ev)
                                }
                            }}
                        />
                    </span>
                    <span className="color-code">{props.color ?? 'N/A'}</span>
                </div>
            </div>
        </Popover>
    )
}

export default ColorPickerPopover
