import * as React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Spin } from 'antd'
import { Well } from '../../well/well'
import { getClassNames } from '../../../_utils/classnames'
import { numberWithCommas } from '../../../_utils/utils'
import { FormContext } from '../context'

interface IReachBuilder {
    className?: string
    loading?: boolean
}

export const ReachDisplay = ({ loading, className }: IReachBuilder) => {
    const { theme, mode, reachEstimate } = React.useContext(FormContext)

    return (
        <div
            className={getClassNames('ndf-section', className, {
                ['section-reach-estimate']: true,
                ['loading']: loading,
                [`theme-${theme.toLowerCase()}`]: true,
                [`mode-${mode.toLowerCase()}`]: true,
            })}
        >
            <div className={getClassNames('ndf-section-wrapper')}>
                <Well showHeader={false} showFooter={false}>
                    <Form.Item label={<span className="well-title">Total Audience Size</span>}>
                        <span className="reach-estimate">{numberWithCommas(reachEstimate ?? 0)}</span>
                        <span className="reach-label">Subscribers</span>

                        {loading && <Spin indicator={<LoadingOutlined spin={true} />} />}
                    </Form.Item>
                </Well>
            </div>
        </div>
    )
}
