import * as React from 'react'
import { IBadgeProps } from './interfaces'
import Icon, { ChromeFilled, ChromeOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

export const WebBadge: React.FC<IBadgeProps> = (props) => {
    const badge = (
        <span className="icon-chrome">
            <ChromeFilled {...props} />
        </span>
    )

    return (
        <span className="delivery-channel-web-badge">
            {props.hideTooltip ? badge : <Tooltip title={props.tooltip || 'Delivery Channel: Web'}>{badge}</Tooltip>}
        </span>
    )
}
