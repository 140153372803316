import * as React from 'react'
import './style/domain-data-form.scss'
import { Container } from 'typescript-ioc/es5'
import { DomainDto } from '../../dtos/domain'
import { AccountDto } from '../../dtos/account.dto'
import { AppState } from '../../stores/app'
import { AccountService, DomainService, UserService } from '../../services'
import { getClassNames } from '../../_utils/classnames'
import { DomainDetailsWell } from './domain-details-well'
import { DomainServerWell } from './domain-server-well'
import { SendIntegrationsWell } from './domain-send-integrations-well'
import { asCaslSubject } from '../../stores/app-ability'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { AbilityAction } from '../../enums/ability-action.enum'

interface IDomainDataForm {
    className?: string
    domainId?: number
}

interface IDDFState {
    domain?: DomainDto
    account?: AccountDto
}

export class DomainDataForm extends React.Component<IDomainDataForm, IDDFState> {
    public readonly state: IDDFState = {}

    protected appState: AppState
    protected domainService: DomainService
    protected accountService: AccountService
    protected userService: UserService

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.domainService = Container.get(DomainService)
        this.accountService = Container.get(AccountService)
        this.userService = Container.get(UserService)

        if (props.domainId) {
            this.fetchRequestedDomain()
        }
    }

    public render() {
        return (
            <div className={getClassNames('ddf', this.props.className)}>
                <div className={getClassNames('ddf-wrapper')}>
                    <DomainDetailsWell
                        loading={this.isLoading}
                        domain={this.state.domain!}
                        onDomainUpdated={this.handleDomainUpdated}
                    />

                    {this.appState.currentUser?.isInternalUser && (
                        <DomainServerWell
                            loading={this.isLoading}
                            domain={this.state.domain!}
                            onDomainUpdated={this.handleDomainUpdated}
                        />
                    )}

                    <SendIntegrationsWell
                        loading={this.isLoading}
                        domain={this.state.domain!}
                        onDomainUpdated={this.handleDomainUpdated}
                    />
                </div>
            </div>
        )
    }

    protected get isLoading(): boolean {
        return !!this.props.domainId && !this.state.domain && !this.state.account
    }

    protected handleDomainUpdated = async (updates: Partial<DomainDto>) => {
        this.setState(({ domain }) => ({
            domain: {
                ...domain,
                ...updates,
            } as DomainDto,
        }))

        await this.appState.fetchUserDependencies()
    }

    protected async fetchRequestedDomain() {
        const { ok, data: domain } = await this.domainService.fetchById(this.props.domainId!, {
            showLoadingScreen: false,
            cancellationKey: 'ddf-fetch',
        })

        if (ok) {
            // internal type check
            // all internal users should see creator/updator info on domains
            if (this.appState.currentUser?.isInternalUser) {
                const creatorIdentity = asCaslSubject(SubjectEntity.USER, { id: domain.createdBy })
                const updaterIdentity = asCaslSubject(SubjectEntity.USER, { id: domain.updatedBy })
                const canReadCreator = this.appState.abilityStore.can(AbilityAction.READ, creatorIdentity)
                const canReadUpdater = this.appState.abilityStore.can(AbilityAction.READ, updaterIdentity)

                const authorReqs: Array<Promise<any>> = []
                const hasUpdateAuthor = !!domain.updatedBy
                const sameAuthor = domain.createdBy === domain.updatedBy

                if (!domain.createdByUsername && canReadCreator) {
                    authorReqs.push(
                        this.userService.fetchUserById(domain.createdBy, { muteErrors: true }).then((user) => {
                            if (user) {
                                domain.createdByUsername = user.name
                                if (hasUpdateAuthor && sameAuthor) {
                                    domain.updatedByUsername = user.name
                                }
                            }
                        }),
                    )
                }

                if (!domain.updatedByUsername && hasUpdateAuthor && !sameAuthor && canReadUpdater) {
                    authorReqs.push(
                        this.userService.fetchUserById(domain.updatedBy, { muteErrors: true }).then((user) => {
                            if (user) {
                                domain.updatedByUsername = user.name
                            }
                        }),
                    )
                }

                await Promise.all(authorReqs)
            }

            this.setState({ domain })
        }
    }
}
