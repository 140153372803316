import * as React from 'react'
import { Button, Well } from '@pushly/aqe/lib/components'
import { EditOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import { Skeleton } from 'antd'
import { Container } from 'typescript-ioc/es5'
import * as randomstring from 'randomstring'
import 'jsoneditor-react/es/editor.min.css'
import { DomainDto } from '../../dtos/domain'
import { AppState } from '../../stores/app'
import FallbackNotificationEditor from './fallback-notification-editor'
// @ts-ignore
import { JsonEditor } from 'jsoneditor-react'
import { NotificationDto } from '../../features/notifications'
import { getDomainFallbackNotification, getFallbackDisplayPreviewBuild } from './helpers'
import NotificationBuilderPreview from '../notification-builder/elements/notification-builder-preview'
import { DomainService, NotificationService } from '../../services'
import { NotificationVariantModel } from '../../models/notification/notification-variant.model'
import { parseLoadedVariant } from '../notification-builder/helpers'

interface IDefaultNotificationWell {
    loading?: boolean
    domain: DomainDto
    title?: string
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface IState {
    showDrawer: boolean
    drawerKey?: string
}

class FallbackNotificationWell extends React.Component<IDefaultNotificationWell, IState> {
    public state: IState = {
        showDrawer: false,
    }

    protected readonly appState: AppState
    protected readonly notifService: NotificationService
    protected readonly domainService: DomainService

    public constructor(props: IDefaultNotificationWell) {
        super(props)

        this.appState = Container.get(AppState)
        this.notifService = Container.get(NotificationService)
        this.domainService = Container.get(DomainService)
    }

    public render() {
        const domain = this.props.domain

        let fallback: NotificationDto | undefined
        let fallbackVariantModel: NotificationVariantModel | undefined
        if (!!domain) {
            fallback = getDomainFallbackNotification(domain)
            fallbackVariantModel = parseLoadedVariant(fallback)
        }

        return (
            <Well
                className="nested sw-v2-fallback-notif-well"
                title="Fallback Notification"
                actions={
                    <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                        <EditOutlined />
                        <span>Edit</span>
                    </Button>
                }
                hideFooter={true}
            >
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading && (
                        <>
                            <NotificationBuilderPreview
                                loading={this.props.loading}
                                domain={domain}
                                source={fallbackVariantModel!}
                                chromeless={true}
                                onPreviewRequest={async (type) => {
                                    const previewBuild = getFallbackDisplayPreviewBuild(domain, type, fallback!)

                                    await this.notifService.sendNotification(domain.id, previewBuild, {
                                        cancellationKey: `notif-send-preview`,
                                    })
                                }}
                            />

                            <FallbackNotificationEditor
                                visible={this.state.showDrawer}
                                onSubmit={async (notif) => {
                                    const { ok, data } = await this.domainService.updateDomainById(this.domain.id, {
                                        fallbackNotificationTemplate: notif.template,
                                    })

                                    if (ok) {
                                        this.handleDrawerClose()
                                        this.props.onDomainUpdated(data)
                                    }
                                }}
                                defaultValue={fallback}
                                onClose={this.handleDrawerClose}
                            />
                        </>
                    )}
                </Skeleton>
            </Well>
        )
    }

    protected get domain(): DomainDto {
        return this.props.domain
    }

    protected handleDrawerOpen = () => {
        this.setState({ showDrawer: true, drawerKey: randomstring.generate() })
    }

    protected handleDrawerClose = () => {
        this.setState({ showDrawer: false })
    }
}

export default FallbackNotificationWell
