import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService, NotificationService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { PageHeader } from '../../components/page-header/page-header'
import '../create-notification/create-notification.scss'
import { NotificationDto } from '../../features/notifications/index'
import { observer } from 'mobx-react'
import { observe } from 'mobx'
import { DomainDto } from '../../dtos/domain'
import { NotificationDataFormV2 } from '../../components/notification-data-form/notification-data-form'
import { NdfFormTheme } from '../../components/notification-data-form/enums/ndf-form-theme.enum'
import { NdfFormMode } from '../../components/notification-data-form/enums/ndf-form-mode.enum'
import { ApiVersion } from '../../enums/api-version.enum'

interface IEditNotificationProps {}

interface IEditNotificationState {
    domain?: DomainDto
    notification?: NotificationDto
}

@observer
export class EditNotification extends BetterComponent<IEditNotificationProps, IEditNotificationState> {
    public readonly defaultClassName: string = 'sw-mv-create-notification'

    protected appState: AppState
    protected appService: AppService
    protected notificationService: NotificationService

    protected disposeObservers: any[] = []

    public constructor(props: IEditNotificationProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.notificationService = Container.get(NotificationService)

        this.state = {
            domain: this.appState.currentDomain,
        }
    }

    public async UNSAFE_componentWillMount() {
        this.disposeObservers.push(observe(this.appState, 'currentDomainJsonData', () => this.ensureSameDomain()))

        this.appService.resetContentScrollPosition()
        this.fetchEditableNotification()
    }

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.disposeObservers.forEach((fn) => fn())
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <PageHeader title="Edit Notification" />

                <NotificationDataFormV2
                    theme={NdfFormTheme.MOBILE}
                    mode={NdfFormMode.EDIT}
                    completionRedirect={this.dashboardUrl}
                    template={this.state.notification}
                    loading={!this.state.notification}
                />
            </div>
        )
    }

    protected get dashboardUrl(): string {
        return this.appService.routeWithinDomain('/dashboard', true)
    }

    protected get editableNotificationId(): number | undefined {
        try {
            return parseInt(this.injectedProps.match.params.notifId, 10)
        } catch {
            return
        }
    }

    protected ensureSameDomain(): void {
        if (!!this.state.domain && !!this.appState.currentDomain) {
            if (this.state.domain.id !== this.appState.currentDomain.id) {
                this.appService.routeWithinDomain('/dashboard')
            }
        }

        if (!this.state.domain && !!this.appState.currentDomain) {
            this.fetchEditableNotification()
        }

        this.setState({ domain: this.appState.currentDomain })
    }

    protected async fetchEditableNotification(): Promise<void> {
        const domain = this.appState.currentDomain
        const notificationId = this.editableNotificationId
        const state: any = {}

        const query = { include_schedules: true }
        if (domain && domain.id && notificationId) {
            const notification = await this.notificationService.fetchNotificationById(domain.id, notificationId, {
                query,
                showLoadingScreen: true,
                cancellationKey: 'mv.endf.fetch',
                version: ApiVersion.V4,
            })

            if (notification) {
                state.notification = notification
            }

            this.setState(state)
        }
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName, 'page-view']

        return classNames.join(' ')
    }
}
