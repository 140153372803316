import * as React from 'react'
import autobind from 'autobind-decorator'
import { IRule, IRuleDefinition } from './interfaces/rule'
import './styles/rule-row.scss'
import { IRuleBuilderField } from '../rule-builder/rule-builder'
import * as deepEqual from 'react-fast-compare'
import { Select } from 'antd'
import * as randomstring from 'randomstring'
import { stripDomainIdPattern } from './utils'
import { SegmentBuilderContext } from '../segment-builder/segment-builder-context'

interface IRuleRowPropertyProps {
    mode: 'display' | 'edit'
    rule: IRule
    builderFields: IRuleBuilderField[]
    onChange: (property: string, rule: IRule) => any
}

export class RuleRowProperty extends React.Component<IRuleRowPropertyProps, {}> {
    public static contextType = SegmentBuilderContext
    public context!: React.ContextType<typeof SegmentBuilderContext>

    public readonly defaultClassName = 'sw-v2-rb-rule-row-property'
    public ref: any

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className="display-mode">{this.propertyDisplay}</div>

                <div className="edit-mode">
                    <Select
                        dropdownClassName={this.buildClassName('dropdown')}
                        placeholder="Choose a property"
                        value={this.rule.builderProperty}
                        onChange={this.handleChange}
                        onSelect={() => (document?.activeElement as HTMLElement).blur()}
                        showSearch={true}
                        optionFilterProp="title"
                        // optionLabelProp="title"
                        filterOption={true}
                    >
                        {this.compiledFieldGroups.map((group) => (
                            <Select.OptGroup key={group.label} label={group.label}>
                                {group.fields.map((field) => {
                                    const display = field.display || field.property

                                    return field.type === 'divider' ? (
                                        <Select.Option key={randomstring.generate()} value="divider" disabled={true}>
                                            <hr className="field-option-divider" />
                                        </Select.Option>
                                    ) : !field.condition || field.condition() ? (
                                        <Select.Option
                                            key={field.property}
                                            value={field.property}
                                            title={!field.groupDisplay ? display : `${field.groupDisplay}: ${display}`}
                                        >
                                            {display}
                                        </Select.Option>
                                    ) : (
                                        ''
                                    )
                                })}
                            </Select.OptGroup>
                        ))}
                    </Select>
                </div>
            </div>
        )
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get field(): IRuleBuilderField | undefined {
        return this.props.builderFields.find((f) => {
            return (
                f.property === this.rule.builderProperty ||
                stripDomainIdPattern(f.property) === this.rule.builderProperty
            )
        })
    }

    protected get propertyDisplay(): string {
        let property = this.rule.builderProperty
        if (!this.field) return property

        if (property === 'custom_profile') property = this.rule.property
        else property = this.field.display?.replace(/^[\w]+:[\s]*?/i, '') || property

        return property
    }

    protected get compiledFieldGroups(): any[] {
        const builderGroups: any[] = []

        this.props.builderFields
            .filter((bf) => !bf.hidden)
            .forEach((bf) => {
                if (bf.property === 'divider') {
                    return
                }

                let groupLabel = 'Other'
                let fieldLabel = bf.display

                const displayParts = (bf.display || '').split(/:[\s]?/)
                if (displayParts.length > 1) {
                    groupLabel = displayParts.shift()!
                    fieldLabel = displayParts.join(': ')
                }

                let groupIdx = builderGroups.findIndex((bg) => bg.label === groupLabel)
                if (groupIdx === -1) {
                    builderGroups.push({
                        label: groupLabel,
                        fields: [],
                    })

                    groupIdx = builderGroups.length - 1
                }

                builderGroups[groupIdx].fields.push({
                    ...bf,
                    display: fieldLabel,
                    groupDisplay: groupLabel,
                })
            })

        return builderGroups
    }

    @autobind
    protected async handleChange(value: string): Promise<void> {
        if (!deepEqual(value, this.rule.builderProperty)) this.props.onChange(value, this.props.rule)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
