import * as React from 'react'
import './macro-manager.scss'
import * as clone from 'clone'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import Icon, { Loading3QuartersOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Popover, Input, Tooltip } from 'antd'
import { PopoverProps } from 'antd/lib/popover'
import { getClassNames } from '../../_utils/classnames'
import { generateShortID } from '../campaign-builder/helpers/uid'
import { escapeRegExpString as esc } from '../../_utils/regexp'
import { IconMacro } from '../svg/icon-macro'
import { isPromise } from '../../_utils/object'
import { InputProps } from 'antd/lib/input'
import { BetterInput } from '../better-input/better-input'
import { PromiseableProp, resolvePromiseableProp } from '../../_utils/promiseable-prop'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { EcommItemType } from 'components/campaign-builder/enums'

function fireNativeInputChangeEvent(el, value) {
    const nativeSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')!.set!
    nativeSetter.call(el, value)

    const fauxEvent = new Event('input', { bubbles: true })
    el.dispatchEvent(fauxEvent)
}

interface IMacroOption {
    label: string
    value: string
    option_types?: MacroOptionType[]
}

interface IMacroOptionGroup {
    label: string
    options: IMacroOption[]
}

export type MacroOptionType = EcommItemType
type MacroType = 'domain' | 'device' | 'location' | 'ecomm' | 'custom' | 'notification'
type MacroOptions = { [key in MacroType]: IMacroOptionGroup }

const MacroOptions: MacroOptions = {
    domain: {
        label: 'Domain',
        options: [
            {
                label: 'Domain ID',
                value: 'domain_id',
            },
            {
                label: 'Domain URL',
                value: 'domain_url',
            },
        ],
    },
    device: {
        label: 'Device',
        options: [
            {
                label: 'Type',
                value: 'profile.placement',
            },
        ],
    },
    notification: {
        label: 'Notification',
        options: [
            {
                label: 'Notification ID',
                value: 'notification_id',
            },
            {
                label: 'Notification Title',
                value: 'notification_title',
            },
            {
                label: 'Notification Body',
                value: 'notification_body',
            },
            {
                label: 'Segment IDs',
                value: 'segment_ids',
            },
            {
                label: 'Segment Names',
                value: 'segment_names',
            },
            {
                label: 'Keywords',
                value: 'keywords',
            },
            {
                label: 'Notification Source (Manual, Feed)',
                value: 'notification_source',
            },
            {
                label: 'Delivery Type (Immediate, Scheduled)',
                value: 'delivery_type',
            },
        ],
    },
    location: {
        label: 'Location',
        options: [
            {
                label: 'City',
                value: 'profile.city',
            },
            {
                label: 'Province',
                value: 'profile.province',
            },
            {
                label: 'Country Code',
                value: 'profile.country_code',
            },
        ],
    },
    ecomm: {
        label: 'E-Commerce Item',
        options: [
            {
                label: 'ID',
                value: 'item.item_id',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Name',
                value: 'item.name',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Description',
                value: 'item.description',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Landing URL',
                value: 'item.url',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Image URL',
                value: 'item.image',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },
            {
                label: 'Price',
                value: 'item.price',
                option_types: [EcommItemType.EVENT, EcommItemType.PRODUCT],
            },

            // PRODUCT
            {
                label: 'Brand Name',
                value: 'item.brand_name',
                option_types: [EcommItemType.PRODUCT],
            },

            // EVENT
            {
                label: 'Event Start Date',
                value: 'item.date_start',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Event End Date',
                value: 'item.date_end',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Genre: Major',
                value: 'item.genre_major',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Genre: Minor',
                value: 'item.genre_minor',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Performer Name',
                value: 'item.performer_name',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Performer URL',
                value: 'item.performer_url',
                option_types: [EcommItemType.EVENT],
            },

            {
                label: 'Performer Image URL',
                value: 'item.performer_image',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue ID',
                value: 'item.location_id',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue Name',
                value: 'item.location_name',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue City',
                value: 'item.location_city',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue Region',
                value: 'item.location_region',
                option_types: [EcommItemType.EVENT],
            },
            {
                label: 'Venue Country',
                value: 'item.location_country',
                option_types: [EcommItemType.EVENT],
            },
        ],
    },
    custom: {
        label: 'Custom',
        options: [],
    },
}

type MacroManagerOnSelect = (macro: IMacroOption) => any
type MacroInputElement = React.ReactElement<Input> | React.ReactElement<HTMLInputElement>
type MacroInputTrigger = '{{.}}' | '[[.]]' | '((.))' | '<<.>>'

const BASE_CLASSNAME = 'macro-manager'
const DEFAULT_INPUT_TRIGGER: MacroInputTrigger = '{{.}}'

interface IInputContext {
    input: {
        value: string
        start: string
        end: string
    }
    macro: null | {
        startTrigger: string
        startPos: number
        endTrigger: string
        endPos: null | number
        value: null | string
    }
    cursor: {
        cursorPos: number
        inTrigger: boolean
    }
    seq: {
        start: RegExp
        end: RegExp
    }
}

interface IGetMacroOptionsProps {
    types?: MacroType[]
    options?: PromiseableProp<MacroOptions>
    customOptions?: PromiseableProp<IMacroOption[]>
    validOptionTypes?: MacroOptionType[]
}
const getMacroOptions = ({
    types,
    options,
    customOptions,
    validOptionTypes,
}: IGetMacroOptionsProps): IMacroOptionGroup[] => {
    let macroOptions = clone(MacroOptions)
    if (typeof options === 'object' && !isPromise(options)) {
        macroOptions = options as any
    }

    if (Array.isArray(customOptions)) {
        macroOptions.custom = {
            label: 'Custom',
            options: customOptions,
        }
    }

    const visibleTypes = types ?? ['domain', 'device', 'location', 'ecomm', 'custom']
    const macroGroups: { label: string; options: IMacroOption[] }[] = []
    for (const type of visibleTypes) {
        const typeConfig = macroOptions[type]
        if (!typeConfig) {
            continue
        }

        const group: IMacroOptionGroup = {
            label: typeConfig.label,
            options: [],
        }

        for (const opt of typeConfig.options) {
            if (
                validOptionTypes &&
                validOptionTypes.length > 0 &&
                opt.option_types &&
                !opt.option_types.some((ot) => validOptionTypes.includes(ot))
            ) {
                // if valid option types is provided do not return any option that does not have one of those types
                continue
            }

            group.options.push(opt)
        }

        if (group.options.length > 0) {
            macroGroups.push(group)
        }
    }

    return macroGroups
}

interface IMacroOptionProps {
    macro: IMacroOption
    onClick: Function
    context?: IInputContext
}
const MacroOption = ({ macro, onClick, context }: IMacroOptionProps) => {
    let isActive = context?.macro?.value?.trim() === macro.value.trim()

    return (
        <li
            className={getClassNames(`${BASE_CLASSNAME}-item`, {
                ['active']: isActive,
            })}
            onClick={(e) => {
                e.preventDefault()
                onClick(macro)
            }}
        >
            <span className="label">{macro.label}</span>
            {isActive && (
                <span className="active-indicator">
                    <LegacyIcon type={'check'} />
                </span>
            )}
        </li>
    )
}

interface IMacroOptionGroupProps {
    group: IMacroOptionGroup
    onItemSelect: Function
    context?: IInputContext
}
const MacroOptionGroup = ({ group, onItemSelect, ...props }: IMacroOptionGroupProps) => {
    return (
        <li className={getClassNames(`${BASE_CLASSNAME}-group`)}>
            <span className="heading">{group.label}</span>

            <ul>
                {group.options.map((macro, idx) => (
                    <MacroOption key={idx} macro={macro} onClick={onItemSelect} {...props} />
                ))}
            </ul>
        </li>
    )
}

interface IMacroOptionsListProps {
    loading?: boolean
    macros: IMacroOptionGroup[]
    onItemSelect: Function
    context?: IInputContext
}
const MacroOptionsList = ({ macros, loading, ...props }: IMacroOptionsListProps) => {
    return (
        <ul className={getClassNames(`${BASE_CLASSNAME}-item-list`)}>
            {loading && (
                <li className={getClassNames(`${BASE_CLASSNAME}-group`)}>
                    <span className="heading">
                        <Loading3QuartersOutlined spin={true} />
                        <span> Loading</span>
                    </span>
                </li>
            )}
            {macros.map((group, idx) => (
                <MacroOptionGroup key={idx} group={group} {...props} />
            ))}
        </ul>
    )
}

interface IMacroOptionsListToggleProps {
    loading?: boolean
    hideToggle?: boolean
    onClick: (ev: any) => any
}
const MacroOptionsListToggle = ({ onClick, loading, hideToggle }: IMacroOptionsListToggleProps) => {
    return (
        <span
            className={getClassNames(`${BASE_CLASSNAME}-macro-toggle`, {
                ['hidden']: hideToggle,
            })}
        >
            <Icon component={IconMacro} onClick={onClick} />
        </span>
    )
}

/**
 * Example start sequence: (?<!{){{(?:(?!{|}}).)*$
 * Example end sequence: ^(?:(?<!}|{{).)*}}(?!})
 */
const getInputContext = (input: HTMLInputElement, trigger: MacroInputTrigger): IInputContext => {
    const [tStart, tEnd] = trigger.split('.')
    const seq = {
        start: new RegExp(`(?<!${esc(tStart[0])})${esc(tStart)}(?:(?!${esc(tStart[0])}|${esc(tEnd)}).)*$`),
        end: new RegExp(`^(?:(?<!${esc(tEnd[0])}|${esc(tStart)}).)*${esc(tEnd)}(?!${esc(tEnd[0])})`),
    }

    const value = input.value
    const cursorPos = input.selectionStart!
    const [vStart, vEnd] = [value.slice(0, cursorPos), value.slice(cursorPos)]

    let tStartPos: number | null = null
    const startMatch = vStart.match(seq.start)
    if (startMatch) {
        tStartPos = startMatch.index!
    }

    let tEndPos: number | null = null
    const endMatch = vEnd.match(seq.end)
    if (endMatch) {
        tEndPos = vStart.length + endMatch[0].indexOf(tEnd)
    }

    const macroConfig =
        tStartPos === null
            ? null
            : {
                  startTrigger: tStart,
                  startPos: tStartPos,
                  endTrigger: tEnd,
                  endPos: tEndPos,
                  value: value
                      .slice(tStartPos, tEndPos ?? undefined)
                      .replace(tStart, '')
                      .replace(tEnd, ''),
              }

    return {
        input: {
            value,
            start: vStart,
            end: vEnd,
        },
        macro: macroConfig,
        cursor: {
            cursorPos,
            inTrigger:
                !!macroConfig &&
                tStartPos !== null &&
                cursorPos >= tStartPos &&
                (tEndPos === null || cursorPos <= tEndPos),
        },
        seq: {
            start: seq.start.compile(seq.start.source, seq.start.flags),
            end: seq.end.compile(seq.end.source, seq.end.flags),
        },
    }
}

interface IMacroManager extends Partial<PopoverProps> {
    types?: MacroType[]
    options?: PromiseableProp<MacroOptions>
    customOptions?: PromiseableProp<IMacroOption[]>
    inputTrigger?: MacroInputTrigger
    padInputTrigger?: boolean
    onSelect?: MacroManagerOnSelect
    hideToggle?: boolean
    children: MacroInputElement
    disabled?: boolean
    validOptionTypes?: MacroOptionType[]
}

interface IState {
    showMacroOptions?: boolean
    inputContext?: IInputContext

    loading: boolean
    options?: MacroOptions
    customOptions?: IMacroOption[]
}

export class MacroManager extends React.Component<IMacroManager, IState> {
    public state: IState = {
        showMacroOptions: false,
        loading: true,
        options: {} as any,
        customOptions: [],
    }

    public id = `mm-${generateShortID()}`
    public inputComponentRef: any
    protected unmounting: boolean = false

    private readonly appState: AppState

    public constructor(props: IMacroManager) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public UNSAFE_componentWillMount() {
        this.configureInputElement()
        this.resolveOptions()
    }

    public componentDidMount() {
        this.wireComponentEvents()
    }

    public componentDidUpdate(prev: IMacroManager) {
        if ((!prev.options && !!this.props.options) || (!prev.customOptions && !!this.props.customOptions)) {
            this.resolveOptions()
        }
    }

    public componentWillUnmount() {
        this.unmounting = true
        this.unwireComponentEvents()
    }

    public render() {
        const {
            types,
            options,
            customOptions,
            inputTrigger,
            onSelect,
            hideToggle,
            children,
            disabled,
            validOptionTypes,
            ...props
        } = this.props
        const InputComponent = React.Children.only(children)

        const visible = this.state.showMacroOptions ?? props.visible
        const title = props.title ?? 'Macros'
        const macroOptions = getMacroOptions({
            types,
            options: this.state.options,
            customOptions: this.state.customOptions,
            validOptionTypes,
        })

        const input = this.getInputElement()
        const inputProps = this.getInputProps()
        const inputContext = this.state.inputContext
        const inputRect = !input ? undefined : this.getInputElement().getBoundingClientRect()

        // calculate popover Y adjustment based on element height
        const offsetY =
            inputProps?.size === 'small'
                ? (inputRect?.height ?? 24) + 8
                : inputProps?.size === 'large'
                ? (inputRect?.height ?? 40) + 2
                : (inputRect?.height ?? 32) + 4

        return (
            <div
                key={this.id}
                id={this.id}
                className={getClassNames(BASE_CLASSNAME, this.id, {
                    ['overlay-visible']: visible,
                    ['hide-toggle']: hideToggle,
                    disabled,
                })}
            >
                <div className={getClassNames(`${BASE_CLASSNAME}-input-wrapper`)}>
                    <InputComponent.type
                        {...InputComponent.props}
                        ref={(el) => {
                            // call initial ref method
                            const { ref } = InputComponent as any
                            if (ref && typeof ref === 'function') {
                                ref(el)
                            }

                            // set internal ref
                            this.inputComponentRef = el
                        }}
                        className={getClassNames(`${BASE_CLASSNAME}-input`, InputComponent.props.className)}
                    />
                    <Popover
                        key={`${this.id}-macro-list`}
                        {...props}
                        className={getClassNames(`${BASE_CLASSNAME}-control`, props.className, this.id, {})}
                        visible={visible}
                        title={
                            <div className={getClassNames(`${BASE_CLASSNAME}-overlay-title`)}>
                                <span className={getClassNames(`${BASE_CLASSNAME}-title`)}>{title}</span>
                                <span className={getClassNames(`${BASE_CLASSNAME}-learn-more`)}>
                                    <Tooltip title="Learn More">
                                        <a
                                            href={`${this.appState.documentationLink}/platform/notifications/macros`}
                                            target="_blank"
                                        >
                                            <QuestionCircleOutlined className="info-icon" />
                                        </a>
                                    </Tooltip>
                                </span>
                            </div>
                        }
                        align={{
                            // adjust for icon location and height
                            points: ['tr', 'tr'],
                            offset: [8, offsetY],
                        }}
                        overlayClassName={getClassNames(
                            `${BASE_CLASSNAME}-overlay`,
                            props.overlayClassName,
                            this.id,
                            {},
                        )}
                        overlayStyle={
                            !inputRect
                                ? props.overlayStyle
                                : {
                                      ...props.overlayStyle,
                                      width: `${inputRect.width}px`,
                                  }
                        }
                        content={
                            <MacroOptionsList
                                loading={this.state.loading}
                                context={inputContext}
                                macros={macroOptions}
                                onItemSelect={this.handleSelect}
                            />
                        }
                        trigger="click"
                    >
                        <MacroOptionsListToggle
                            loading={this.state.loading}
                            onClick={this.handleMacroMenuToggle}
                            hideToggle={hideToggle}
                        />
                    </Popover>
                </div>
            </div>
        )
    }

    protected isInputComponentStateful(): boolean {
        return !!this.inputComponentRef?.setState
    }

    /**
     * Validates passed children to ensure single
     * element matching one of the following types:
     *   antd <Input />
     *   sw   <BetterInput />
     *   html <input />
     *
     */
    protected configureInputElement() {
        const child = React.Children.only(this.props.children)
        const isProperType = child.type === Input || child.type === BetterInput || child.type === 'input'
        if (!isProperType) {
            throw new Error('MacroManger child component must be type input or Input')
        }
    }

    protected getInputProps(): InputProps | undefined {
        return this.inputComponentRef?.props
    }

    /**
     * Returns the actual html input element
     * whether nested or direct
     *
     */
    protected getInputElement(): HTMLInputElement {
        const inputConstructor = this.inputComponentRef?.constructor
        return inputConstructor === Input || inputConstructor === BetterInput
            ? this.inputComponentRef.input
            : this.inputComponentRef
    }

    protected wireComponentEvents() {
        const el = this.getInputElement()
        if (el) {
            el.addEventListener('focus', this.handleInputInteraction)
            el.addEventListener('keyup', this.handleInputInteraction)
            el.addEventListener('click', this.handleInputInteraction)
        }

        document.body.addEventListener('click', this.handleDocumentClick)
    }

    protected unwireComponentEvents() {
        const el = this.getInputElement()
        if (el) {
            el.removeEventListener('focus', this.handleInputInteraction)
            el.removeEventListener('keyup', this.handleInputInteraction)
            el.removeEventListener('click', this.handleInputInteraction)
        }

        document.body.removeEventListener('click', this.handleDocumentClick)
    }

    /**
     * Determines whether click is blur or focus.
     * Uses component unique id to prevent collisions.
     *
     */
    protected handleDocumentClick = (ev: any) => {
        const toEl = ev.toElement
        if (toEl) {
            const update: any = {}

            // determine if clicked element is inside
            // the current macro manager scope
            // only true external clicks should close
            const masterEl = toEl.closest(`#${this.id}`)
            const overlayEl = toEl.closest(`.${this.id}`)
            const shouldClose = !masterEl && !overlayEl

            // computed blur evenst should clear
            // input state and hide macro options
            if (shouldClose) {
                update.showMacroOptions = false
                update.inputContext = undefined
            }

            this.setState(update)
        }
    }

    /**
     * Handles focus, keyup, and click events for the
     * inputComponent.
     *
     * Gathers current inputContext and updates state
     *
     */
    protected handleInputInteraction = (ev: any) => {
        ev.stopPropagation?.()
        ev.stopImmediatePropagation?.()

        let showMacroOptions = false
        let inputContext: IInputContext

        try {
            const evType = ev.type
            inputContext = getInputContext(ev.target, this.props.inputTrigger ?? DEFAULT_INPUT_TRIGGER)

            // a focus event while already open should ignore inTrigger state
            showMacroOptions = evType === 'focus' && this.state.showMacroOptions ? true : inputContext.cursor.inTrigger
        } catch (err) {
            console.warn('Error computing input state', err)
        }

        this.setState(() => ({
            showMacroOptions,
            inputContext,
        }))
    }

    /**
     * Handles interaction with the
     * <MacroOptionsListToggle /> element
     *
     * If toggling off the active macro state is cleared
     *
     */
    protected handleMacroMenuToggle = (ev: any) => {
        ev.stopPropagation?.()
        ev.stopImmediatePropagation?.()

        this.setState(
            ({ showMacroOptions, inputContext }) => ({
                showMacroOptions: !showMacroOptions,
                inputContext: {
                    ...inputContext,
                    macro: showMacroOptions ? null : inputContext?.macro,
                } as any,
            }),
            () => {
                // if showMacroOptions is true refocus
                // the active input element to allow
                // continued typing
                if (this.state.showMacroOptions) {
                    this.getInputElement().focus()
                }
            },
        )
    }

    /**
     * Handles interaction with the
     * <MacroOption /> element
     *
     * If toggling off the active macro state is cleared
     *
     */
    protected handleSelect = async (macro: IMacroOption) => {
        const el = this.getInputElement()
        const [tStart, tEnd] = (this.props.trigger?.toString() ?? DEFAULT_INPUT_TRIGGER).split('.')
        const padInputTrigger = this.props.padInputTrigger ?? false

        // calculate current positions based on
        // input context and activeMacro
        const inputContext = this.state.inputContext
        const startPos = !!inputContext?.macro ? inputContext.macro.startPos : el.selectionStart ?? 0
        const endPos = !!inputContext?.macro
            ? inputContext.macro.endPos ?? inputContext.macro.startPos
            : el.selectionStart ?? 0

        // using calculated positions the wrapped
        // macro value is injected into the current
        // value prop
        const trimStartSeq = new RegExp(`${esc(tStart)}$`)
        const trimEndSeq = new RegExp(`^${esc(tEnd)}`)

        const newValueStart =
            el.value.slice(0, startPos).replace(trimStartSeq, '') +
            `${tStart}${padInputTrigger ? ' ' : ''}${macro.value}${padInputTrigger ? ' ' : ''}${tEnd}`
        const newValueEnd = el.value
            .slice(endPos)
            .replace(trimEndSeq, '')
            // workaround for trailing open tokens
            .replace(trimStartSeq, '')

        const newValue = newValueStart + newValueEnd

        // set value on component using
        // simulated native event
        fireNativeInputChangeEvent(el, newValue)

        // reset cursor position
        el.selectionStart = newValueStart.length
        el.selectionEnd = newValueStart.length
        el.focus()
        await this.setState({ showMacroOptions: false })

        // fire any listening method
        this.props.onSelect?.(macro)
    }

    /**
     * Ensures options are set from static
     * or async sources and adjusts loading
     * state accordingly
     *
     */
    protected async resolveOptions() {
        const { options, customOptions } = this.props

        const resolvedOptions = !options ? undefined : await resolvePromiseableProp(options)
        const resolvedCustomOptions = !customOptions ? [] : await resolvePromiseableProp(customOptions)

        if (!this.unmounting) {
            this.setState({
                loading: false,
                options: resolvedOptions,
                customOptions: resolvedCustomOptions,
            })
        }
    }
}
