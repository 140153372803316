import * as React from 'react'
import './style/user-data-form.scss'
import { Container } from 'typescript-ioc/es5'
import { UserDetailsWell } from './user-details-well'
import { UserAccessWell } from './user-access-well'
import { UserDto } from '../../dtos/user'
import { AppService, UserService } from '../../services'
import { getClassNames } from '../../_utils/classnames'
import { UserCredentialsWell } from './user-credentials-well'
import { AppState } from '../../stores/app'
import { ErrorPage } from '../error-page/error-page'

interface IUserDataForm {
    userId: number
    accountId?: number
    domainId?: number
}

interface IUDFState {
    loading: boolean
    user?: UserDto
    accessDenied?: boolean
}

export class UserDataForm extends React.Component<IUserDataForm, IUDFState> {
    protected appState: AppState
    protected appService: AppService
    protected userService: UserService

    public constructor(props: IUserDataForm) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.userService = Container.get(UserService)

        if (props.userId) {
            this.fetchRequestedUser()
        }

        this.state = {
            loading: true,
        }
    }

    public render() {
        const isEditingSelf = this.state.user && this.state.user.id === this.appState.currentUser?.id
        const canAccessCredentialWell = isEditingSelf && (this.state.user?.isInternalUser || this.state.user?.isDbUser)
        const canShowAccessWell = this.state.user && this.props.accountId && !isEditingSelf

        return (
            <div className={getClassNames('user-data-form')}>
                <UserDetailsWell
                    loading={this.state.loading}
                    user={this.state.user}
                    onUpdated={this.handleUserUpdated}
                    accountId={this.props.accountId}
                    domainId={this.props.domainId}
                    emptyUserState={<ErrorPage errorCode={403} />}
                />

                {canAccessCredentialWell && (
                    <UserCredentialsWell
                        loading={this.state.loading}
                        user={this.state.user}
                        onUpdated={this.handleUserUpdated}
                        emptyUserState={<ErrorPage errorCode={403} />}
                    />
                )}

                {canShowAccessWell && (
                    <UserAccessWell
                        mode="read-only"
                        loading={this.state.loading}
                        user={this.state.user}
                        accountId={this.props.accountId}
                        domainId={this.props.domainId}
                        onUpdated={this.handleUserUpdated}
                    />
                )}
            </div>
        )
    }

    protected handleUserUpdated = async (updates: Partial<UserDto>) => {
        this.setState(({ user }) => {
            return {
                user: {
                    ...user,
                    ...updates,
                } as UserDto,
            }
        })
    }

    protected async fetchRequestedUser() {
        if (!this.props.userId) {
            setTimeout(() => this.fetchRequestedUser(), 50)
            return
        }

        const update: any = { loading: false }
        const options: any = { showLoadingScreen: false }
        const userRes = await this.userService.fetchById(this.props.userId, options)

        if (userRes.ok) {
            update.user = userRes.data
            update.accessDenied = false
        } else {
            update.accessDenied = true
        }

        this.setState(update)
    }
}
