import * as React from 'react'
import { BetterComponent } from '../../better-component/better-component'
import { Well } from '../../well/well'
import { AppState } from '../../../stores/app'
import { AppService, DomainService } from '../../../services'
import { Container } from 'typescript-ioc/es5'
import { NdfFormMode } from '../enums/ndf-form-mode.enum'
import { NdfFormTheme } from '../enums/ndf-form-theme.enum'
import * as clone from 'clone'
import { InfoCircleOutlined, RetweetOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Col, DatePicker, InputNumber, Popover, Radio, Row, Select, Skeleton, Switch, Tooltip } from 'antd'
import autobind from 'autobind-decorator'
import { RadioChangeEvent } from 'antd/lib/radio'
import { INdfSectionValidationResponse } from '../interfaces/ndf-section-validation-response'
import { INdfSchedulingValue } from '../interfaces/ndf-scheduling-value'
import { Moment } from 'moment'
import * as moment from 'moment-timezone'
import { INdfTestValue } from '../interfaces/ndf-test-value'
import { NdfTestScheduleType } from '../enums/ndf-test-schedule-type.enum'
import { NdfTestType } from '../enums/ndf-test-type.enum'
import { BASE_TIME_FORMAT_WITHOUT_TZ } from '../../../constants'
import { INotificationTreatment } from '../../../interfaces/notification-treatment'
import { SchedulingTimepicker } from './scheduling-timepicker'
import { type INdfScheduleTimepickerValue } from '../interfaces/ndf-schedule-timepicker-value'
import { getSupportedTimezones } from '../../../_utils/moment'
import { NotificationDeliveryType } from '../../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../../enums/notification-delivery-window'

interface INdfSchedulingSectionProps {
    className?: string
    mode: NdfFormMode
    theme: NdfFormTheme
    value: INdfSchedulingValue
    onChange: (value: INdfSchedulingValue, testValue?: INdfTestValue, treatments?: INotificationTreatment[]) => any
    test?: INdfTestValue
    treatments?: INotificationTreatment[]

    loading?: boolean
    deliveryInProgress?: boolean

    formActions?: {
        onSubmit?: () => any
        onCancel?: () => any
        submitDisabled?: boolean
    }
}

interface INdfSchedulingSectionState {
    exclusionsEnabled?: boolean
}

export class SchedulingSection extends BetterComponent<INdfSchedulingSectionProps, INdfSchedulingSectionState> {
    public readonly defaultClassName: string = 'sw-v2-ndf-section'

    protected appState: AppState
    protected appService: AppService
    protected domainService: DomainService

    public constructor(props: INdfSchedulingSectionProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)

        this.state = {}
    }

    public render(): React.ReactNode {
        const wellProps: any = {
            showFooter: false,
        }

        if (!!this.props.formActions) {
            wellProps.showFooter = true
            wellProps.onSubmit = this.props.formActions.onSubmit
            wellProps.onCancel = this.props.formActions.onCancel
            wellProps.submitDisabled = this.props.formActions.submitDisabled
        }

        return (
            <div className={this.buildRootClassNames('scheduling')}>
                <div className={this.buildClassName('wrapper')}>
                    <Well title="Scheduling" {...wellProps}>
                        <Skeleton
                            active={true}
                            title={false}
                            avatar={false}
                            paragraph={{ rows: 3 }}
                            loading={this.isLoading}
                        >
                            <Well showHeader={false} showFooter={false} ghost={true}>
                                <Form.Item label="When do you want to send?">
                                    <Radio.Group
                                        buttonStyle="solid"
                                        size="small"
                                        onChange={this.handleTypeChange}
                                        disabled={this.deliveryInProgress}
                                        value={this.currentTypeValue}
                                    >
                                        <Radio.Button value={NotificationDeliveryType.IMMEDIATE}>Now</Radio.Button>
                                        <Radio.Button value={NotificationDeliveryType.SCHEDULED}>Later</Radio.Button>
                                    </Radio.Group>
                                    {this.currentTypeValue === NotificationDeliveryType.SCHEDULED && (
                                        <React.Fragment>
                                            <Form.Item>
                                                <div className="switch-row">
                                                    <div className="switch-left">
                                                        <Switch
                                                            checked={
                                                                this.currentWindowValue ===
                                                                NotificationDeliveryWindow.TIMEZONE
                                                            }
                                                            onChange={this.handleWindowChange}
                                                            disabled={
                                                                this.deliveryInProgress ||
                                                                this.testTypeIsSample ||
                                                                this.testScheduleTypeIsSpecific
                                                            }
                                                        />
                                                    </div>
                                                    <div className="switch-right">
                                                        <span className="timezone-window-label">
                                                            Time Zone Delivery
                                                            <Popover
                                                                getPopupContainer={this.appService.getAppContainer}
                                                                overlayClassName="lifespan-popover"
                                                                content={
                                                                    <div>
                                                                        <p>
                                                                            Notifications will be delivered at the
                                                                            selected time in each subscriber's time
                                                                            zone. If the selected time has already
                                                                            passed in the subscriber's time zone we will
                                                                            not attempt to deliver the notification.
                                                                        </p>
                                                                    </div>
                                                                }
                                                            >
                                                                <span>
                                                                    <InfoCircleOutlined className="info-icon" />
                                                                </span>
                                                            </Popover>
                                                        </span>
                                                    </div>
                                                </div>
                                            </Form.Item>

                                            {this.props.test && (
                                                <Form.Item>
                                                    <div className="switch-row">
                                                        <div className="switch-left">
                                                            <Switch
                                                                checked={
                                                                    this.props.test
                                                                        ? this.props.test.isTimeVariant
                                                                        : false
                                                                }
                                                                onChange={this.handleTimeVariantAbTestChange}
                                                                disabled={
                                                                    this.deliveryInProgress ||
                                                                    this.testTypeIsSample ||
                                                                    this.testScheduleTypeIsSpecific
                                                                }
                                                            />
                                                        </div>
                                                        <div className="switch-right">
                                                            <span className="timezone-window-label">
                                                                Choose a different delivery time for each variant.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Form.Item>
                                            )}

                                            {!this.isTimeVariant ? (
                                                <SchedulingTimepicker
                                                    loading={this.props.loading!}
                                                    deliveryInProgress={this.deliveryInProgress}
                                                    deliveryWindow={this.currentWindowValue}
                                                    timezone={this.currentTimezoneValue}
                                                    sendDate={this.currentSendDateValue}
                                                    onChange={this.handleSchedulingTimepickerChange}
                                                />
                                            ) : (
                                                <React.Fragment>
                                                    {this.props.treatments!.map((treatment, idx) => {
                                                        return (
                                                            <SchedulingTimepicker
                                                                key={idx}
                                                                label={this.getTreatmentName(idx)}
                                                                labelClass={this.getTreatmentClass(idx)}
                                                                loading={this.props.loading!}
                                                                deliveryInProgress={this.deliveryInProgress}
                                                                deliveryWindow={this.currentWindowValue}
                                                                timezone={treatment.schedule!.timezone!}
                                                                sendDate={treatment.schedule!.sendDate as any}
                                                                onChange={this.handleTreatmentSchedulingTimepickerChange(
                                                                    idx,
                                                                )}
                                                            />
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </Form.Item>
                            </Well>

                            {this.currentTestTypeValue === NdfTestType.SAMPLE && (
                                <Well title="Sample Winner" showFooter={false} ghost={true}>
                                    <Form.Item label="When do you want to send the winner?">
                                        <Radio.Group
                                            size="small"
                                            disabled={this.deliveryInProgress}
                                            onChange={this.handleScheduleTypeChange}
                                            value={this.currentTestScheduleTypeValue as any}
                                        >
                                            <Radio.Button value={NdfTestScheduleType.ELAPSED}>
                                                Time Elapsed After Send
                                            </Radio.Button>
                                            <Radio.Button value={NdfTestScheduleType.SPECIFIC}>
                                                Specific Time
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>

                                    {this.currentTestScheduleTypeValue === NdfTestScheduleType.ELAPSED ? (
                                        <Form.Item
                                            className="elapsed-value"
                                            label={
                                                <>
                                                    <span>Hours</span>
                                                    <Tooltip title="The variation with the highest CTR will be sent to the rest of the audience once the chosen number of hours has elapsed.">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </>
                                            }
                                        >
                                            <InputNumber
                                                disabled={this.deliveryInProgress}
                                                defaultValue={1}
                                                value={this.currentTestScheduleValue}
                                                onChange={this.handleScheduleElapsedValueChange}
                                                max={100}
                                                min={1}
                                                type="number"
                                            />
                                        </Form.Item>
                                    ) : (
                                        <Form.Item
                                            className="specific-value"
                                            label={
                                                <>
                                                    <span>Send Date</span>
                                                    <Tooltip title="The variation with the highest CTR will be sent to the rest of the audience at the chosen date and time.">
                                                        <InfoCircleOutlined className="info-icon" />
                                                    </Tooltip>
                                                </>
                                            }
                                        >
                                            <Row gutter={8} className="schedule-details">
                                                <Col span={14}>
                                                    <DatePicker
                                                        showTime={{
                                                            format: 'HH:mm',
                                                            use12Hours: true,
                                                        }}
                                                        format="ddd, MMM DD, YYYY \a\t h:mm a"
                                                        placeholder="Select Time"
                                                        disabled={this.deliveryInProgress}
                                                        value={this.currentTestScheduleValue}
                                                        onChange={this.handleScheduleSpecificValueChange}
                                                        disabledDate={(current) =>
                                                            !!current &&
                                                            current <=
                                                                (this.currentSendDateValue
                                                                    ? clone(this.currentSendDateValue).startOf('day')
                                                                    : moment().startOf('day'))
                                                        }
                                                    />
                                                </Col>
                                                <Col span={9}>
                                                    <Select
                                                        showSearch={true}
                                                        placeholder="Select a time zone"
                                                        disabled={this.deliveryInProgress}
                                                        value={this.currentTestScheduleTimezoneValue}
                                                        onChange={this.handleTestScheduleTimeZoneChange}
                                                    >
                                                        {this.availableTimezones.map((tz) => (
                                                            <Select.Option key={tz} value={tz}>
                                                                {tz}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col span={1}>
                                                    {this.currentTypeValue !== NotificationDeliveryType.IMMEDIATE &&
                                                        this.currentWindowValue !==
                                                            NotificationDeliveryWindow.TIMEZONE && (
                                                            <span
                                                                className="winner-tz-sync"
                                                                onClick={this.handleTimeZoneSync}
                                                            >
                                                                <Tooltip title="Synchronize with above time zone">
                                                                    <RetweetOutlined />
                                                                </Tooltip>
                                                            </span>
                                                        )}
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    )}
                                </Well>
                            )}
                        </Skeleton>
                    </Well>
                </div>
            </div>
        )
    }

    public validate(): INdfSectionValidationResponse {
        const response: INdfSectionValidationResponse = { ok: true }

        return response
    }

    protected get isLoading(): boolean {
        return this.props.loading === true
    }

    protected get currentMode(): NdfFormMode {
        return this.props.mode
    }

    protected get currentTheme(): NdfFormTheme {
        return this.props.theme
    }

    protected get currentValue(): INdfSchedulingValue {
        return clone({
            ...this.props.value,
        })
    }

    protected get currentTypeValue(): NotificationDeliveryType {
        const defaultValue = NotificationDeliveryType.IMMEDIATE
        return !this.props.value ? defaultValue : this.props.value.type || defaultValue
    }

    protected get currentWindowValue(): NotificationDeliveryWindow {
        const defaultValue = NotificationDeliveryWindow.STANDARD
        return !this.props.value ? defaultValue : this.props.value.window || defaultValue
    }

    protected get currentSendDateValue(): Moment {
        const defaultValue = undefined
        return !this.props.value
            ? defaultValue
            : moment.tz(this.props.value.sendDate, this.currentTimezoneValue) || defaultValue
    }

    protected get currentTimezoneValue(): string {
        const defaultValue = this.suggestedTimezone
        return !this.props.value ? defaultValue : this.props.value.timezone || defaultValue
    }

    protected get availableTimezones(): string[] {
        return getSupportedTimezones()
    }

    protected get suggestedTimezone(): string {
        return this.appState.currentDomain ? this.appState.currentDomain.timezone : moment.tz.guess()
    }

    protected get deliveryInProgress(): boolean {
        return this.props.deliveryInProgress || false
    }

    protected get currentTestValue(): INdfTestValue {
        return clone({
            ...this.props.test,
        })
    }

    protected get currentTestTypeValue(): NdfTestType {
        return this.currentTestValue.type || NdfTestType.SPLIT
    }

    protected get testTypeIsSample(): boolean {
        return this.currentTestTypeValue === NdfTestType.SAMPLE
    }

    protected get testScheduleTypeIsSpecific(): boolean {
        return (
            !!this.props.test &&
            !!this.props.test.schedule &&
            this.props.test.schedule.type === NdfTestScheduleType.SPECIFIC
        )
    }

    protected get currentTestScheduleTypeValue(): NdfTestScheduleType {
        return (this.currentTestValue.schedule || {}).type || NdfTestScheduleType.ELAPSED
    }

    protected get currentTestScheduleTimezoneValue(): string {
        const defaultValue = this.currentTimezoneValue
        return !this.currentTestValue.schedule ? defaultValue : this.currentTestValue.schedule.$timezone || defaultValue
    }

    protected get currentTestScheduleValue(): any {
        let value = (this.currentTestValue.schedule || {}).value

        if (!!value && this.currentTestScheduleTypeValue === NdfTestScheduleType.SPECIFIC) {
            value = moment(value, BASE_TIME_FORMAT_WITHOUT_TZ)
        }

        return value
    }

    protected get isTimeVariant(): boolean {
        return !!this.props.test?.isTimeVariant
    }

    protected get defaultElapsedSchedule(): any {
        return {
            type: NdfTestScheduleType.ELAPSED,
            value: 1,
        }
    }

    protected get defaultSpecificSchedule(): any {
        return {
            type: NdfTestScheduleType.SPECIFIC,
            value: !!this.currentSendDateValue
                ? clone(this.currentSendDateValue).add(1, 'd').format(BASE_TIME_FORMAT_WITHOUT_TZ)
                : moment().add(1, 'd').format(BASE_TIME_FORMAT_WITHOUT_TZ),
        }
    }

    protected getDefaultSchedule(type: NdfTestScheduleType): any {
        return type === NdfTestScheduleType.ELAPSED ? this.defaultElapsedSchedule : this.defaultSpecificSchedule
    }

    protected getTreatmentClass(idx: number) {
        const letters = ['a', 'b', 'c', 'd', 'e']
        return `variant-${letters[idx]}`
    }

    protected getTreatmentName(idx: number) {
        const letters = ['A', 'B', 'C', 'D', 'E']
        return `Variant ${letters[idx]}`
    }

    @autobind
    protected handleTypeChange(ev: RadioChangeEvent): void {
        const change: Partial<INdfSchedulingValue> = {
            type: ev.target.value,
            sendDate: moment.tz(this.suggestedTimezone).toISOString(),
            timezone: this.suggestedTimezone,
        }

        if (change.type === NotificationDeliveryType.IMMEDIATE) {
            change.window = NotificationDeliveryWindow.STANDARD
            change.sendDate = undefined
            change.timezone = undefined
        }

        if (this.props.test) {
            const treatmentsClone = clone(this.props.treatments!)
            for (const treatment of treatmentsClone) {
                treatment.schedule = undefined
            }

            this.props.onChange(
                change,
                {
                    ...this.currentTestValue,
                    isTimeVariant: false,
                },
                treatmentsClone,
            )
        } else {
            this.emitChange(change)
        }
    }

    @autobind
    protected handleWindowChange(stzEnabled: boolean): void {
        const window = stzEnabled ? NotificationDeliveryWindow.TIMEZONE : NotificationDeliveryWindow.STANDARD
        const change: Partial<INdfSchedulingValue> = {
            window,
            timezone: this.currentTypeValue === NotificationDeliveryType.IMMEDIATE ? undefined : this.suggestedTimezone,
        }

        if (change.window === NotificationDeliveryWindow.TIMEZONE) {
            change.timezone = undefined
        }

        const treatmentsClone = clone(this.props.treatments!)
        for (const treatment of treatmentsClone) {
            if (this.isTimeVariant) {
                treatment.schedule = {
                    type: this.currentTypeValue,
                    window,
                    sendDate: treatment.schedule!.sendDate || (this.currentSendDateValue as any),
                    timezone: change.timezone,
                }
            } else {
                treatment.schedule = undefined
            }
        }

        this.props.onChange(
            {
                ...this.currentValue,
                ...change,
            },
            undefined,
            treatmentsClone,
        )
    }

    @autobind
    protected handleTreatmentSchedulingTimepickerChange(idx: number) {
        return (value: INdfScheduleTimepickerValue) => {
            const treatmentsClone = clone(this.props.treatments!)
            const treatment = treatmentsClone[idx]

            if ('timezone' in value) {
                const newSendDate: string = moment
                    .tz(treatment.schedule!.sendDate, treatment.schedule!.timezone)
                    .tz(value.timezone, true)
                    .toISOString()

                Object.assign(treatment.schedule!, {
                    sendDate: newSendDate,
                    timezone: value.timezone,
                })
            } else if ('sendDate' in value) {
                Object.assign(treatment.schedule!, {
                    sendDate: value.sendDate,
                })
            }

            // If time variant is used, set the shell notification's delivery schedule to the oldest send date
            const treatmentSchedules = treatmentsClone.map((t) => t.schedule!)

            treatmentSchedules.sort((a, b) => {
                const aMoment = moment(a.sendDate!)
                const bMoment = moment(b.sendDate!)

                if (aMoment.isAfter(bMoment)) {
                    return 1
                } else if (aMoment.isBefore(bMoment)) {
                    return -1
                }

                return 0
            })

            this.emitTreatmentsChange(treatmentSchedules[0], treatmentsClone)
        }
    }

    @autobind
    protected handleSchedulingTimepickerChange(value: INdfScheduleTimepickerValue): void {
        if ('timezone' in value) {
            const newSendDate = moment
                .tz(value.sendDate || this.currentSendDateValue, this.currentTimezoneValue)
                .tz(value.timezone, true)
                .toISOString()

            this.emitChange({
                sendDate: newSendDate,
                timezone: value.timezone,
            })
        } else if ('sendDate' in value) {
            this.emitChange({
                sendDate: value.sendDate as any,
            })
        }
    }

    @autobind
    protected handleSendDateChange(sendDate: Moment): void {
        const sendDateTz = !!sendDate ? sendDate.toISOString() : undefined

        this.emitChange({
            sendDate: sendDateTz as any,
        })
    }

    @autobind
    protected handleTimeZoneChange(timezone: string): void {
        const change: Partial<INdfSchedulingValue> = {
            timezone,
        }

        const currentSendDate = this.currentSendDateValue
        const currentTimezone = this.currentTimezoneValue
        if (!!currentSendDate) {
            change.sendDate = moment.tz(currentSendDate, currentTimezone).tz(timezone, true).toISOString()
        }

        this.emitChange(change)
    }

    @autobind
    protected handleTimeVariantAbTestChange(isTimeVariant: boolean): void {
        const change: Partial<INdfTestValue> = {
            isTimeVariant,
        }

        const treatmentsClone = clone(this.props.treatments!)
        for (const treatment of treatmentsClone) {
            if (isTimeVariant) {
                treatment.schedule = {
                    window: this.currentWindowValue,
                    sendDate: this.currentSendDateValue as any,
                    timezone: this.currentTimezoneValue,
                }
            } else {
                treatment.schedule = undefined
            }
        }

        this.props.onChange(
            this.currentValue,
            {
                ...this.currentTestValue,
                ...change,
            },
            treatmentsClone,
        )
    }

    @autobind
    protected handleTimeZoneSync(): void {
        const changes: Partial<INdfTestValue> = {
            schedule: {
                ...this.currentTestValue.schedule,
                $timezone: this.currentTimezoneValue,
            },
        }

        this.emitTestChange(changes)
    }

    @autobind
    protected handleScheduleTypeChange(ev: RadioChangeEvent): void {
        const changes: Partial<INdfTestValue> = {
            schedule: {
                ...this.currentTestValue.schedule,
                ...this.getDefaultSchedule(ev.target.value),
            },
        }

        this.emitTestChange(changes)
    }

    @autobind
    protected handleScheduleElapsedValueChange(elapsedValue: number): void {
        const value = elapsedValue

        const changes: Partial<INdfTestValue> = {
            schedule: {
                ...this.currentTestValue.schedule,
                value,
            },
        }

        this.emitTestChange(changes)
    }

    @autobind
    protected handleScheduleSpecificValueChange(dateValue: Moment | null): void {
        const value = !dateValue ? undefined : dateValue.format(BASE_TIME_FORMAT_WITHOUT_TZ)

        const changes: Partial<INdfTestValue> = {
            schedule: {
                ...this.currentTestValue.schedule,
                value,
            },
        }

        this.emitTestChange(changes)
    }

    @autobind
    protected handleTestScheduleTimeZoneChange(timezone: string): void {
        const changes: Partial<INdfTestValue> = {
            schedule: {
                ...this.currentTestValue.schedule,
                $timezone: timezone,
            },
        }

        this.emitTestChange(changes)
    }

    protected emitChange(changes: Partial<INdfSchedulingValue>): void {
        this.props.onChange({
            ...this.currentValue,
            ...changes,
        })
    }

    protected emitTestChange(changes: Partial<INdfTestValue>): void {
        this.props.onChange(this.currentValue, {
            ...this.currentTestValue,
            ...changes,
        })
    }

    protected emitTreatmentsChange(changes: Partial<INdfSchedulingValue>, treatments: INotificationTreatment[]): void {
        this.props.onChange(
            {
                ...this.currentValue,
                ...changes,
            },
            undefined,
            treatments,
        )
    }

    protected buildClassName(className: string, extras?: string): string {
        className = `${this.defaultClassName}-${className}`
        if (!!extras) className = `${className} ${extras}`
        return className
    }

    protected buildRootClassNames(sectionName: string): string {
        const classNames: string[] = [
            this.defaultClassName,
            `section-${sectionName}`,
            `theme-${this.currentTheme.toLowerCase()}`,
            `mode-${this.currentMode.toLowerCase()}`,
        ]

        if (!!this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
