import * as React from 'react'
import { AndroidOutlined, AppleOutlined, QuestionCircleOutlined, WindowsOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Radio, Skeleton, Tooltip } from 'antd'
import clone from 'clone-deep'
import { Button } from '@pushly/aqe/lib/components'
import PreviewSDK, { Browser, Platform } from '@pushly/cuttlefish'
import { Container } from 'typescript-ioc/es5'
import { NdfSubmitRequestType } from '../interfaces/ndf-submit-request-type'
import { INotificationTreatment } from '../../../interfaces/notification-treatment'
import { cancelNamedAxiosRequest } from '../../../config/axios-setup'
import { DomainDto } from '../../../dtos/domain'
import { DomainService } from '../../../services/index'
import { FormContext } from '../context'
import { getPlatformNotifications } from '../../../_utils/platform'
import { getPushlySdk } from '../../../_utils/pushly-sdk'
import { getClassNames } from '../../../_utils/classnames'
import { Well } from '../../well/well'
import { getDefaultNotificationActions } from '../../../_utils/domain'
import { isMacroMatch } from '../../../_utils/macros'
import { AppState } from '../../../stores/app'

const REG_CHECK_REQ_KEY = 'ndf.checkPreviewRegistrationCount'
const REG_CHECK_MAX_REQUESTS = 5
let REG_CHECK_TIMEOUT: any

const cancelPreviewRegistrationCountCheck = () => {
    cancelNamedAxiosRequest(REG_CHECK_REQ_KEY)
    clearTimeout(REG_CHECK_TIMEOUT)
}

const checkPreviewRegistrationCount = async (domain: DomainDto, reducer: Function, iter: number = 0): Promise<void> => {
    clearTimeout(REG_CHECK_TIMEOUT)

    if (iter < REG_CHECK_MAX_REQUESTS) {
        REG_CHECK_TIMEOUT = setTimeout(
            async () => {
                const service: DomainService = Container.get(DomainService)
                const res = await service.getPreviewRegistrationCount(domain.id, {
                    cancellationKey: REG_CHECK_REQ_KEY,
                })

                if (!res.cancelled) {
                    if (res.data > 0) {
                        reducer(res.data)
                    } else {
                        checkPreviewRegistrationCount(domain, reducer, iter + 1).then()
                    }
                }
            },
            !iter ? 320 : 10000,
        )
    }
}

const buildPreviewConfig = (
    domain: DomainDto,
    treatment: INotificationTreatment,
    platform: Platform,
    chromeless: boolean = false,
) => {
    const defaultTitle = 'Sample Notification Title'
    const defaultBody = 'Sample Notification Body'
    const defaultBadge = '/assets/logos/pushly_icon_2019.png'
    const actions = treatment.actions ?? getDefaultNotificationActions(domain)

    // intercept ecomm macro image
    let imageUrl = treatment.image
    if (!!imageUrl && isMacroMatch(imageUrl, 'item.image')) {
        imageUrl = '/assets/ecomm-item-image-placeholder.png'
    }

    const previewConfig: any = {
        animated: false,
        browser: Browser.CHROME,
        platform,
        chromeless,
        notification: {
            title: treatment.title || defaultTitle,
            body: treatment.body || defaultBody,
            icon: treatment.icon,
            image: imageUrl,
            badge: treatment.badge ?? defaultBadge,
            actions: actions.length === 0 ? undefined : actions.map((a) => ({ title: a.label })),
        },
    }

    if (!!domain) {
        previewConfig.notification.domain = domain.name
    }

    return previewConfig
}

type PreviewSubmitType = NdfSubmitRequestType.TEAM_PREVIEW | NdfSubmitRequestType.PERSONAL_PREVIEW

interface IPreviewBuilder {
    className?: string
    treatment?: INotificationTreatment
    onPlatformChange: (platform: Platform) => void
    onSendRequest?: (requestType: PreviewSubmitType) => any

    loading?: boolean
}

interface IState {
    regCount: number
}

export class PreviewDisplay extends React.PureComponent<IPreviewBuilder> {
    public static contextType = FormContext
    public context!: React.ContextType<typeof FormContext>

    public state: IState = {
        regCount: 0,
    }

    private readonly appState: AppState

    public constructor(props: IPreviewBuilder) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public componentDidMount(): void {
        checkPreviewRegistrationCount(this.context.domain, (regCount) => this.setState({ regCount })).then()
    }

    public componentWillUnmount(): void {
        cancelPreviewRegistrationCountCheck()
    }

    public render() {
        const props = this.props
        const context = this.context
        const { regCount } = this.state

        const treatment = clone(props.treatment ?? {}) as INotificationTreatment
        let userSubStatus = 'none'

        const platformNotifications = getPlatformNotifications()
        const sdk = getPushlySdk()
        if (sdk) {
            userSubStatus = sdk.context?.user?.subscriberState
        }

        const SubStatusWhitelist = ['granted', 'none', 'subscribed', 'dismissed']
        const dismissPreviewAction = userSubStatus === 'dismissed'
        const disablePreviewAction = SubStatusWhitelist.indexOf(userSubStatus) === -1
        const previewConfig = buildPreviewConfig(context.domain, treatment, context.inlinePreviewPlatform!)

        return (
            <div
                className={getClassNames('ndf-section', props.className, {
                    ['section-preview']: true,
                    [`theme-${context.theme.toLowerCase()}`]: true,
                    [`mode-${context.mode.toLowerCase()}`]: true,
                })}
            >
                <div className={getClassNames('ndf-section-wrapper')}>
                    <Well showHeader={false} showFooter={false}>
                        <Skeleton
                            active={true}
                            title={false}
                            avatar={false}
                            paragraph={{ rows: 2 }}
                            loading={props.loading}
                        >
                            <Form.Item
                                label={
                                    <Tooltip
                                        overlayClassName="wide-tooltip"
                                        title={
                                            <React.Fragment>
                                                <p>
                                                    The Inline Preview is a best-guess at how this notification will
                                                    look across the most common devices for the selected operating
                                                    system.
                                                </p>

                                                <p>
                                                    Due to variability between devices this preview may not accurately
                                                    depict title & body length limitations or image crop & placement. A
                                                    more accurate preview may be obtained via a notification preview on
                                                    your browser or mobile device by using the Device Preview.
                                                </p>
                                            </React.Fragment>
                                        }
                                    >
                                        <span className="well-title">Inline Preview</span>
                                        <QuestionCircleOutlined className="info-icon" />
                                    </Tooltip>
                                }
                            >
                                <Radio.Group
                                    size="small"
                                    value={context.inlinePreviewPlatform ?? Platform.WINDOWS}
                                    onChange={(ev) => props.onPlatformChange?.(ev.target.value)}
                                >
                                    <Radio.Button value="android">
                                        <AndroidOutlined />
                                        Android
                                    </Radio.Button>
                                    <Radio.Button value="windows">
                                        <WindowsOutlined />
                                        Windows
                                    </Radio.Button>
                                    <Radio.Button value="mac_os">
                                        <AppleOutlined />
                                        Mac OS
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            <div className="preview-wrapper">
                                <PreviewSDK.Preview config={previewConfig} />
                            </div>
                            <hr className="preview-divider" />

                            <Form.Item
                                className="layout-h device-preview"
                                label={
                                    <span>
                                        <Tooltip
                                            overlayClassName="wide-tooltip"
                                            title={
                                                <>
                                                    <p>
                                                        A device preview will immediately deliver the selected
                                                        notification to all registered devices for the chosen preview
                                                        destination.
                                                    </p>
                                                    <p>
                                                        You may choose to deliver a preview of the currently selected
                                                        notification to your registered personal devices (Personal
                                                        Preview) or to all internal {context.domain?.displayName}
                                                        users that have registered devices for preview notifications
                                                        (Team Preview).
                                                    </p>
                                                </>
                                            }
                                        >
                                            <span className="well-title">Device Preview</span>
                                            <QuestionCircleOutlined className="info-icon" />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                {platformNotifications.supported && (
                                    <div style={{ textAlign: 'right' }}>
                                        <Button
                                            size="small"
                                            onClick={() => props.onSendRequest?.(NdfSubmitRequestType.PERSONAL_PREVIEW)}
                                            disabled={disablePreviewAction}
                                            actions={[
                                                {
                                                    text: 'Send Team Preview',
                                                    onClick: () =>
                                                        props.onSendRequest?.(NdfSubmitRequestType.TEAM_PREVIEW),
                                                    disabled: disablePreviewAction || regCount === 0,
                                                    disabledMessage: `At least one team member must be
                                                    registered to send a team preview.`,
                                                },
                                            ]}
                                        >
                                            <span>Send Personal Preview</span>
                                        </Button>
                                    </div>
                                )}
                            </Form.Item>
                            {platformNotifications.supported ? (
                                platformNotifications.configured ? (
                                    !dismissPreviewAction ? (
                                        !disablePreviewAction ? (
                                            <p>
                                                To receive previews on multiple devices you may
                                                <br />
                                                <a
                                                    className="register-preview-device-link"
                                                    href={`${this.appState.documentationLink}/platform/notifications/notification-previews#registering-additional-preview-devices`}
                                                    target="_blank"
                                                >
                                                    associate additional devices
                                                </a>{' '}
                                                with your user.
                                                <Tooltip
                                                    title={
                                                        <>
                                                            <p>
                                                                Any additional devices you add will be added to your
                                                                list of personal devices and will receive a notification
                                                                preview when you choose the ‘Personal Preview’
                                                                destination or someone else sends a ‘Team Preview'.
                                                            </p>
                                                        </>
                                                    }
                                                >
                                                    <QuestionCircleOutlined className="info-icon" />
                                                </Tooltip>
                                            </p>
                                        ) : (
                                            <>
                                                <p>Notifications are blocked in the current browser.</p>
                                                <p>
                                                    Please manually reset your browser permissions, reload the page, and
                                                    re-register this device for notification previews.
                                                </p>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <p>Notifications have been dismissed in the current browser.</p>
                                            <p>
                                                Please reload the page, and re-register this device for notification
                                                previews.
                                            </p>
                                        </>
                                    )
                                ) : (
                                    <p>Notifications are not configured in the current browser.</p>
                                )
                            ) : (
                                <p>Notifications are not supported in the current browser.</p>
                            )}
                        </Skeleton>
                    </Well>
                </div>
            </div>
        )
    }
}
