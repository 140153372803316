import * as React from 'react'
import { PageHeader } from '@pushly/aqe/lib/components'
import { Container } from 'typescript-ioc/es5'
import * as querystring from 'query-string'
import './style/user.scss'
import { AppState } from '../../stores/app'
import { AppService, UserService } from '../../services'
import { UserDataForm } from '../../components/user-data-form/user-data-form'
import { getClassNames } from '../../_utils/classnames'
import { asCaslSubject, CurrentUserCan } from 'stores/app-ability'
import { AbilityAction } from '../../enums/ability-action.enum'
import { AccessDenied403 } from '../../components/403/403'
import { tryParseInt } from '../../_utils/try-parse'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { ModuleLoadingScreen } from '../../components/module-loading-screen/module-loading-screen'
import { ICaslUser } from '../../interfaces/casl-user'
import { observer } from 'mobx-react'
import { UserDto } from 'dtos/user'
import { ErrorPage } from '../../components/error-page/error-page'

interface IUserState {
    loading: boolean
    user?: UserDto
}

@observer
export class User extends React.Component<any, IUserState> {
    public state: IUserState = {
        loading: true,
    }

    protected appState: AppState
    protected appService: AppService
    protected userService: UserService

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.userService = Container.get(UserService)
    }

    public componentDidMount() {
        this.loadRequestedUser()
    }

    public render() {
        const caslUser: ICaslUser = { id: this.requestedUserId }
        if (this.state.user) {
            caslUser.userTypeId = this.state.user.userTypeId
        }

        if (this.requestedDomainIdFilter) {
            caslUser.domainId = this.requestedDomainIdFilter
        } else if (this.requestedAccountIdFilter) {
            caslUser.accountId = this.requestedAccountIdFilter
        }

        const caslUserIdentity = asCaslSubject(SubjectEntity.USER, caslUser)

        return (
            <React.Fragment>
                <ModuleLoadingScreen />

                {!this.state.loading && (
                    <CurrentUserCan do={AbilityAction.UPDATE} on={caslUserIdentity} passThrough>
                        {(allowed) =>
                            !allowed ? (
                                <ErrorPage errorCode={403} showAction={true} />
                            ) : (
                                <div className={getClassNames('page', 'single-user')}>
                                    <div className={getClassNames('page-wrapper')}>
                                        <PageHeader
                                            title="User"
                                            append={<span>ID: {this.requestedUserId}</span>}
                                            onTitleSet={this.appService.customizeTabTitle}
                                        />

                                        <UserDataForm
                                            userId={this.requestedUserId}
                                            accountId={this.requestedAccountIdFilter}
                                            domainId={this.requestedDomainIdFilter}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </CurrentUserCan>
                )}
            </React.Fragment>
        )
    }

    protected get requestedUserId(): number {
        return tryParseInt(this.props.match.params.userId)
    }

    protected get requestedAccountIdFilter(): number {
        const qs = querystring.parse(this.props.location.search)
        let accountId: any = qs.account_id || qs.accountId
        if (accountId) {
            accountId = parseInt(accountId, 10)
        }
        return accountId
    }

    protected get requestedDomainIdFilter(): number {
        const qs = querystring.parse(this.props.location.search)
        let domainId: any = qs.domain_id || qs.domainId
        if (domainId) {
            domainId = parseInt(domainId, 10)
        }
        return domainId
    }

    protected async loadRequestedUser() {
        const shouldLoad = !!this.requestedUserId && !this.state.user
        this.setState({ loading: shouldLoad })

        if (shouldLoad) {
            const { ok, data } = await this.userService.fetchById(this.requestedUserId, {
                showLoadingScreen: true,
            })

            if (ok) {
                this.setState({ user: data })
            }
        }

        this.setState({ loading: false })
    }
}
