import * as React from 'react'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { NotificationScheduleDto } from '../../features/notifications/dtos/notification-schedule-dto'
import {
    CloseOutlined,
    EditOutlined,
    EyeOutlined,
    Loading3QuartersOutlined,
    LoadingOutlined,
    NumberOutlined,
    PercentageOutlined,
    TeamOutlined,
    ThunderboltOutlined,
    UsergroupDeleteOutlined,
    UserOutlined,
    WarningOutlined,
} from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Modal, Tooltip } from 'antd'
import * as moment from 'moment-timezone'
import { Moment } from 'moment'
import { BASE_DATE_FORMAT, BASE_TIME_ONLY_FORMAT, BASE_TIME_ONLY_FORMAT_WITHOUT_TZ } from '../../constants'
import { numberWithCommas, numberWithPercent, preventAll, titleCase } from '../../_utils/utils'
import { NotificationScheduleService } from '../../services/notification-schedule'
import { StatusType } from '../../enums/status-type'
import { InsightsService } from '../../services/insights'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { NoTranslate } from 'components/no-translate/no-translate'

interface IRecentNotifCardProps {
    className?: string
    loading?: boolean
    schedule: NotificationScheduleDto
    onChange?: (schedule: NotificationScheduleDto) => any
}

interface IRecentNotifCardState {
    open?: boolean
    loadingStats?: boolean
    stats: any
}

export class RecentNotificationCard extends React.Component<IRecentNotifCardProps, IRecentNotifCardState> {
    public readonly defaultClassName: string = 'sw-mv-recent-notification-card'

    protected appState: AppState
    protected appService: AppService
    protected scheduleService: NotificationScheduleService
    protected insightsService: InsightsService

    public constructor(props: IRecentNotifCardProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.scheduleService = Container.get(NotificationScheduleService)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            stats: {},
        }
    }

    public async componentDidMount() {
        await this.fetchStats()
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()} onClick={this.handleOpenStateChange}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('meta-wrapper')}>
                        <div className={this.buildClassName('meta')}>
                            <div className={this.buildClassName('date')}>{this.renderSendDate()}</div>
                        </div>
                    </div>

                    <div className={this.buildClassName('summary-wrapper')}>
                        <div className={this.buildClassName('summary')}>
                            <div className={this.buildClassName('title')}>{this.props.schedule.webTemplate.title}</div>
                            <div className={this.buildClassName('body')}>{this.props.schedule.webTemplate.body}</div>
                        </div>
                    </div>

                    {this.showStats && (
                        <div className={this.buildClassName('stats-wrapper')}>
                            <div className={this.buildClassName('stats')}>
                                <div className={this.buildClassName('clicks-wrapper')}>
                                    <div className={this.buildClassName('clicks')}>{this.renderClicks()}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className={this.buildClassName('details-wrapper')}>
                    <div className={this.buildClassName('details')}>
                        {this.renderAudience()}
                        {this.renderTestSummary()}
                        {this.renderIdInformation()}
                        {this.renderActions()}
                    </div>
                </div>

                <div className={this.buildClassName('loader-wrapper')}>
                    <div className={this.buildClassName('loader')}>
                        <LoadingOutlined spin={true} />
                    </div>
                </div>
            </div>
        )
    }

    protected renderStatusIcon(): React.ReactNode {
        const status = this.schedule.status

        let dateIcon = 'carry-out'
        switch (status) {
            case StatusType.SCHEDULED.name:
                dateIcon = 'clock-circle'
                break
            case StatusType.CANCELLED.name:
            case StatusType.CANCELLING.name:
                dateIcon = 'close-square'
                break
            default:
                break
        }

        return (
            <span className="status-icon">
                <LegacyIcon type={dateIcon} />
            </span>
        )
    }

    protected renderSendDate(): React.ReactNode {
        const dateString = this.schedule.deliverySpec.sendDateUtc || this.schedule.createdAt
        const date: Moment = moment.tz(dateString, this.appState.currentDomain!.timezone)
        const status = this.schedule.status

        let statusText
        switch (status) {
            case StatusType.COMPLETED.name:
                statusText = 'DELIVERED'
                break
            case StatusType.QUEUED.name:
                statusText = 'DELIVERING'
                break
            default:
                statusText = status
                break
        }

        return (
            <>
                {this.renderStatusIcon()}

                <span>
                    <b>{titleCase(statusText)}</b>
                </span>
                <span>|</span>

                <span>
                    {date.format(BASE_DATE_FORMAT)} at
                    {this.schedule.deliverySpec.window === NotificationDeliveryWindow.TIMEZONE && (
                        <span>
                            {' '}
                            {date.format(BASE_TIME_ONLY_FORMAT_WITHOUT_TZ)}
                            <span className="stz-label">
                                {' '}
                                <Tooltip title="Subscriber's Time Zone">STZ</Tooltip>
                            </span>
                        </span>
                    )}
                    {this.schedule.deliverySpec.window === NotificationDeliveryWindow.STANDARD && (
                        <span> {date.format(BASE_TIME_ONLY_FORMAT)}</span>
                    )}
                </span>
            </>
        )
    }

    protected renderAudience(): React.ReactNode {
        let audienceType: any = this.schedule.getLegacyAudienceType()
        if (audienceType === 'SEGMENTS') {
            const isUsingDefaultAll =
                this.schedule.audience.segments!.length === 1 && this.schedule.audience.segments![0].isDefault
            const isUsingExcludedSegments =
                !!this.schedule.audience.excludedSegments && this.schedule.audience.excludedSegments.length > 0

            if (isUsingDefaultAll && !isUsingExcludedSegments) {
                audienceType = <span>All Subscribers</span>
            } else {
                const segments = this.schedule.audience.segments || []
                const excludedSegments = this.schedule.audience.excludedSegments || []
                const nonDefaultSegments = isUsingDefaultAll ? segments : segments.filter((s) => !s.isDefault)

                audienceType = (
                    <>
                        {nonDefaultSegments.length > 0 &&
                            (isUsingDefaultAll ? (
                                <span>All Subscribers</span>
                            ) : (
                                <React.Fragment>
                                    <span>{nonDefaultSegments.length}</span>
                                    <span>Included {nonDefaultSegments.length === 1 ? 'Segment' : 'Segments'}</span>
                                </React.Fragment>
                            ))}
                        {excludedSegments.length > 0 && (
                            <React.Fragment>
                                <span className="details-divider">|</span>
                                <span className="excluded-icon">
                                    <UsergroupDeleteOutlined />
                                </span>
                                <span>{excludedSegments.length}</span>
                                <span>Excluded {excludedSegments.length === 1 ? 'Segment' : 'Segments'}</span>
                            </React.Fragment>
                        )}
                    </>
                )
            }
        } else {
            audienceType = <span>Subscriber IDs</span>
        }

        return (
            <div className={this.buildClassName('audience-wrapper')}>
                <span className="included-icon">
                    <TeamOutlined />
                </span>

                {audienceType}
            </div>
        )
    }

    protected renderTestSummary(): React.ReactNode {
        return this.schedule.abTest ? (
            <div className={this.buildClassName('test-wrapper')}>
                <span>
                    <PercentageOutlined />
                </span>

                <span>A/B Test:</span>

                <span>{this.schedule.abTest.name}</span>
            </div>
        ) : (
            ''
        )
    }

    protected renderIdInformation(): React.ReactNode {
        return (
            <div className={this.buildClassName('author-wrapper')}>
                <span>
                    <NumberOutlined />
                </span>

                <span>ID</span>

                <span>{this.schedule.notificationId}</span>

                <span className="details-divider">|</span>

                <span>
                    <UserOutlined />
                </span>

                <span>
                    Created by <NoTranslate>{(this.schedule as any).createdByUserName}</NoTranslate>
                </span>
            </div>
        )
    }

    protected renderActions(): React.ReactNode {
        const schedule = this.schedule
        const hasTest = !!schedule.abTest
        const statusIsScheduled = schedule.status === StatusType.SCHEDULED.name
        const isInformedCancellable =
            schedule.deliverySpec.window === NotificationDeliveryWindow.INFORMED &&
            (schedule.status === StatusType.DELIVERING.name || schedule.status === StatusType.SCHEDULING.name)

        return (
            <div
                className={this.buildClassName(
                    'actions',
                    !this.currentUserIsReadOnly && !hasTest && statusIsScheduled ? 'has-actions' : 'no-actions',
                )}
            >
                <div className={this.buildClassName('actions-wrapper')}>
                    {statusIsScheduled && (
                        <div className={this.buildClassName('action', 'edit-action')} onClick={this.handleEdit}>
                            <div className={this.buildClassName('action-wrapper')}>
                                <EditOutlined />
                                Edit
                            </div>
                        </div>
                    )}
                    {(statusIsScheduled || isInformedCancellable) && (
                        <div className={this.buildClassName('action', 'cancel-action')} onClick={this.confirmCancel}>
                            <div className={this.buildClassName('action-wrapper')}>
                                <CloseOutlined />
                                Cancel
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    protected renderImpressions(): React.ReactNode {
        const impressions = this.isLoading ? 0 : this.state.stats.impressions || 0

        return (
            <div className={this.buildClassName('stat-wrapper')}>
                <span>
                    <EyeOutlined />
                </span>

                <span className="value">
                    {this.isLoading ? (
                        <>
                            <Loading3QuartersOutlined spin={true} />
                        </>
                    ) : (
                        <>{numberWithCommas(impressions)}</>
                    )}
                </span>

                <span>Impressions</span>
            </div>
        )
    }

    protected renderClicks(): React.ReactNode {
        const clicks = this.isLoading ? 0 : this.state.stats.clicks || 0
        const ctr = this.isLoading ? 0 : this.state.stats.ctr_decimal || 0

        return (
            <div className={this.buildClassName('stat-wrapper')}>
                <span>
                    <ThunderboltOutlined />
                </span>

                <span className="value">
                    {this.isLoading ? (
                        <>
                            <Loading3QuartersOutlined spin={true} />
                        </>
                    ) : (
                        <>{!ctr ? '--' : numberWithPercent(ctr)}</>
                    )}
                </span>

                <span>CTR</span>

                <span>|</span>

                <span className="value">
                    {this.isLoading ? (
                        <>
                            <Loading3QuartersOutlined spin={true} />
                        </>
                    ) : (
                        <>{numberWithCommas(clicks)}</>
                    )}
                </span>

                <span>Clicks</span>
            </div>
        )
    }

    protected get isLoading(): boolean {
        return this.props.loading || this.state.loadingStats !== false
    }

    protected get schedule(): NotificationScheduleDto {
        return this.props.schedule
    }

    protected get status(): string {
        return this.schedule.status
    }

    protected get showStats(): boolean {
        return this.status === StatusType.COMPLETED.name
    }

    protected get isOpen(): boolean {
        return this.state.open === true
    }

    protected get currentUserIsReadOnly(): boolean {
        const notifAbilityIdentity = this.appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.NOTIFICATION)

        return (
            (this.appState.abilityStore.can(AbilityAction.READ, notifAbilityIdentity) &&
                this.appState.abilityStore.cannot(AbilityAction.CREATE, notifAbilityIdentity) &&
                this.appState.abilityStore.cannot(AbilityAction.UPDATE, notifAbilityIdentity)) ??
            false
        )
    }

    protected handleOpenStateChange = (): void => {
        this.setState(({ open }) => ({ open: !open }))
    }

    protected handleEdit = async (ev: React.MouseEvent<HTMLDivElement>): Promise<void> => {
        preventAll(ev)

        this.appService.routeWithinDomain(`/notifications/edit/${this.schedule.notificationId}`)
    }

    protected confirmCancel = async (ev: React.MouseEvent<HTMLDivElement>): Promise<any> => {
        preventAll(ev)

        Modal.confirm({
            okText: 'Confirm',
            title: 'Confirm Notification Cancellation',
            content: 'Are you sure you wish to cancel this notification?',
            icon: <WarningOutlined />,
            onOk: this.handleCancel,
        })
    }

    protected handleCancel = async (): Promise<any> => {
        await this.scheduleService.cancelSchedule(this.schedule, `mv.rnc.cancel#${this.schedule.id}`)

        if (this.props.onChange) {
            this.props.onChange({
                ...this.schedule,
                status: StatusType.CANCELLED.name,
            } as any)
        }
    }

    protected async fetchStats(): Promise<void> {
        const update: any = { loadingStats: true }
        this.setState({ ...update })

        const stats = await this.insightsService.fetch(
            {
                date_preset: 'lifetime',
                date_increment: 'lifetime',
                action_attribution: 'send_time',
                entity: 'notifications',
                breakdowns: ['notification'],
                fields: [
                    'notification.id',
                    'deliveries',
                    'impressions',
                    'clicks',
                    'delivery_rate_decimal',
                    'ctr_decimal',
                ],
                filters: [
                    { field: 'domain.id', operator: 'eq', value: this.appState.currentDomain!.id },
                    { field: 'notification.id', operator: 'in', value: this.props.schedule.notificationId },
                ],
            },
            false,
            `rnc.fetchStats(${this.props.schedule.id})`,
        )

        update.loadingStats = false

        if (!!stats && stats.length > 0) {
            update.stats = stats[0]
        }

        this.setState(update)
    }

    protected buildClassName(className: string, extras?: string): string {
        className = `${this.defaultClassName}-${className}`
        if (!!extras) className = `${className} ${extras}`
        return className
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [
            this.defaultClassName,
            this.isLoading ? 'loading' : 'loaded',
            this.isOpen ? 'open' : 'closed',
        ]

        if (this.props.className) classNames.push(this.props.className)

        const status: string = this.schedule.status
        const statusClass = `status-${status.toLowerCase()}`
        classNames.push(statusClass)

        return classNames.join(' ')
    }
}
