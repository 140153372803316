export class RuleBuilderOperator {
    public readonly _key: string
    public readonly longDisplay: string

    public constructor(
        public readonly value: string,
        public readonly display: string,
        options?: {
            key?: string
            longDisplay?: string
        },
    ) {
        this._key = options?.key ?? this.value
        this.longDisplay = options?.longDisplay ?? this.display
    }

    public clone(options?: { value?: string; display?: string; longDisplay?: string }) {
        return new RuleBuilderOperator(options?.value ?? this.value, options?.display ?? this.display, {
            key: this._key,
            longDisplay: options?.longDisplay ?? options?.display ?? this.longDisplay,
        })
    }
}

export const baseOperators = {
    bool_eq: new RuleBuilderOperator('eq', 'is', { key: 'bool_eq' }),
    bool_neq: new RuleBuilderOperator('neq', 'is not', { key: 'bool_neq' }),
    eq: new RuleBuilderOperator('eq', '==', { longDisplay: 'is equal to' }),
    neq: new RuleBuilderOperator('neq', '!=', { longDisplay: 'does not equal' }),
    like: new RuleBuilderOperator('like', 'like', { longDisplay: 'is like' }),
    not_like: new RuleBuilderOperator('not_like', 'is not like'),
    contains: new RuleBuilderOperator('contains', 'contains'),
    not_contains: new RuleBuilderOperator('not_contains', 'does not contain'),
    a_contains: new RuleBuilderOperator('a_contains', 'equals'),
    a_not_contains: new RuleBuilderOperator('a_not_contains', 'does not equal'),
    prefix: new RuleBuilderOperator('prefix', 'starts with'),
    not_prefix: new RuleBuilderOperator('not_prefix', 'does not start with'),
    gt: new RuleBuilderOperator('gt', '>', { longDisplay: 'is greater than' }),
    gte: new RuleBuilderOperator('gte', '>=', { longDisplay: 'is greater than or equal to' }),
    lt: new RuleBuilderOperator('lt', '<', { longDisplay: 'is less than' }),
    lte: new RuleBuilderOperator('lte', '<=', { longDisplay: 'is less than or equal to' }),
    between: new RuleBuilderOperator('between', 'is between'),
    not_between: new RuleBuilderOperator('not_between', 'is not between'),
    date_in: new RuleBuilderOperator('date_in', 'is within'),
    date_not_in: new RuleBuilderOperator('date_not_in', 'is not within'),
    exists: new RuleBuilderOperator('exists', 'is present'),
    not_exists: new RuleBuilderOperator('not_exists', 'is not present'),
    geo_includes: new RuleBuilderOperator('geo_includes', 'in geo polygon'),
    geo_excludes: new RuleBuilderOperator('geo_excludes', 'not in geo polygon'),
}

export const stringOperators = [baseOperators.eq, baseOperators.neq, baseOperators.contains, baseOperators.not_contains]

export const booleanOperators = [baseOperators.bool_eq]

export const stringArrOperators = [baseOperators.a_contains, baseOperators.a_not_contains]

export const selectOperators = [baseOperators.eq, baseOperators.neq, baseOperators.contains, baseOperators.not_contains]

export const numericOperators = [baseOperators.eq, baseOperators.neq, baseOperators.gte, baseOperators.lte]

export const dateOperators = [
    baseOperators.gte,
    baseOperators.date_in,
    baseOperators.date_not_in,
    baseOperators.eq,
    baseOperators.neq,
    baseOperators.gt,
    baseOperators.lt,
    baseOperators.lte,
]

export const betterDateOperators = [
    baseOperators.gte,
    baseOperators.eq,
    baseOperators.neq,
    baseOperators.gt,
    baseOperators.lt,
    baseOperators.lte,
]

export const rangeOperators = [baseOperators.between, baseOperators.not_between]

export const existenceOperators = [baseOperators.exists, baseOperators.not_exists]

export const customOperators = [
    baseOperators.eq,
    baseOperators.neq,
    baseOperators.gte,
    baseOperators.lte,
    baseOperators.exists,
    baseOperators.not_exists,
    baseOperators.a_contains,
    baseOperators.a_not_contains,
]
